import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const QRCode = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.QRCode}>
    {({ color }) => (
      <>
        <g>
          <rect width="27" height="27" rx="6.14" fill="none" />
          <rect
            x="0.31"
            y="0.31"
            width="26.39"
            height="26.39"
            rx="5.83"
            fill="none"
            stroke="#fff"
            strokeWidth="0.61"
          />
        </g>
        <g>
          <g>
            <rect x="2.45" y="2.45" width="22.09" height="22.09" fill="none" />
            <g>
              <path
                d="M12.65,5.57H6V12.2h6.63Zm-1.1,5.52H7.13V6.68h4.42Z"
                transform="translate(-2.82 -2.35)"
                fill="#fff"
              />
              <path
                d="M6,25.78h6.67V19.15H6Zm1.11-5.55h4.41v4.42H7.15Z"
                transform="translate(-2.82 -2.35)"
                fill="#fff"
              />
              <path
                d="M19.66,5.57V12.2h6.59V5.57Zm5.52,5.52H20.76V6.68h4.42Z"
                transform="translate(-2.82 -2.35)"
                fill="#fff"
              />
              <path
                d="M8.58,14.42H6V17H9.83V15.68H8.58Z"
                transform="translate(-2.82 -2.35)"
                fill="#fff"
              />
              <rect x="12.07" y="14.6" width="2.52" height="2.53" fill="#fff" />
              <rect x="7.01" y="12.07" width="2.53" height="1.26" fill="#fff" />
              <path
                d="M17.42,20.74H14.89V22h1.26v1.26h1.26Z"
                transform="translate(-2.82 -2.35)"
                fill="#fff"
              />
              <path
                d="M13.63,14.42v1.26H12.36v1.26h2.53V14.42Z"
                transform="translate(-2.82 -2.35)"
                fill="#fff"
              />
              <rect x="13.33" y="8.27" width="1.26" height="2.53" fill="#fff" />
              <path
                d="M17.41,15.68v1.26h2.53V14.42H16.15v1.26Z"
                transform="translate(-2.82 -2.35)"
                fill="#fff"
              />
              <rect x="14.6" y="20.91" width="2.53" height="2.53" fill="#fff" />
              <rect
                x="12.07"
                y="20.91"
                width="1.26"
                height="2.53"
                fill="#fff"
              />
              <path
                d="M17.42,9.36V6.84H16.15V5.57H14.89v5h1.26V9.36Z"
                transform="translate(-2.82 -2.35)"
                fill="#fff"
              />
              <rect
                x="18.39"
                y="20.91"
                width="1.26"
                height="2.53"
                fill="#fff"
              />
              <rect
                x="17.12"
                y="18.39"
                width="2.52"
                height="1.26"
                fill="#fff"
              />
              <path
                d="M23.73,18.21v1.26H25v1.27h1.25V18.21Z"
                transform="translate(-2.82 -2.35)"
                fill="#fff"
              />
              <path
                d="M25,22H23.73v3.78h2.52V23.26H25Z"
                transform="translate(-2.82 -2.35)"
                fill="#fff"
              />
              <path
                d="M18.68,18.21v1.26h3.79V17H19.94v1.26Z"
                transform="translate(-2.82 -2.35)"
                fill="#fff"
              />
              <path
                d="M21.21,14.42v1.26h2.52v1.26h2.52V14.42Z"
                transform="translate(-2.82 -2.35)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </>
    )}
  </CreateSvgIcon>
);
