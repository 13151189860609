import React, { useCallback, useState } from "react";
import { makeStyles } from "hooks/makeStyles";
import { useTranslation } from "react-i18next";
import { Typography, Icons, Box, IconButton } from "ui/shared";
import { Friend } from "lib";
import { getInterestsLabels } from "utils";
import { ChatFlowStore } from "../stores/chat-flow-store";
import countries from "lib/countries.json";
import { darkTheme } from "lib/theme";
import clsx from "clsx";
import EmblaCarouselMini from "ui/components/embla-carousel-mini/embla-carousel-mini";
import { lighten } from "utils/fade";

export interface ProfileModalProps {
  friend?: Friend;
  visible: boolean;
  isTop?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    boxSizing: "border-box",
  },
  profile: {
    width: "100%",
    margin: 0,
    marginLeft: 0,
    backgroundColor: theme.colors.background.paper.topbar,
    borderRadius: 10,
    position: "relative",
    overflow: "hidden",
  },
  headerItem: {
    width: 40,
  },
  infoWrapper: {
    padding: theme.spacing(5),
  },
  infoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(6.25),
  },
  avatarContainer: {
    position: "relative",
  },
  avatarContainerItems: {
    position: "absolute",
    paddingLeft: 9,
    bottom: -32,
    display: "flex",
    flexWrap: "nowrap",
    width: "100%",
  },
  avatarContainerItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  karmaWrapper: {},
  vipWrapper: {},
  itemWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  buttonWrap: {
    flexGrow: 1,
    alignItems: "flex-end",
    paddingRight: theme.spacing(4.25),
  },
  iconWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.colors.background.paper.lighter,
    width: 34,
    height: 34,
    borderRadius: "50%",
  },
  likeButton: {
    backgroundColor: theme.colors.background.paper.lighter,
    "&:hover": {
      backgroundColor: lighten(theme.colors.background.paper.lighter, 0.2),
    },
    "&$active": {
      backgroundColor: theme.colors.primary,
      "&:hover": {
        backgroundColor: lighten(theme.colors.primary, 0.2),
      },
    },
  },
  gender: {
    textTransform: "capitalize",
  },
  breakText: {
    wordBreak: "break-all",
    whiteSpace: "pre-line",
  },
  active: {},
}));

export const ProfileModal = (props: ProfileModalProps) => {
  const { friend, visible } = props;
  const { t, i18n } = useTranslation("chat");
  const css = useStyles();
  const [chatFlowStore] = useState(new ChatFlowStore());

  /**************************************************/

  const ageRender = useCallback((items: number[]) => {
    const newItems = items
      .filter((item, i) => items.indexOf(item) === i)
      .join("-");

    if (items[0] !== 0 && items[1] !== 0) {
      return newItems;
    } else {
      return;
    }
  }, []);

  const wrappedLabel = useCallback((el: React.ReactElement) => {
    return <Box mb={2.5}>{el}</Box>;
  }, []);

  /**************************************************/

  if (!friend) return null;

  return (
    <div className={css.container} data-cy="chat-profile-modal">
      <div className={css.avatarContainer}>
        {visible ? (
          <EmblaCarouselMini
            userId={friend.friendUserId}
            profileAvatar={friend.avatar}
          />
        ) : null}
        <div className={css.avatarContainerItems}>
          <div className={css.avatarContainerItem}>
            {friend.level === "vip" ? (
              <div className={css.vipWrapper} data-cy="chat-profile-modal-vip">
                <Icons.VIP width={43} height={49} />
              </div>
            ) : (
              ""
            )}
          </div>

          <div
            className={css.avatarContainerItem}
            style={{ margin: "8px 7.5px 0 0" }}
          >
            {friend.friend && !friend.official ? (
              <div className={clsx(css.itemWrap, css.iconWrap)}>
                <Icons.Star width={24} height={24} className={css.star} />
              </div>
            ) : (
              ""
            )}
            {friend.official ? (
              <div className={clsx(css.itemWrap, css.iconWrap)}>
                <Icons.Star width={20} height={20} className={css.star} />
              </div>
            ) : (
              ""
            )}
          </div>

          <div className={css.avatarContainerItem} style={{ marginTop: 8 }}>
            <div className={css.karmaWrapper}>
              <div className={css.iconWrap}>
                <Icons.NotActiveKarma
                  width={28}
                  height={28}
                  secondaryColor="#655285"
                />
              </div>
              <Typography size="body(14px)" data-cy="chat-profile-modal-karma">
                {friend.activeKarma}
              </Typography>
            </div>
          </div>
          <div
            className={clsx(css.avatarContainerItem, css.buttonWrap)}
            style={{ marginTop: 8 }}
          >
            <IconButton
              onClick={() => {
                !friend.likeByMe
                  ? chatFlowStore.setLike(friend._id)
                  : chatFlowStore.setUnLike(friend._id);
              }}
              className={clsx(css.likeButton, {
                [css.active]: friend.likeByMe,
              })}
              data-cy="chat-profile-modal-like-button"
            >
              <Icons.Like
                width={40}
                height={40}
                color={"transparent"}
                secondaryColor={darkTheme.colors.common.white}
                data-cy="chat-profile-modal-like-icon"
                viewBox="0 0 42 42"
              />
            </IconButton>
          </div>
        </div>
      </div>

      <div className={css.infoWrapper}>
        <div className={css.infoContainer}>
          {wrappedLabel(
            <Typography size="caption(12px)" color="secondary">
              {t("all:friend_profile_login")}:
            </Typography>
          )}
          <Typography data-cy="chat-profile-modal-username">
            {friend.friendLogin}
          </Typography>
        </div>

        {friend.gender ? (
          <div className={css.infoContainer}>
            {wrappedLabel(
              <Typography size="caption(12px)" color="secondary">
                {t("all:friend_profile_gender")}:
              </Typography>
            )}
            <Typography
              className={css.gender}
              data-cy="chat-profile-modal-gender"
            >
              {t(`all:gender_${friend.gender}`)}
            </Typography>
          </div>
        ) : null}

        {friend.age &&
        friend.age.length &&
        friend.age.every((value) => value !== 0) ? (
          <div className={css.infoContainer}>
            {wrappedLabel(
              <Typography size="caption(12px)" color="secondary">
                {t("all:friend_profile_age")}:
              </Typography>
            )}
            <Typography data-cy="chat-profile-modal-age">
              {ageRender(friend.age)}
            </Typography>
          </div>
        ) : null}

        {friend.country ? (
          <div className={css.infoContainer}>
            {wrappedLabel(
              <Typography size="caption(12px)" color="secondary">
                {t("all:friend_profile_country")}:
              </Typography>
            )}
            <Typography data-cy="chat-profile-modal-country">
              {(countries as any)[friend.country][i18n.language.split("-")[0]]}{" "}
              {friend.country}
            </Typography>
          </div>
        ) : null}

        {friend.about ? (
          <div className={css.infoContainer}>
            {wrappedLabel(
              <Typography size="caption(12px)" color="secondary">
                {t("all:friend_profile_about")}:
              </Typography>
            )}
            <Typography
              data-cy="chat-profile-modal-about"
              className={css.breakText}
            >
              {friend.about}
            </Typography>
          </div>
        ) : null}

        {friend.interests && friend.interests.length ? (
          <div className={css.infoContainer}>
            {wrappedLabel(
              <Typography size="caption(12px)" color="secondary">
                {t("all:friend_profile_interests")}:
              </Typography>
            )}
            <Typography
              data-cy="chat-profile-modal-interests"
              className={css.breakText}
            >
              {getInterestsLabels(friend.interests)}
            </Typography>
          </div>
        ) : null}
      </div>
    </div>
  );
};
