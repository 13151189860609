import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Dislike = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Dislike}>
    {({ color, secondaryColor }) => (
      <>
        <g id="Path_2318" data-name="Path 2318">
          <path
            d="M28.49,14.12a3.42,3.42,0,0,0-.63-2,3.7,3.7,0,0,0,0-1.75A2.59,2.59,0,0,0,27,8.94a3.53,3.53,0,0,0-.77-2.74,3.66,3.66,0,0,0-3-1.19h-2A13.08,13.08,0,0,0,19,5.22c-.77.14-1.4.28-1.9.42s-1.05.35-1.82.56a9.5,9.5,0,0,1-2.45.63,1.27,1.27,0,0,0-.7.28.88.88,0,0,0-.27.7v9.67a.69.69,0,0,0,.27.64.87.87,0,0,0,.63.27c.21,0,.63.35,1.19.91a19,19,0,0,1,1.54,1.82c.7.84,1.19,1.47,1.55,1.82a2.9,2.9,0,0,1,.49.71c.13.27.21.56.27.7a6.7,6.7,0,0,1,.21.91,7.06,7.06,0,0,0,.21.91,5.41,5.41,0,0,0,.27.77c.15.26.32.52.5.77a.86.86,0,0,0,.7.27A6,6,0,0,0,21,27.84a2.69,2.69,0,0,0,.91-.42,2.51,2.51,0,0,0,.63-.63,5.71,5.71,0,0,0,.35-.7,4.85,4.85,0,0,0,.21-.77,2.73,2.73,0,0,0,.07-.7v-.56A6,6,0,0,0,23,22.94a7.3,7.3,0,0,0-.27-.91,4.4,4.4,0,0,0-.42-.84c0-.07-.07-.14-.14-.28a.79.79,0,0,1-.13-.35c-.07-.07-.07-.21-.14-.35h4.27a3.06,3.06,0,0,0,2.11-.84,2.1,2.1,0,0,0,.83-1.82,3.32,3.32,0,0,0-.84-2.24A4.71,4.71,0,0,0,28.49,14.12Z"
            transform="translate(-2.11 -1.68)"
            fill={color}
          />
          <path
            d="M21.2,6.38a10.49,10.49,0,0,0-1.93.19c-.71.13-1.29.26-1.73.38s-1.06.35-1.83.56a13.84,13.84,0,0,1-2.46.67v8.91A4.53,4.53,0,0,1,15,18.34a19.5,19.5,0,0,1,1.64,1.93l.56.68c.38.47.68.83.88,1A3.75,3.75,0,0,1,18.78,23a4.21,4.21,0,0,1,.25.62.69.69,0,0,1,0,.1l0,.07,0,.09A6.83,6.83,0,0,1,19.4,25a5.71,5.71,0,0,0,.15.7l.06.19a2.59,2.59,0,0,0,.15.4,1.92,1.92,0,0,0,.18.29,6.48,6.48,0,0,0,.67-.08l.21-.08.19-.07,0,0,.11-.07a1.09,1.09,0,0,0,.23-.23,1.8,1.8,0,0,1,.11-.2l.11-.2a4.39,4.39,0,0,0,.16-.61V25a1.67,1.67,0,0,0,0-.37v-.56a4.33,4.33,0,0,0-.11-.85,5.18,5.18,0,0,0-.17-.56c0-.07-.05-.13-.07-.19a3.71,3.71,0,0,0-.29-.57l-.2-.32V21.5a2.07,2.07,0,0,1-.21-.48c0-.1-.06-.19-.08-.25l-1-1.92h6.48a1.73,1.73,0,0,0,1.2-.5l.08-.07.1-.06a.9.9,0,0,0,.2-.67,2,2,0,0,0-.5-1.33l-.5-.56.21-.72a3.23,3.23,0,0,0,.16-.82,2,2,0,0,0-.36-1.21l-.39-.52.16-.63a2.32,2.32,0,0,0,0-1.11,1.25,1.25,0,0,0-.38-.66l-.53-.45,0-.69a2.13,2.13,0,0,0-.42-1.73l0,0,0,0c-.14-.17-.57-.69-2-.69h-2M21.2,5h2a3.65,3.65,0,0,1,3,1.19A3.53,3.53,0,0,1,27,8.94a2.62,2.62,0,0,1,.84,1.4,3.7,3.7,0,0,1,0,1.75,3.33,3.33,0,0,1,.63,2,4.27,4.27,0,0,1-.21,1.19,3.3,3.3,0,0,1,.84,2.24,2.09,2.09,0,0,1-.84,1.83,3.09,3.09,0,0,1-2.11.84H21.9c.07.14.07.28.14.35s.07.21.14.35.14.21.14.28a4.6,4.6,0,0,1,.42.84A7.27,7.27,0,0,1,23,23a6.13,6.13,0,0,1,.14,1.12v.56a2.9,2.9,0,0,1-.07.7,5.68,5.68,0,0,1-.21.77,6.48,6.48,0,0,1-.35.7,2.67,2.67,0,0,1-.63.63,2.57,2.57,0,0,1-.91.43,6.28,6.28,0,0,1-1.26.14.9.9,0,0,1-.7-.28,8.85,8.85,0,0,1-.49-.78,5.32,5.32,0,0,1-.28-.77,6,6,0,0,1-.21-.91,6,6,0,0,0-.21-.91c-.07-.14-.14-.42-.28-.7a2.51,2.51,0,0,0-.49-.7c-.35-.35-.85-1-1.55-1.82A19.22,19.22,0,0,0,14,19.31c-.56-.56-1-.91-1.19-.91a.86.86,0,0,1-.63-.28.69.69,0,0,1-.28-.64V7.82a.91.91,0,0,1,.28-.71,1.24,1.24,0,0,1,.7-.28,10,10,0,0,0,2.45-.63c.77-.21,1.41-.42,1.83-.56s1.12-.28,1.89-.42A12,12,0,0,1,21.2,5Z"
            transform="translate(-2.11 -1.68)"
            fill={secondaryColor}
          />
        </g>
        <g id="Path_2319" data-name="Path 2319">
          <path
            d="M9.32,18.19a.9.9,0,0,0,.27-.71V7.89a1,1,0,0,0-.93-1H4.21a.93.93,0,0,0-.71.28,1,1,0,0,0-.28.7v9.67a1,1,0,0,0,.28.71.88.88,0,0,0,.7.27H8.62A1.32,1.32,0,0,0,9.32,18.19Z"
            transform="translate(-2.11 -1.68)"
            fill={color}
          />
          <path
            d="M8.23,17.17V8.27H4.59v8.9H8.23m.39,1.37H4.21a.93.93,0,0,1-.71-.28.9.9,0,0,1-.28-.7V7.89a1,1,0,0,1,.28-.71.93.93,0,0,1,.71-.28H8.62a.9.9,0,0,1,.7.28.92.92,0,0,1,.28.71v9.59a.91.91,0,0,1-.28.71A1.33,1.33,0,0,1,8.62,18.54Z"
            transform="translate(-2.11 -1.68)"
            fill={secondaryColor}
          />
        </g>
      </>
    )}
  </CreateSvgIcon>
);
