import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Verified = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Verified}>
    {({ color }) => (
      <g>
        <g>
          <path
            d="M16.25,3,5.65,7.15V17.39s.61,7.14,10.6,11.08c10-3.94,10.6-11.08,10.6-11.08V7.15Z"
            transform="translate(0 0.02)"
            fill="#6db400"
          />
          <rect x="2.5" y="2.02" width="27.5" height="27.5" fill="none" />
          <path
            d="M21.4,10.24l-6.72,7.39-3.76-3.76-1.82,2,5.58,5.45,8.72-9.2Z"
            transform="translate(0 0.02)"
            fill="#fff"
          />
        </g>
      </g>
    )}
  </CreateSvgIcon>
);
