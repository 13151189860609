import "emoji-mart/css/emoji-mart.css";
import React, { useEffect, useRef, useCallback, useState } from "react";
import { observer } from "mobx-react";
import { makeStyles } from "hooks/makeStyles";
import { ChatActionPanel } from "./action-panel/action-panel";
import { ChatTopBar } from "./chat-topbar";
import { Chat } from "./chat";
import { chatsStore } from "global-stores/chats-store";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import { globalUIStore } from "global-stores/global-ui-store";
import { useTracker } from "hooks/useTracker";
import { Friends } from "collections/friends";
import { DropZone } from "ui/shared/dropzone/dropzone";
import { getDropZoneStore } from "ui/shared/dropzone/dropzone-store";
import { useEventCallback } from "hooks/useEventCallback";
import ChatsSidebar from "./chats-sidebar";
import chatBg from "assets/svg/chat-bg.png";
import { DefaultPanel, Modal } from "ui/shared";
import { MODALS } from "lib/constants";
import TranslateSettingsModal from "./modals/translate-settings";
import TranslateHideAlertModal from "./modals/translate-hide-alert-modal";
import { useTranslation } from "react-i18next";
import { Friend } from "lib";

export interface ChatsProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "minmax(250px, 30%) 1fr",
  },
  chatContainer: {
    position: "relative",
    overflow: "hidden",
  },
  main: {
    width: "100%",
    height: "100%",
    position: "relative",
    backgroundImage: `url(${chatBg})`,
    backgroundColor: theme.colors.background.chat,
  },
  chat: {
    width: "inherit",
    height: "inherit",
    display: "grid",
    gridTemplateRows: "60px 1fr auto",
    position: "relative",
  },
  findButton: {
    marginTop: theme.spacing(3),
  },
  hidden: {
    visibility: "hidden",
  },
}));

export interface ChatContext {
  width: number;
  height: number;
}

export const ChatContext = React.createContext<ChatContext | null>(null);

export const Chats = observer(
  (props: ChatsProps & RouteComponentProps<{ id: string }>) => {
    const css = useStyles();
    const history = useHistory();
    const { t } = useTranslation("all");

    const wrapper = useRef<HTMLDivElement>(null);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const [chatContext, setChatContext] = useState<ChatContext | null>(null);

    const { match } = props;

    const { selectedFriend } = useTracker(() => {
      return {
        selectedFriend: Friends.findOne({ _id: match.params.id }),
      };
    }, [match.params.id]);

    const scrollToBottom = useCallback(
      (smooth?: boolean) => {
        const ref = wrapper as React.RefObject<HTMLDivElement>;

        if (ref.current) {
          if (smooth) {
            ref.current.scrollTo({
              top: ref.current.scrollHeight - ref.current.clientHeight,
              behavior: "smooth",
            });
          } else {
            ref.current.scrollTop =
              ref.current.scrollHeight - ref.current.clientHeight;
          }
        }
      },
      [wrapper]
    );

    useEffect(() => {
      if (selectedFriend) {
        chatsStore.setSelectedFriend(selectedFriend);
      }
    }, [selectedFriend]);

    /**************************************************/

    const updateChatContext = useEventCallback(() => {
      if (chatContainerRef && chatContainerRef.current) {
        const width = chatContainerRef.current.clientWidth;
        const height = chatContainerRef.current.clientHeight;

        if (
          width &&
          height &&
          (width !== chatContext?.width || height !== chatContext?.height)
        ) {
          setChatContext({
            width,
            height,
          });
        }
      }
    });

    const selectChatWithFriend = useCallback(
      (friend: Friend) => {
        const path = `/chats/${friend._id}`;
        if (history.location.pathname === path) {
          scrollToBottom(true);
          return;
        }
        globalUIStore.setSwitchingChat(true);

        setTimeout(() => {
          chatsStore.clearSelectedChat();
          history.push(path);
          globalUIStore.setShouldFocusChatTextarea(true);
        }, 0);
      },
      [scrollToBottom, history]
    );

    /**************************************************/

    useEffect(() => {
      setTimeout(() => {
        updateChatContext();
      }, 500);
    }, [updateChatContext]);

    useEffect(() => {
      window.addEventListener("resize", updateChatContext);
    }, [updateChatContext]);

    const dropzoneStore =
      selectedFriend && getDropZoneStore(selectedFriend._id);

    if (globalUIStore.loading)
      return (
        <div className={css.root}>
          <div className={css.side}></div>
        </div>
      );

    const loading = globalUIStore.loading || chatsStore.loading;

    if (!loading && match.params.id && !selectedFriend)
      return <Redirect to="/chats" />;

    return (
      <div className={css.root}>
        <ChatsSidebar
          loading={loading}
          chats={chatsStore.friends}
          selectedFriendId={match.params.id}
          selectChatCallback={selectChatWithFriend}
        />
        <React.Suspense fallback={null}>
          <div ref={chatContainerRef} className={css.main}>
            <div className={css.chat}>
              {selectedFriend && chatContext ? (
                <ChatContext.Provider value={chatContext}>
                  <ChatTopBar
                    friend={selectedFriend}
                    loading={!globalUIStore.animationReady || !selectedFriend}
                  />
                  {chatsStore.selectedChatStore ? (
                    <div className={css.chatContainer}>
                      <DropZone store={dropzoneStore} />
                      <React.Suspense fallback={null}>
                        <Chat
                          ref={wrapper}
                          friend={selectedFriend}
                          scrollToBottom={scrollToBottom}
                          loading={!globalUIStore.animationReady}
                          store={chatsStore.selectedChatStore}
                        />
                      </React.Suspense>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <ChatActionPanel
                    loading={!globalUIStore.animationReady}
                    scrollToBottom={scrollToBottom}
                    dropzoneStore={dropzoneStore}
                    hidden={selectedFriend.finish}
                  />
                  <Modal modalId={MODALS.TRANSLATE_SETTINGS}>
                    {(visible, className) => (
                      <DefaultPanel
                        title={t("all:trans_settings_title")}
                        className={className}
                        withoutScroll
                      >
                        <TranslateSettingsModal friend={selectedFriend} />
                      </DefaultPanel>
                    )}
                  </Modal>
                  <Modal modalId={MODALS.TRANSLATE_HIDE_ALERT}>
                    {(visible, className) => (
                      <DefaultPanel className={className} withoutScroll>
                        <TranslateHideAlertModal />
                      </DefaultPanel>
                    )}
                  </Modal>
                </ChatContext.Provider>
              ) : null}
            </div>
          </div>
        </React.Suspense>
      </div>
    );
  }
);
