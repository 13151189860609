import React, { useCallback, useEffect } from "react";
import { makeStyles } from "hooks/makeStyles";
import { DefaultPanel, Button, AnimatedPage, Loader } from "ui/shared";
import { useTranslation } from "react-i18next";
import { MySearchItem } from "./my-search-item";
import { observer } from "mobx-react";
import { searchStore } from "../stores/search-store";
import { useHistory } from "react-router-dom";
import { loadDynamicScript } from "utils";
import { Meteor } from "meteor/meteor";
import { Pickup } from "lib";

export interface MySearchProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing(5.75)} ${theme.spacing(6)} ${theme.spacing(5)}`,
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    background: theme.colors.background.paper.main,
    alignItems: "flex-end",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    background: theme.colors.background.paper.main,
  },
  body: {
    boxShadow: theme.shadows.panel,
    marginBottom: theme.spacing(5),
    height: "auto",
  },
  createSearchBtn: {},
}));

export const MySearch = observer((props: MySearchProps) => {
  const { t } = useTranslation("all");
  const css = useStyles();
  const history = useHistory();

  /**************************************************/

  const handleCreateNewSearch = useCallback(() => {
    searchStore.setWantToCreateNewSearch(true);
    history.push("/search");
  }, [history]);

  const handleTogglePickup = useCallback((value: boolean, pickup: Pickup) => {
    if (value) {
      searchStore.enablePickup(pickup._id);
      return;
    }
    searchStore.disablePickup(pickup._id);
  }, []);

  /**************************************************/

  useEffect(() => {
    (window as any).onLoadCaptcha = () => {
      searchStore.setCaptchaReady(true);
    };
    (window as any).onCaptcha = (token: string) => {
      Meteor.call(
        "reCaptcha.checkWeb",
        { token },
        (err: Meteor.Error, res: any) => {
          console.log("reCaptcha check", err, res);
        }
      );
    };
    loadDynamicScript(
      "https://www.google.com/recaptcha/api.js?onload=onLoadCaptcha",
      "reCaptcha"
    );
  }, []);

  useEffect(() => {
    if (
      searchStore.loading != null &&
      !searchStore.loading &&
      !searchStore.pickups.length
    ) {
      history.push("/search");
    }
  });

  if (
    searchStore.loading == null ||
    searchStore.loading ||
    !searchStore.pickups.length
  )
    return <Loader />;

  return (
    <AnimatedPage animation="vertical" containerClassName={css.root}>
      <DefaultPanel title={t("all:search_title")} className={css.body}>
        {searchStore.pickups.map((pickup, index) => (
          <MySearchItem
            key={index}
            pickup={pickup}
            onTogglePickup={(value) => handleTogglePickup(value, pickup)}
          />
        ))}
      </DefaultPanel>
      <Button
        className={css.createSearchBtn}
        primary
        onClick={handleCreateNewSearch}
        data-cy="search.my.actions.createNew"
      >
        {t("all:search_button_new")}
      </Button>
    </AnimatedPage>
  );
});
