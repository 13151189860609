import React, { useState, useRef, useEffect, useCallback } from "react";
import { makeStyles } from "hooks/makeStyles";
import { OverlayPositioner } from "./overlay-positioner";
import { useEventCallback } from "hooks/useEventCallback";
import { KEY_CODES } from "lib";
import uniqueId from "lodash/uniqueId";
import { findDOMNode } from "react-dom";
import { Trigger } from "./trigger";
import { OverlayPositionerProps } from "./overlay-positioner";
import clsx from "clsx";

export interface DropdownProps extends Partial<OverlayPositionerProps> {
  render?: (close: (force?: boolean) => void) => React.ReactNode;
  items?: any[];
  on?: "hover" | "click";
  renderItem?: (
    item: any,
    index: number,
    forceFocus: boolean
  ) => React.ReactElement;
  withTail?: boolean;
  withArrow?: boolean;
  overlayClassName?: string;
  onClose?: () => void;
  /** Use visibility instead of display none */
  useVisibility?: boolean;
  children: React.ReactElement;
  className?: string;
  onTriggerClick?: (e: React.MouseEvent) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
}));

export const Dropdown = (props: DropdownProps) => {
  const [isOpen, setOpen] = useState(false);
  const [forceFocus, setForceFocus] = useState(false);
  const itemElements = useRef<{ [key: number]: HTMLElement }>({});
  const focusedItem = useRef<number | undefined>();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const triggerRef = React.useRef<HTMLButtonElement>(null);
  const overlayRef = React.useRef<HTMLDivElement>(null);
  const {
    render,
    items,
    renderItem,
    on = "click",
    onClose,
    children,
    className,
    onTriggerClick,
    ...passthrough
  } = props;

  const css = useStyles();
  const overlayId = uniqueId("overlay-");

  /**************************************************/

  const close = useCallback(
    (force?: boolean) => {
      if (force || focusedItem.current == null) {
        setOpen(false);
        setForceFocus(false);
      }
      if (onClose) {
        onClose();
      }
    },
    [setOpen, setForceFocus, onClose, focusedItem.current]
  );

  const handleOverlayKeyDown = useEventCallback((e: KeyboardEvent) => {
    let key = e.which || e.keyCode;

    switch (key) {
      case KEY_CODES.RETURN:
      case KEY_CODES.ESC:
        e.preventDefault();
        close(false);
        if (triggerRef && triggerRef.current) {
          triggerRef.current.focus();
        }
        break;
    }
  });

  const handleTriggerKeyUp = useEventCallback((e: KeyboardEvent) => {
    let key = e.which || e.keyCode;

    switch (key) {
      case KEY_CODES.UP:
      case KEY_CODES.DOWN:
        e.preventDefault();
        open();
        break;
    }
  });

  const handleTriggerClick = useEventCallback((e: React.MouseEvent) => {
    if (isOpen) {
      close(true);
    } else {
      open();
    }
    if (onTriggerClick) {
      onTriggerClick(e);
    }
  });

  const handleOverlayOnBlur = useEventCallback(
    (e: React.FocusEvent<HTMLDivElement>) => {
      setTimeout(() => {
        if (
          !focusedItem.current &&
          containerRef.current &&
          !containerRef.current.contains(document.activeElement)
        ) {
          close(false);
        }
      }, 50);
    }
  );

  const focusOverlay = useCallback(() => {
    setTimeout(() => {
      overlayRef.current && overlayRef.current.focus();
    }, 0);
  }, [overlayRef.current]);

  const focusFirstItem = useEventCallback(() => {
    if (items && renderItem && itemElements.current[0]) {
      setTimeout(() => {
        itemElements.current[0].focus();
      }, 0);
    } else {
      focusOverlay();
    }
  });

  const focusLastItem = useCallback(() => {
    if (items && itemElements.current[items.length - 1]) {
      setTimeout(() => itemElements.current[items.length - 1].focus(), 0);
    }
  }, [items, itemElements.current]);

  const focusPreviousItem = useCallback(() => {
    if (focusedItem.current == null) return;

    if (focusedItem.current === 0) {
      focusLastItem();
    } else {
      itemElements.current[focusedItem.current - 1].focus();
    }
  }, [focusLastItem, focusedItem.current, itemElements.current]);

  const focusNextItem = useCallback(() => {
    if (focusedItem.current == null) return;

    if (items && focusedItem.current === items.length - 1) {
      focusFirstItem();
    } else {
      itemElements.current[focusedItem.current + 1].focus();
    }
  }, [items, focusFirstItem, focusedItem.current, itemElements.current]);

  const setFocusToTrigger = useCallback(() => {
    if (triggerRef && triggerRef.current) {
      triggerRef.current.focus();
    }
  }, [triggerRef.current]);

  const open = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleItemKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      var flag = false;

      if (
        e.ctrlKey ||
        e.altKey ||
        e.metaKey ||
        e.keyCode === KEY_CODES.SPACE ||
        e.keyCode === KEY_CODES.RETURN
      ) {
        return;
      }

      if (e.shiftKey) {
        if (e.keyCode === KEY_CODES.TAB) {
          setFocusToTrigger();
          close(true);

          flag = true;
        }
      } else {
        switch (e.keyCode) {
          case KEY_CODES.ESC:
            setFocusToTrigger();
            close(true);
            flag = true;
            break;

          case KEY_CODES.UP:
            focusPreviousItem();
            setForceFocus(true);
            flag = true;
            break;

          case KEY_CODES.DOWN:
            focusNextItem();
            setForceFocus(true);
            flag = true;
            break;

          case KEY_CODES.HOME:
          case KEY_CODES.PAGEUP:
            focusFirstItem();
            flag = true;
            break;

          case KEY_CODES.END:
          case KEY_CODES.PAGEDOWN:
            focusLastItem();
            flag = true;
            break;

          case KEY_CODES.TAB:
            setFocusToTrigger();
            close(true);
            break;

          default:
            break;
        }
      }

      if (flag) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [
      setFocusToTrigger,
      close,
      focusPreviousItem,
      setForceFocus,
      focusNextItem,
      focusFirstItem,
      focusLastItem,
    ]
  );

  const handleItemClick = useCallback(
    (e: React.MouseEvent) => {
      setFocusToTrigger();
      close(true);
    },
    [setFocusToTrigger, close]
  );

  const handleItemFocus = useCallback((index: number) => {
    return (e: React.KeyboardEvent) => {
      focusedItem.current = index;
    };
  }, []);

  const handleItemBlur = useCallback((e: React.KeyboardEvent) => {
    focusedItem.current = undefined;
    setTimeout(() => close(), 150);
  }, []);

  const storeItemElementRef = useCallback((index: number) => {
    return (ref: HTMLElement | React.Component<any> | null) => {
      if (ref) {
        const element =
          ref instanceof HTMLElement ? ref : (findDOMNode(ref) as HTMLElement);
        itemElements.current[index] = element;
      } else {
        delete itemElements.current[index];
      }
    };
  }, []);

  const renderItems = useCallback(
    (items: any[]) => {
      return items.map((item, index) => {
        const Item = renderItem!(item, index, forceFocus);
        return React.cloneElement(Item, {
          role: "menu-item",
          tabIndex: -1,
          key: item.id,
          ref: storeItemElementRef(index),
          onKeyDown: handleItemKeyDown,
          onClick: (e: React.MouseEvent) => {
            if (Item.props.onClick) {
              Item.props.onClick(e);
            }
            handleItemClick(e);
          },
          onFocus: handleItemFocus(index),
          onBlur: handleItemBlur,
        });
      });
    },
    [
      items,
      renderItem,
      storeItemElementRef,
      handleItemKeyDown,
      handleItemClick,
      handleItemFocus,
      handleItemBlur,
    ]
  );

  /**************************************************/

  const triggerProps = {
    "aria-controls": overlayId,
    "aria-haspopup": true,
    "aria-expanded": isOpen ? true : undefined,
    ref: triggerRef,
    onClick: handleTriggerClick,
  };

  useEffect(() => {
    if (isOpen) {
      focusFirstItem();
    }
  }, [isOpen, focusFirstItem]);

  return (
    <div
      className={clsx(css.root, {
        [className!]: !!className,
      })}
      ref={containerRef}
    >
      <Trigger {...triggerProps}>{children}</Trigger>
      <OverlayPositioner
        visible={isOpen}
        open={open as any}
        close={close}
        overlayId={overlayId}
        trigger={triggerRef}
        onOverlayKeyDown={handleOverlayKeyDown as any}
        onTriggerKeyUp={handleTriggerKeyUp as any}
        onOverlayBlur={handleOverlayOnBlur}
        on={on}
        ref={overlayRef}
        {...passthrough}
      >
        {render
          ? render(close)
          : items && renderItem
          ? renderItems(items)
          : null}
      </OverlayPositioner>
    </div>
  );
};
