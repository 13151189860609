import React from "react";
import { motion } from "framer-motion";

interface GamepadProps {
  className?: string;
}

const Gamepad = ({ className }: GamepadProps) => {
  return (
    <motion.svg
      width="108"
      height="93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      initial={{
        y: 40,
        opacity: 0,
        scale: 0.8,
      }}
      animate={{
        y: 0,
        opacity: 1,
        scale: 1,
      }}
      transition={{
        duration: 0.3,
        delay: 0.05,
        type: "spring",
        damping: 10,
        stiffness: 100,
      }}
      className={className}
    >
      <path
        d="M102.517 54.456c.683-5.559.683-11.301-1.475-16.705-1.996-5.08-5.788-9.476-10.175-13.137-4.498-3.575-9.144-6.765-14.136-9.595-.944-.519-1.987-1.05-3.03-1.58-1.043-.532-2.086-1.063-3.042-1.483-5.152-2.345-10.565-4.219-16.125-5.707-5.485-1.278-11.266-1.787-16.572-.423-5.64 1.625-10.284 4.984-14.38 8.813-7.873 7.497-13.645 16.762-17.977 26.608-1.123 2.582-2.097 5.586-.813 8.262 1.307 2.478 4.25 3.947 7.117 4.4 5.72 1.005 11.297-.93 16.39-3.025 5.094-2.095 10.323-4.475 16.066-4.475 7.975-.13 15.562 3.724 20.302 10.25 3.458 4.656 4.586 10.236 5.95 15.541 1.351 5.405 3.087 10.958 7.227 14.993 2.075 1.967 5.006 3.536 7.783 3.071 2.976-.44 4.707-3.049 6.116-5.495 5.375-9.315 9.446-19.495 10.774-30.313Z"
        fill="#183D49"
      />
      <path
        d="M102.517 54.456c.683-5.559.683-11.301-1.475-16.705-1.996-5.08-5.788-9.476-10.175-13.137-4.498-3.575-9.144-6.765-14.136-9.595-.944-.519-1.987-1.05-3.03-1.58-1.043-.532-2.086-1.063-3.042-1.483-5.152-2.345-10.565-4.219-16.125-5.707-5.485-1.278-11.266-1.787-16.572-.423-5.64 1.625-10.284 4.984-14.38 8.813-7.873 7.497-13.645 16.762-17.977 26.608-1.123 2.582-2.097 5.586-.813 8.262.735 1.4 1.915 2.452 3.244 3.119 1.805-2.398 3.81-4.772 5.988-6.922 3.812-3.965 8.17-7.46 13.314-9.146a29.62 29.62 0 0 1 14.87-.491 91.214 91.214 0 0 1 14.225 4.769c.956.42 1.813.827 2.67 1.234.856.408 1.8.927 2.645 1.433 4.322 2.546 8.409 5.365 12.236 8.656a29.401 29.401 0 0 1 8.474 12.224c1.685 5.144 1.4 10.75.531 16.185a70.1 70.1 0 0 1-2.002 8.923 7.654 7.654 0 0 0 4.442.747c2.976-.44 4.706-3.05 6.115-5.496 5.574-9.29 9.645-19.47 10.973-30.288Z"
        fill="#45385B"
      />
      <path
        d="M82.65 64.324c-1.735-4.747-4.634-9.032-8.474-12.224s-7.914-6.11-12.236-8.656c-.845-.506-1.69-1.013-2.645-1.433-.845-.506-1.8-.926-2.67-1.234a91.214 91.214 0 0 0-14.225-4.77 27.549 27.549 0 0 0-14.87.492c-5.243 1.674-9.614 5.268-13.414 9.134-2.079 2.162-4.083 4.536-5.988 6.921 1.23.655 2.496 1.012 3.774 1.27 5.72 1.004 11.297-.93 16.39-3.025 5.094-2.095 10.323-4.476 16.066-4.476 7.975-.129 15.562 3.724 20.302 10.25 3.458 4.657 4.587 10.236 5.95 15.542 1.364 5.306 3.088 10.958 7.227 14.992.994.928 2.112 1.67 3.242 2.313a70.12 70.12 0 0 0 2.003-8.923c.956-5.323 1.253-11.03-.432-16.173Z"
        fill="#655285"
      />
      <path
        d="M48.94 13.422c-.52.944-2.764 1.172-4.874.309-2.11-.864-3.54-2.35-3.01-3.392.532-1.043 2.665-1.184 4.875-.309 2.21.876 3.54 2.35 3.009 3.392ZM53.86 21.279c-.519.943-2.764 1.172-4.874.308-2.11-.864-3.54-2.35-3.01-3.392.532-1.043 2.665-1.184 4.875-.308 2.21.875 3.54 2.348 3.009 3.392ZM41.314 16.802c-.519.944-2.751 1.073-4.874.308-2.123-.764-3.54-2.348-3.01-3.391.532-1.043 2.665-1.185 4.875-.309 2.21.876 3.54 2.35 3.009 3.392ZM45.32 23.969c-.52.944-2.752 1.072-4.874.308-2.123-.764-3.54-2.349-3.01-3.392.532-1.043 2.665-1.184 4.874-.308 2.21.876 3.54 2.349 3.01 3.392ZM79.518 33.255c-3.358 3.514-2 10.26 3.03 15.066 5.032 4.807 11.832 5.855 15.19 2.342 3.357-3.514 2-10.26-3.032-15.066-5.031-4.807-11.831-5.855-15.188-2.342Z"
        fill="#F6E79E"
      />
      <path
        d="M88.844 34.8c-.531 1.043.651 2.9 2.614 4.15 1.962 1.248 3.96 1.392 4.478.448.52-.943-.651-2.9-2.614-4.149-1.962-1.248-3.947-1.492-4.478-.45ZM79.6 35.5c-.53 1.044.652 2.901 2.614 4.15 1.963 1.248 3.96 1.393 4.479.45.519-.945-.651-2.902-2.614-4.15-1.962-1.249-3.947-1.492-4.478-.45ZM90.648 42.943c-.531 1.043.651 2.901 2.614 4.15 1.962 1.248 3.96 1.393 4.478.449.52-.944-.651-2.901-2.614-4.15-1.962-1.248-3.947-1.492-4.478-.449ZM82.503 43.971c-.531 1.043.651 2.901 2.614 4.15 1.962 1.248 3.96 1.393 4.478.449.531-1.043-.651-2.901-2.614-4.15-1.962-1.248-3.947-1.492-4.478-.449Z"
        fill="#CFAB00"
      />
      <path
        d="M57.286 34.426c-.764 2.123-3.801 3.06-6.693 2-2.893-1.061-4.796-3.612-4.032-5.735.765-2.122 3.801-3.06 6.694-1.999 2.892 1.06 4.795 3.611 4.031 5.734Z"
        fill="#7D718B"
      />
      <path
        d="M55.334 31.654c-.344 1.166-1.906 1.579-3.458 1.086-1.551-.492-2.485-1.917-2.152-2.984.333-1.068 2.006-1.567 3.458-1.087 1.452.48 2.584 1.93 2.152 2.985Z"
        fill="#DBCCED"
      />
      <path
        d="M75.273 45.679c-1.522 1.727-4.611 1.449-6.985-.555-2.373-2.005-3.1-5.116-1.677-6.856 1.423-1.74 4.611-1.449 6.985.556 2.373 2.004 3.199 5.128 1.677 6.855Z"
        fill="#7D718B"
      />
      <path
        d="M74.356 42.706c-.903.796-2.492.6-3.66-.55-1.168-1.151-1.47-2.8-.665-3.608.804-.808 2.491-.6 3.66.55 1.168 1.151 1.482 2.7.665 3.608Z"
        fill="#DBCCED"
      />
      <path
        d="M67.896 25.058c8.63 4.385 18.019 4.228 23.554.172-.186-.123-.36-.346-.546-.47-4.498-3.575-9.144-6.765-14.136-9.594-.944-.52-1.987-1.05-3.03-1.581-1.043-.531-2.087-1.062-3.043-1.482-5.251-2.358-10.663-4.231-16.223-5.72-.187-.124-.484-.16-.683-.184.065 6.858 5.477 14.475 14.107 18.86Z"
        fill="#655285"
      />
    </motion.svg>
  );
};

export default Gamepad;
