import React, { useCallback } from "react";
import { makeStyles } from "hooks/makeStyles";
import { Button } from "../button/button";
import { Icons } from "../icons";
import { Typography } from "../typography/typography";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export interface VIPButtonProps {
  onClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    alignItems: "center",
    borderColor: theme.colors.primary,
    padding: `${theme.spacing(1.5)} 0 ${theme.spacing(1.5)} ${theme.spacing(
      2.5
    )}`,
  },
  text: {
    color: theme.colors.primary,
  },
}));

export const VIPButton = ({ onClick }: VIPButtonProps) => {
  const css = useStyles();
  const { t } = useTranslation("all");
  const history = useHistory();

  const clickHandler = useCallback(() => {
    history.push("/vip");
    if (onClick) {
      onClick();
    }
  }, []);

  return (
    <Button secondary className={css.button} onClick={clickHandler}>
      <Typography font="light" className={css.text}>
        {t("all:profile_vip_button")}
      </Typography>
      <Icons.VIP height={20} />
    </Button>
  );
};
