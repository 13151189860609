import React, { useRef, useEffect, useState, useCallback } from "react";
import { makeStyles } from "hooks/makeStyles";
import clsx from "clsx";
import { useEventCallback } from "hooks/useEventCallback";
import { fade } from "utils";
import { SliderLabel } from "./slider-label";
import { Typography } from "..";

const KEY_CODES = Object.freeze({
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  pageUp: 33,
  pageDown: 34,
  end: 35,
  home: 36,
});

interface SliderProps {
  value: number | number[];
  step?: number;
  disabled?: boolean;
  displayLabels?: "on" | "off";
  showBottomLabel?: boolean;
  max?: number;
  min?: number;
  name?: string;
  onChange?: (
    e: React.MouseEvent,
    value: number | number[],
    activeIndex: number
  ) => void;
  "data-cy"?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 2,
    display: "inline-block",
    padding: "12px 0",
    touchAction: "none",
    boxSizing: "content-box",
    position: "relative",
    cursor: "pointer",
    color: theme.colors.primary,
  },
  rail: {
    width: "100%",
    height: 4,
    display: "block",
    opacity: 0.4,
    position: "absolute",
    borderRadius: 2,
    backgroundColor: theme.colors.purple.main,
  },
  track: {
    display: "block",
    position: "absolute",
    height: 4,
    borderRadius: 2,
    backgroundColor: "currentColor",
  },
  thumb: {
    position: "absolute",
    width: 10,
    height: 10,
    marginTop: -3,
    marginLeft: -5,
    boxSizing: "border-box",
    borderRadius: "50%",
    outline: 0,
    backgroundColor: "currentColor",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&$focusVisible,&:hover": {
      boxShadow: `0px 0px 0px 10px ${fade(theme.colors.primary, 0.56)}`,
      "@media (hover: none)": {
        boxShadow: "none",
      },
    },
    "&$active": {
      boxShadow: `0px 0px 0px 14px ${fade(theme.colors.primary, 0.56)}`,
    },
    "&$disabled": {
      width: 8,
      height: 8,
      marginLeft: -4,
      marginTop: -3,
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
  bottomLabelContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
  },
  active: {},
  disabled: {},
  focusVisible: {},
}));

export const Slider = (props: SliderProps) => {
  const {
    value,
    disabled,
    displayLabels,
    onChange,
    step = 1,
    min = 0,
    max = 100,
    name,
    showBottomLabel,
  } = props;
  // const pointerId = useRef();
  const [active, setActive] = React.useState(-1);
  const [open, setOpen] = React.useState(-1);
  const css = useStyles();

  const previousIndex = React.useRef<number>();

  const sliderRef = useRef<HTMLDivElement>(null);

  const [valueDerived, setValueState] = useState(value);

  const range = Array.isArray(valueDerived);

  let values: number[] = range
    ? [...(valueDerived as number[])].slice().sort(asc)
    : [valueDerived as number];
  values = values.map((value) => fit(value as number, min, max));

  const [focusVisible, setFocusVisible] = React.useState(-1);

  /**************************************************/

  const setValueIndex = useCallback(
    ({ values, source, newValue, index }: any) => {
      if (values[index] === newValue) {
        return source;
      }

      const output = [...values];
      output[index] = newValue;
      return output;
    },
    []
  );

  const findClosest = useCallback((values: number[], currentValue: number) => {
    const { index: closestIndex } = values.reduce(
      (acc: { distance: number; index: number } | null, value, index) => {
        const distance = Math.abs(currentValue - value);

        if (
          acc === null ||
          distance < acc.distance ||
          distance === acc.distance
        ) {
          return {
            distance,
            index,
          };
        }

        return acc;
      },
      null
    ) as any;
    return closestIndex;
  }, []);

  const getNewValue = useCallback(
    ({
      position,
      move = false,
      values: values2,
      source,
    }: {
      position: { x: number; y: number };
      move?: boolean;
      values: number[];
      source: number[];
    }) => {
      const { current: slider } = sliderRef;

      const { width, left } = slider!.getBoundingClientRect();
      let percent;

      percent = (position.x - left) / width;

      let newValue;
      newValue = percentToValue(percent, min, max);

      if (step) {
        newValue = roundValueToStep(newValue, step, min);
      }

      newValue = fit(newValue, min, max);

      let activeIndex = 0;

      if (range) {
        if (!move) {
          activeIndex = findClosest(values2, newValue);
        } else {
          activeIndex = previousIndex.current!;
        }

        const previousValue = newValue;
        newValue = setValueIndex({
          values: values2,
          source,
          newValue,
          index: activeIndex,
        })
          .slice()
          .sort(asc);
        activeIndex = newValue.indexOf(previousValue);
        previousIndex.current = activeIndex;
      }

      return { newValue, activeIndex };
    },
    [
      sliderRef.current,
      step,
      percentToValue,
      roundValueToStep,
      fit,
      findClosest,
      previousIndex.current,
      setValueIndex,
    ]
  );

  const handleFocus = useEventCallback((event: React.FocusEvent) => {
    const index = Number(event.currentTarget.getAttribute("data-index"));
    setFocusVisible(index);
    setOpen(index);
  });
  const handleBlur = useEventCallback(() => {
    if (focusVisible !== -1) {
      setFocusVisible(-1);
    }
    setOpen(-1);
  });
  const handleMouseOver = useEventCallback((event: React.MouseEvent) => {
    const index = Number(event.currentTarget.getAttribute("data-index"));
    setOpen(index);
  });
  const handleMouseLeave = useEventCallback(() => {
    setOpen(-1);
  });

  const cx = clsx(css.root);

  const trackOffset = valueToPercent(range ? values[0] : min, min, max);
  const trackLeap =
    valueToPercent(values[values.length - 1], min, max) - trackOffset;

  const trackStyle = {
    left: `${trackOffset}%`,
    width: `${trackLeap}%`,
  };

  const focusThumb = useCallback(
    ({ sliderRef, activeIndex, setActive }: any) => {
      if (
        !sliderRef.current.contains(document.activeElement) ||
        Number(document.activeElement!.getAttribute("data-index")) !==
          activeIndex
      ) {
        sliderRef.current
          .querySelector(`[role="slider"][data-index="${activeIndex}"]`)
          .focus();
      }

      if (setActive) {
        setActive(activeIndex);
      }
    },
    [setActive]
  );

  const handleKeyDown = useEventCallback((event) => {
    const index = Number(event.currentTarget.getAttribute("data-index"));
    const value = values[index];
    const tenPercents = (max - min) / 10;
    let newValue;

    switch (event.keyCode) {
      case KEY_CODES.home:
        newValue = min;
        break;
      case KEY_CODES.end:
        newValue = max;
        break;
      case KEY_CODES.pageUp:
        newValue = value + tenPercents;
        break;
      case KEY_CODES.pageDown:
        newValue = value - tenPercents;
        break;
      case KEY_CODES.right:
      case KEY_CODES.up:
        newValue = value + step;
        break;
      case KEY_CODES.left:
      case KEY_CODES.down:
        newValue = value - step;
        break;
      default:
        return;
    }

    // Prevent scroll of the page
    event.preventDefault();

    if (step) {
      newValue = roundValueToStep(newValue, step, min);
    }

    newValue = fit(newValue, min, max);

    if (range) {
      const previousValue = newValue;
      newValue = setValueIndex({
        values,
        source: valueDerived,
        newValue,
        index,
      })
        .slice()
        .sort(asc);
      focusThumb({ sliderRef, activeIndex: newValue.indexOf(previousValue) });
    }

    setValueState(newValue);
    setFocusVisible(index);

    if (onChange) {
      onChange(event, newValue, index);
    }
  });

  const getPointerPosition = useCallback((e: React.PointerEvent) => {
    return {
      x: e.clientX,
      y: e.clientY,
    };
  }, []);

  const handleGestureStart = useEventCallback((e: React.PointerEvent) => {
    e.preventDefault();

    if (window.PointerEvent) {
      (e.target as any).setPointerCapture(e.pointerId);
    }

    const position = getPointerPosition(e);
    const { newValue, activeIndex } = getNewValue({
      position,
      values,
      source: valueDerived as number[],
    });
    focusThumb({ sliderRef, activeIndex, setActive });

    setValueState(newValue);

    if (onChange) {
      onChange(e, newValue, activeIndex);
    }
  });

  const handleGestureEnd = useEventCallback((e: React.PointerEvent) => {
    e.preventDefault();

    if (window.PointerEvent) {
      (e.target as any).releasePointerCapture(e.pointerId);
    }

    setActive(-1);
  });

  const handleGestureMove = useEventCallback((e: React.PointerEvent) => {
    if (active === -1) return;

    const position = getPointerPosition(e);

    if (!position) {
      return;
    }

    const { newValue, activeIndex } = getNewValue({
      position,
      move: true,
      values,
      source: valueDerived as number[],
    });

    focusThumb({ sliderRef, activeIndex, setActive });
    setValueState(newValue);

    if (onChange) {
      onChange(e, newValue, activeIndex);
    }
  });

  /**************************************************/

  useEffect(() => {
    setValueState(value);
  }, [value]);

  useEffect(() => {
    const { current: slider } = sliderRef;
    slider?.addEventListener("pointerdown", handleGestureStart as any);
    slider?.addEventListener("pointerup", handleGestureEnd as any);
    slider?.addEventListener("pointercancel", handleGestureEnd as any);
    slider?.addEventListener("pointermove", handleGestureMove as any);

    return () => {
      slider?.removeEventListener("pointerdown", handleGestureStart as any);
      slider?.removeEventListener("pointerup", handleGestureStart as any);
      slider?.removeEventListener("pointercancel", handleGestureStart as any);
      slider?.removeEventListener("pointermove", handleGestureStart as any);
    };
  }, [handleGestureStart, handleGestureEnd, handleGestureMove]);

  return (
    <div ref={sliderRef} className={cx} data-cy={props["data-cy"]}>
      <span className={css.rail} />
      <span className={css.track} style={trackStyle} />
      <input
        value={values.join(",")}
        name={name}
        type="hidden"
        data-cy={props["data-cy"] + ".input"}
      />
      {values.map((value, index) => {
        const percent = valueToPercent(value as number, min, max);
        const style = { left: `${percent}%` };

        return (
          <SliderLabel
            key={index}
            open={open === index || active === index || displayLabels === "on"}
            value={value}
          >
            <span
              className={clsx(css.thumb, {
                [css.active]: active === index,
                [css.disabled]: disabled,
                [css.focusVisible]: focusVisible === index,
              })}
              tabIndex={disabled ? undefined : 0}
              role="slider"
              aria-valuemin={min}
              aria-valuemax={max}
              aria-valuenow={value as number}
              style={style}
              data-index={index}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
              data-cy={props["data-cy"] + ".thumb" + index}
            />
          </SliderLabel>
        );
      })}
      {showBottomLabel ? (
        <div className={css.bottomLabelContainer}>
          <Typography size="caption(12px)" color="disabled">
            {values[0]} - {values[1]}
          </Typography>
        </div>
      ) : null}
    </div>
  );
};

function asc(a: number, b: number) {
  return a - b;
}

function fit(value: number, min: number, max: number) {
  return Math.min(Math.max(min, value), max);
}

function valueToPercent(value: number, min: number, max: number) {
  return ((value - min) * 100) / (max - min);
}

function percentToValue(percent: number, min: number, max: number) {
  return (max - min) * percent + min;
}

function roundValueToStep(value: number, step: number, min: number) {
  const nearest = Math.round((value - min) / step) * step + min;
  return Number(nearest.toFixed(getDecimalPrecision(step)));
}

function getDecimalPrecision(num: number) {
  // This handles the case when num is very small (0.00000001), js will turn this into 1e-8.
  // When num is bigger than 1 or less than -1 it won't get converted to this notation so it's fine.
  if (Math.abs(num) < 1) {
    const parts = num.toExponential().split("e-");
    const matissaDecimalPart = parts[0].split(".")[1];
    return (
      (matissaDecimalPart ? matissaDecimalPart.length : 0) +
      parseInt(parts[1], 10)
    );
  }

  const decimalPart = num.toString().split(".")[1];
  return decimalPart ? decimalPart.length : 0;
}
