import React, { useCallback } from "react";
import { DefaultPanel, Modal, Typography } from "ui/shared";
import { MODALS } from "lib/constants";
import { qrCodeStore } from "global-stores/qrcode-store";
import { userStore } from "global-stores/user-store";
import { ExportAccount } from "ui/pages/profile/modals/export-modal";
import { makeStyles } from "hooks/makeStyles";
import { InviteCode } from "ui/pages/profile/modals/invite-code-modal";
import { AvatarsModal } from "./avatars-modal/avatars-modal";
import { Gif } from "@giphy/react-components";
import { chatsStore } from "global-stores/chats-store";
import { IGif } from "@giphy/js-types";
import { observer } from "mobx-react";
import { InviteFriend } from "ui/pages/profile/modals/invite-friend-modal";
import { useTranslation } from "react-i18next";
import HelperModal from "./helper-modal/helper-modal";
import { modalManager } from "global-stores/modal-manager";
import clsx from "clsx";
import TranslateVIPAlertModal from "ui/pages/chats/modals/translate-vip-alert-modal";
import TranslateVerAlertModal from "ui/pages/chats/modals/translate-ver-alert-modal";

const useStyles = makeStyles((theme) => ({
  vipModalContainer: {
    "& > div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    "& > div > *:first-child": {
      marginBottom: theme.spacing(2),
    },
    "&:focus": {
      outline: "none",
    },
  },
  modal: {
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  imagePreview: {
    maxHeight: "80vh",
    objectFit: "contain",
  },
  iviteCodeContainer: {
    maxWidth: 268,
  },
}));

const Modals = observer(() => {
  const css = useStyles();
  const { t } = useTranslation("all");

  const profile = userStore.user && userStore.user.profile;
  const userName = userStore.user && userStore.user.username;

  /**************************************************/

  const handleCloseHelperModal = useCallback(() => {
    modalManager.clearStack();
  }, []);

  const handleCloseMediaModal = useCallback(() => {
    chatsStore.clearSelectedMedia();
  }, []);

  /**************************************************/

  return (
    <>
      <Modal modalId={MODALS.PROFILE_EXPORT_ACCOUNT}>
        {(_, className) => {
          return (
            <DefaultPanel title={t("all:export_title")} className={className}>
              <ExportAccount
                loading={qrCodeStore.loading}
                userName={userName}
              />
            </DefaultPanel>
          );
        }}
      </Modal>
      <Modal modalId={MODALS.PROFILE_INVITE}>
        {(visible, className) => (
          <DefaultPanel
            title={t("all:invite_set_placeholder")}
            className={clsx(className, css.iviteCodeContainer)}
          >
            <InviteCode visible={visible} />
          </DefaultPanel>
        )}
      </Modal>
      <Modal modalId={MODALS.PROFILE_INVITE_FRIENDS}>
        {(visible, className) => (
          <DefaultPanel
            title={t("all:invite_set_placeholder")}
            className={className}
          >
            <InviteFriend code={profile && profile.invite} />
          </DefaultPanel>
        )}
      </Modal>
      <Modal modalId={MODALS.HELPER} onClose={handleCloseHelperModal}>
        {() => <HelperModal />}
      </Modal>
      <AvatarsModal
        data-cy="profile.avatarsModal"
        modalId={MODALS.PROFILE_AVATARS}
      />
      <Modal modalId={MODALS.VIP_GUARD}>
        {() => (
          <div className={css.vipModalContainer}>
            <Typography>{t("all:alert_only_for_vip")}</Typography>
          </div>
        )}
      </Modal>
      <Modal
        modalId={MODALS.CHAT_FULLSIZE_MEDIA}
        onClose={handleCloseMediaModal}
      >
        {() =>
          chatsStore.selectedMedia && chatsStore.selectedMedia.media ? (
            chatsStore.selectedMedia.type === "GIF" ? (
              <Gif
                gif={chatsStore.selectedMedia.media as IGif}
                width={chatsStore.selectedMedia.fullSizeWidth}
                height={chatsStore.selectedMedia.fullSizeHeight}
                noLink
                hideAttribution
                borderRadius={10}
                className={css.modal}
                backgroundColor="transparent"
              />
            ) : (
              <div
                className={css.modal}
                style={{
                  width: chatsStore.selectedMedia.fullSizeWidth,
                  height: chatsStore.selectedMedia.fullSizeHeight,
                }}
              >
                <img
                  width="100%"
                  alt=""
                  src={chatsStore.selectedMedia.media as string}
                  className={css.imagePreview}
                />
              </div>
            )
          ) : (
            <></>
          )
        }
      </Modal>
      <Modal modalId={MODALS.TRANSLATE_VIP_ALERT}>
        {(visible, className) => (
          <DefaultPanel className={className} withoutScroll>
            <TranslateVIPAlertModal />
          </DefaultPanel>
        )}
      </Modal>
      <Modal modalId={MODALS.TRANSLATE_VER_ALERT}>
        {(visible, className) => (
          <DefaultPanel className={className} withoutScroll>
            <TranslateVerAlertModal />
          </DefaultPanel>
        )}
      </Modal>
    </>
  );
});

export default Modals;
