import { observable, action, computed } from "mobx";
import { Meteor } from "meteor/meteor";
import { Tracker } from "meteor/tracker";
import { globalUIStore } from "./global-ui-store";
import { callWithPromise, storage } from "utils";
import { encriptionStore } from "./encryption-store";

class UserStore {
  @observable user: Meteor.User | null = null;
  @observable loggingIn?: boolean;

  @action
  setProfileValue(name: string, value: any) {
    callWithPromise("users.setProfileValue", {
      name,
      value,
    });
  }

  subscribe() {
    Tracker.autorun(() => {
      this.user = Meteor.user();
      this.loggingIn = Meteor.loggingIn();

      // cleanup storage if no valid user
      if (this.loggingIn != null && !this.loggingIn && !this.user) {
        storage.clearAll();
        globalUIStore.setUserFilledAdditionalInfo(false);
        globalUIStore.setUserFilledEmail(false);
      }

      if (this.user) {
        encriptionStore.getKeys(true);
        globalUIStore.setLoading(false);
      }
    });
  }

  @computed
  get interestsLimit() {
    return (
      (this.user && this.user.profile && this.user.profile.interestsLimit) ?? 0
    );
  }

  @computed
  get isVerfiedUser() {
    return this.user && this.user.profile && this.user.profile.level === "ver";
  }

  @computed
  get isVIP() {
    return this.user && this.user.profile && this.user.profile.level === "vip";
  }
}

export const userStore = new UserStore();
