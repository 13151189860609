import React, { InputHTMLAttributes } from "react";
import { makeStyles } from "hooks/makeStyles";
import clsx from "clsx";
import { Typography } from "../typography/typography";
import { Box, BoxProps, pickBoxProps } from "../box/box";
import { WithTestData } from "lib";

interface InputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    WithTestData {
  fullWidth?: boolean;
  helperText?: string;
  error?: boolean;
  succeed?: boolean;
  errorClassName?: string;
  helperTextClassName?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  input: {
    padding: theme.spacing(3),
    borderRadius: 999,
    backgroundColor: theme.colors.background.paper.topbar,
    border: `2px solid ${theme.colors.textPrimary}`,
    color: theme.colors.textPrimary,
    "&:focus": {
      outline: "none",
      border: `2px solid ${theme.colors.primary}`,
    },
    "&::placeholder": {
      color: theme.colors.purple.main,
    },
    transition: "border .2s",
    fontSize: theme.sizes.typography.subtitle1,
    textAlign: "center",
  },
  fullWidth: {
    width: "100%",
  },
  error: {
    border: `2px solid ${theme.colors.red.main} !important`,
  },
  errorText: {
    color: theme.colors.red.main,
  },
  succeed: {
    border: `2px solid ${theme.colors.green.main} !important`,
  },
  succeedText: {
    color: theme.colors.green.main,
  },
}));

export const Input = React.forwardRef(
  (props: InputProps & BoxProps, ref: React.Ref<HTMLInputElement>) => {
    const css = useStyles();

    const { boxProps, other } = pickBoxProps(props);
    const {
      fullWidth,
      error,
      helperText,
      succeed,
      className,
      errorClassName,
      helperTextClassName,
      ...passthrough
    } = other;

    const cx = clsx(css.input, {
      [css.fullWidth]: fullWidth,
      [errorClassName || css.error]: !!error,
      [css.succeed]: !!succeed,
      [className!]: !!className,
    });

    const typocx = clsx({
      [css.errorText]: !!error,
      [css.succeedText]: !!succeed,
      [helperTextClassName]: !!helperTextClassName,
    });

    return (
      <Box {...boxProps} className={css.container}>
        <input ref={ref} {...passthrough} className={cx} />
        {helperText ? (
          <Box mt={2}>
            <Typography
              align="center"
              className={typocx}
              data-cy={`${props["data-cy"]}-helper-text`}
            >
              {helperText}
            </Typography>
          </Box>
        ) : null}
      </Box>
    );
  }
);
