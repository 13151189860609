import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { ToggleButton, ToggleButtonGroup } from "ui/shared";

interface GenderButtonGroupProps {
  labels?: string[];
  value: string | string[];
  onChange: (e: React.MouseEvent, value?: string | string[]) => void;
}

const GenderButtonGroup = observer(
  ({ value, onChange, labels }: GenderButtonGroupProps) => {
    const { t } = useTranslation("all");

    let actualLabels =
      labels && labels.length === 3
        ? labels
        : [
            t("all:main_button_never_mind"),
            t("all:main_button_gender_1"),
            t("all:main_button_gender_2"),
          ];

    return (
      <ToggleButtonGroup fullWidth exclusive value={value} onChange={onChange}>
        <ToggleButton value="" data-cy="basicSearch.gender.any">
          {actualLabels[0]}
        </ToggleButton>
        <ToggleButton value="m" data-cy="basicSearch.gender.m">
          {actualLabels[1]}
        </ToggleButton>
        <ToggleButton value="f" data-cy="basicSearch.gender.f">
          {actualLabels[2]}
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }
);

export default GenderButtonGroup;
