import React, { useCallback } from "react";
import { makeStyles } from "hooks/makeStyles";
import { Dropdown, Button } from "ui/shared";
import { lighten } from "utils/fade";
import { observer } from "mobx-react";
import { globalUIStore } from "global-stores/global-ui-store";
import { useTranslation } from "react-i18next";

export interface LanguageDropdownProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  button: {
    width: 40,
    height: 30,
    padding: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
    border: "1px solid transparent",
  },
  dropdownBtn: {
    extend: "button",
    marginBottom: "1px",
    "&:hover": {
      border: `1px solid ${theme.colors.textPrimary}`,
    },
    "&:focus": {
      backgroundColor: theme.colors.background.paper.lighter,
      boxShadow: "none",
      outline: "none",
      animation: "none",
    },
  },
  trigger: {
    extend: "button",
    border: `1px solid ${theme.colors.textPrimary}`,
    position: "relative",
    zIndex: 1000,
  },
  overlay: {
    backgroundColor: theme.colors.background.paper.topbar,
    borderTopLeftRadius: 999,
    borderTopRightRadius: 999,
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: -15,
      left: 0,
      height: 15,
      width: 40,
      zIndex: -1,
      backgroundColor: theme.colors.background.paper.topbar,
    },
  },
}));

export const LanguageDropdown = observer((props: LanguageDropdownProps) => {
  const css = useStyles();
  const { i18n } = useTranslation();

  /**************************************************/

  const handleSelectLanguage = useCallback(
    (e: React.MouseEvent, value: string) => {
      globalUIStore.setLanguage(value);
    },
    []
  );

  /**************************************************/

  return (
    <div className={css.root}>
      <Dropdown
        preferTop
        items={[
          { id: "ru", text: "ру" },
          { id: "en", text: "en" },
        ]}
        renderItem={(item, index) => (
          <Button
            onClick={(e) => handleSelectLanguage(e, item.id)}
            className={css.dropdownBtn}
          >
            {item.text}
          </Button>
        )}
        overlayClassName={css.overlay}
      >
        <Button className={css.trigger}>{i18n.language.split("-")[0]}</Button>
      </Dropdown>
    </div>
  );
});
