import React, { useContext } from "react";
import { makeStyles } from "hooks/makeStyles";
import { TabsContext } from "./tabs";
import clsx from "clsx";

export interface TabPanelProps {
  value: string | number;
  className?: string;
  children: React.ReactNode;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));

export const TabPanel = (props: TabPanelProps) => {
  const { value, className, children } = props;
  const { value: contextValue } = useContext(TabsContext);
  const css = useStyles();

  return (
    <div
      aria-labelledby={`tab-${value}`}
      className={clsx(css.root, className)}
      hidden={value !== contextValue}
      id={`tab-panel-${value}`}
      role="tabpanel"
    >
      {value === contextValue && children}
    </div>
  );
};
