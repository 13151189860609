import React, { useCallback, useState } from "react";
import { Box, Button, Skeleton, Typography } from "ui/shared";
import moment from "moment";
import { makeStyles } from "hooks/makeStyles";
import { useTranslation } from "react-i18next";
import vipStore from "global-stores/vip-store";
import { Subscription, SubscriptionType } from "lib";

interface ActiveSubscriptionProps {
  subscription?: Subscription;
  loading?: boolean;
}

const useStyles = makeStyles((theme) => ({
  activeSubscriptionContainer: {
    width: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cancelBtn: {
    backgroundColor: "transparent",
  },
}));

const ActiveSubscription = ({
  subscription,
  loading,
}: ActiveSubscriptionProps) => {
  const css = useStyles();
  const { t } = useTranslation("all");

  const [openingPortal, setOpeningPortal] = useState(false);

  /**************************************************/

  const handleClickManageSubscription = useCallback(async () => {
    if (openingPortal) return;

    setOpeningPortal(true);
    const portalUrl = await vipStore.getCustomerPortalUrl();
    if (portalUrl && window) {
      window.open(portalUrl, "_blank")!.focus();
    }
    setOpeningPortal(false);
  }, []);

  /**************************************************/

  if (loading) {
    return (
      <Box mt={6} className={css.activeSubscriptionContainer}>
        <Skeleton.Line width="30%" />
        <Skeleton.Line width="30%" height={42} borderRadius={99} />
      </Box>
    );
  }

  return subscription ? (
    <Box mt={6} className={css.activeSubscriptionContainer}>
      <Typography>
        {t(`all:subscription_${subscription.type}`).replace(
          "$1",
          `${moment(subscription.expiresDate).format("DD.MM.yyyy")}`
        )}
      </Typography>
      {subscription.type === SubscriptionType.Stripe ? (
        <Button
          secondary
          disabled={openingPortal}
          className={css.cancelBtn}
          onClick={handleClickManageSubscription}
        >
          {t("all:subscription_control_button")}
        </Button>
      ) : null}
    </Box>
  ) : null;
};

export default ActiveSubscription;
