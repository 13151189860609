import React from "react";
import { makeStyles } from "hooks/makeStyles";
import { observer } from "mobx-react";
import { ToastAlign, ToastType } from "lib";
import { toastStore } from "global-stores/toasts-store";
import clsx from "clsx";
import { Typography } from "../typography/typography";
import { motion, AnimatePresence } from "framer-motion";
import { Icons } from "../icons";
import { IconButton } from "../icon-button/icon-button";
import { fade } from "utils";
import { Box } from "../box/box";

interface ToastsProps {
  align: ToastAlign;
}

const useStyles = makeStyles((theme) => ({
  toastsContainer: {
    position: "fixed",
    zIndex: 1201,
    left: 0,
    top: "calc(10% + 120px)",
    width: "100%",
    display: "flex",
    alignItems: ({ align }: ToastsProps) => align,
    flexDirection: "column",
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },
  toast: {
    maxWidth: 300,
    display: "flex",
    backgroundColor: theme.colors.common.white,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    overflow: "hidden",
    "&$error": {
      color: theme.colors.common.white,
    },
    "& p": {
      padding: theme.spacing(2),
    },
  },
  error: {},
  normalIcon: {
    flexBasis: 30,
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.colors.yellow.main,
  },
  errorIcon: {
    backgroundColor: theme.colors.red.dark,
  },
  closeButtonContainer: {
    display: "flex",
    alignItems: "center",
    flexBasis: 32,
  },
  closeButton: {
    width: 32,
    height: 32,
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: fade(theme.colors.common.black, 0.1),
    },
  },
}));

export const Toasts = observer((props: ToastsProps) => {
  const css = useStyles(props);

  return (
    <ul className={css.toastsContainer}>
      <AnimatePresence initial={false}>
        {toastStore.toasts.map((toast, index) => (
          <motion.li
            layout="position"
            key={toast.id}
            initial={{
              opacity: 0,
              x: document.body.clientWidth * 1.5,
              scale: 0.3,
            }}
            animate={{
              opacity: 1,
              x: 0,
              scale: 1,
              transition: { ease: "easeInOut", duration: 0.3 },
            }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
            className={clsx(css.toast, {
              [css.error]: toast.type === ToastType.Error,
            })}
          >
            <span
              className={clsx(css.normalIcon, {
                [css.errorIcon]: toast.type === ToastType.Error,
              })}
            >
              {toast.type === ToastType.Error ? (
                <Icons.Attention />
              ) : (
                <Icons.Bell />
              )}
            </span>
            {toast.render ?? (
              <Typography size="body(14px)" font="light" color="black">
                {toast.message}
              </Typography>
            )}
            {toast.persisted ? (
              <Box mr={2} className={css.closeButtonContainer}>
                <IconButton
                  onClick={() => toastStore.remove(toast.id!)}
                  className={css.closeButton}
                >
                  <Icons.Cross width={20} color="#000" />
                </IconButton>
              </Box>
            ) : null}
          </motion.li>
        ))}
      </AnimatePresence>
    </ul>
  );
});
