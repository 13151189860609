import { Friend } from "lib";
import { action, observable } from "mobx";

export interface ChatSubscription {
  friendId: string;
  limit: number;
  cacheLimit: number;
}

const LOAD_MORE_BUNCH_SIZE = 40;

class SubscriptionsStore {
  @observable ready = false;
  @observable chatSubscriptions: { [key: string]: ChatSubscription } = {};
  @observable updates: string[] = [];

  @action
  setSubscriptionsReady(value: boolean) {
    this.ready = value;
  }

  @action
  addUpdate(id: string) {
    if (this.updates.indexOf(id) === -1) {
      this.updates.push(id);
    }
  }

  @action
  clearUpdates() {
    this.updates = [];
  }

  @action
  addSubscription(friendId: string) {
    if (!this.chatSubscriptions[friendId]) {
      this.chatSubscriptions[friendId] = {
        friendId,
        limit: LOAD_MORE_BUNCH_SIZE,
        cacheLimit: LOAD_MORE_BUNCH_SIZE * 2,
      };
    }
  }

  @action
  incrementLimit(friendId: string) {
    if (!this.chatSubscriptions[friendId]) return;
    this.chatSubscriptions[friendId].limit += 1;

    const diff =
      this.chatSubscriptions[friendId].cacheLimit -
      this.chatSubscriptions[friendId].limit;
    if (diff <= 10) {
      const incrementValue = LOAD_MORE_BUNCH_SIZE - diff;
      this.incrementCacheLimit(friendId, incrementValue);
    }
  }

  @action
  incrementCacheLimit(friendId: string, value: number) {
    if (!this.chatSubscriptions[friendId]) return;
    this.chatSubscriptions[friendId].cacheLimit += value;
    this.addUpdate(friendId);
  }

  @action
  getMoreMessages(friend: Friend) {
    const sub = this.chatSubscriptions[friend._id];
    if (!sub) return;

    if (friend.messageCounter > sub.limit) {
      if (friend.messageCounter > sub.cacheLimit) {
        sub.limit += LOAD_MORE_BUNCH_SIZE;
        sub.cacheLimit += LOAD_MORE_BUNCH_SIZE;
        this.addUpdate(friend._id);
      } else {
        sub.limit += LOAD_MORE_BUNCH_SIZE;
      }
    }
  }
}

export const subscriptionsStore = new SubscriptionsStore();
