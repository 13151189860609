import { observer } from "mobx-react";
import React from "react";
import { makeStyles } from "hooks/makeStyles";
import { Icons, Typography } from "ui/shared";
import { useTranslation } from "react-i18next";
import { userStore } from "global-stores/user-store";

// interface PromoLinkProps {}

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    zIndex: "0",
    textDecoration: "none",
    background:
      "transparent linear-gradient(266deg, #6100CF 0%, #A84B8D 100%) 0% 0% no-repeat padding-box",
    borderRadius: "23px",
    padding: "14px 20px",
    height: "68px",
    width: "100%",
    overflow: "hidden",
    "&:after": {
      content: "''",
      top: "0",
      transform: "translateX(100%)",
      width: "100%",
      height: "68px",
      position: "absolute",
      zIndex: "1",
      animation: "$slide 2s infinite 3s",
      background:
        "linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%)",
      fallbacks: [
        {
          background:
            "-ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%)",
        },
        {
          background:
            "-o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%)",
        },
        {
          background:
            "-webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%)",
        },
        {
          background:
            "-webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.8)), color-stop(99%,rgba(128,186,232,0)), color-stop(100%,rgba(125,185,232,0)))",
        },
        {
          background:
            "-moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(128,186,232,0) 99%, rgba(125,185,232,0) 100%)",
        },
      ],
    },
  },
  "@keyframes slide": {
    "0%": {
      transform: "translateX(-100%)",
    },
    "100%": {
      transform: "translateX(100%)",
    },
  },
  iconVipWrap: {
    display: "flex",
    alignItems: "center",
    borderRadius: "16px",
    padding: "4px 13px",
    background:
      "transparent linear-gradient(88deg, #6100CF 0%, #A84B8D 100%) 0% 0% no-repeat padding-box",
    flexBasis: "85px",
  },
  iconArrow: {
    transform: "rotate(180deg)",
  },
  iconArrowWrap: {
    flexBasis: "85px",
    display: "flex",
    justifyContent: "end",
  },
}));

const PromoLink = observer(() => {
  const css = useStyles();
  const { t } = useTranslation("all");

  const date = new Date();

  const dateString = date.toLocaleDateString("ru-RU", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const formattedDateString = dateString.replace(/\./g, "");

  const userId = userStore.user?._id;
  const order = `${userId}_${formattedDateString}`;
  const username = userStore.user?.username;
  const email = userStore.user?.profile.email
    ? userStore.user?.profile.email
    : "";

  return (
    <a
      href={`${process.env.REACT_APP_TINKOFF}?key=1662040464770&order=${order}&name=${username}&email=${email}`}
      className={css.link}
      target="_blank"
    >
      <div className={css.iconVipWrap}>
        <Typography size="subtitle1(18px)" font="medium">
          {t("all:user_type_vip")}
        </Typography>
        <Icons.VIP width={29} />
      </div>
      <Typography size="h2(23px)" font="semibold">
        Специальное предложение для пользователей из России
      </Typography>
      <div className={css.iconArrowWrap}>
        <Icons.BackArrow className={css.iconArrow} />
      </div>
    </a>
  );
});

export default PromoLink;
