import { action, observable } from "mobx";
import { i18n } from "services/i18n";

interface StripeCardInputError {
  type: "validation_error";
  code: string;
  message: string;
}

class PayformUIStore {
  @observable inProgress = false;
  @observable email = "";
  @observable emailError?: string;

  @observable cardError?: StripeCardInputError;

  _emailRegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  @action
  setEmail(value: string) {
    if (!value.match(this._emailRegExp)) {
      this.emailError = i18n.t("all:verification_email_error");
    } else {
      if (this.emailError) {
        this.emailError = undefined;
      }
    }
    this.email = value;
  }

  @action
  setCardError(value?: StripeCardInputError) {
    this.cardError = value;
  }

  @action
  setInProgress(value: boolean) {
    this.inProgress = value;
  }
}

export default PayformUIStore;
