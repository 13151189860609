import React from "react";
import { motion } from "framer-motion";

interface ZoomProps {
  in: boolean;
  children: React.ReactNode;
}

const variants = {
  hidden: { scale: 0 },
  visible: { scale: 1 },
};

export const Zoom = (props: ZoomProps) => {
  return (
    <motion.div
      animate={props.in ? "visible" : "hidden"}
      variants={variants}
      transition={{ duration: 0.3 }}
    >
      {props.children}
    </motion.div>
  );
};
