import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { BrowserRouter as Router } from "react-router-dom";
import { Layout } from "ui/components/layout/layout";
import Background from "ui/components/background/background";
import { userStore } from "global-stores/user-store";
import { makeStyles } from "hooks/makeStyles";
import { globalUIStore } from "global-stores/global-ui-store";
import Modals from "ui/components/modals/modals";
import "moment/locale/ru";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useTracker } from "hooks/useTracker";
import { subscriptionsStore } from "global-stores/subscriptions-store";
import { Meteor } from "meteor/meteor";
import configStore from "global-stores/config-store";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*": {
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
      scrollbarWidth: "thin",
      scrollbarColor: `${theme.colors.background.paper.lighter} ${theme.colors.purple.main}`,
      /* Works on Chrome, Edge, and Safari */
      "&::-webkit-scrollbar": {
        width: 8,
      },
      "&::-webkit-scrollbar-track": {
        background: theme.colors.background.paper.lighter,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.colors.purple.main,
        borderRadius: 0,
        // border: `3px solid ${theme.colors.purple.main}`,
      },
    },
    html: {
      width: "100%",
      minWidth: "fit-content",
      height: "100%",
      fontSize: "10px",
      fontFamily: theme.fonts.regular,
      color: theme.colors.textPrimary,
    },
    body: {
      width: "100%",
      minWidth: "fit-content",
      height: "100%",
    },
    "#root": {
      position: "relative",
      minWidth: "fit-content",
      width: "100%",
      height: "100%",
    },
    a: {
      color: theme.colors.textSecondary,
      fontSize: "1.5rem",
    },
    "@font-face": [
      {
        fontFamily: theme.fonts.light,
        src: 'url("/fonts/SFUIText-Light.eot"), url("/fonts/SFUIText-Light.eot?#iefix") format("embedded-opentype"), url("/fonts/SFUIText-Light.woff") format("woff"), url("/fonts/SFUIText-Light.ttf") format("truetype")',
        fontWeight: 300,
        fontStyle: "normal",
      },
      {
        fontFamily: theme.fonts.regular,
        src: 'url("/fonts/SFUIText-Regular.eot"), url("/fonts/SFUIText-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/SFUIText-Regular.woff") format("woff"), url("/fonts/SFUIText-Regular.ttf") format("truetype")',
        fontWeight: "normal",
        fontStyle: "normal",
      },
      {
        fontFamily: theme.fonts.medium,
        src: 'url("/fonts/SFUIText-Medium.eot"), url("/fonts/SFUIText-Medium.ttf") format("truetype"), url("/fonts/SFUIText-Medium.eot?#iefix") format("embedded-opentype"), url("/fonts/SFUIText-Medium.woff") format("woff")',
        fontStyle: "normal",
      },
      {
        fontFamily: theme.fonts.semibold,
        src: 'url("/fonts/SFUIText-Semibold.eot"), url("/fonts/SFUIText-Semibold.eot?#iefix") format("embedded-opentype"), url("/fonts/SFUIText-Semibold.woff") format("woff"), url("/fonts/SFUIText-Semibold.ttf") format("truetype")',
        fontStyle: "normal",
      },
      {
        fontFamily: theme.fonts.script,
        src: 'url("/fonts/segoesc.ttf")',
        fontWeight: "normal",
        fontStyle: "normal",
      },
    ],
  },
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media screen and (max-width: 1000px)": {
      minHeight: 0,
    },
  },
  desktopWidth: {
    minWidth: 768,
  },
}));

const date = new Date();
const until = new Date("2120-01-01");

export const App = observer(() => {
  const css = useStyles();
  const { t, i18n } = useTranslation("all");

  useEffect(() => {
    userStore.subscribe();

    /** Drag files to chat */
    document.addEventListener("dragover", (e) => {
      if (!globalUIStore.isDragOver) {
        globalUIStore.setIsDragOver(true);
      }
    });
    document.addEventListener("drop", (e) => {
      globalUIStore.setIsDragOver(false);
    });
    document.addEventListener("mouseleave", (e) => {
      globalUIStore.setIsDragOver(false);
    });
    globalUIStore.initializeLang();
    globalUIStore.initializeNotifications();
    configStore.init();
  }, []);

  useEffect(() => {
    document.title = t("all:web_title");
  }, [i18n.language, t]);

  useTracker(() => {
    globalUIStore.setConnectionStatus(Meteor.status().status);
    console.log(Meteor.status().status);
  });

  useTracker(() => {
    Object.keys(subscriptionsStore.chatSubscriptions).forEach((friendId) => {
      const sub = subscriptionsStore.chatSubscriptions[friendId];
      Meteor.subscribe("messagesUntil", {
        friendId,
        limit: sub.cacheLimit,
        untilTime: until,
        ver: 1,
      });
      Meteor.subscribe("messagesSince", {
        friendId,
        sinceTime: date,
        ver: 1,
      });
    });
    subscriptionsStore.clearUpdates();
  }, [
    Object.keys(subscriptionsStore.chatSubscriptions).length,
    subscriptionsStore.updates.length,
  ]);

  const cx = clsx({
    [css.root]: true,
    [css.desktopWidth]: !globalUIStore.mobileDevice,
  });

  return (
    <Router>
      <div className={cx}>
        <Layout />
        <Modals />
      </div>
      <Background />
    </Router>
  );
});
