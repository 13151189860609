import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Checkmark = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Checkmark}>
    {({ color }) => (
      <g transform="translate(6 9)">
        <path
          d="M17.659,0,6.633,10.1,2.146,6.025,0,8.152,6.633,14,20,1.949Z"
          fill="#cfab00"
        />
      </g>
    )}
  </CreateSvgIcon>
);
