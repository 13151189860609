import { IGif } from "@giphy/js-types";

export type Maybe<T> = T | null | undefined;

export type Gender = "" | "m" | "f";
export type Age = "" | "choose";
export type Area = "everywhere" | "nearby";

export enum MessageType {
  Text = 0,
  Image,
  GIF,
  Sticker,
  Video,
  Audio,
}

export interface Message {
  _id: string;
  userId: string;
  senderMessageId: string;
  friendId: string;
  friendUserId: string;
  friendMessageId: string;
  createdAt: Date;
  editedAt: Date;
  messageText?: string;
  messageTextT?: string;
  my: boolean;
  clientTime?: Date;
  deliveryStatus: DeliveryStatus;
  filesStatus: FilesStatus;
  boxSize?: string;
  isImage?: boolean;
  attachments: MessageAttachment[];
  /** deprecated */
  service?: boolean;
  plain?: boolean;
  t?: MessageType;
}

export interface MessageShort {
  _id: string;
  service: boolean;
  createdAt: Date;
  my: boolean;
  isImage?: boolean;
  messageText?: string;
  plain?: boolean;
}

export interface MessageAttachment {
  id: string;
  type: string;
  encoded: boolean;
}

export enum DeliveryStatus {
  "Send" = 0,
  "Delivery",
  "Read",
}

export enum FilesStatus {
  "NoFiles" = 0,
  "Uploading",
  "Uploaded",
}

///////////////////////////////////////////////////////////////////

export interface Friend {
  _id: string;
  userId: string;
  createdAt: Date;
  friendIamId: string;
  friendUserId: string;
  friendLogin: string;
  friendPublicKey: string;
  avatar: Avatar;
  level?: string;
  lastMessage?: MessageShort;
  lastActivity: {
    name?: string;
    date?: Date;
  };
  ipv4: string;
  online: boolean;
  typing: boolean;
  active: boolean;
  new: boolean;
  friend: boolean; //false - stranger, true - friend
  type?: "stranger" | "friend"; // Deprecated
  official: boolean;
  friendship: boolean; //Заявка в друзья - Этот параметр может менять только друг
  save: number; //0 - не сохранял, 1 - сохранил, 2 - сохранил и отправил в друзья или друзья
  finish: Finished;
  delTime: Date;
  newMessageCounter: number;
  activeKarma: number;
  interests?: string[];
  interestsMatched?: string[];
  gender?: string;
  age?: number[];
  language?: string[];
  about?: string;
  country?: string;
  likeByMe: boolean;
  messageCounter: number;
}

export enum AvatarPermitted {
  Block = 0,
  ON,
  OK,
}

export enum AvatarCategory {
  Classic = "ava_classic",
  Nefrit = "ava_nefrit",
}

export interface Avatar {
  _id: string;
  userId: string;
  permitted: AvatarPermitted;
  low?: string;
  medium?: string;
  high?: string;
  type?: string;
  main: boolean;
  avatarDId: string;
  createdAt: Date;
  cat: AvatarCategory;
}

export enum Finished {
  Blank = "",
  Deleted = "deleted",
  Unfriended = "unfriended",
  Gone = "gone",
  Leave = "leave",
  Kick = "kick",
}

///////////////////////////////////////////////////////////////////

export interface Pickup {
  userId: string;
  login: string;
  publicKey: string;
  createdAt: Date;
  updateAt: Date;
  needRobotCheck: boolean;
  ipv4: string;
  activeKarma: number;
  notified: boolean;
  coincidedNo: CoincidedNo;
  mGender?: string;
  mAgeRange?: number[];
  hGender: string;
  hAgeRange: number[];
  searchRange: number;
  nearby?: boolean;
  language?: string[];
  interests?: string[];
  pointGeoJSON: {
    type: string;
    coordinates: number[];
  };
  auto?: boolean;
  disable?: boolean;
  _id: string;
  found?: { date: Date; id: string; username: string }[];
}

export interface CoincidedNo {
  other: number;
  interests: number;
  age: number;
  lang: number;
  range: number;
  distance: number;
}

export interface PickupConnection {
  _id: string;
  userId: string;
  hUserId: string;
  allow: boolean;
  hAllow: boolean;
  hKarma: number;
  hLogin: string;
  hGender: Gender;
  hAgeRange: number[];
  distance: number; // (meters) (0 - unknown)
}

///////////////////////////////////////////////////////////////////

export interface User {
  _id: string;
  createdAt: Date;
  username: string;
  services: {
    password: {
      bcrypt: string;
    };
    resume?: {
      loginTokens: {
        when: Date;
        hashedToken: string;
      }[];
    };
  };
  profile: Profile;
}

export interface Profile {
  publicKey: string;
  avatar?: Avatar;
  level?: string;
  interestsLimit?: string;
  email?: string;
  masterUserId?: string;
  invite?: string;
  karma?: {
    activeKarma?: number;
    activeLikesCount?: number;
    unActiveKarma?: number;
    unActiveLikesCount?: number;
  };
  lastActivity: {
    name?: string;
    date?: Date;
  };
}

///////////////////////////////////////////////////////////////////

export interface Like {
  userId: string;
  createdAt: Date;
  value: number;
  active: boolean;
  deadTime: Date;
  reason: Reason;
  description: string;
  login: string;
  authorUserId: string;
}

export enum Reason {
  Spam = "spam",
  Jerk = "jerk",
  Crud = "crud",
  Old = "old",
}

///////////////////////////////////////////////////////////////////

export interface Blacklist {
  _id: string;
  userId: string;
  createdAt: Date;
  blockedUserId: string;
  blockedLogin: string;
  reason: Reason;
  description: string;
}

///////////////////////////////////////////////////////////////////

export interface DeviceToken {
  userId: string;
  updateAt: Date;
  token: string;
  lang: string;
  ver: string;
  type: number; //0 - iOS, 1 - Android
}

export enum NotificationType {
  Custom = "custom",
  Like = "like",
  New = "new",
  Deleled = "del",
  News = "news",
  Message = "msg",
  Search = "search",
}

export interface Notification {
  _id: string;
  userId: string;
  type: NotificationType;
  createdAt: Date;
  title: string;
  body: string;
  friendId: string;
  avatarUrl: string;
  read: boolean;
}

/**************************************************/

export interface WithTestData {
  ["data-cy"]?: string;
}

/**************************************************/

export interface Toast {
  id?: string;
  message: string;
  render?: React.ReactNode;
  type?: ToastType;
  delay?: number;
  persisted?: boolean;
}

export enum ToastType {
  Normal = "Normal",
  Error = "Error",
}

export enum ToastAlign {
  Left = "flex-start",
  Right = "flex-end",
  Center = "center",
}

/**************************************************/

export type MediaType = "GIF" | "Image";

export interface Media {
  type: MediaType;
  media: IGif | string;
  width: number;
  height: number;
  fullSizeWidth: number;
  fullSizeHeight: number;
}

/**************************************************/

export interface VIPOption {
  id: string;
  duration: string;
  currency: string;
  price: number;
  priceId: string;
  oldPrice: number;
  payPrice: number;
  freeDays: number;
  sale: number;
  active: boolean;
}

export enum SubscriptionType {
  Stripe = "stripe",
  Apple = "appl",
  Google = "goog",
  Gift = "gift",
}

export interface Subscription {
  _id: string;
  createAt: Date;
  disable: boolean;
  expiresDate: number;
  panding: boolean;
  trial: boolean;
  type: SubscriptionType;
  updateAt: Date;
  transactionId?: number;
  receiptData?: string;
}

/**************************************************/

export interface TopUserAvatar {
  _id: string;
  low: string;
  medium: string;
  high: string;
}
export interface TopUser {
  _id: string;
  username: string;
  avatar: TopUserAvatar;
  gender: Gender;
  age: number[];
  country: string;
  level: string;
  activeKarma: number;
}

export interface TopUserDetails {
  _id: string;
  username: string;
  avatar: TopUserAvatar;
  gender?: Gender;
  age?: number[];
  country?: string;
  interests?: string[];
  level: string;
  hideLevelIcon: number;
  activeKarma: number;
}
