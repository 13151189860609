import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Trash = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Trash}>
    {({ color }) => (
      <path
        d="M13.107,22h-9.5a1.867,1.867,0,0,1-1.844-1.728L.88,3.52H0V2.64H4.4V1.76A1.771,1.771,0,0,1,6.16,0h4.4A1.762,1.762,0,0,1,12.32,1.76v.88h4.4v.88h-.88l-.889,16.752A1.867,1.867,0,0,1,13.107,22ZM1.76,3.52l.885,16.694a1,1,0,0,0,.968.907h9.5a1,1,0,0,0,.968-.907L14.96,3.52ZM6.16.88a.881.881,0,0,0-.88.88v.88H11.44V1.76a.881.881,0,0,0-.88-.88ZM8.8,19.36H7.92V5.28H8.8V19.359Zm2.64,0h-.88L11.418,5.28h.9L11.44,19.359Zm-6.159,0L4.4,5.28h.88l.88,14.078H5.28Z"
        transform="translate(8 5)"
        fill="#fff"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
      />
    )}
  </CreateSvgIcon>
);
