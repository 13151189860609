import React from "react";
import { vipGuard } from "ui/shared";
import { DisplayInProfilePanelItem } from "./display-in-profile-panel-item";
import { useTranslation } from "react-i18next";

export interface DisplayInProfilePanelHeaderProps {}

export interface ProfileOption {
  label: string;
  prop: string;
  value: boolean;
}

export interface DisplayInProfilePanelProps {
  items: ProfileOption[];
  onSelectItem: (value: boolean, item: ProfileOption) => void;
}

export const DisplayInProfilePanel = (props: DisplayInProfilePanelProps) => {
  const { items, onSelectItem } = props;
  const { t } = useTranslation("all");

  return (
    <>
      {items.map((item, index) => (
        <DisplayInProfilePanelItem
          key={item.label}
          checked={item.value}
          label={t(`all:settings_${item.label}`)}
          onClick={(value) => vipGuard(onSelectItem, value, item)}
        />
      ))}
    </>
  );
};
