import { modalManager } from "global-stores/modal-manager";
import { makeStyles } from "hooks/makeStyles";
import { MODALS } from "lib/constants";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Box, Button, Icons, Typography, VIPButton } from "ui/shared";

const useStyles = makeStyles((theme) => ({
  translationHideAlertContainer: {
    maxWidth: 350,
    padding: `${theme.spacing(8)} ${theme.spacing(5)}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  hideButton: {
    border: 0,
    padding: theme.spacing(3.75),
    backgroundColor: theme.colors.background.paper.lighter,
    marginTop: theme.spacing(5),
  },
  vipButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  alertText: {
    marginTop: theme.spacing(6),
  },
}));

const TranslateVerAlertModal = observer(() => {
  const css = useStyles();
  const { t } = useTranslation("all");
  const history = useHistory();

  return (
    <div className={css.translationHideAlertContainer}>
      <Icons.SadFace width={102} height={82} />
      <Typography
        size="subtitle1(18px)"
        align="center"
        className={css.alertText}
      >
        {t("all:alert_only_for_ver")}
      </Typography>
      <Typography size="body(14px)" align="center" className={css.alertText}>
        {t("all:reg_4_text")}
      </Typography>
      <Box mt={5} className={css.vipButtonContainer}>
        <Button
          primary
          onClick={() => {
            modalManager.close(MODALS.TRANSLATE_VER_ALERT);
            history.push("/profile");
          }}
        >
          {t("all:karma_verify")}
        </Button>
      </Box>
    </div>
  );
});

export default TranslateVerAlertModal;
