import React, { useCallback } from "react";
import { makeStyles } from "hooks/makeStyles";
import clsx from "clsx";
import { focusKeyframeMixin, focusKeyframeAnimationMixin } from "styles";
import { Typography } from "../typography/typography";
import { darken } from "utils/fade";

const KEY_CODES = {
  RETURN: 13,
  SPACE: 32,
};

export interface CheckboxProps {
  checked: boolean;
  label?: string;
  disabled?: boolean;
  right?: boolean;
  onChange?: (value: boolean) => void;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  ...focusKeyframeMixin(theme),
  container: {
    display: "inline-block",
    position: "relative",
    cursor: "pointer",
    userSelect: "none",
    paddingLeft: ({ right }: any) => (right ? 0 : "30px"),
    paddingRight: ({ right }: any) => (right ? "30px" : 0),
    "&:before, &:after": {
      position: "absolute",
      top: "50%",
      left: ({ right }: any) => (right ? undefined : 10),
      transform: "translate(-50%, -50%)",
      content: '""',
    },
    "&:after": {
      right: ({ right }: any) => (right ? 4 : undefined),
    },
    "&:before": {
      width: 20,
      height: 20,
      borderRadius: "calc(0.2em + 3px)",
      backgroundColor: theme.colors.purple.main,
      right: ({ right }: any) => (right ? -10 : undefined),
      transition: "background-color .2s",
    },
    "&:hover:before": {
      backgroundColor: darken(theme.colors.purple.main, 0.2),
    },
    "&:active:before": {
      backgroundColor: theme.colors.purple.main,
    },
    '&[aria-checked="mixed"]:before, &[aria-checked="true"]:before': {
      backgroundColor: theme.colors.primary,
    },
    '&[aria-checked="mixed"]:active:after, &[aria-checked="true"]:after': {
      display: "block",
      width: "4px",
      height: "8px",
      border: `solid ${theme.colors.common.white}`,
      borderWidth: "0 2px 2px 0",
      transform: "translateY(-65%) translateX(-50%) rotate(45deg)",
    },
    '&[aria-checked="mixed"]:active:before, &[aria-checked="true"]:active:before':
      {
        backgroundColor: theme.colors.primary,
      },
    "&:focus": {
      outline: "none",
    },
    "&:focus:before": {
      animation: focusKeyframeAnimationMixin,
    },
    "&$disabled:before": {
      backgroundColor: `${theme.colors.purple.main} !important`,
    },
    "&$disabled": {
      pointerEvents: "none",
      cursor: "default",
    },
  },
  disabled: {},
}));

export const Checkbox = (props: CheckboxProps) => {
  const {
    checked,
    label,
    disabled,
    onChange,
    right,
    className,
    ...passthrough
  } = props;
  const css = useStyles({ right });

  const cx = clsx(css.container, {
    [css.disabled]: disabled,
    [className!]: !!className,
  });

  /**************************************************/

  const toggleCheckbox = useCallback(() => {
    if (onChange) {
      onChange(!checked);
    }
  }, [checked, onChange]);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      toggleCheckbox();
    },
    [toggleCheckbox]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      let flag = false;

      switch (e.keyCode) {
        case KEY_CODES.SPACE:
          toggleCheckbox();
          flag = true;
          break;

        default:
          break;
      }

      if (flag) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [toggleCheckbox]
  );

  /**************************************************/

  return (
    <div
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      role="checkbox"
      aria-checked={checked}
      tabIndex={disabled ? -1 : 0}
      className={cx}
      {...passthrough}
    >
      <Typography size="subtitle1(18px)">{label}</Typography>
    </div>
  );
};
