import React, { useCallback } from "react";
import { makeStyles } from "hooks/makeStyles";
import { useTranslation } from "react-i18next";
import { Typography, Icons, Box, Skeleton } from "ui/shared";
import { TopUserDetails } from "lib";
import { getInterestsLabels } from "utils";
import countries from "lib/countries.json";
import EmblaCarouselMini from "ui/components/embla-carousel-mini/embla-carousel-mini";
import { lighten } from "utils/fade";

export interface TopUserProfileModalProps {
  topUserDetails?: TopUserDetails;
  visible: boolean;
  isTop?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    boxSizing: "border-box",
  },
  profile: {
    width: "100%",
    margin: 0,
    marginLeft: 0,
    backgroundColor: theme.colors.background.paper.topbar,
    borderRadius: 10,
    position: "relative",
    overflow: "hidden",
  },
  headerItem: {
    width: 40,
  },
  infoWrapper: {
    padding: theme.spacing(5),
  },
  infoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(6.25),
  },
  avatarContainer: {
    position: "relative",
    height: 345,
  },
  avatarContainerItems: {
    position: "absolute",
    paddingLeft: 9,
    bottom: -32,
    display: "flex",
    flexWrap: "nowrap",
    width: "100%",
  },
  avatarContainerItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  avatarLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: 345,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  karmaWrapper: {},
  vipWrapper: {},
  itemWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  buttonWrap: {
    flexGrow: 1,
    alignItems: "flex-end",
    paddingRight: theme.spacing(4.25),
  },
  iconWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.colors.background.paper.lighter,
    width: 34,
    height: 34,
    borderRadius: "50%",
  },
  likeButton: {
    backgroundColor: theme.colors.background.paper.lighter,
    "&:hover": {
      backgroundColor: lighten(theme.colors.background.paper.lighter, 0.2),
    },
    "&$active": {
      backgroundColor: theme.colors.primary,
      "&:hover": {
        backgroundColor: lighten(theme.colors.primary, 0.2),
      },
    },
  },
  gender: {
    textTransform: "capitalize",
  },
  breakText: {
    wordBreak: "break-all",
    whiteSpace: "pre-line",
  },
  active: {},
}));

export const TopUserProfileModal = (props: TopUserProfileModalProps) => {
  const { topUserDetails, visible } = props;
  const { t, i18n } = useTranslation("chat");
  const css = useStyles();

  /**************************************************/

  const ageRender = useCallback((items: number[]) => {
    const newItems = items
      .filter((item, i) => items.indexOf(item) === i)
      .join("-");

    if (items[0] !== 0 && items[1] !== 0) {
      return newItems;
    } else {
      return;
    }
  }, []);

  const wrappedLabel = useCallback((el: React.ReactElement) => {
    return <Box mb={2.5}>{el}</Box>;
  }, []);

  /**************************************************/

  return !topUserDetails ? (
    <div className={css.container} data-cy="chat-profile-modal">
      <div className={css.avatarContainer}>
        <div className={css.avatarLoader}>
          <Icons.DotsLoader
            width={32}
            height={14}
            viewBox="0 0 120 30"
            fill="#fff"
          />
        </div>
        <div className={css.avatarContainerItems}>
          <div className={css.avatarContainerItem} style={{ marginTop: 8 }}>
            <div className={css.karmaWrapper}>
              <div className={css.iconWrap}>
                <Icons.NotActiveKarma
                  width={28}
                  height={28}
                  secondaryColor="#655285"
                />
              </div>
              <Skeleton.Line />
            </div>
          </div>
        </div>
      </div>

      <div className={css.infoWrapper}>
        <div className={css.infoContainer}>
          {wrappedLabel(
            <Typography size="caption(12px)" color="secondary">
              {t("all:friend_profile_login")}:
            </Typography>
          )}
          <Skeleton.Line width="70%" />
        </div>
      </div>
    </div>
  ) : (
    <div className={css.container} data-cy="chat-profile-modal">
      <div className={css.avatarContainer}>
        {visible ? (
          <EmblaCarouselMini
            userId={topUserDetails._id}
            profileAvatar={topUserDetails.avatar}
          />
        ) : null}
        <div className={css.avatarContainerItems}>
          <div className={css.avatarContainerItem}>
            {topUserDetails.level === "vip" ? (
              <div className={css.vipWrapper} data-cy="chat-profile-modal-vip">
                <Icons.VIP width={43} height={49} />
              </div>
            ) : (
              ""
            )}
          </div>

          <div className={css.avatarContainerItem} style={{ marginTop: 8 }}>
            <div className={css.karmaWrapper}>
              <div className={css.iconWrap}>
                <Icons.NotActiveKarma
                  width={28}
                  height={28}
                  secondaryColor="#655285"
                />
              </div>
              <Typography size="body(14px)" data-cy="chat-profile-modal-karma">
                {topUserDetails.activeKarma}
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className={css.infoWrapper}>
        <div className={css.infoContainer}>
          {wrappedLabel(
            <Typography size="caption(12px)" color="secondary">
              {t("all:friend_profile_login")}:
            </Typography>
          )}
          <Typography data-cy="chat-profile-modal-username">
            {topUserDetails.username}
          </Typography>
        </div>

        {topUserDetails.gender ? (
          <div className={css.infoContainer}>
            {wrappedLabel(
              <Typography size="caption(12px)" color="secondary">
                {t("all:friend_profile_gender")}:
              </Typography>
            )}
            <Typography
              className={css.gender}
              data-cy="chat-profile-modal-gender"
            >
              {t(`all:gender_${topUserDetails.gender}`)}
            </Typography>
          </div>
        ) : null}

        {topUserDetails.age &&
        topUserDetails.age.length &&
        topUserDetails.age.every((value) => value !== 0) ? (
          <div className={css.infoContainer}>
            {wrappedLabel(
              <Typography size="caption(12px)" color="secondary">
                {t("all:friend_profile_age")}:
              </Typography>
            )}
            <Typography data-cy="chat-profile-modal-age">
              {ageRender(topUserDetails.age)}
            </Typography>
          </div>
        ) : null}

        {topUserDetails.country ? (
          <div className={css.infoContainer}>
            {wrappedLabel(
              <Typography size="caption(12px)" color="secondary">
                {t("all:friend_profile_country")}:
              </Typography>
            )}
            <Typography data-cy="chat-profile-modal-country">
              {
                (countries as any)[topUserDetails.country][
                  i18n.language.split("-")[0]
                ]
              }{" "}
              {topUserDetails.country}
            </Typography>
          </div>
        ) : null}

        {topUserDetails.interests && topUserDetails.interests.length ? (
          <div className={css.infoContainer}>
            {wrappedLabel(
              <Typography size="caption(12px)" color="secondary">
                {t("all:friend_profile_interests")}:
              </Typography>
            )}
            <Typography
              data-cy="chat-profile-modal-interests"
              className={css.breakText}
            >
              {getInterestsLabels(topUserDetails.interests)}
            </Typography>
          </div>
        ) : null}
      </div>
    </div>
  );
};
