import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, DefaultPanel, Modal, Typography } from "ui/shared";
import {
  EditableContainer,
  wrappedLabel,
} from "../../shared/editable/editable";
import { profileStore } from "./store.profile";
import { userStore } from "global-stores/user-store";
import { Box } from "../../shared/box/box";
import { AboutMe, AboutMeHeader } from "./modals/about-me";
import { makeStyles } from "hooks/makeStyles";
import { MODALS } from "lib/constants";
import { modalManager } from "global-stores/modal-manager";

const useStyles = makeStyles((theme) => ({
  text: {
    wordBreak: "break-all",
    whiteSpace: "pre-line",
  },
}));

interface AboutEditableProps {
  profile?: any;
}

const AboutEditable = observer((props: AboutEditableProps) => {
  const { t } = useTranslation("all");
  const css = useStyles();

  const { profile } = props;

  /**************************************************/

  const openAboutModal = useCallback(() => {
    profileStore.setAboutMe((profile && profile.about) || "");
    modalManager.open(MODALS.PROFILE_ABOUT);
  }, [profile.about]);

  const handleCloseAboutModal = useCallback(() => {
    if (!profileStore.about.replace(/\s/g, "").length) {
      // string only contains whitespace (ie. spaces, tabs or line breaks)
      return;
    }
    userStore.setProfileValue("about", profileStore.about.trim());
  }, []);

  const handleChangeAboutMe = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.value.length <= 300) {
        profileStore.setAboutMe(e.target.value);
      }
    },
    []
  );

  /**************************************************/

  return (
    <Box mb={6}>
      {wrappedLabel(
        <Typography size="caption(12px)" color="secondary" lowercase>
          {t("all:profile_about")}:
        </Typography>
      )}
      <EditableContainer>
        <Button onClick={openAboutModal} data-cy="profile.actions.about">
          <Typography data-cy="profile.values.about" className={css.text}>
            {profile && profile.about
              ? profile.about
              : t("all:profile_placeholder_no")}
          </Typography>
        </Button>
      </EditableContainer>

      <Modal modalId={MODALS.PROFILE_ABOUT} onClose={handleCloseAboutModal}>
        {(visible, className) => (
          <DefaultPanel
            header={<AboutMeHeader value={profileStore.about.length} />}
            className={className}
          >
            <AboutMe
              visible={visible}
              value={profileStore.about}
              onChange={handleChangeAboutMe}
              placeholder={t("all:profile_placeholder_no")}
            />
          </DefaultPanel>
        )}
      </Modal>
    </Box>
  );
});

export default AboutEditable;
