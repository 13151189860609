import { makeStyles } from "hooks/makeStyles";
import { TopUser } from "lib";
import React from "react";
import TopUserItem from "./top-user-item";
import ArrowKeyFocuser from "ui/shared/arrow-key-focuser/arrow-key-focuser";
import { Loader } from "ui/shared";

interface TopUsersListProps {
  users: TopUser[];
  onSelectItem?: (item: any) => void;
}

const useStyles = makeStyles((theme) => ({
  topUsersListContainer: {
    flex: 1,
    listStyle: "none",
  },
  loadingContainer: {
    width: "100%",
    height: 300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.background.paper.topbar,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
}));

const Loading = () => {
  const css = useStyles();

  return (
    <div className={css.loadingContainer}>
      <Loader />
    </div>
  );
};

const TopUsersList = React.memo(
  ({ users, onSelectItem }: TopUsersListProps) => {
    const css = useStyles();

    return (
      <ArrowKeyFocuser
        items={users}
        ItemComponent={TopUserItem}
        onSelectItem={onSelectItem}
        isLoading={!users.length}
        LoadingComponent={Loading}
        className={css.topUsersListContainer}
      />
    );
  }
);

export default TopUsersList;
