import { userStore } from "global-stores/user-store";
import { makeStyles } from "hooks/makeStyles";
import { observer } from "mobx-react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Input, Typography } from "ui/shared";
import {
  Editable,
  HelperMessageType,
  wrappedLabel,
} from "../../shared/editable/editable";
import { profileStore } from "./store.profile";

const useStyles = makeStyles((theme) => ({
  loginText: {
    textTransform: "none",
  },
  editInfoInputWrapper: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    width: "100%",
    height: 40,
  },
  editInfoInput: {
    border: "0 !important",
    textAlign: "left",
    padding: 0,
    fontSize: "14px",
    borderRadius: 0,
    width: "90%",
    backgroundColor: "transparent",
  },
  helperText: {
    textAlign: "left",
  },
  confirmBtn: {
    height: 40,
    width: 40,
    padding: 0,
    flexShrink: 0,
  },
}));

const UsernameEditable = observer(() => {
  const { t } = useTranslation("all");
  const css = useStyles();

  const [userNameEdit, setUserNameEdit] = useState(false);

  /**************************************************/

  const handleUsernameEdit = useCallback(() => {
    const value = (userStore.user && userStore.user.username) || "";
    profileStore.setUsername(value);
    profileStore.setUsernameBeforeEdit(value);

    setUserNameEdit(true);
  }, [setUserNameEdit]);

  const handleUsernameInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const username = userStore.user && userStore.user.username;
      profileStore.setUsername(e.target.value, e.target.value !== username);
    },
    []
  );

  const handleSetNewUserName = useCallback(() => {
    profileStore.setNewUserName();
    setUserNameEdit(false);
    profileStore.clearErrors();
  }, [setUserNameEdit]);

  const getMessageColor = useCallback(() => {
    if (profileStore.usernameError) return HelperMessageType.Error;
    if (profileStore.message) return HelperMessageType.Success;
    if (profileStore.warning) return HelperMessageType.Warning;
  }, []);

  /**************************************************/

  const messageType =
    userNameEdit && profileStore.isUsernameDirty
      ? getMessageColor()
      : undefined;

  return (
    <Editable
      isEdit={userNameEdit}
      label={wrappedLabel(
        <Typography size="caption(12px)" color="secondary" lowercase>
          {t("all:profile_login")}:
        </Typography>
      )}
      message={
        userNameEdit
          ? profileStore.usernameError ||
            profileStore.message ||
            profileStore.warning
          : ""
      }
      messageType={messageType}
      onEdit={handleUsernameEdit}
      view={
        <Box className={css.editInfoInputWrapper}>
          <Input
            disabled={!userNameEdit}
            autoFocus
            className={css.editInfoInput}
            value={
              userNameEdit
                ? profileStore.username
                : userStore.user && userStore.user.username
                ? userStore.user.username
                : ""
            }
            onChange={handleUsernameInput}
            placeholder={t("all:main_placeholder_login")}
            error={!!profileStore.usernameError}
            succeed={!!profileStore.message}
            helperTextClassName={css.helperText}
            onBlur={handleSetNewUserName}
            data-cy="username.input"
          />
          {userNameEdit ? (
            <Button
              className={css.confirmBtn}
              primary
              onClick={handleSetNewUserName}
              disabled={
                !profileStore.username ||
                !!profileStore.usernameError ||
                !!profileStore.warning
              }
              data-cy="username.actions.continue"
            >
              OK
            </Button>
          ) : null}
        </Box>
      }
    />
  );
});

export default UsernameEditable;
