import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Photo = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Photo}>
    {({ color }) => (
      <>
        <g transform="translate(-645.5 -674.5)">
          <g transform="translate(653.058 684.011)">
            <path
              fill="#fff"
              d="M16,2.3h-3.3c-0.2,0-0.5-0.1-0.6-0.4l-0.6-1.5c-0.2-0.5-0.7-0.8-1.2-0.8H6.6
			c-0.5,0-1,0.3-1.2,0.8L4.7,1.9C4.7,2.1,4.4,2.3,4.2,2.3H0.9c-0.8,0-1.4,0.6-1.4,1.4v8.3c0,0.8,0.6,1.4,1.4,1.4H16
			c0.8,0,1.4-0.6,1.4-1.4V3.6C17.4,2.9,16.8,2.3,16,2.3z M8.4,11.9c-2.3,0-4.1-1.8-4.1-4.1s1.8-4.1,4.1-4.1c2.3,0,4.1,1.8,4.1,4.1
			l0,0C12.6,10,10.7,11.9,8.4,11.9C8.4,11.9,8.4,11.9,8.4,11.9L8.4,11.9z"
            />
            <circle fill="#fff" cx="8.4" cy="7.7" r="3.1" />
          </g>
          <path
            fill="#fff"
            d="M661.5,675.6c8.2,0,14.9,6.7,14.9,14.9s-6.7,14.9-14.9,14.9s-14.9-6.7-14.9-14.9c0,0,0,0,0,0
		C646.6,682.3,653.3,675.7,661.5,675.6C661.5,675.6,661.5,675.6,661.5,675.6 M661.5,674.5c-8.8,0-16,7.2-16,16s7.2,16,16,16
		s16-7.2,16-16C677.5,681.7,670.3,674.5,661.5,674.5z"
          />
        </g>
      </>
    )}
  </CreateSvgIcon>
);
