import "meteor-client";
import "mobx-react-lite/batchingForReactDom";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "./app";
import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";
import { i18n } from "./services/i18n";
import { Meteor } from "meteor/meteor";
import { configureTheme } from "lib/theme";
import { ThemeProvider } from "react-jss";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const theme = configureTheme("dark");

if (process.env.NODE_ENV === "production" && process.env.REACT_APP_SENTRY_PUB) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_PUB,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}

Meteor.startup(() => {
  ReactDOM.render(
    <React.StrictMode>
      <div
        className="g-recaptcha"
        data-sitekey="6LfnQboZAAAAACStu_DzJ3TNfFVmuB0dr-FC_7Vt"
        data-callback="onCaptcha"
        data-size="invisible"
        style={{ display: "none" }}
      ></div>
      <I18nextProvider i18n={i18n}>
        <React.Suspense fallback={null}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </React.Suspense>
      </I18nextProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
