import React from "react";
import { makeStyles } from "hooks/makeStyles";
import { Typography, Icons, IconButton } from "ui/shared";
import { LanguageItem } from "ui/pages/search/language-item";
import { useTranslation } from "react-i18next";
import { capitalize } from "utils";
import { langs } from "lib";

export interface LanguagesHeaderProps {
  selected: string[];
  max?: number;
  onClear: (e: React.MouseEvent) => void;
}

export interface LanguagesProps {
  selected: string[];
  onSelectLanguage: (e: React.MouseEvent, item: string) => void;
  includeAuto?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  languagesHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
    backgroundColor: theme.colors.background.paper.lighter,
  },
  headerItem: {
    width: 40,
  },
}));

export const LanguagesHeader = (props: LanguagesHeaderProps) => {
  const { selected, onClear, max } = props;
  const { t } = useTranslation("profile");
  const css = useStyles();

  return (
    <div className={css.languagesHeader}>
      <Typography
        className={css.headerItem}
        data-cy="profile.languages.header.lenthLabel"
      >
        {selected.length}/{max}
      </Typography>
      <Typography size="subtitle1(18px)" align="center" uppercase>
        {capitalize(t("all:profile_langs_title"))}
      </Typography>
      <IconButton onClick={onClear} data-cy="profile.languages.header.clear">
        <Icons.Option />
      </IconButton>
    </div>
  );
};

export const Languages = (props: LanguagesProps) => {
  const { selected, onSelectLanguage, includeAuto } = props;
  const { t } = useTranslation("all");

  const items = includeAuto
    ? [{ code: "auto", label: t("all:trans_settings_lang_detect") }, ...langs]
    : langs;

  return (
    <>
      {items.map((item) => (
        <LanguageItem
          key={item.code}
          item={item.code}
          checked={selected.includes(item.code)}
          label={item.label}
          onClick={(e, value) => {
            onSelectLanguage(e, value);
          }}
          data-cy={`profile.languages.items.item-${item.code}`}
        />
      ))}
    </>
  );
};
