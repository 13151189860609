import { modalManager } from "global-stores/modal-manager";
import { makeStyles } from "hooks/makeStyles";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DefaultPanel, IconButton, Zoom } from "ui/shared";
import { BackArrow } from "ui/shared/icons/icons";
import parseHtmlString from "utils/parseHtmlString";
import { motion } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  helperContainer: {
    position: "relative",
    backgroundColor: theme.colors.background.paper.topbar,
    width: 500,
    height: "auto",
    borderRadius: 10,
    overflow: "hidden",
    "&:focus": {
      outline: 0,
    },
  },
  scrollContainer: {
    overflowY: "auto",
    maxHeight: "50vh",
    boxSizing: "border-box",
    backgroundColor: theme.colors.background.paper.topbar,
    padding: `${theme.spacing(5.5)}`,
    fontFamily: theme.fonts.light,
  },
  backButton: {
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  headerImageContainer: {
    width: "100%",
    height: 200,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "60vh",
  },
  menu: {
    padding: `${theme.spacing(5.5)}`,
    "& div": {
      textAlign: "start",
      "&:not(:last-child)": {
        marginBottom: 32,
      },
    },
  },
}));

const HelperModal = observer(() => {
  const css = useStyles();
  const { t } = useTranslation("all");

  const modalName = modalManager.stack[modalManager.stack.length - 1];

  /**************************************************/

  const replaceBrokenImage = useCallback((e: any) => {
    e.target.src = "/images/karma_help.png";
    e.target.srcset = "/images/karma_help@2x.png 2x";
  }, []);

  /**************************************************/

  return (
    <motion.div layout>
      <div className={css.helperContainer}>
        {modalName !== "help_list" ? (
          <div className={css.backButton}>
            <Zoom in={!!modalManager.stack.length}>
              <IconButton onClick={() => modalManager.pop()}>
                <BackArrow />
              </IconButton>
            </Zoom>
          </div>
        ) : null}
        <div className={css.content}>
          {modalManager.stack.length && modalName !== "help_list" ? (
            <>
              <div className={css.headerImageContainer}>
                <img
                  src={`/images/${modalName}.png`}
                  srcSet={`/images/${modalName}@2x.png 2x`}
                  alt={`${modalName}`}
                  onError={replaceBrokenImage}
                />
              </div>
              <div className={css.scrollContainer}>
                {parseHtmlString(t(`all:${modalName}`), true)}
              </div>
            </>
          ) : (
            <DefaultPanel className={css.menu}>
              {parseHtmlString(t("all:help_list"), true)}
            </DefaultPanel>
          )}
        </div>
      </div>
    </motion.div>
  );
});

export default HelperModal;
