import React from "react";

interface SmallBubbleSecondProps {
  className?: string;
}

const SmallBubbleSecond = ({ className }: SmallBubbleSecondProps) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity="0.264"
        d="M11.2 1.20003C9.80005 0.500032 8.30002 0.300026 6.80002 0.600026C3.90002 1.10003 1.20001 2.80003 0.600013 3.70003C0.200013 4.40003 4.94793e-05 5.20002 0.200049 6.00002C0.400049 7.20002 2.30005 13.2 6.20005 14.1C7.60005 14.4 9.40006 14.2 11.4001 12.3C12.6001 11.1 13.2 9.40003 13.2 7.70003C13.2 5.40003 12.4 2.10003 11.2 1.20003Z"
        fill="#FDF8DD"
      />
    </svg>
  );
};

export default SmallBubbleSecond;
