import { userStore } from "global-stores/user-store";
import { makeStyles } from "hooks/makeStyles";
import { observer } from "mobx-react";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { Box, Button, IconButton, Icons, Input, Typography } from "ui/shared";
import {
  Editable,
  EditableContainer,
  HelperMessageType,
  wrappedLabel,
} from "../../shared/editable/editable";
import { profileStore } from "./store.profile";
import { Timer } from "global-stores/timer";

const useStyles = makeStyles((theme) => ({
  loginText: {
    textTransform: "none",
    lineHeight: 1,
  },
  emailLabelWrapper: {},
  editInfoInputWrapper: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    width: "100%",
    height: 40,
  },
  editInfoInput: {
    border: "0 !important",
    textAlign: "left",
    padding: 0,
    fontSize: "14px",
    lineHeight: 1,
    borderRadius: 0,
    width: "90%",
    backgroundColor: "transparent",
  },
  deleteEmailBtn: {
    display: "flex",
    justifyContent: "space-between",
  },
  confirmBtn: {
    height: 40,
    width: 40,
    padding: 0,
    flexShrink: 0,
    marginRight: 5,
  },
  cancelBtn: {
    height: 40,
    width: 40,
    padding: 0,
    flexShrink: 0,
    backgroundColor: theme.colors.purple.main,
  },
  helpMessage: {
    display: "inline-flex",
    fontSize: "14px",
    textTransform: "none",
    marginTop: theme.spacing(1),
    color: theme.colors.primary,
  },
  repeatButton: {
    display: "inline-flex",
    fontSize: "14px",
    color: theme.colors.primary,
    background: "none",
    border: "none",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const EmailEditable = observer(() => {
  const { t } = useTranslation("all");
  const css = useStyles();

  const [emailEdit, setEmailEdit] = useState(false);
  const [timer, setTimer] = useState(new Timer(30));

  const email =
    userStore.user && userStore.user.profile && userStore.user.profile.email;

  const verificationEmailSent =
    profileStore.verifcationEmail && email !== profileStore.email;

  /**************************************************/

  const handleEmailEdit = useCallback(() => {
    const value =
      profileStore.email || profileStore.verifcationEmail || email || "";
    profileStore.setEmail(value);
    profileStore.setEmailBeforeEdit(value);

    setEmailEdit(true);
  }, [setEmailEdit, email]);

  const handleEmailInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      profileStore.setEmail(e.target.value, e.target.value !== email);
    },
    []
  );

  const handleSetNewEmail = useCallback(() => {
    if (profileStore.isEmailDirty) profileStore.setNewEmail();
    setEmailEdit(false);
    profileStore.clearErrors();
    setEmailEdit(false);

    timer.start();
  }, [timer, setEmailEdit]);

  const handleDeleteVerification = useCallback(() => {
    profileStore.deleteVerificationEmail();
  }, []);

  const handleCancelEmailEdit = useCallback(() => {
    const value = email || profileStore.verifcationEmail || "";
    profileStore.setEmail(value);
    setEmailEdit(false);
  }, [setEmailEdit, email]);

  const renderTimer = useCallback(() => {
    return (
      <>
        <Typography className={css.helpMessage}>
          {t("all:verification_email_sent")}.
        </Typography>
        {timer.counter === 0 ? (
          <button
            className={css.repeatButton}
            onClick={() => handleSetNewEmail()}
          >
            {t("all:verification_code_repeat")}
          </button>
        ) : (
          <Typography className={css.helpMessage}>
            {t("all:verification_code_repeat_after").replace("$1", "")}{" "}
            {timer.counter}
          </Typography>
        )}
        <button
          className={css.repeatButton}
          onClick={() => handleDeleteVerification()}
        >
          {t("all:verification_code_cancel")}
        </button>
      </>
    );
  }, [timer, handleDeleteVerification, handleSetNewEmail]);

  const emailRender = useCallback(() => {
    return (
      profileStore.email ||
      profileStore.verifcationEmail ||
      email ||
      t("all:profile_placeholder_email")
    );
  }, [email]);

  const getMessageColor = useCallback(() => {
    if (profileStore.emailError) return HelperMessageType.Error;
    if (profileStore.message) return HelperMessageType.Success;
    if (profileStore.warning) return HelperMessageType.Warning;
  }, []);

  /**************************************************/

  const messageType =
    emailEdit && profileStore.isEmailDirty
      ? getMessageColor()
      : verificationEmailSent
      ? HelperMessageType.Warning
      : undefined;

  return (
    <Editable
      isEdit={emailEdit}
      label={wrappedLabel(
        <Typography size="caption(12px)" color="secondary" lowercase>
          {t("all:profile_email")}:
        </Typography>
      )}
      message={
        emailEdit
          ? profileStore.isEmailDirty
            ? profileStore.emailError ||
              profileStore.message ||
              profileStore.warning
            : ""
          : verificationEmailSent
          ? renderTimer()
          : null
      }
      messageType={messageType}
      onEdit={handleEmailEdit}
      view={
        <Box className={css.editInfoInputWrapper}>
          <Input
            disabled={!emailEdit}
            autoFocus
            className={css.editInfoInput}
            value={emailEdit ? profileStore.email : emailRender()}
            onChange={handleEmailInput}
            placeholder={t("all:profile_placeholder_email")}
            error={!!profileStore.emailError}
            succeed={!!profileStore.message}
            helperTextClassName={css.helperText}
            // onBlur={() => setEmailEdit(false)}
            onFocus={handleEmailInput}
            data-cy="email.input"
            type="email"
          />
          {emailEdit ? (
            <>
              <Button
                className={css.confirmBtn}
                primary
                onClick={handleSetNewEmail}
                disabled={
                  !profileStore.email ||
                  !!profileStore.emailError ||
                  !!profileStore.warning ||
                  !profileStore.isEmailDirty
                }
                data-cy="email.actions.continue"
              >
                OK
              </Button>
              <IconButton
                className={css.cancelBtn}
                onClick={handleCancelEmailEdit}
              >
                <Icons.Cross width={20} height={20} />
              </IconButton>
            </>
          ) : null}
        </Box>
      }
    />
  );
});

export default EmailEditable;
