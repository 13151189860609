import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const NotActiveKarma = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.NotActiveKarma}>
    {({ color, secondaryColor }) => (
      <>
        <g>
          <g>
            <g>
              <path
                d="M17.51,21.74a11.35,11.35,0,0,1-1.23-.07,10.47,10.47,0,0,1-.51-1.85A6.58,6.58,0,0,1,15.65,18a4.22,4.22,0,0,1,.46-1.7,7.85,7.85,0,0,1,2.26-2.46,11.45,11.45,0,0,1,2.75-1.4,15,15,0,0,1,3.13-.74A14.84,14.84,0,0,1,24.2,15a12.37,12.37,0,0,1-.77,3,7,7,0,0,1-1.93,2.7,4.36,4.36,0,0,1-1.9.87A9.94,9.94,0,0,1,17.51,21.74Z"
                transform="translate(0)"
                fill={secondaryColor ? "#655285" : secondaryColor}
              />
              <path
                d="M17.51,21.55a10.2,10.2,0,0,0,2-.19,4.32,4.32,0,0,0,1.83-.82,7.2,7.2,0,0,0,1.88-2.63A11.84,11.84,0,0,0,24,15a14.73,14.73,0,0,0,.07-3,14.61,14.61,0,0,0-2.91.73A10.53,10.53,0,0,0,18.47,14a7.25,7.25,0,0,0-2.22,2.38A4.06,4.06,0,0,0,15.82,18a7.64,7.64,0,0,0,.12,1.74,14.74,14.74,0,0,0,.46,1.73,9.58,9.58,0,0,0,1.11.05m0,.36a12.76,12.76,0,0,1-1.38-.07s-1.42-3.44-.21-5.59c2.38-4.23,8.47-4.72,8.47-4.72s.82,6.14-2.79,9.27A6.52,6.52,0,0,1,17.51,21.91Z"
                transform="translate(0)"
                fill={color ? "#fff" : color}
              />
            </g>
            <g>
              <path
                d="M14.58,21.74h0a10.66,10.66,0,0,1-2.07-.19,4,4,0,0,1-1.9-.87A7.67,7.67,0,0,1,8.68,18a11.1,11.1,0,0,1-.79-3,15.29,15.29,0,0,1-.07-3.25A14.72,14.72,0,0,1,11,12.5a12.29,12.29,0,0,1,2.76,1.39A7.41,7.41,0,0,1,16,16.37a3.94,3.94,0,0,1,.45,1.69,7.27,7.27,0,0,1-.12,1.76,11.76,11.76,0,0,1-.5,1.85A11.35,11.35,0,0,1,14.58,21.74Z"
                transform="translate(0)"
                fill={secondaryColor ? "#655285" : secondaryColor}
              />
              <path
                d="M14.58,21.55a9.84,9.84,0,0,0,1.11-.05,8.87,8.87,0,0,0,.46-1.71,7.43,7.43,0,0,0,.12-1.71,4,4,0,0,0-.44-1.61h0a7.29,7.29,0,0,0-2.21-2.41,10.83,10.83,0,0,0-2.72-1.37A14.09,14.09,0,0,0,8,12a14.47,14.47,0,0,0,.1,3,12,12,0,0,0,.77,2.94,7,7,0,0,0,1.88,2.62,4,4,0,0,0,1.83.82,12,12,0,0,0,2,.17h0m0,.36a6.59,6.59,0,0,1-4.09-1.11c-3.64-3.13-2.84-9.27-2.84-9.27s6.11.49,8.5,4.75c1.22,2.14-.22,5.56-.22,5.56A11.83,11.83,0,0,1,14.58,21.91Z"
                transform="translate(0)"
                fill={color ? "#fff" : color}
              />
            </g>
            <g>
              <path
                d="M16,23a12.93,12.93,0,0,1-1.8-1.3,8.75,8.75,0,0,1-1.4-1.49A4.73,4.73,0,0,1,12,18.3h0a8.8,8.8,0,0,1,.27-3.85,12.94,12.94,0,0,1,1.47-3.25A16.31,16.31,0,0,1,16,8.26a17.14,17.14,0,0,1,2.29,3,12.5,12.5,0,0,1,1.45,3.25A8.36,8.36,0,0,1,20,18.33a4.18,4.18,0,0,1-.82,1.78,10.38,10.38,0,0,1-1.37,1.52A15.94,15.94,0,0,1,16,23Z"
                transform="translate(0)"
                fill={secondaryColor ? "#655285" : secondaryColor}
              />
              <path
                d="M16,22.73a13.92,13.92,0,0,0,1.66-1.27,6.54,6.54,0,0,0,2.15-3.2,8.42,8.42,0,0,0-.22-3.74,12.93,12.93,0,0,0-1.42-3.2,17,17,0,0,0-2.14-2.81,14.89,14.89,0,0,0-2.14,2.79,13.33,13.33,0,0,0-1.45,3.2,8.77,8.77,0,0,0-.26,3.78h0A4.43,4.43,0,0,0,13,20.06a7.23,7.23,0,0,0,1.34,1.47A17.9,17.9,0,0,0,16,22.73m0,.44s-3.76-2.05-4.19-4.87C10.94,12.81,16.05,8,16.05,8s5.13,4.91,4.14,10.32C19.68,20.93,16.05,23.17,16.05,23.17Z"
                transform="translate(0)"
                fill={color ? "#fff" : color}
              />
            </g>
            <g>
              <path
                d="M20.52,24a13.47,13.47,0,0,1-4.48-1,10.24,10.24,0,0,1,.12-1.9,7.14,7.14,0,0,1,.53-1.71A4,4,0,0,1,17.82,18a9.24,9.24,0,0,1,3.51-1.62A15.83,15.83,0,0,1,25,16a18.56,18.56,0,0,1,3.49.32,14.45,14.45,0,0,1-1.25,3,11.6,11.6,0,0,1-1.95,2.55,8.43,8.43,0,0,1-3.08,1.92A5.73,5.73,0,0,1,20.52,24Z"
                transform="translate(0)"
                fill={secondaryColor ? "#655285" : secondaryColor}
              />
              <path
                d="M20.52,23.82a5,5,0,0,0,1.54-.21,8.52,8.52,0,0,0,3-1.88A11.51,11.51,0,0,0,27,19.22a13.64,13.64,0,0,0,1.2-2.79A21.56,21.56,0,0,0,25,16.17a14.93,14.93,0,0,0-3.59.41,9,9,0,0,0-3.44,1.59h0a3.77,3.77,0,0,0-1.08,1.37,7.42,7.42,0,0,0-.51,1.63,8.82,8.82,0,0,0-.12,1.74,13.31,13.31,0,0,0,4.29.91m0,.36a13.87,13.87,0,0,1-4.65-1s-.29-3.68,1.83-5.3a11.9,11.9,0,0,1,7.24-2.07,18.6,18.6,0,0,1,3.71.36s-1.37,6-6.47,7.8A5.21,5.21,0,0,1,20.52,24.18Z"
                transform="translate(0)"
                fill={color ? "#fff" : color}
              />
            </g>
            <g>
              <path
                d="M11.28,24h0a4.7,4.7,0,0,1-1.61-.24,8.9,8.9,0,0,1-3.08-1.92A10.5,10.5,0,0,1,4.64,19.3a13.45,13.45,0,0,1-1.25-3A18.66,18.66,0,0,1,6.81,16a15.53,15.53,0,0,1,3.66.41A9.21,9.21,0,0,1,14,18a4.16,4.16,0,0,1,1.14,1.45,6.49,6.49,0,0,1,.53,1.71,9.16,9.16,0,0,1,.12,1.88c-.22.07-.73.26-1.38.45A13.56,13.56,0,0,1,11.28,24Z"
                transform="translate(0)"
                fill={secondaryColor ? "#655285" : secondaryColor}
              />
              <path
                d="M11.28,23.82a13.39,13.39,0,0,0,4.31-.91c0-.8,0-3.49-1.71-4.77h0a8.91,8.91,0,0,0-3.46-1.59,15.63,15.63,0,0,0-3.61-.41,19.62,19.62,0,0,0-3.21.27A14.2,14.2,0,0,0,4.81,19.2a11.12,11.12,0,0,0,1.92,2.5,7.94,7.94,0,0,0,3,1.88,4,4,0,0,0,1.51.24h0m0,.36a5.33,5.33,0,0,1-1.66-.24c-5.1-1.78-6.47-7.82-6.47-7.82a18.88,18.88,0,0,1,3.63-.34,12.16,12.16,0,0,1,7.32,2.07c2.12,1.64,1.83,5.3,1.83,5.3A13.87,13.87,0,0,1,11.28,24.18Z"
                transform="translate(0)"
                fill={color ? "#fff" : color}
              />
            </g>
          </g>
        </g>
      </>
    )}
  </CreateSvgIcon>
);
