import utf8 from "utf8";

const MAX_MESSAGE_LENGTH = 4096;

export const utf8base64lengthFromString = (str: string) => {
  const textUTF8 = utf8.encode(str);
  const textLength = ((textUTF8.length + 16) * 4) / 3 + 1;

  return textLength;
};

export const validateMessageLength = (message: string) => {
  return utf8base64lengthFromString(message) < MAX_MESSAGE_LENGTH;
};

const getLastSpaceIndex = (str: string) => {
  return str.lastIndexOf(" ");
};

const splitMessage = (message: string, acc: string[] = []): string[] => {
  const isMessageValidLength = validateMessageLength(message);
  if (isMessageValidLength) {
    acc.push(message);
    return acc;
  }

  const hashLength = utf8base64lengthFromString(message);
  const diff = MAX_MESSAGE_LENGTH / hashLength;

  let exactSplitSimbol = Math.floor(message.length * diff) - 100;
  let messageCandidate = message.slice(0, exactSplitSimbol);

  while (!validateMessageLength(messageCandidate)) {
    exactSplitSimbol -= 100;
    messageCandidate = message.slice(0, exactSplitSimbol);
  }

  const closestSpaceIndex = getLastSpaceIndex(
    message.slice(0, exactSplitSimbol)
  );
  const validMessage = message.slice(0, closestSpaceIndex);
  const passthrough = message.slice(closestSpaceIndex + 1);

  acc.push(validMessage);

  return splitMessage(passthrough, acc);
};

export const prepareAndSplitMessage = (message: string) => {
  const isMessageValidLength = validateMessageLength(message);

  if (isMessageValidLength) {
    return [message];
  } else {
    // Split message to parts with valid base64 lengthes
    return splitMessage(message);
  }
};
