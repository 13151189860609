import React, { useRef, useCallback } from "react";
import { makeStyles } from "hooks/makeStyles";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Icons,
  Button,
  Box,
  IconButton,
  Switch,
  Textarea,
} from "ui/shared";
import { Friend } from "lib";
import { deleteDialogStore } from "ui/pages/chats/stores/delete-dialog-store";
import clsx from "clsx";
import { darkTheme } from "lib/theme";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { ReasonItem } from "ui/pages/chats/reason-item";
import { observer } from "mobx-react";
import { fade } from "utils";

export interface DeleteDialogModalProps {
  friend: Friend;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(5)} ${theme.spacing(5.5)} ${theme.spacing(5.5)}`,
    boxSizing: "border-box",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  row: {
    display: "flex",
  },
  centered: {
    justifyContent: "center",
    alignItems: "center",
  },
  login: {
    marginBottom: theme.spacing(2.5),
  },
  ratingButton: {
    height: 46,
    width: "100%",
    maxWidth: 112,
  },
  like: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.colors.primary,
    borderRadius: "29px 2px 2px 29px",
    "&:hover": {
      backgroundColor: fade(`${theme.colors.primary}`, 0.6),
    },
  },
  dislike: {
    borderRadius: "2px 29px 29px 2px",
    backgroundColor: theme.colors.background.paper.lighter,
  },
  dislikeIcon: {
    transform: "scale(1, -1)",
  },
  deleteButton: {
    padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
    marginBottom: theme.spacing(2.5),
    backgroundColor: theme.colors.background.paper.lighter,
  },
  switchLabel: {
    marginRight: theme.spacing(1.5),
  },
  textarea: {
    border: "none",
    padding: 0,
    color: theme.colors.textPrimary,
    backgroundColor: "transparent",
    resize: "none",
    margin: `${theme.spacing(5)} ${theme.spacing(0)} ${theme.spacing(5.5)}`,
    borderRadius: 0,
    "&:focus": {
      border: "none",
      outline: 0,
    },
  },
}));

export const DeleteDialogModal = observer((props: DeleteDialogModalProps) => {
  const { friend } = props;
  const { t } = useTranslation("all");
  const css = useStyles();

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    deleteDialogStore.initList();
  }, []);

  /**************************************************/

  const handleDeleteDialog = useCallback(() => {
    deleteDialogStore.delete(friend._id);
  }, [friend]);

  const handleLike = useCallback(() => {
    deleteDialogStore.like(friend._id, true);
  }, [friend]);

  const handleDisLike = useCallback(() => {
    deleteDialogStore.setIsOpenDislike(true);
  }, []);

  const handleSelectReason = useCallback(
    (e: React.MouseEvent, item: string) => {
      deleteDialogStore.setReasons(item);
      deleteDialogStore.setReason(item);
    },
    []
  );

  const handleTogglePickup = useCallback((value: boolean) => {
    deleteDialogStore.setAddToBlacklist(value);
  }, []);

  const handleChangeDescription = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.value.length <= 120) {
        deleteDialogStore.setDeleteDescription(e.target.value);
      }
    },
    []
  );

  const handleDeleteAndReport = useCallback(() => {
    deleteDialogStore.dislike(friend._id);
  }, [friend]);

  /**************************************************/

  return (
    <div className={css.container}>
      <motion.div layout>
        <div className={css.body}>
          {!deleteDialogStore.isOpenDislike ? (
            <>
              <Typography
                size="body(14px)"
                align="center"
                className={css.login}
              >
                {friend.friendLogin}
              </Typography>
              <Typography size="body(14px)" align="center">
                {t("all:friend_dialog_rate")}
              </Typography>
              <Box mt={6} mb={2.5}>
                <div className={clsx(css.row, css.centered)}>
                  <IconButton
                    className={clsx(css.ratingButton, css.like)}
                    onClick={handleLike}
                  >
                    <Icons.Like
                      width={40}
                      height={40}
                      color="transparent"
                      secondaryColor={darkTheme.colors.background.paper.default}
                      viewBox="0 0 42 42"
                    />
                  </IconButton>
                  <IconButton
                    className={clsx(css.ratingButton, css.dislike)}
                    onClick={handleDisLike}
                  >
                    <Icons.Like
                      width={40}
                      height={40}
                      color="transparent"
                      secondaryColor={darkTheme.colors.background.paper.default}
                      viewBox="0 0 42 42"
                      className={css.dislikeIcon}
                    />
                  </IconButton>
                </div>
              </Box>
              <Button
                className={clsx(css.deleteButton)}
                onClick={handleDeleteDialog}
              >
                {t("all:friend_dialog_just_del")}
              </Button>
              <div className={clsx(css.switchWrap, css.row, css.centered)}>
                <Typography className={css.switchLabel}>
                  {t("all:friend_dialog_ignore_checkbox")}
                </Typography>
                <Switch
                  defaultBgColor={darkTheme.colors.purple.main}
                  checked={deleteDialogStore.addToBlacklist}
                  onChange={(value) => handleTogglePickup(value)}
                />
              </div>
            </>
          ) : (
            <>
              <div>
                {deleteDialogStore.reasons?.map((item) => (
                  <ReasonItem
                    key={item.name}
                    item={item.name}
                    checked={item.isChecked}
                    label={item.label}
                    onClick={(e, item) => handleSelectReason(e, item)}
                  />
                ))}
              </div>
              <Textarea
                ref={textAreaRef}
                value={deleteDialogStore.deleteDescription}
                onChange={handleChangeDescription}
                minRows={1}
                maxRows={10}
                placeholder={`${t("all:friend_dialog_abuse_details")}`}
                className={css.textarea}
              />
              <Button
                className={css.deleteButton}
                onClick={handleDeleteAndReport}
              >
                {t("all:friend_dialog_delete_block")}
              </Button>
            </>
          )}
        </div>
      </motion.div>
    </div>
  );
});
