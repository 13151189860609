import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const ArrowWithoutTail = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.ArrowWithoutTail}>
    {({ color }) => (
      <>
        <circle cx="20" cy="20" r="20" opacity=".296" fill={color} />
        <path
          d="M8.9 16c.4 0 .8.2 1 .4L20.4 27 31 16.5c.4-.4 1.1-.5 1.5-.1l.1.1c.5.5.5 1.3 0 1.7L21.2 29.5c-.5.5-1.3.5-1.7 0L8.1 18.1c-.5-.5-.5-1.3 0-1.7.2-.2.4-.3.8-.4z"
          fill={color}
        />
      </>
    )}
  </CreateSvgIcon>
);
