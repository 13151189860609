import React, { useCallback } from "react";
import { makeStyles } from "hooks/makeStyles";
import clsx from "clsx";

export interface ToggleButtonGroupProps {
  fullWidth?: boolean;
  value?: string | string[];
  onChange?: (e: React.MouseEvent, value?: string | string[]) => void;
  exclusive?: boolean;
  className?: string;
  children: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
  },
  fullWidth: {
    width: "100%",
  },
}));

export const ToggleButtonGroup = (props: ToggleButtonGroupProps) => {
  const {
    fullWidth,
    value,
    onChange,
    exclusive,
    className,
    children,
    ...passthrough
  } = props;
  const css = useStyles();

  const cx = clsx(css.root, className, {
    [css.fullWidth]: fullWidth,
  });

  /**************************************************/

  const handleChange = useCallback(
    (e: React.MouseEvent, buttonValue: string) => {
      if (!onChange) {
        return;
      }

      const index = value ? value.indexOf(buttonValue) : -1;
      let newValue;

      if (value && index >= 0) {
        newValue = [...(value as string[])];
        newValue.splice(index, 1);
      } else {
        newValue = value
          ? [...(value as string[]), buttonValue]
          : [buttonValue];
      }

      onChange(e, newValue);
    },
    [onChange, value]
  );

  const handleExclusiveChange = useCallback(
    (e: React.MouseEvent, buttonValue: string) => {
      if (!onChange) {
        return;
      }

      onChange(e, value === buttonValue ? undefined : buttonValue);
    },
    [onChange, value]
  );

  const isValueSelected = useCallback(
    (value?: string, values?: string | string[]) => {
      if (values == null || value == null) {
        return false;
      }

      if (Array.isArray(values)) {
        return values.indexOf(value) >= 0;
      }

      return value === values;
    },
    []
  );

  /**************************************************/

  return (
    <div className={cx} {...passthrough}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        const { selected: toggleButtonSelected, value: toggleButtonValue } =
          child.props;

        const selected =
          toggleButtonSelected == null
            ? isValueSelected(toggleButtonValue, value)
            : toggleButtonSelected;

        return React.cloneElement(child, {
          selected,
          onChange: exclusive ? handleExclusiveChange : handleChange,
        });
      })}
    </div>
  );
};
