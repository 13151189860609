import QrScanner from "qr-scanner";
import QRCode from "qrcode";
import { action, observable } from "mobx";
import { encriptionStore } from "./encryption-store";

class QRCodeStore {
  private scanner?: QrScanner;

  @observable loading: boolean = false;
  @observable error?: string;

  initScanner(
    video: HTMLVideoElement,
    onDecode: (result: QrScanner.ScanResult) => void,
    onDecodeError?: (error: string | Error) => void,
    preferredFacingMode?: "environment" | "user"
  ) {
    if (this.scanner) {
      this.scanner.destroy();
    }

    const options = {
      highlightScanRegion: true,
      highlightCodeOutline: true,
      onDecodeError,
      preferredCamera: preferredFacingMode,
    };
    this.scanner = new QrScanner(video, onDecode, options);
  }

  scan() {
    if (this.scanner) {
      this.scanner.start();
    }
  }

  stop() {
    if (this.scanner) {
      this.scanner.stop();
    }
  }

  parseQRCodeString(str: string) {
    return encriptionStore.decodeAccountString(str);
  }

  scanImage(img: File, onDecode: (result: QrScanner.ScanResult) => void) {
    QrScanner.scanImage(img, { returnDetailedScanResult: true })
      .then((res) => onDecode(res))
      .catch((err) => console.log(err));
  }

  async generateQRCode(value: string) {
    try {
      this.loading = true;
      const qrcode = await QRCode.toDataURL(value);
      this.loading = false;

      return qrcode;
    } catch (err) {
      console.log(err);
      this.error = err;
    }
  }

  @action
  clear() {
    this.loading = false;
    this.error = undefined;
  }
}

export const qrCodeStore = new QRCodeStore();
