import React, { useContext, ButtonHTMLAttributes } from "react";
import { makeStyles } from "hooks/makeStyles";
import { Button, TabsContext } from "ui/shared";
import { lighten } from "utils/fade";
import clsx from "clsx";

export interface TabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  value: string | number;
  children: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  tab: {
    padding: `0 ${theme.spacing(2.5)}`,
    margin: 0,
    height: "100%",
    boxSizing: "border-box",
    display: "block",
    borderRadius: 0,
    borderBottom: `2px solid transparent`,
    "&:focus": {
      animation: "none",
      backgroundColor: theme.colors.background.paper.lighter,
      borderBottom: `2px solid ${theme.colors.purple.main}`,
    },
    "&$active": {
      backgroundColor: theme.colors.background.paper.lighter,
      borderBottom: `2px solid ${theme.colors.primary}`,
    },
  },
  active: {},
}));

export const Tab = (props: TabProps) => {
  const { value, children, ...passthrough } = props;
  const css = useStyles();

  const { value: contextValue, onChange } = useContext(TabsContext);

  return (
    <Button
      id={`tab-${value}`}
      role="tab"
      onClick={() => onChange(value)}
      className={clsx(css.tab, { [css.active]: value === contextValue })}
      {...passthrough}
    >
      {children}
    </Button>
  );
};
