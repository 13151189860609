import React, { useState, useEffect, useCallback } from "react";
import { useEmblaCarousel } from "embla-carousel/react";
import { Thumb } from "./embla-carousel-thumb";
import "./embla.css";
import { useTracker } from "hooks/useTracker";
import { Meteor } from "meteor/meteor";
import { Avatars } from "collections/avatars";
import { Avatar } from "lib";
import { observer } from "mobx-react";
import { Button, IconButton, Icons } from "ui/shared";
import { avatarsStore } from "../modals/avatars-modal/store.avatars-modal";
import { useTranslation } from "react-i18next";

const EmblaCarousel = observer(() => {
  const { t } = useTranslation("all");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [mainViewportRef, embla] = useEmblaCarousel({ skipSnaps: false });
  const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
    containScroll: "keepSnaps",
    selectedClass: "",
    dragFree: true,
  });

  const { myLoading, myAvatars }: { myLoading: boolean; myAvatars: Avatar[] } =
    useTracker(() => {
      const handle = Meteor.subscribe("avatars.u");

      const avatars = Avatars.find({}, { sort: { updateAt: -1 } }).fetch();

      return {
        myLoading: !handle.ready(),
        myAvatars: avatars,
      };
    }, []);

  /**************************************************/

  const onThumbClick = useCallback(
    (index) => {
      if (!embla || !emblaThumbs) return;
      if (emblaThumbs.clickAllowed()) embla.scrollTo(index);
    },
    [embla, emblaThumbs]
  );

  const onSelect = useCallback(() => {
    if (!embla || !emblaThumbs) return;
    setSelectedIndex(embla.selectedScrollSnap());
    emblaThumbs.scrollTo(embla.selectedScrollSnap());
  }, [embla, emblaThumbs, setSelectedIndex]);

  const handleSetMain = useCallback((id: string) => {
    avatarsStore.setMainAvatar(id);
  }, []);

  const handleDeleteAvatar = useCallback((id: string) => {
    avatarsStore.deleteMyAvatar(id);
  }, []);

  const renderFooterButtons = useCallback(() => {
    const selectedAvatar = myAvatars[selectedIndex];

    return selectedAvatar ? (
      <div className="embla__slide--footer">
        <div className="footer__left">
          <IconButton onClick={() => handleDeleteAvatar(selectedAvatar._id)}>
            <Icons.Trash />
          </IconButton>
        </div>
        <div className="footer__center">
          {selectedAvatar.permitted === 0 ? (
            <div className="footer__blocked">{t("all:avatars_rejected")}</div>
          ) : selectedAvatar.main ? null : (
            <Button
              primary
              onClick={() => handleSetMain(selectedAvatar._id)}
              className="footer__btn"
            >
              {t("all:avatars_button_select")}
            </Button>
          )}
        </div>
        <div className="footer__right"></div>
      </div>
    ) : null;
  }, [selectedIndex, myAvatars]);

  /**************************************************/

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
  }, [embla, onSelect]);

  useEffect(() => {
    embla?.reInit();
    const mainAvatarIndex = myAvatars.findIndex((el) => el.main);
    if (mainAvatarIndex > -1) embla?.scrollTo(mainAvatarIndex);
  }, [embla, myAvatars]);

  return (
    <>
      <div className="embla">
        {renderFooterButtons()}
        <div className="embla__viewport" ref={mainViewportRef}>
          <div className="embla__container">
            {myAvatars.map((avatar: Avatar) => (
              <div className="embla__slide" key={avatar._id}>
                <div className="embla__gradient"></div>
                <div
                  className="embla__slide__inner"
                  style={{ width: "100%", height: 590 }}
                >
                  <img
                    className="embla__slide__img"
                    src={avatar.high || avatar.low}
                    alt="User avatar"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="embla embla--thumb">
        <div className="embla__viewport" ref={thumbViewportRef}>
          <div className="embla__container embla__container--thumb">
            {myAvatars.map((avatar: Avatar, index: number) => (
              <Thumb
                onClick={() => onThumbClick(index)}
                selected={index === selectedIndex}
                imgSrc={avatar.high || avatar.low}
                isMain={avatar.main}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
});

export default EmblaCarousel;
