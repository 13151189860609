import { callWithPromise, storage } from "utils";
import moment from "moment";
import { action, computed, observable } from "mobx";
import { toastStore } from "./toasts-store";
import { Maybe, Message, ToastType } from "lib";
import { modalManager } from "./modal-manager";
import { MODALS } from "lib/constants";
import { i18n } from "services/i18n";
import { chatsStore } from "./chats-store";
import { translationLangsValidation } from "utils/validation";
import { getBestTranslation, getDetectedLang } from "utils/translations";
import { Meteor } from "meteor/meteor";
import { Messages } from "collections/messages";

interface AzureToken {
  token: string;
  millisecondsToExpire: number;
  endpoint: string;
  isGlobalTranslationEnabled: boolean;
  createdAt: number;
}

const AZURE_STORAGE_KEY = "azure_token";
const AZURE_DAILY_LIMIT_STORAGE_KEY = "azure_daily_limit";
const TRANSLATOR_GLOBAL_ENABLED = "translator_global_enabled";

const LIMIT_ERROR = "translate.getAzure.limit";
const CREDENTIALS_ERROR_CODE = 401000;

class Translator {
  @observable isTranslatingMyMessage: boolean = false;
  @observable isTranslatingFriendMessage: { [key: string]: boolean } = {};

  @observable initialized = false;
  @observable myInitialLang: Maybe<string> = null;
  @observable failedToInitialize = false;
  @observable isGlobalTranslationEnabled = true;
  @observable dailyLimitReached = { date: Date.now(), value: false };

  private token?: AzureToken;
  private expiredDate?: Date;

  private lastCall?: moment.Moment;

  constructor() {
    this.getTokenFromStorage();
  }

  @computed
  get isEnabled() {
    return this.isGlobalTranslationEnabled && !this.isDailyLimitReached;
  }

  @action
  setIsGlobalTranslationEnabled(value: boolean) {
    this.isGlobalTranslationEnabled = value;
    storage.write(TRANSLATOR_GLOBAL_ENABLED, value);
  }

  @action
  async translateMessageWithValidation(message: string) {
    const friendLang =
      chatsStore.selectedChatStore!.translationSettings!.friendLang;
    const myLang = chatsStore.selectedChatStore!.translationSettings!.myLang;

    const validFriendLang = friendLang
      ? friendLang === "auto"
        ? await this.autodetectFriendLang()
        : friendLang
      : null;

    if (!validFriendLang) {
      toastStore.showToast({
        message: i18n.t("all:trans_alert_partner_lang_text"),
      });
      return;
    }

    const langsIsValid = translationLangsValidation(validFriendLang, myLang);

    if (!langsIsValid) return;

    const translated = await this.translateText(
      message,
      validFriendLang,
      myLang
    );

    if (translated && translated.length && !translated.error) {
      const translatedMessage = getBestTranslation(translated);
      return translatedMessage.text;
    }
  }

  @action
  async translateAndSendMyMessage(callback?: () => void) {
    const friendLang =
      chatsStore.selectedChatStore!.translationSettings!.friendLang;
    const myLang = chatsStore.selectedChatStore!.translationSettings!.myLang;

    const validFriendLang = friendLang
      ? friendLang === "auto"
        ? await this.autodetectFriendLang()
        : friendLang
      : null;

    if (!validFriendLang) {
      toastStore.showToast({
        message: i18n.t("all:trans_alert_partner_lang_text"),
      });
      return;
    }

    const langsIsValid = translationLangsValidation(validFriendLang, myLang);

    if (!langsIsValid) return;

    const translated = await this.translateText(
      chatsStore.messageInput,
      validFriendLang,
      myLang
    );

    if (translated && translated.length && !translated.error) {
      const translatedMessage = getBestTranslation(translated);
      await chatsStore.sendMessage(translatedMessage.text, callback);
      chatsStore.setMessageInput("");
    } else {
      // Some error occurs while tranlate text
      if (this.initialized) {
        toastStore.showToast({
          message: i18n.t("all:trans_alert_wrong_lang"),
          type: ToastType.Error,
        });
      }
    }
  }

  @action
  async translateMyMessage() {
    if (
      chatsStore.messageInput &&
      chatsStore.selectedChatStore &&
      chatsStore.selectedChatStore.translationSettings
    ) {
      this.isTranslatingMyMessage = true;
      const friendLang =
        chatsStore.selectedChatStore.translationSettings.friendLang;

      const validFriendLang = friendLang
        ? friendLang === "auto"
          ? await this.autodetectFriendLang()
          : friendLang
        : null;

      if (!validFriendLang) {
        toastStore.showToast({
          message: i18n.t("all:trans_alert_partner_lang_text"),
        });
        this.isTranslatingMyMessage = false;
        return;
      }

      const myLang = chatsStore.selectedChatStore.translationSettings.myLang;

      const langsIsValid = translationLangsValidation(validFriendLang, myLang);

      if (!langsIsValid) {
        this.isTranslatingMyMessage = false;
        return;
      }

      const translated = await this.translateText(
        chatsStore.messageInput,
        validFriendLang,
        myLang
      );
      if (translated && translated.length && !translated.error) {
        const translatedMessage = getBestTranslation(translated);
        chatsStore.setMessageInput(translatedMessage.text, true);
      } else {
        // Some error occurs while tranlate text
        if (this.initialized) {
          toastStore.showToast({
            message: i18n.t("all:trans_alert_wrong_lang"),
            type: ToastType.Error,
          });
        }
      }
      this.isTranslatingMyMessage = false;
    }
  }

  @action
  async translateFriendMessage(
    message: Message,
    toLang?: string,
    fromLang?: string
  ) {
    if (!message.messageText) return;

    if (!toLang) {
      toastStore.showToast({ message: i18n.t("all:trans_alert_my_lang_text") });
      return;
    }
    this.isTranslatingFriendMessage[message._id] = true;

    const friendLang = fromLang ? (fromLang === "auto" ? "" : fromLang) : "";

    const langsIsValid = translationLangsValidation(toLang, friendLang);

    if (!langsIsValid) {
      this.isTranslatingFriendMessage[message._id] = false;
      return;
    }

    const decryptedOriginalMessageText = chatsStore.decryptMessage(
      message,
      undefined,
      { dropCache: true }
    );
    if (!decryptedOriginalMessageText) {
      this.isTranslatingFriendMessage[message._id] = false;
      return;
    }

    // Check if already translated
    const decryptedTranslatedMessageText =
      message.messageTextT &&
      chatsStore.decryptMessage(message, undefined, {
        useTranslation: true,
        dropCache: true,
      });
    const isAlreadyTranslatedToThisLang =
      decryptedTranslatedMessageText &&
      decryptedTranslatedMessageText.substring(0, 2) === toLang;

    if (isAlreadyTranslatedToThisLang) {
      this.isTranslatingFriendMessage[message._id] = false;
      return;
    }

    const translated = await translator.translateText(
      decryptedOriginalMessageText,
      toLang!,
      friendLang
    );

    const translatedMessage = getBestTranslation(translated);

    if (!friendLang) {
      // Set detected language to translation settings
      const detectedFriendLang = getDetectedLang(translated);

      if (detectedFriendLang && chatsStore.selectedChatStore) {
        chatsStore.selectedChatStore.setTranslationSettings({
          friendLang: detectedFriendLang,
        });
      }
    }

    const encrypted =
      translatedMessage &&
      toLang &&
      chatsStore.encryptTranslatedMessage(toLang + translatedMessage.text);

    if (encrypted) {
      // Set message translation in Meteor
      Meteor.call("messages.setTranslate", {
        messageId: message._id,
        messageTextT: encrypted,
      });
    }

    this.isTranslatingFriendMessage[message._id] = false;
  }

  @action
  async autodetectFriendLang() {
    if (chatsStore.selectedChatStore) {
      const lastMessage = Messages.findOne(
        { friendId: chatsStore.selectedChatStore.friendId },
        { sort: { createdAt: -1 } }
      );

      if (lastMessage && lastMessage.messageText && !lastMessage.service) {
        const decriptedText = chatsStore.decryptMessage(
          lastMessage,
          undefined,
          { dropCache: true }
        );
        const myLang =
          chatsStore.selectedChatStore &&
          chatsStore.selectedChatStore.translationSettings &&
          chatsStore.selectedChatStore.translationSettings.myLang;

        if (!myLang || !decriptedText) {
          return;
        }

        const translated = await this.translateText(decriptedText, myLang);
        const detectedFriendLang = getDetectedLang(translated);

        if (detectedFriendLang) {
          chatsStore.selectedChatStore.setTranslationSettings({
            friendLang: detectedFriendLang,
          });
          return detectedFriendLang;
        }
      }
    }
  }

  async translateText(text: string, toLangCode: string, fromLangCode?: string) {
    // If turned off
    if (!this.isGlobalTranslationEnabled || this.isDailyLimitReached) return;

    // Check token expired
    if (!this.token || this.isTokenExpired) {
      await this.getAzureToken(true);
    }

    return this._translateText(text, toLangCode, fromLangCode);
  }

  private async _translateText(
    text: string,
    toLangCode: string,
    fromLangCode?: string
  ) {
    if (!this.token) return;
    const cleanedText = text.replace(/00:00:/g, "");

    const to = toLangCode ? `&to=${toLangCode}` : "";
    const from = fromLangCode ? `&from=${fromLangCode}` : "";

    const url = this.token.endpoint + "/translate?api-version=3.0" + from + to;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.token.token}`,
        "Content-Type": "application/json",
        "Content-Length": `${text.length}`,
      },
      body: JSON.stringify([{ Text: cleanedText }]),
    });
    const result = await response.json();

    if (result.error && result.error.code === CREDENTIALS_ERROR_CODE) {
      await this.getAzureToken();
      const newAttemptResponse: any = await this._translateText(
        text,
        toLangCode,
        fromLangCode
      );
      return newAttemptResponse;
    }

    return result;
  }

  get isTokenExpired() {
    return this.expiredDate ? new Date() > this.expiredDate : true;
  }

  @computed
  get isDailyLimitReached() {
    return (
      moment(this.dailyLimitReached.date).isSame(moment(), "day") &&
      this.dailyLimitReached.value
    );
  }

  async getTokenFromStorage(showError?: boolean) {
    try {
      this.myInitialLang = navigator.language.substring(0, 2);
      const token = await storage.read(AZURE_STORAGE_KEY, true, true);
      const isGlobalEnabled = await storage.read(
        TRANSLATOR_GLOBAL_ENABLED,
        true,
        true
      );
      const limit = await storage.read(
        AZURE_DAILY_LIMIT_STORAGE_KEY,
        true,
        true
      );
      if (token) {
        this.token = token;
        this.expiredDate = moment(this.token!.createdAt)
          .add(this.token!.millisecondsToExpire / 1000, "seconds")
          .toDate();
        this.initialized = true;
        this.failedToInitialize = false;
      }
      if (limit) {
        this.dailyLimitReached = limit;
      }

      this.isGlobalTranslationEnabled =
        isGlobalEnabled != null ? isGlobalEnabled : true;
    } catch (err) {
      console.log(err);
      this.initialized = false;
      this.failedToInitialize = true;
    }
  }

  async getAzureToken(showError?: boolean) {
    // Allow try another request only after 30 seconds
    const now = moment();
    const isRequestAllowed = this.lastCall
      ? now.diff(this.lastCall, "seconds") > 30
      : true;

    if (!isRequestAllowed || this.isDailyLimitReached) {
      return;
    }

    try {
      this.token = (await callWithPromise(
        "translate.getAzureToken"
      )) as AzureToken;
      if (this.token) {
        this.expiredDate = moment(new Date())
          .add(this.token.millisecondsToExpire / 1000, "seconds")
          .toDate();
        storage.write(AZURE_STORAGE_KEY, {
          ...this.token,
          createdAt: Date.now(),
        });
        storage.write("AZURE_DAILY_LIMIT_STORAGE_KEY", {
          date: Date.now(),
          value: false,
        });
      }
      this.failedToInitialize = false;
      this.dailyLimitReached = {
        date: Date.now(),
        value: false,
      };
      this.initialized = true;
    } catch (err) {
      if ((err as any).error === LIMIT_ERROR) {
        this.dailyLimitReached = {
          date: Date.now(),
          value: true,
        };
        modalManager.open(MODALS.TRANSLATE_VIP_ALERT);
        storage.write(AZURE_DAILY_LIMIT_STORAGE_KEY, {
          date: Date.now(),
          value: true,
        });
      } else {
        if (showError) {
          toastStore.showToast({
            message: i18n.t("all:trans_alert_error"),
            type: ToastType.Error,
          });
        }
        this.initialized = false;
        this.failedToInitialize = true;
        this.isGlobalTranslationEnabled = false;
      }
    }

    this.lastCall = moment();
  }
}

const translator = new Translator();

export default translator;
