import React, { useCallback, useRef } from "react";
import { findDOMNode } from "react-dom";
import { KEY_CODES } from "lib";

export interface ArrowKeyFocuserItemProps<T = any> {
  item: T;
  index: number;
  tabIndex?: number;
  onFocus?: (e: React.FocusEvent) => void;
  onClick?: (item: T) => void;
}

interface ArrowKeyFocuserProps {
  items: any[];
  ItemComponent: React.ForwardRefExoticComponent<ArrowKeyFocuserItemProps>;
  onSelectItem?: (item: any) => void;
  itemProps?: object;
  isLoading?: boolean;
  LoadingComponent?: () => JSX.Element;
  className?: string;
}

/**
 * Wrapper for list components to be able to navigate
 * through list items with arrow keys and PAGEUP, PAGEDOWN
 */
const ArrowKeyFocuser = React.memo((props: ArrowKeyFocuserProps) => {
  const {
    items,
    ItemComponent,
    className,
    onSelectItem,
    itemProps,
    isLoading,
    LoadingComponent,
  } = props;

  const currentFocusedItem = useRef<number | undefined>();
  const listContainerRed = useRef<HTMLUListElement>(null);
  const itemElements = useRef<{ [key: number]: HTMLElement }>({});

  const focusFirstItem = useCallback(() => {
    if (currentFocusedItem.current != null) {
      const focusEl = itemElements.current[0];
      if (focusEl) {
        focusEl.focus();
      }
    }
  }, []);

  const focusLastItem = useCallback(() => {
    if (currentFocusedItem.current != null) {
      const focusEl = itemElements.current[items.length - 1];
      if (focusEl) {
        focusEl.focus();
      }
    }
  }, [items]);

  const focusNextItem = useCallback(() => {
    if (currentFocusedItem.current != null) {
      if (currentFocusedItem.current === items.length - 1) {
        focusFirstItem();
      } else {
        const nextElement =
          itemElements.current[currentFocusedItem.current + 1];
        nextElement.focus();
      }
    }
  }, [focusFirstItem, items]);

  const focusPreviousItem = useCallback(() => {
    if (currentFocusedItem.current != null) {
      if (currentFocusedItem.current === 0) {
        focusLastItem();
      } else {
        const nextElement =
          itemElements.current[currentFocusedItem.current - 1];
        nextElement.focus();
      }
    }
  }, [focusLastItem]);

  const handleItemKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLUListElement>) => {
      let flag = false;

      switch (e.keyCode) {
        case KEY_CODES.RETURN:
          if (onSelectItem && currentFocusedItem.current != null) {
            onSelectItem(items[currentFocusedItem.current]);
          }
          break;
        case KEY_CODES.DOWN:
          focusNextItem();
          flag = true;
          break;
        case KEY_CODES.UP:
          focusPreviousItem();
          flag = true;
          break;
        case KEY_CODES.END:
        case KEY_CODES.PAGEDOWN:
          focusLastItem();
          flag = true;
          break;
        case KEY_CODES.HOME:
        case KEY_CODES.PAGEUP:
          focusFirstItem();
          flag = true;
          break;
        default:
          break;
      }

      if (flag) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [
      focusLastItem,
      focusFirstItem,
      focusNextItem,
      focusPreviousItem,
      onSelectItem,
      items,
    ]
  );

  const handleFocusItem = useCallback((index: number) => {
    return (e: React.FocusEvent) => {
      currentFocusedItem.current = index;
    };
  }, []);

  const storeItemElementRef = useCallback((index: number) => {
    return (ref: HTMLElement | React.Component<any> | null) => {
      if (ref) {
        const element =
          ref instanceof HTMLElement ? ref : (findDOMNode(ref) as HTMLElement);
        itemElements.current[index] = element;
      } else {
        delete itemElements.current[index];
      }
    };
  }, []);

  const renderItems = useCallback(
    (items: any[]) => {
      if (!ItemComponent) return null;

      return items.map((item, index) => {
        const Item = (
          <ItemComponent
            key={item._id}
            item={item}
            index={index}
            {...itemProps}
          />
        );

        return React.cloneElement(Item, {
          tabIndex: index === 0 ? 0 : -1,
          ref: storeItemElementRef(index),
          onFocus: handleFocusItem(index),
          onClick: onSelectItem,
        });
      });
    },
    [
      ItemComponent,
      storeItemElementRef,
      handleFocusItem,
      onSelectItem,
      itemProps,
    ]
  );

  return (
    <ul
      ref={listContainerRed}
      onKeyDown={handleItemKeyDown}
      className={className}
    >
      {isLoading && LoadingComponent ? (
        <LoadingComponent />
      ) : (
        renderItems(items)
      )}
    </ul>
  );
});

export default ArrowKeyFocuser;
