import { observable, action } from "mobx";
import { callWithPromise } from "utils";
import { i18n } from "services/i18n";

interface ReasonsList {
  name: string;
  label: string;
  isChecked: boolean;
}

class DeleteDialogStore {
  @observable loading?: boolean;
  @observable reason: string = "spam";
  @observable deleteDescription: string = "";
  @observable isOpenDislike: boolean = false;
  @observable addToBlacklist: boolean = false;

  initList() {
    this.reasons  = [
      {
        name: "spam",
        label: `${i18n.t("all:friend_dialog_abuse_spam")}`,
        isChecked: true,
      },
      {
        name: "jerk",
        label: `${i18n.t("all:friend_dialog_abuse_bad")}`,
        isChecked: false,
      },
      {
        name: "crud",
        label: `${i18n.t("all:friend_dialog_abuse_objectionable")}`,
        isChecked: false,
      },
    ]
  }

  @observable reasons?: ReasonsList[];

  @action
  delete(friendId: string) {
    this.loading = true;
    try {
      callWithPromise("friends.deleteFriend", { friendId, blacklist: this.addToBlacklist });
    } catch (err) {
      console.log(err.message);
    }
    this.loading = false;
  }

  @action
  like(friendId: string, del: boolean) {
    this.loading = true;
    try {
      callWithPromise("friends.like", {
        friendId, 
        del,
        blacklist: this.addToBlacklist
      });
    } catch (err) {
      console.log(err.message);
    }
    this.loading = false;
  }

  @action
  dislike(friendId: string) {
    this.loading = true;
    try {
      callWithPromise("friends.disLike", {
        friendId,
        reason: this.reason,
        description: this.deleteDescription
      });
    } catch (err) {
      console.log(err.message);
    }
    this.loading = false;
  }

  @action
  setReason(value: string) {
    this.reason = value;
  }

  @action setReasons(item: string){
    this.reasons = this.reasons ? this.reasons.map((reason) => {
        if (reason.name === item) {
          return { ...reason, isChecked: true };
      }
      return { ...reason, isChecked: false };
    }) : []
  }

  @action setDeleteDescription(value: string){
    this.deleteDescription = value;
  }

  @action setIsOpenDislike(value: boolean){
    this.isOpenDislike = value;
  }

  @action setAddToBlacklist(value: boolean){
    this.addToBlacklist = value;
  }

  @action
  clear() {
    this.deleteDescription = "";
    this.setReason("spam");
    this.initList();
    this.setAddToBlacklist(false)
  }
}

export const deleteDialogStore = new DeleteDialogStore();
