import { makeStyles } from "hooks/makeStyles";
import React from "react";
import { Icons } from "ui/shared";

const useStyles = makeStyles((theme) => ({
  main: {
    position: "absolute",
    zIndex: 1,
    top: "calc(50% - 10px)",
    left: "calc(50% - 10px)",
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.common.white,
    borderRadius: "50%",
  },
}));

export const Thumb = ({ selected, onClick, imgSrc, isMain }: any) => {
  const css = useStyles();

  return (
    <div className={`embla__slide--thumb ${selected ? "is-selected" : ""}`}>
      <button
        onClick={onClick}
        className="embla__slide__inner--thumb"
        type="button"
      >
        {isMain ? (
          <div className={css.main}>
            <Icons.Checkmark />
          </div>
        ) : null}
        <img className="embla__slide__thumbnail" src={imgSrc} />
      </button>
    </div>
  );
};
