import React, { useCallback, useState } from "react";
import { Typography, Button, Input, LayoutMessageBubble, Box } from "ui/shared";
import { useTranslation } from "react-i18next";
import { LoginFlowStore } from "ui/pages/login/stores/login-flow-store";
import { observer } from "mobx-react";
import { makeStyles } from "hooks/makeStyles";
import { storage } from "utils";
import { EMAIL_INFO_KEY } from "lib";
import { motion } from "framer-motion";
import ModalLayoutContainer from "ui/components/modal-layout-container/modal-layout-container";

const activeKarma = require("assets/svg/big-active-karma.svg");

const useStyles = makeStyles((theme) => ({
  title: {
    lineHeight: 1,
    marginBottom: theme.spacing(2),
  },
  inputContainer: {
    position: "relative",
    width: "100%",
  },
  bubble: {
    position: "absolute",
    top: -13,
    left: -134,
    color: theme.colors.common.black,
  },
  centered: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    height: 46,
  },
  karmaWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  karmaCounter: {
    fontSize: 40,
  },
}));

const variants = {
  hidden: {
    y: 50,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.3 },
  },
};

export const Email = observer(() => {
  const css = useStyles();
  const { t } = useTranslation("all");

  const [loginStore] = useState(new LoginFlowStore());

  /**************************************************/

  const handleEmailInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      loginStore.setEmail(e.target.value);
    },
    []
  );

  const handleOccupyEmail = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    loginStore.setNewEmail();
    storage.write(EMAIL_INFO_KEY, true);
  }, []);

  /**************************************************/

  return (
    <motion.div
      initial={"hidden"}
      animate={"visible"}
      variants={variants}
      className={css.emailContainer}
    >
      <ModalLayoutContainer>
        <Box mb={3}>
          <Typography size="h2(23px)" align="center" className={css.title}>
            {t("all:reg_4_text")}
          </Typography>
        </Box>
        <div className={css.inputContainer}>
          <LayoutMessageBubble
            className={css.bubble}
            data-cy="login.messages.login"
          >
            {loginStore.emailError || loginStore.message || loginStore.warning}
          </LayoutMessageBubble>
          <Input
            value={loginStore.email}
            onChange={handleEmailInput}
            mt={2}
            placeholder={t("all:verification_email_placeholder")}
            error={!!loginStore.emailError}
            succeed={!!loginStore.message}
            data-cy="login.input"
          />
        </div>
        <Box mt={2.5} mb={2.5} className={css.centered}>
          <Button
            disabled={!!loginStore.emailError}
            fullWidth
            primary
            onClick={handleOccupyEmail}
            data-cy="login.actions.continue"
            className={css.button}
          >
            {t("all:alert_review_button")}
          </Button>
        </Box>
        <div className={css.karmaWrap}>
          <img src={activeKarma} width={84} alt="Active karma" />
          <Typography className={css.karmaCounter} align="center">
            +20
          </Typography>
        </div>
      </ModalLayoutContainer>
    </motion.div>
  );
});
