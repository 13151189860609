import React, { useState, useEffect } from "react";
import { makeStyles } from "hooks/makeStyles";
import { Modal, DefaultPanel } from "ui/shared";
import {
  AvatarsPanelHeader,
  AvatarsLoginPanel,
} from "ui/components/avatars-login-panel/avatars-login-panel";
import { ModalProps } from "ui/shared/modal/modal";
import { observer } from "mobx-react";
import { modalManager } from "global-stores/modal-manager";
import { MODALS } from "lib/constants";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { avatarsStore } from "./store.avatars-modal";
import { useTracker } from "hooks/useTracker";
import { Meteor } from "meteor/meteor";
import { Avatars } from "collections/avatars";
import { userStore } from "global-stores/user-store";

interface AvatarsModalProps
  extends Pick<ModalProps, "modalId" | "onClose" | "withCloseButton"> {}

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "590px",
    maxWidth: "590px",
    "&:focus": {
      outline: "none",
    },
  },
}));

export const AvatarsModal = observer((props: AvatarsModalProps) => {
  const css = useStyles();
  const { t } = useTranslation("all");
  const [previousLength, setPreviousLength] = useState(0);

  const setStandartAvatar = (id: string) => {
    // Need to send `one` prop for avatars from additional info registration step
    const one = modalManager.opened[MODALS.PROFILE_AVATARS].isAdditionalInfo;
    avatarsStore.moveToMyAvatar(id, undefined, one);

    modalManager.close(MODALS.PROFILE_AVATARS);
    if (props.onClose) {
      props.onClose();
    }
  };

  const setMainAvatar = (id: string) => {
    avatarsStore.setMainAvatar(id);

    modalManager.close(MODALS.PROFILE_AVATARS);
    if (props.onClose) {
      props.onClose();
    }
  };

  const deleteAvatar = (id: string) => {
    avatarsStore.deleteMyAvatar(id);
  };

  useEffect(() => {
    avatarsStore.getAvatars();
  }, [avatarsStore, userStore.user]);

  const { myLoading, myAvatars } = useTracker(() => {
    const handle = Meteor.subscribe("avatars.u");

    const avatars = Avatars.find().fetch();
    if (avatars.length !== previousLength) {
      setPreviousLength(avatars.length);
      avatarsStore.finishUpload();
    }

    return {
      myLoading: !handle.ready(),
      myAvatars: avatars,
      disableUpload: !!avatars.find((avatar) => !avatar.avatarDId),
    };
  }, []);

  return (
    <Modal {...props}>
      {(_, className) => (
        <DefaultPanel
          header={
            <AvatarsPanelHeader
              activeTab={avatarsStore.activeTab}
              setActiveTab={avatarsStore.setActiveTab.bind(avatarsStore)}
              data-cy="additionalInfo.avatarModal"
            />
          }
          className={clsx(className, css.modal)}
        >
          <AvatarsLoginPanel
            data-cy="additionalInfo.avatarModal"
            loading={avatarsStore.loading}
            myLoading={myLoading}
            activeTab={avatarsStore.activeTab}
            activeUpload={!!avatarsStore.activeUpload}
            avatars={
              avatarsStore.activeTab === 0
                ? avatarsStore.classicAvatars
                : avatarsStore.nefritAvatars
            }
            myAvatars={myAvatars}
            onSelectStandart={setStandartAvatar}
            onSelectMine={setMainAvatar}
            onSetAsMain={setMainAvatar}
            onDelete={deleteAvatar}
          />
        </DefaultPanel>
      )}
    </Modal>
  );
});
