import React, { useCallback } from "react";
import { makeStyles } from "hooks/makeStyles";
import { Box, Button, AnimatedPage } from "ui/shared";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { BasicSearch } from "./basic-search/basic-search";
import { searchStore } from "./stores/search-store";
import { useHistory } from "react-router-dom";

export interface RegularSearchProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateRows: "60px 1fr",
    background: theme.colors.background.paper.main,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  searchContainer: {
    width: "40%",
    marginTop: -60,
  },
}));

export const RegularSearch = observer((props: RegularSearchProps) => {
  const css = useStyles();
  const { t } = useTranslation("all");
  const history = useHistory();

  /**************************************************/

  const handleStartSearch = useCallback(() => {
    searchStore.startSearch(undefined, () => history.push("/search/my"));
  }, [history]);

  /**************************************************/

  return (
    <AnimatedPage
      animation="horizontal"
      containerClassName={css.root}
      back={{
        label: t("all:reg_prev_button"),
        path: "/search",
        boxProps: {
          pl: 5,
        },
      }}
    >
      <div className={css.container}>
        <div className={css.searchContainer}>
          <BasicSearch store={searchStore} />
          <Box mt={10}>
            <Button
              fullWidth
              primary
              onClick={handleStartSearch}
              data-cy="regularSearch.start"
            >
              {t("all:main_button_start_search")}
            </Button>
          </Box>
        </div>
      </div>
    </AnimatedPage>
  );
});
