import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "hooks/makeStyles";
import {
  Tabs,
  Tab,
  Icons,
  Karma,
  Avatar,
  Dropdown,
  IconButton,
  Typography,
} from "ui/shared";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { userStore } from "global-stores/user-store";
import { chatsStore } from "global-stores/chats-store";
import logo from "assets/svg/logo-inside-me.svg";
import { getUserAvatar } from "utils/profile";
import NotificationsList from "./notifications";
import { useTracker } from "hooks/useTracker";
import { Meteor } from "meteor/meteor";
import { Notifications } from "collections/notifications";
import { Notification } from "lib";
import activeSearch from "assets/svg/search-active.svg";
import vipStore from "global-stores/vip-store";
import { searchStore } from "ui/pages/search/stores/search-store";

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    position: "relative",
  },
  header: {
    width: "100%",
    height: theme.sizes.header,
    display: "flex",
    flexShrink: 0,
    justifyContent: "space-between",
    background: theme.colors.background.paper.topbar,
    borderTopLeftRadius: "inherit",
    borderTopRightRadius: "inherit",
    padding: `0 ${theme.spacing(2)}`,
    boxSizing: "border-box",
    boxShadow: "0px 3px 6px #00000029",
    position: "relative",
  },
  vipContainer: {
    display: "flex",
    alignItems: "center",
    width: 138,
  },
  marker: {
    position: "absolute",
    top: -12,
    right: "calc(50% - 3px)",
    width: 6,
    height: 6,
    borderRadius: "50%",
    backgroundColor: theme.colors.primary,
  },
  logo: {
    width: 66,
    height: 38,
    marginLeft: theme.spacing(3),
  },
  rightButtonsContainer: {
    display: "flex",
    position: "relative",
  },
  notificationButton: {
    width: 54,
    height: 60,
    borderRadius: 0,
  },
  notificationsContainer: {
    width: 250,
    maxHeight: 280,
    backgroundColor: theme.colors.background.paper.topbar,
    right: -54 * 3 - 4,
    boxShadow: "0px 3px 6px #00000029",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    overflowY: "auto",
  },
  notificationMarker: {
    position: "absolute",
    top: 6,
    right: 6,
    width: 6,
    height: 6,
    borderRadius: "50%",
    backgroundColor: theme.colors.primary,
    zIndex: 1,
  },
  tabFlexContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

interface HeaderProps {
  isActiveSearch: boolean;
}

export const Header = observer(({ isActiveSearch }: HeaderProps) => {
  const { t } = useTranslation("all");
  const history = useHistory();
  const location = useLocation();
  const [value, setValue] = useState<string | number>();
  const css = useStyles();

  const hasNewMessagesOrChats = chatsStore.friends.some(
    (friend) => !!friend.newMessageCounter
  );

  const { notifications }: { loading: boolean; notifications: Notification[] } =
    useTracker(() => {
      const handle = Meteor.subscribe("notifications.my");

      const notifications = Notifications.find(
        {},
        { sort: { createdAt: -1 } }
      ).fetch();

      return {
        loading: !handle.ready(),
        notifications,
      };
    });

  const profile = userStore.user && userStore.user.profile;
  const isOfficial = profile && profile.official;
  const isVerified = profile && profile.level === "ver";
  const isVIP = profile && profile.level === "vip";
  const avatar = getUserAvatar(profile);

  /**************************************************/

  const renderNotifications = useCallback(
    (close: (force?: boolean | undefined) => void) => {
      return (
        <NotificationsList notifications={notifications} closeOverlay={close} />
      );
    },
    [notifications]
  );

  const handleSelectTab = useCallback(
    (value: string | number) => {
      if (history.location.pathname.startsWith(`/${value}`)) return;
      setValue(value);
      const isSearchMy =
        value === "search" &&
        searchStore.pickups.length &&
        !searchStore.wantToCreateNewSearch;
      history.push(`/${isSearchMy ? value + "/my" : value}`);
    },
    [setValue, history]
  );

  /**************************************************/

  useEffect(() => {
    const path = location.pathname.split("?")[0].split("/")[1];
    if (path) {
      setValue(path);
    }
  }, [location]);

  return (
    <div className={css.header}>
      <div className={css.vipContainer}>
        <img className={css.logo} src={logo} alt="Logo" />
      </div>
      <Tabs value={value} onChange={handleSelectTab}>
        <Tab value="chats">
          <div className={css.tabContainer}>
            <Typography size="subtitle1(18px)">
              {t("all:nav_friends")}
            </Typography>

            {hasNewMessagesOrChats ? <span className={css.marker} /> : null}
          </div>
        </Tab>
        <Tab value="search">
          <div className={css.tabFlexContainer}>
            <Typography
              size="subtitle1(18px)"
              style={{ marginRight: isActiveSearch ? 12 : 0 }}
            >
              {t("all:nav_search")}
            </Typography>
            {isActiveSearch ? (
              <img
                height={20}
                src={activeSearch}
                alt="Active search icon"
                style={{ opacity: 0.6 }}
              />
            ) : null}
          </div>
        </Tab>
        <Tab value="top">
          <div className={css.tabFlexContainer}>
            <Typography
              size="subtitle1(18px)"
              style={{ marginRight: isActiveSearch ? 12 : 0 }}
            >
              {t("all:top_title")}
            </Typography>
          </div>
        </Tab>
        {!vipStore.initError ? (
          <Tab value="vip">
            <div className={css.tabFlexContainer}>
              <Typography size="subtitle1(18px)">
                {t("all:user_type_vip")}
              </Typography>
              <Icons.VIP width={21} />
            </div>
          </Tab>
        ) : null}
        {/* <Tab value="posts">{t("all:tabs.posts")}</Tab> */}
      </Tabs>
      <div className={css.rightButtonsContainer}>
        <div className={css.tabContainer}>
          {notifications.length ? (
            <span className={css.notificationMarker} />
          ) : null}
          <Dropdown
            preferTop={false}
            render={renderNotifications}
            overlayClassName={css.notificationsContainer}
          >
            <IconButton className={css.notificationButton}>
              <Icons.Bell />
            </IconButton>
          </Dropdown>
        </div>
        <Tabs value={value} onChange={handleSelectTab}>
          <Tab value="profile">
            <Avatar.Minified
              verified={isVerified}
              official={isOfficial}
              vip={isVIP}
              img={avatar}
            />
          </Tab>
          <Tab value="karma">
            <Karma
              value={
                profile && profile.karma && profile.karma.activeKarma
                  ? profile.karma.activeKarma
                  : 0
              }
            />
          </Tab>
          <Tab value="settings">
            <Icons.Settings height={34} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
});
