import { Meteor } from "meteor/meteor";
import { observable } from "mobx";

class ConfigStore {
  @observable fileServer?: string;
  @observable avatar404?: any;

  init() {
    Meteor.call("settings.get", (err: any, res: any) => {
      this.fileServer = res.bestFileServer;
      this.avatar404 = res.avatar404;
    });
  }
}

const configStore = new ConfigStore();

export default configStore;
