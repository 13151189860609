import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Filters = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Filters}>
    {({ color }) => (
      <>
        <path
          fill={color}
          d="M25.5 16H15v-1h-1v1H6.5c-.3 0-.5.2-.5.5s.2.5.5.5H14v1h1v-1h10.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM25.5 21H23v-1h-1v1H6.5c-.3 0-.5.2-.5.5s.2.5.5.5H22v1h1v-1h2.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM6.5 12H9v1h1v-1h15.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H10v-1H9v1H6.5c-.3 0-.5.2-.5.5s.2.5.5.5z"
        />
        <path fill="none" d="M0 0h32v32H0z" />
      </>
    )}
  </CreateSvgIcon>
);
