import React from "react";
import { Blacklist } from "lib";
import { BlacklistItem } from "./blacklist-panel-item";

export interface BlacklistPanelProps {
  items: Blacklist[];
  selected: string[];
  onSelectBlackList: (e: React.MouseEvent, item: string) => void;
}

export const BlacklistPanel = (props: BlacklistPanelProps) => {
  const { items, selected, onSelectBlackList } = props;

  return (
    <>
      {items.map((item) => (
        <BlacklistItem
          key={item._id}
          item={item._id}
          login={item.blockedLogin}
          description={item.description}
          reason={item.reason}
          date={item.createdAt}
          checked={selected.includes(item._id)}
          onClick={(e, value) => {
            onSelectBlackList(e, value);
          }}
        />
      ))}
    </>
  );
};
