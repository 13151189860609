import configStore from "global-stores/config-store";
import { makeStyles } from "hooks/makeStyles";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Icons, Karma, Typography } from "ui/shared";
import { ArrowKeyFocuserItemProps } from "ui/shared/arrow-key-focuser/arrow-key-focuser";
import { Female } from "ui/shared/icons/icons";
import { capitalize, fade } from "utils";

const useStyles = makeStyles((theme) => ({
  topUserListItemContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
    paddingRight: theme.spacing(4),
    cursor: "pointer",
    outline: "none",
    backgroundColor: theme.colors.background.paper.topbar,
    "&:first-child": {
      borderTop: `1px solid ${fade(theme.colors.background.paper.main, 0.6)}`,
    },
    "&:last-child": {
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
    "&:not(:last-child)": {
      borderBottom: `1px solid ${fade(
        theme.colors.background.paper.main,
        0.6
      )}`,
    },
    "&:hover, &:focus": {
      backgroundColor: theme.colors.background.paper.lighter,
    },
    profileModal: {
      maxWidth: 353,
    },
  },
  rank: {
    width: 30,
    textAlign: "center",
    marginRight: theme.spacing(2),
  },
  userInfo: {
    flex: 1,
    paddingLeft: theme.spacing(4),
  },
  username: {
    paddingBottom: theme.spacing(2),
  },
  genderContainer: {
    display: "flex",
    alignItems: "center",
  },
  genderText: {
    marginLeft: theme.spacing(1),
  },
}));

const TopUserItem = React.forwardRef(
  (
    { item, index, tabIndex, onFocus, onClick }: ArrowKeyFocuserItemProps,
    ref: any
  ) => {
    const css = useStyles();
    const { t } = useTranslation();

    const handleClick = useCallback(() => {
      if (onClick) {
        onClick(item);
      }
    }, [onClick, item]);

    return (
      <li
        ref={ref}
        className={css.topUserListItemContainer}
        tabIndex={tabIndex}
        onClick={handleClick}
        onFocus={onFocus}
      >
        <div className={css.rank}>
          <Typography>{index + 1}</Typography>
        </div>
        <Avatar.Top
          img={item.avatar.medium || configStore.avatar404}
          verified={item.level === "ver"}
          vip={item.level === "vip"}
        />
        <div className={css.userInfo}>
          <Typography size="subtitle2(16px)" className={css.username}>
            {item.username}
          </Typography>
          {item.gender != null ? (
            <div className={css.genderContainer}>
              {item.gender === "m" ? (
                <Icons.Male width={20} height={20} />
              ) : (
                <Female width={20} height={20} />
              )}
              <Typography font="light" className={css.genderText}>
                {capitalize(
                  item.gender === "m" ? t("all:gender_m") : t("all:gender_f")
                )}{" "}
                {item.age && item.age[0] !== 0 ? ` | ${item.age[0]}` : ""}
              </Typography>
            </div>
          ) : null}
        </div>
        <Karma gray value={item.activeKarma || 0} />
      </li>
    );
  }
);
export default TopUserItem;
