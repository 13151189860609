import React from "react";
import { makeStyles } from "hooks/makeStyles";

import { Button, Avatar, Loader } from "ui/shared";
import { useTranslation } from "react-i18next";

import { Avatar as AvatarType, WithTestData } from "lib";
import { useRef } from "react";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: theme.colors.background.paper.topbar,
    color: theme.colors.textPrimary,
    borderRadius: "10px 10px 0px 0px",
    position: "relative",
    overflow: "hidden",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "flex-start",
    overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: theme.colors.background.paper.lighter,
    height: 546,
    width: "100%",
  },
  addButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 0,
    width: 50,
  },
  tabButton: {
    flexGrow: 1,
    borderRadius: 0,
    "&:focus, &:active": {
      backgroundColor: theme.colors.purple.main,
    },
  },
  imgBtn: {
    backgroundColor: "transparent",
    borderRadius: 0,
    border: 0,
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      borber: 0,
    },
  },
  image: {
    width: "33.33%",
    height: "36%",
    position: "relative",
  },
  innerButtonsContainer: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    paddingBottom: theme.spacing(2),
  },
  avatarSkeleton: {
    width: 197,
    height: 197,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.background.paper.topbar,
  },
  footer: {
    width: "100%",
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

interface AvatarsPanelHeaderProps extends WithTestData {
  activeTab: number;
  setActiveTab: (value: number) => void;
}

export const AvatarsPanelHeader = (props: AvatarsPanelHeaderProps) => {
  const css = useStyles();
  const { t } = useTranslation("all");
  const { activeTab, setActiveTab } = props;

  return (
    <div className={css.header}>
      <Button
        className={css.tabButton}
        tertiary={activeTab === 0}
        onClick={() => setActiveTab(0)}
      >
        {t("all:ava_classic")}
      </Button>
      <Button
        className={css.tabButton}
        tertiary={activeTab === 1}
        onClick={() => setActiveTab(1)}
      >
        {t("all:ava_nefrit")}
      </Button>
    </div>
  );
};

interface AvatarsLoginPanelProps extends WithTestData {
  loading: boolean;
  activeUpload?: boolean;
  myLoading: boolean;
  avatars: AvatarType[];
  myAvatars: AvatarType[];
  activeTab: number;
  selectedAvatar?: string;
  onSelectStandart: (id: string) => void;
  onSelectMine: (id: string) => void;
  onSetAsMain: (id: string) => void;
  onDelete: (id: string) => void;
}

export const AvatarsLoginPanel = (props: AvatarsLoginPanelProps) => {
  return (
    <AvatarsCollection
      loading={props.loading}
      avatars={props.avatars}
      activeTab={props.activeTab}
      renderAvatar={(avatar) => (
        <AvatarView
          key={avatar._id}
          avatar={avatar}
          onSelect={props.onSelectStandart}
          onSetAsMain={props.onSetAsMain}
          onDelete={props.onDelete}
        />
      )}
    />
  );
};

interface AvatarViewProps {
  isMine?: boolean;
  avatar: AvatarType;
  onSelect: (id: string) => void;
  onSetAsMain: (id: string) => void;
  onDelete: (id: string) => void;
}

const AvatarView = (props: AvatarViewProps) => {
  const css = useStyles();
  const { avatar, onSelect } = props;

  return (
    <div className={css.image}>
      <Button
        tabIndex={-1}
        className={css.imgBtn}
        onClick={() => onSelect(avatar._id)}
      >
        <Avatar.Square img={avatar.medium} height="197px" width="197px" />
      </Button>
    </div>
  );
};

interface AvatarsCollectionProps extends WithTestData {
  loading: boolean;
  activeUpload?: boolean;
  avatars: AvatarType[];
  activeTab: number;
  renderAvatar: (avatar: AvatarType) => React.ReactNode;
}

const AvatarsCollection = React.memo((props: AvatarsCollectionProps) => {
  const css = useStyles();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation("all");

  useEffect(() => {
    if (scrollRef.current) {
      // reset scroll position when tab's changed
      scrollRef.current.scrollTop = 0;
    }
  }, [props.avatars]);

  return (
    <div
      ref={scrollRef}
      className={css.body}
      data-cy={props["data-cy"] + ".container"}
    >
      {props.loading ? (
        <Loader />
      ) : (
        props.avatars.map((avatar) => props.renderAvatar(avatar))
      )}
      {props.activeUpload ? (
        <div className={css.avatarSkeleton}>
          <Loader />
        </div>
      ) : null}
      {!props.loading && props.avatars.length ? (
        <div className={css.footer}>
          {props.activeTab === 0
            ? t("all:ava_classic_author")
            : t("all:ava_nefrit_author")}
        </div>
      ) : null}
    </div>
  );
});
