export const euroPrice = (value: number) => {
  return (value / 100).toFixed(2);
};

export const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case "eur":
      return "€";
    case "rub":
      return "₽";
  }
};

export const getPriceDurationInMonths = (price: any) => {
  return price.recurring.interval === "month"
    ? price.recurring.interval_count
    : price.recurring.interval === "year"
    ? price.recurring.interval_count * 12
    : 0;
};

export const getSale = (price: number, smallestPrice: number) => {
  return Math.floor(((smallestPrice - price) / smallestPrice) * 100);
};
