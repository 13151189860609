import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Male = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Male}>
    {({ color }) => (
      <path
        d="M17.9403 6.53731L18.1194 8.32836L22.4179 7.97015L16.1493 14.2388C13.4627 12.2687 9.52239 12.4478 7.01493 14.7761C4.32836 17.4627 4.32836 21.9403 7.01493 24.6269C8.26866 25.8806 10.0597 26.597 11.8507 26.597C13.6418 26.597 15.4328 25.8806 16.6866 24.6269C19.194 22.1194 19.3731 18.1791 17.2239 15.4925L23.6716 9.04478L22.9552 13.5224L24.7463 13.8806L26 6L17.9403 6.53731V6.53731ZM15.6119 23.3731C13.6418 25.3433 10.4179 25.3433 8.26866 23.3731C6.29851 21.403 6.29851 18.1791 8.26866 16.0299C9.16418 15.1343 10.597 14.597 11.8507 14.597C13.2836 14.597 14.5373 15.1343 15.4328 16.0299C17.5821 18 17.5821 21.2239 15.6119 23.3731Z"
        fill={color}
      />
    )}
  </CreateSvgIcon>
);
