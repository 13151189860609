import React, { useCallback } from "react";
import { makeStyles } from "hooks/makeStyles";
import { Typography, Icons, Switch } from "ui/shared";
import { fade } from "utils";
import clsx from "clsx";

export interface DisplayInProfilePanelItemProps {
  label: string;
  checked?: boolean;
  onClick: (value: boolean) => void;
  withSwitch?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: 300,
    height: 40,
    padding: `0 ${theme.spacing(6)}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: fade(theme.colors.background.paper.lighter, 0.6),
    },
  },
  withSwitch: {
    backgroundColor: fade(theme.colors.background.paper.lighter, 0.6),
    padding: `${theme.spacing(1)} ${theme.spacing(6)}`,
    height: 48,
  },
}));

export const DisplayInProfilePanelItem = (
  props: DisplayInProfilePanelItemProps
) => {
  const { label, checked, onClick, withSwitch } = props;
  const css = useStyles();

  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onClick(!checked);
    },
    [onClick]
  );

  return (
    <div
      className={clsx(css.root, { [css.withSwitch]: !!withSwitch })}
      onClick={clickHandler}
    >
      <Typography>{label}</Typography>
      {checked && !withSwitch ? <Icons.Checkmark /> : null}
      {withSwitch && <Switch checked={!!checked} />}
    </div>
  );
};
