import React from "react";
import { makeStyles } from "hooks/makeStyles";
import { Typography } from "../typography/typography";
import { fade } from "utils";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Icons } from "../icons";

interface AvatarProps {
  img?: string;
  border?: boolean;
  loading?: boolean;
  asButton?: boolean;
}

interface MinifiedAvatarProps {
  width?: number;
  height?: number;
  vip?: boolean;
  verified?: boolean;
  official?: boolean;
}

interface SquareAvatarProps {
  width?: number | string;
  height?: number | string;
  selected?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: 150,
    height: 150,
    border: `2px solid ${theme.colors.common.white}`,
    borderRadius: "50%",
    clipPath: "circle(72px at center)",
    "&:hover $changeHover": {
      transform: ({ asButton }: AvatarProps) =>
        asButton ? "translateY(0)" : "translateY(100%)",
    },
    "&$borderless": {
      border: "none",
    },
  },
  avatar: {
    width: "100%",
    height: "100%",
  },
  minifiedContainer: {
    position: "relative",
  },
  minified: {
    width: (props: MinifiedAvatarProps) => props.width,
    height: (props: MinifiedAvatarProps) => props.height,
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "50%",
  },
  minifiedIcon: {
    position: "absolute",
    left: -11,
    bottom: -7,
  },
  squareContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  square: {
    width: (props: SquareAvatarProps) => props.width,
    height: (props: SquareAvatarProps) => props.height,
  },
  squareIcon: {
    position: "absolute",
    top: "50%",
    right: "5%",
  },
  changeHover: {
    transform: "translateY(100%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: 40,
    backgroundColor: fade(theme.colors.purple.main, 0.4),
    transition: "transform .2s ease-out",
  },
  borderless: {},
}));

export const Big = (props: AvatarProps) => {
  const { img, border, loading } = props;
  const { t } = useTranslation("all");
  const css = useStyles(props);

  return (
    <div
      className={clsx(css.container, {
        [css.borderless]: !border,
      })}
    >
      {!loading ? (
        <div
          className={css.avatar}
          style={{
            backgroundImage: `url(${img})`,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          data-cy="profile.avatar"
        >
          <div className={css.changeHover}>
            <Typography align="center" style={{ color: "black" }}>
              {t("all:avatars_button_select")}
            </Typography>
          </div>
        </div>
      ) : (
        "loading..."
      )}
    </div>
  );
};

export const Minified = ({
  img,
  width = 34,
  height = 34,
  vip,
  verified,
  official,
}: AvatarProps & MinifiedAvatarProps) => {
  const css = useStyles({ width, height });

  return (
    <div className={css.minifiedContainer}>
      <div
        className={css.minified}
        style={{
          backgroundImage: `url(${img})`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        data-cy="chat-profile-modal-avatar"
      />
      <div className={css.minifiedIcon}>
        {official ? (
          <Icons.Official height={20} />
        ) : vip ? (
          <Icons.VIP height={25} />
        ) : verified ? (
          <Icons.Verified height={25} />
        ) : null}
      </div>
    </div>
  );
};

export const Top = ({
  img,
  width = 56,
  height = 56,
  vip,
  verified,
  official,
}: AvatarProps & MinifiedAvatarProps) => {
  const css = useStyles({ width, height });

  return (
    <div className={css.minifiedContainer}>
      <div
        className={css.minified}
        style={{
          backgroundImage: `url(${img})`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        data-cy="chat-profile-modal-avatar"
      />
      <div className={css.minifiedIcon}>
        {official ? (
          <Icons.Official height={20} />
        ) : vip ? (
          <Icons.VIP height={25} />
        ) : verified ? (
          <Icons.Verified height={25} />
        ) : null}
      </div>
    </div>
  );
};

export const Square = ({
  img,
  width,
  height,
  selected,
}: AvatarProps & SquareAvatarProps) => {
  const css = useStyles({ width, height });

  return (
    <div className={css.squareContainer}>
      <div
        className={css.square}
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      />
      <div className={css.squareIcon}>
        {selected ? <Icons.Checkmark height={32} /> : null}
      </div>
    </div>
  );
};
