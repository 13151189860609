import React, { useCallback, useEffect } from "react";
import { makeStyles } from "hooks/makeStyles";
import clsx from "clsx";
import { Gender, Age, Area } from "lib";
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Slider,
  Modal,
  DefaultPanel,
  Button,
} from "ui/shared";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { SearchStore } from "../stores/search-store";
import { userStore } from "global-stores/user-store";
import { MODALS } from "lib/constants";
import { modalManager } from "global-stores/modal-manager";
import GenderButtonGroup from "ui/components/gender-button-group/gender-button-group";
import { profileStore } from "ui/pages/profile/store.profile";

export interface BasicSearchProps {
  store: SearchStore;
}

const useStyles = makeStyles((theme) => ({
  chooseAge: {
    "&$opened": {
      height: "70px",
      opacity: 1,
      "& > div": {
        visibility: "visible",
      },
    },
    height: 0,
    overflow: "hidden",
    opacity: 0,
    transition: "height .4s, opacity .6s cubic-bezier(0, 0, 0, 1)",
    "& > div": {
      visibility: "hidden",
    },
  },
  dialog: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  opened: {},
}));

export const BasicSearch = observer((props: BasicSearchProps) => {
  const { store } = props;
  const css = useStyles();
  const { t } = useTranslation("all");

  const profile = userStore.user && userStore.user.profile;

  /**************************************************/

  const handleChangeMyGender = useCallback(
    (e: React.MouseEvent, value?: string | string[]) => {
      if (value) profileStore.setGender(value as Gender);
      modalManager.close(MODALS.SEARCH_SET_YOUR_GENDER);
    },
    []
  );

  const handleChangeHGender = useCallback(
    (e: React.MouseEvent, value?: string | string[]) => {
      if (!profile.gender) {
        // Open dialog to choose user gender first
        modalManager.open(MODALS.SEARCH_SET_YOUR_GENDER);
        return;
      }

      store.setHGender(value as Gender);
    },
    [profile.gender]
  );

  const handleChangeHAge = useCallback(
    (e: React.MouseEvent, value?: string | string[]) => {
      if (profile.age && !profile.age[0] && !profile.age[1]) {
        // Open dialog to choose user gender first
        modalManager.open(MODALS.SEARCH_SET_YOUR_AGE);
        return;
      }

      store.setHAge(value as Age);
    },
    [profile.age]
  );

  const handleChangeMyAge = useCallback(
    (e: React.MouseEvent, value: number | number[], activeIndex: number) => {
      profileStore.setAgeSliderValue(value as number[], activeIndex);
    },
    []
  );

  const handleCloseAgeModal = useCallback(() => {
    profileStore.setAgeSliderValue([20, 30], 1);
  }, []);

  const handleSaveMyAge = useCallback(() => {
    profileStore.saveAge();
    modalManager.close(MODALS.SEARCH_SET_YOUR_AGE);
    store.setHAge("choose");
  }, [store]);

  /**************************************************/

  useEffect(() => {
    return () => {
      store.clear();
    };
  }, []);

  return (
    <div>
      <Box mb={3}>
        <Typography size="body(14px)" font="light" uppercase align="center">
          {t("all:main_his_gender")}
        </Typography>
      </Box>
      <GenderButtonGroup value={store.hGender} onChange={handleChangeHGender} />
      <Box mt={6} mb={3}>
        <Typography size="body(14px)" font="light" uppercase align="center">
          {t("all:main_his_age")}
        </Typography>
      </Box>
      <ToggleButtonGroup
        fullWidth
        exclusive
        value={store.hAge}
        onChange={handleChangeHAge}
      >
        <ToggleButton value="" data-cy="basicSearch.age.any">
          {t("all:main_button_never_mind")}
        </ToggleButton>
        <ToggleButton value="choose" data-cy="basicSearch.age.choose">
          {t("all:main_placeholder_age")}
        </ToggleButton>
      </ToggleButtonGroup>
      <Box
        pr={6}
        pl={6}
        pt={6}
        mt={5}
        className={clsx(css.chooseAge, {
          [css.opened]: store.hAge === "choose",
        })}
      >
        <Slider
          showBottomLabel
          value={store.hAgeValue}
          min={18}
          max={99}
          onChange={(e, value) => store.setHAgeValue(value as number[])}
          data-cy="basicSearch.age.slider"
        />
      </Box>
      <Box mt={6} mb={3}>
        <Typography size="body(14px)" font="light" uppercase align="center">
          {t("all:main_search_where")}
        </Typography>
      </Box>
      <ToggleButtonGroup
        fullWidth
        exclusive
        value={store.area}
        onChange={(e, value) => store.setArea(value as Area)}
      >
        <ToggleButton value="everywhere" data-cy="basicSearch.area.everywhere">
          {t("all:main_search_where_everywhere")}
        </ToggleButton>
        <ToggleButton value="nearby" data-cy="basicSearch.area.nearby">
          {t("all:main_search_where_nearby")}
        </ToggleButton>
      </ToggleButtonGroup>
      <Modal modalId={MODALS.SEARCH_SET_YOUR_GENDER}>
        {() => (
          <DefaultPanel className={css.dialog}>
            <Box mb={3.5}>
              <Typography size="subtitle2(16px)" align="center">
                {t("all:dialog_dis_set_gender_text")}
              </Typography>
            </Box>
            <GenderButtonGroup
              labels={[
                t("all:reg_2_not_disclose"),
                t("all:dialog_set_im_a_man"),
                t("all:dialog_set_im_a_woman"),
              ]}
              value={profile.gender}
              onChange={handleChangeMyGender}
            />
          </DefaultPanel>
        )}
      </Modal>
      <Modal modalId={MODALS.SEARCH_SET_YOUR_AGE} onClose={handleCloseAgeModal}>
        {() => (
          <DefaultPanel>
            <div className={css.dialog}>
              <Box mb={2}>
                <Typography>{t("all:dialog_dis_set_age_text")}</Typography>
              </Box>
              <Box
                pr={6}
                pl={6}
                pt={6}
                mt={5}
                mb={2}
                className={clsx(css.chooseAge, css.opened)}
              >
                <Slider
                  displayLabels="on"
                  value={profileStore.ageSliderValue}
                  min={18}
                  max={99}
                  onChange={handleChangeMyAge}
                  data-cy="age.modal.slider"
                />
              </Box>
              <Button primary onClick={handleSaveMyAge}>
                {t("all:dialog_set_age_ok_button")}
              </Button>
            </div>
          </DefaultPanel>
        )}
      </Modal>
    </div>
  );
});
