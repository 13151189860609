import { toastStore } from "global-stores/toasts-store";
import {
  ADDITIONAL_INFO_KEY,
  EMAIL_INFO_KEY,
  Encryption,
  ToastType,
} from "lib";
import { Meteor } from "meteor/meteor";
import { action, observable } from "mobx";
import { i18n } from "services/i18n";
import { storage } from "utils";
import { globalUIStore } from "global-stores/global-ui-store";

export class RestoreAccountStore {
  @observable inProgress: boolean = false;
  @observable emailValue: string = "";
  @observable error?: string;
  @observable isCodeSent: boolean = false;
  @observable codeValue: string = "";
  @observable codeTimestamp?: number;
  @observable timer?: NodeJS.Timeout;
  @observable countDownTimer?: string;

  _emailRegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  @action
  setEmail(value: string) {
    const newValue = value.trim();
    if (newValue.length <= 150) {
      this.emailValue = newValue;
      if (newValue.length >= 1) {
        this.checkEmail();
      } else {
        this.error = undefined;
      }
    }
    if (!newValue) {
      this.error = undefined;
    }
  }

  @action
  setError(value: string) {
    this.error = value;
  }

  @action
  setIsCodeSent(value: boolean) {
    this.isCodeSent = value;
  }

  @action
  setCode(value: string) {
    this.codeValue = value;
  }

  @action
  startTimer(duration: number) {
    const self = this;
    var timer = duration,
      minutes,
      seconds;
    this.codeTimestamp = Date.now();
    this.timer = setInterval(function () {
      minutes = parseInt(`${timer / 60}`, 10);
      seconds = parseInt(`${timer % 60}`, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      self.countDownTimer = minutes + ":" + seconds;

      if (--timer < 0) {
        clearInterval(self.timer!);
        self.countDownTimer = undefined;
        self.timer = undefined;
      }
    }, 1000);
  }

  @action
  async checkEmail() {
    if (!this.emailValue.match(this._emailRegExp)) {
      this.error = i18n.t("all:verification_email_error");
      return;
    }
    this.error = undefined;
  }

  @action
  sendCode() {
    const self = this;
    Meteor.call(
      "users.reset",
      { email: this.emailValue, lang: i18n.language },
      (err: Meteor.Error) => {
        if (err) {
          self.error = i18n.t(`all:${err.error}`) as string;
          console.log(err);
          return;
        }
        self.isCodeSent = true;
        // Five minutes
        self.startTimer(30);
      }
    );
  }

  @action
  confirmReset() {
    if (!this.emailValue || !this.codeValue) return;
    const self = this;

    let e = new Encryption();
    let publicKey = e.myPublicKeyBase64;
    let secretKey = e.mySecretKeyBase64;

    Meteor.call(
      "users.resetConfirm",
      {
        email: this.emailValue,
        code: this.codeValue,
        newPassword: secretKey,
        publicKey,
      },
      (err: Meteor.Error, res: { userId: string }) => {
        if (err) {
          self.error = i18n.t(`all:${err.error}`) as string;
          return;
        }
        if (res.userId) {
          Meteor.loginWithPassword({ id: res.userId }, secretKey, (err) => {
            if (err) {
              // Handle login error
              console.log(err);
              toastStore.showToast({
                message: err.message,
                type: ToastType.Error,
              });
              return;
            }
            storage.write("publicKey", publicKey);
            storage.write("secretKey", secretKey);
            storage.write(ADDITIONAL_INFO_KEY, true);
            storage.write(EMAIL_INFO_KEY, true);

            globalUIStore.setUserFilledAdditionalInfo(true);
            globalUIStore.setUserFilledEmail(true);
            self.clear();
          });
        }
      }
    );
  }

  @action
  clear() {
    this.emailValue = "";
    this.error = undefined;
    this.isCodeSent = false;
    this.codeValue = "";
    this.codeTimestamp = undefined;
    this.timer = undefined;
    this.countDownTimer = undefined;
  }
}
