import { makeStyles } from "hooks/makeStyles";
import { Theme } from "lib/theme";
import React from "react";
import bg800 from "assets/jpg/bg-800.jpeg";
import bg1600 from "assets/jpg/bg-1600.jpeg";
import bg2500 from "assets/jpg/bg-2500.jpeg";
import bg4000 from "assets/jpg/bg-4000.jpeg";
import { AppLoaderNew } from "ui/shared/app-loader-new";
import { globalUIStore } from "global-stores/global-ui-store";
import { userStore } from "global-stores/user-store";
import { observer } from "mobx-react";

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    overflow: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#ccc",
    zIndex: -1,
    "@media screen and (max-width: 1000px)": {
      minHeight: 0,
    },
  },
  bgImg: {
    width: "100%",
    height: "100%",
  },
  center: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Background = observer(() => {
  const css = useStyles();

  return (
    <div className={css.bg}>
      <div className={css.center}>
        {globalUIStore.loading &&
        !userStore.user &&
        !globalUIStore.mobileDevice ? (
          <AppLoaderNew />
        ) : null}
      </div>
      <picture className={css.bg}>
        <source media="(max-width: 799px)" srcSet={bg800} />
        <source media="(min-width: 2500px)" srcSet={bg4000} />
        <source media="(min-width: 1600px)" srcSet={bg2500} />
        <source media="(min-width: 800px)" srcSet={bg1600} />
        <img
          src={bg1600}
          alt="Inside.me background"
          className={css.bgImg}
        ></img>
      </picture>
    </div>
  );
});

export default Background;
