import React from "react";
import { makeStyles } from "hooks/makeStyles";
import { Typography, Icons } from "ui/shared";
import clsx from "clsx";
import moment from "moment";

export interface PeopleArchivePanelItemProps {
  login: string;
  date: Date;
  item: string;
  checked?: boolean;
  onClick: (e: React.MouseEvent, item: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
    boxSizing: "border-box",
    backgroundColor: theme.colors.background.paper.topbar,
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.colors.background.paper.main}`,
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: 44,
  },
  textContainer: { paddingRight: theme.spacing(3), flexGrow: 1 },
  flexEnd: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  flex: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  padded: {
    marginTop: theme.spacing(2),
  },
}));

export const PeopleArchivePanelItem = (props: PeopleArchivePanelItemProps) => {
  const { login, date, item, checked, onClick } = props;
  const css = useStyles();

  return (
    <div
      className={css.root}
      onClick={(e) => onClick(e, item)}
      data-cy={`peopleArchivePanel.items.item`}
    >
      <div className={clsx(css.container, css.textContainer)}>
        <Typography size="subtitle2(16px)" data-cy="peopleArchivePanel.login">
          {login}
        </Typography>
      </div>
      <div className={clsx(css.container, css.flexEnd)}>
        {checked ? <Icons.Checkmark /> : null}
        <Typography
          size="tiny(10px)"
          font="light"
          align="right"
          color="secondary"
          data-cy="peopleArchivePanel.date"
        >
          {moment(date).format("DD.MM.YYYY")}
        </Typography>
      </div>
    </div>
  );
};
