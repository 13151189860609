import * as React from "react";
import { IconProps } from "./names";

interface CreateSvgIconProps {
  children: (props: Partial<IconProps>) => React.ReactElement;
}

export const CreateSvgIcon = (
  props: React.SVGProps<SVGSVGElement> & IconProps & CreateSvgIconProps
) => {
  const {
    width = 32,
    height = 32,
    viewBox = "0 0 32 32",
    color = "#fff",
    secondaryColor,
    children,
    className,
    ...passthrough
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      data-cy={props["data-cy"]}
      className={className}
      {...passthrough}
    >
      {children({ color, secondaryColor })}
    </svg>
  );
};
