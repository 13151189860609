import React, { useCallback, useState } from "react";
import { useTracker } from "hooks/useTracker";
import { Meteor } from "meteor/meteor";
import { Blacklists } from "collections/blacklist";
import { Blacklist } from "lib";

import { makeStyles } from "hooks/makeStyles";
import {
  BackButton,
  Button,
  Box,
  DefaultPanel,
  Typography,
  AnimatedPage,
} from "ui/shared";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { BlacklistStore } from "./store/blacklist-store";
import { BlacklistPanel } from "./blacklist-panel";

export interface BlackListProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    background: theme.colors.background.paper.main,
  },
  container: {
    display: "flex",
    flexGrow: 1,
    width: "100%",
    height: "100%",
  },
  panel: {
    maxHeight: "100%",
    height: "auto",
    overflow: "hidden",
  },
  side: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: 302,
    flex: "0 1 40%",
    padding: `0 ${theme.spacing(5)} ${theme.spacing(5.25)}`,
  },
  main: {
    flex: "1 1 60%",
    marginTop: theme.spacing(6),
    marginRight: theme.spacing(7),
    marginBottom: theme.spacing(6),
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  backButton: {
    height: "fit-content",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    "&:not(:last-child)": {
      marginBottom: theme.spacing(2.5),
    },
  },
  button: {
    height: 46,
    "&:not(:last-child)": {
      marginBottom: theme.spacing(2.5),
    },
  },
  emptyList: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));

export const BlackList = observer((props: BlackListProps) => {
  const { t } = useTranslation("all");
  const css = useStyles();

  const [blacklistStore] = useState(new BlacklistStore());

  const { blacklists }: { blacklists: Blacklist[] } = useTracker(() => {
    Meteor.subscribe("blacklist.myBad");

    return { blacklists: Blacklists.find().fetch() };
  });

  const isItems = typeof blacklists !== "undefined" && blacklists.length > 0;

  /**************************************************/

  const handleSelectBlacklist = useCallback(
    (e: React.MouseEvent, item: string) => {
      if (blacklistStore.selectedItems.includes(item)) {
        blacklistStore.setSelectedItems(
          blacklistStore.selectedItems.filter(
            (displayItems: string) => displayItems !== item
          )
        );
      } else {
        blacklistStore.setSelectedItems([
          ...blacklistStore.selectedItems,
          item,
        ]);
      }
    },
    [blacklistStore]
  );

  /**************************************************/

  return (
    <AnimatedPage animation="horizontal" containerClassName={css.root}>
      <div className={css.container}>
        <div className={css.side}>
          <BackButton
            label={t("all:settings_blacklist")}
            path="/settings"
            data-cy="blackList.backButton"
          />
          <Box className={css.buttonContainer}>
            <Button
              className={css.button}
              onClick={() => {
                blacklistStore.deleteBlacklists();
              }}
              data-cy="blackList.deleteSelected"
              disabled={blacklistStore.selectedItems.length <= 0}
            >
              {t("all:settings_list_del_button")}
            </Button>
            <Button
              className={css.button}
              onClick={() => {
                blacklistStore.clearBlacklists();
              }}
              data-cy="blackList.clearList"
              disabled={!isItems}
            >
              {t("all:settings_list_clear_button")}
            </Button>
          </Box>
        </div>
        <div className={css.main}>
          {isItems ? (
            <DefaultPanel className={css.panel}>
              <BlacklistPanel
                items={blacklists}
                selected={blacklistStore.selectedItems}
                onSelectBlackList={handleSelectBlacklist}
              />
            </DefaultPanel>
          ) : (
            <div className={css.emptyList}>
              <Typography font="script" size="subtitle2(16px)">
                {t("all:settings_blacklist_empty")}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </AnimatedPage>
  );
});
