import React from "react";

interface SmallBubbleProps {
  className?: string;
}

const SmallBubble = ({ className }: SmallBubbleProps) => {
  return (
    <svg
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity="0.226"
        d="M8.49999 24.0001C12.4 24.0001 16.8 20.5001 19.8 17.7001C24 13.8001 28.8 7.20009 26.1 2.20009C24.2 -1.39991 16.6 0.400068 9.79997 4.30007C7.29997 5.70007 4.99997 7.4001 2.79997 9.4001C-0.600027 12.6001 -0.800027 17.9001 2.29997 21.3001C3.99997 23.0001 6.19999 24.0001 8.49999 24.0001Z"
        fill="#FAF7CF"
      />
    </svg>
  );
};

export default SmallBubble;
