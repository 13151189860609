import React, { HTMLAttributes } from "react";
import { makeStyles } from "hooks/makeStyles";
import clsx from "clsx";
import { Sizes } from "lib/theme";

type SizeOptions =
  | "tiny(10px)"
  | "caption(12px)"
  | "body(14px)"
  | "subtitle1(18px)"
  | "subtitle2(16px)"
  | "h3(20px)"
  | "h2(23px)"
  | "h1(33px)";

interface TypographyProps extends HTMLAttributes<HTMLParagraphElement> {
  size?: SizeOptions;
  font?: "script" | "light" | "regular" | "medium" | "semibold";
  align?: "center" | "left" | "right" | "justify";
  color?:
    | "primary"
    | "secondary"
    | "disabled"
    | "black"
    | "white"
    | "yellow"
    | "red";
  uppercase?: boolean;
  lowercase?: boolean;
  ellipsis?: boolean;
  noSelection?: boolean;
  htmlTag?: keyof JSX.IntrinsicElements;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  default: {
    color: theme.colors.textPrimary,
    margin: 0,
  },
  base: {
    fontFamily: ({ font = "regular" }: TypographyProps) => theme.fonts[font],
    fontSize: ({ size = "body(14px)" }: TypographyProps) =>
      theme.sizes.typography[getSize(size)],
  },
  alignCenter: {
    textAlign: "center",
  },
  alignLeft: {
    textAlign: "left",
  },
  alignRight: {
    textAlign: "right",
  },
  alignJustify: {
    textAlign: "justify",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  lowercase: {
    textTransform: "lowercase",
  },
  primary: {
    color: theme.colors.textPrimary,
  },
  secondary: {
    color: theme.colors.textSecondary,
  },
  black: {
    color: theme.colors.textBlack,
  },
  white: {
    color: theme.colors.common.white,
  },
  yellow: {
    color: theme.colors.primary,
  },
  red: {
    color: theme.colors.red.secondary,
  },
  disabled: {
    color: theme.colors.textDisabled,
  },
  ellipsis: {
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  noSelection: {
    userSelect: "none",
  },
}));

export const Typography = (
  props: TypographyProps & React.HTMLAttributes<HTMLOrSVGElement>
) => {
  const css = useStyles(props);

  const {
    align,
    color = "primary",
    uppercase,
    lowercase,
    ellipsis,
    className,
    size,
    htmlTag,
    noSelection,
    ...passgthrough
  } = props;

  const cx = clsx(css.default, css.base, {
    [css.alignCenter]: align === "center",
    [css.alignLeft]: align === "left",
    [css.alignRight]: align === "right",
    [css.alignJustify]: align === "justify",
    [css.primary]: color === "primary",
    [css.secondary]: color === "secondary",
    [css.disabled]: color === "disabled",
    [css.black]: color === "black",
    [css.white]: color === "white",
    [css.yellow]: color === "yellow",
    [css.red]: color === "red",
    [css.uppercase]: uppercase,
    [css.lowercase]: lowercase,
    [css.ellipsis]: ellipsis,
    [css.noSelection]: noSelection,
    [className!]: !!className,
  });

  const Tag = htmlTag ? htmlTag : "p";

  return (
    <Tag className={cx} {...passgthrough}>
      {props.children}
    </Tag>
  );
};

function getSize(size: SizeOptions): keyof Sizes["typography"] {
  return size.split("(")[0] as keyof Sizes["typography"];
}
