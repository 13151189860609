import { observable, action } from "mobx";
import { Friend, Media, Message, MessageType } from "lib";
import { callWithPromise } from "utils";
import { clearMessage, validateBase64 } from "utils/strings";
import { UploadedFile } from "ui/shared/dropzone/dropzone-store";
import { Random } from "meteor/random";
import { userStore } from "./user-store";
import { encriptionStore } from "./encryption-store";
import { Meteor } from "meteor/meteor";
import optimizeImage from "utils/resizer";
import {
  ChatStore,
  ChatStoreState,
  getChatStore,
} from "ui/pages/chats/stores/chat-store";
import { subscriptionsStore } from "./subscriptions-store";
import configStore from "./config-store";

interface ImageMessageResult {
  myMessageId: string;
  friendMessageId: string;
}

class ChatsStore {
  @observable loading?: boolean;
  @observable isUploadShown: boolean = false;

  @observable messageInput: string = "";

  // Translate props
  @observable isMessageFullyTranslated: boolean = false;
  @observable originMessageBeforeTranslate: string = "";
  @observable translatedMessage: string = "";
  @observable showOriginalMessage: boolean = false;

  @observable cursorIndex: number = 0;

  @observable friends: Friend[] = [];
  @observable selectedFriend?: Friend;
  @observable selectedChatStore?: ChatStore;

  @observable selectedMedia?: Media;

  private cache: { [key: string]: any } = {};

  @action
  setSelectedMedia(media: Media) {
    this.selectedMedia = media;
  }

  @action
  clearSelectedMedia() {
    this.selectedMedia = undefined;
  }

  @action
  clearSelectedChat() {
    this.selectedChatStore = undefined;
    this.selectedFriend = undefined;
  }

  @action
  setSelectedFriend(friend: Friend) {
    if (
      (this.selectedFriend && this.selectedFriend._id !== friend._id) ||
      !this.selectedFriend
    ) {
      this.selectedFriend = friend;
      this.selectedChatStore = getChatStore(friend._id);
      this.selectedChatStore.setState(ChatStoreState.NotReady);
      subscriptionsStore.addSubscription(friend._id);
      if (!encriptionStore.getEncryptor(friend._id)) {
        encriptionStore.createEncryption(friend);
      }
    }
  }

  @action
  setMessageInput(value: string, isMessageFullyTranslated?: boolean) {
    if (isMessageFullyTranslated) {
      this.originMessageBeforeTranslate = this.messageInput;
      this.isMessageFullyTranslated = !!isMessageFullyTranslated;
      this.translatedMessage = value;
    } else {
      this.originMessageBeforeTranslate = "";
      this.isMessageFullyTranslated = false;
      this.translatedMessage = "";
      this.showOriginalMessage = false;
    }
    if (value != null && value != this.messageInput) {
      this.messageInput = value;
    }
  }

  @action
  toggleShowOriginalMessage() {
    this.showOriginalMessage = !this.showOriginalMessage;
    if (this.showOriginalMessage && this.originMessageBeforeTranslate) {
      this.messageInput = this.originMessageBeforeTranslate;
    } else if (!this.showOriginalMessage && this.translatedMessage) {
      this.messageInput = this.translatedMessage;
    }
  }

  @action
  setCursorIndex(value: number) {
    if (value != null) {
      this.cursorIndex = value;
    }
  }

  @action
  encryptTranslatedMessage(text: string) {
    if (!text) return;
    if (!this.selectedFriend) return;

    const encryptor = encriptionStore.getEncryptor(this.selectedFriend._id);
    if (!encryptor) return;

    const encrypted = encryptor.encryptMessage(text);

    return encrypted;
  }

  @action
  async sendMessage(text: string, afterSend?: () => void) {
    if (!text) return;

    if (this.selectedFriend) {
      const encryptor = encriptionStore.getEncryptor(this.selectedFriend._id);
      if (!encryptor) return;

      const messageText = encryptor.encryptMessage(text);
      const messageTextMy = encryptor.encryptMessageForMe(text);

      await callWithPromise("messages.send", {
        friendId: this.selectedFriend._id,
        messageText,
        messageTextMy,
        msgType: MessageType.Text,
      });

      if (afterSend) {
        afterSend();
      }
    }
  }

  @action
  sendGif(
    id: string,
    url: string,
    width: string,
    height: string,
    afterSend?: () => void
  ) {
    if (!id) return;
    const text = `{"t":"giphy","i":"${id}"}|${url}`;

    if (this.selectedFriend) {
      const encryptor = encriptionStore.getEncryptor(this.selectedFriend._id);
      if (!encryptor) return;

      const messageText = encryptor.encryptMessage(text);
      const messageTextMy = encryptor.encryptMessageForMe(text);

      callWithPromise("messages.send", {
        friendId: this.selectedFriend._id,
        messageText,
        messageTextMy,
        boxSize: `[${width},${height}]`,
        msgType: MessageType.GIF,
      })
        .then((res) => {
          if (afterSend) {
            afterSend();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  @action
  decryptMessage(
    message: Message,
    friendId?: string,
    options?: {
      useTranslation?: boolean;
      dropCache?: boolean;
    }
  ): string | undefined {
    if (!message.messageText) return "";
    if (message.plain) return message.messageText;

    // validate base64 string
    const isValid = validateBase64(message.messageText);

    const useTranslation = options && options.useTranslation;
    const dropCache = options && options.dropCache;

    if (isValid) {
      const id = message._id;

      if (this.cache[id] && !dropCache) {
        return this.cache[id];
      }

      const encryptor = message.friendId
        ? encriptionStore.getEncryptor(message.friendId)
        : encriptionStore.getEncryptor(friendId!);

      if (encryptor) {
        if (message.my && message.messageText) {
          const decripted = encryptor.decryptMessageForMe(message.messageText);
          this.cache[id] = clearMessage(decripted);
          return this.cache[id];
        } else if (!message.my && useTranslation && message.messageTextT) {
          const decripted = encryptor.decryptMessage(message.messageTextT);
          this.cache[id] = clearMessage(decripted);
          return this.cache[id];
        } else if (!message.my && message.messageText) {
          const decripted = encryptor.decryptMessage(message.messageText);
          this.cache[id] = clearMessage(decripted);
          return this.cache[id];
        }
      }
    } else {
      return message.messageText;
    }
  }

  @action
  sendFriendRequest(friendId: string) {
    this.loading = true;
    try {
      callWithPromise("friends.friendship", { friendId });
    } catch (err) {
      console.log(err.message);
    }
    this.loading = false;
  }

  @action
  clearHistory(friendId: string) {
    this.loading = true;
    try {
      callWithPromise("friends.clearDialog", { friendId });
    } catch (err) {
      console.log(err.message);
    }
    this.loading = false;
  }

  @action
  leave(friendId: string, blacklist?: boolean) {
    this.loading = true;
    try {
      callWithPromise("friends.leave", { friendId, blacklist });
    } catch (err) {
      console.log(err.message);
    }
    this.loading = false;
  }

  @action
  addToBlacklist(
    friendId: string,
    login: string,
    reason: string,
    description: string
  ) {
    this.loading = true;
    try {
      callWithPromise("blacklist.add", {
        friendId,
        login,
        reason,
        description,
      });
    } catch (err) {
      console.log(err.message);
    }
    this.loading = false;
  }

  @action
  typing(friendIamId: string) {
    this.loading = true;
    try {
      callWithPromise("friends.typing", { friendIamId });
    } catch (err) {
      console.log(err.message);
    }
    this.loading = false;
  }

  @action
  toggleUpload(value?: boolean) {
    if (value != null) {
      this.isUploadShown = value;
    } else {
      this.isUploadShown = !this.isUploadShown;
    }
  }

  @action
  async optimizeFile(file: UploadedFile): Promise<UploadedFile | string> {
    const optimized = await optimizeImage(file.file);
    return {
      ...file,
      buffer: optimized.file,
      size: optimized.size,
    };
  }

  @action
  sendImageMessage(file: UploadedFile): Promise<string | ImageMessageResult> {
    return new Promise((resolve, reject) => {
      // TODO: handle errors
      if (!this.selectedFriend) return reject("No selected friend");
      if (!file.size || !file.buffer) return reject("Invalid file");
      if (!userStore.user || !encriptionStore.token)
        return reject("No user or wrong token");

      const newUploadingMessage = {
        id: Random.id(),
        friendId: this.selectedFriend._id,
        uploading: true,
        boxSize: `[${file.size.width},${file.size.height}]`, //Передаём размермеры картинки, для мгновенного отображения бокса у получателя
        msgType: MessageType.Image,
      };

      Meteor.call(
        "messages.send",
        newUploadingMessage,
        (
          err: Meteor.Error,
          res: {
            myMessageId: string;
            friendMessageId: string;
          }
        ) => {
          if (err) {
            reject(err.message);
          } else {
            resolve(res);
          }
        }
      );
    });
  }

  @action
  async upload(
    imageMessageResult: ImageMessageResult,
    file: UploadedFile
  ): Promise<
    string | { encoded: boolean; id: string; messageId: string; type: string }[]
  > {
    return new Promise(async (resolve, reject) => {
      // TODO: handle errors
      if (!this.selectedFriend) return reject("No selected friend");
      if (!userStore.user || !encriptionStore.token)
        return reject("No user or wrong token");

      const encryptor = encriptionStore.getEncryptor(this.selectedFriend._id);

      if (!encryptor) return reject("Encryptor hasn't been initialized");

      // Encrypt images

      const arr = new Uint8Array(file.buffer!);

      const encryptedImageForFriend = encryptor.encryptImage(arr);
      const encryptedImageForMe = encryptor.encryptImageForMe(arr);

      const body = new FormData();

      const myFileId = Random.id();
      const friendFileId = Random.id();

      body.append(
        myFileId,
        new Blob([new Buffer(encryptedImageForMe)]),
        myFileId
      );
      body.append(
        friendFileId,
        new Blob([new Buffer(encryptedImageForFriend)]),
        friendFileId
      );

      fetch(`${configStore.fileServer}/up/raw/${userStore.user._id}`, {
        method: "POST",
        headers: {
          secret: encriptionStore.token,
          type: file.file.type,
          "message-id": imageMessageResult.myMessageId,
          "friend-user-id": this.selectedFriend.friendUserId,
          "friend-message-id": imageMessageResult.friendMessageId,
          "friend-id": this.selectedFriend._id,
          "friend-iam-id": this.selectedFriend.friendIamId,
        },
        body,
      })
        .then((res) => res.json())
        .then((data) => resolve(data));
    });
  }
}

export const chatsStore = new ChatsStore();
