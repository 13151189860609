import React from "react";
import { makeStyles } from "hooks/makeStyles";
import { useTranslation } from "react-i18next";
import { darkTheme } from "lib/theme";
import { Button, Icons, Typography } from "ui/shared";
import { observer } from "mobx-react";
import { chatsStore } from "global-stores/chats-store";

interface ServiceMessageProps {
  message: string;
  isFriend: boolean;
  friendId: string;
}

const useStyles = makeStyles((theme) => ({
  separator: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 999,
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    backgroundColor: theme.colors.background.paper.lighter,
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: `${darkTheme.spacing(1)} ${darkTheme.spacing(4)}`,
    textTransform: "none",
  },
}));

export const ServiceMessage = observer((props: ServiceMessageProps) => {
  const { message, isFriend, friendId } = props;
  const { t } = useTranslation("all");
  const css = useStyles();

  if (message === "friend_in") {
    return (
      <Button
        className={css.button}
        disabled={isFriend}
        primary
        disabledColor={darkTheme.colors.background.paper.lighter}
        onClick={() => chatsStore.sendFriendRequest(friendId)}
      >
        <Icons.Bell width={24} height={24} /> {t(`all:${message}`)}
      </Button>
    );
  } else {
    return (
      <div className={css.separator}>
        <Typography color="white" data-cy="chat-service-message">
          {t(`all:${message}`)}
        </Typography>
      </div>
    );
  }
});
