import { modalManager } from "global-stores/modal-manager";
import { toastStore } from "global-stores/toasts-store";
import { makeStyles } from "hooks/makeStyles";
import { ToastType } from "lib";
import { MODALS } from "lib/constants";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "ui/shared";

interface PhotoCaptureProps {
  open: boolean;
  onCapturePhoto: (image: string) => void;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    position: "relative",
    display: "flex",
    minHeight: 320,
    margin: "0 auto",
  },
  takePhotoContainer: {
    position: "absolute",
    bottom: 15,
    left: 0,
    right: 0,
    display: "flex",
    zIndex: 1,
  },
  takePhotoButtonOutline: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 64,
    height: 64,
    border: "2px solid white",
    borderRadius: "50%",
    margin: "0 auto",
    "&:hover $takePhotoButton": {
      width: 52,
      height: 52,
    },
  },
  takePhotoButton: {
    width: 56,
    height: 56,
    border: 0,
    borderRadius: "50%",
    backgroundColor: "#fff",
    cursor: "pointer",
    transition: "all .2s ease-in-out",
  },
  photoCanvas: {
    display: "none",
    width: 1280,
    height: 720,
  },
  video: {
    margin: "0 auto",
    maxWidth: 1280,
  },
}));

const PhotoCapture = (props: PhotoCaptureProps) => {
  const { open, onCapturePhoto, onClose } = props;
  const css = useStyles();
  const { t } = useTranslation("all");
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [videoIsStreaming, setVideoIsStreaming] = useState(false);

  const stopStream = useCallback(() => {
    if (videoRef.current) {
      const stream = videoRef.current.srcObject as MediaStream;
      if (stream) {
        const tracks = stream.getTracks();

        tracks.forEach(function (track) {
          track.stop();
        });

        videoRef.current.srcObject = null;
      }
    }
  }, [videoRef]);

  const handleVideoModalClose = useCallback(() => {
    stopStream();
    setVideoIsStreaming(false);

    onClose();
  }, [stopStream, onClose]);

  const handleStartCapturing = useCallback(() => {
    modalManager.open(MODALS.CAPTURE_PHOTO);

    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: { width: { min: 1280 }, height: { min: 720 } },
      })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.onloadedmetadata = function (e) {
            if (videoRef.current) {
              videoRef.current.play();
              setVideoIsStreaming(true);
            }
          };
        }
      })
      .catch((err) => {
        toastStore.showToast({
          message: t("all:alert_access_camera_text"),
          type: ToastType.Error,
        });
        modalManager.close(MODALS.CAPTURE_PHOTO);
        handleVideoModalClose();
      });
  }, [handleVideoModalClose, t]);

  const handlePhoto = useCallback(() => {
    if (canvasRef.current && videoRef.current) {
      const canvasContext = canvasRef.current.getContext("2d");
      if (canvasContext) {
        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.height = videoRef.current.videoHeight;
        canvasContext.drawImage(videoRef.current, 0, 0);
        let imageData = canvasRef.current.toDataURL("image/jpeg", 1);

        if (imageData && onCapturePhoto) {
          onCapturePhoto(imageData);
        }
        modalManager.close(MODALS.CAPTURE_PHOTO);
        handleVideoModalClose();
      }
    }
  }, [handleVideoModalClose, onCapturePhoto]);

  useEffect(() => {
    if (open) {
      handleStartCapturing();
    }
  }, [open, handleStartCapturing]);

  return (
    <>
      <Modal modalId={MODALS.CAPTURE_PHOTO} onClose={handleVideoModalClose}>
        {() => (
          <div className={css.videoContainer}>
            <div className={css.takePhotoContainer}>
              {videoIsStreaming ? (
                <div className={css.takePhotoButtonOutline}>
                  <button
                    onClick={handlePhoto}
                    className={css.takePhotoButton}
                  ></button>
                </div>
              ) : null}
            </div>
            <video ref={videoRef} width="100%" className={css.video}></video>
          </div>
        )}
      </Modal>
      <canvas ref={canvasRef} className={css.photoCanvas} />
    </>
  );
};

export default PhotoCapture;
