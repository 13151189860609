import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Send = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Send}>
    {({ color }) => (
      <>
        <path
          id="Path_2120"
          fill="#fff"
          d="M16,1.1c8.2,0,14.9,6.7,14.9,14.9S24.2,30.9,16,30.9S1.1,24.2,1.1,16c0,0,0,0,0,0
	C1.1,7.8,7.8,1.2,16,1.1C16,1.1,16,1.1,16,1.1 M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16s16-7.2,16-16C32,7.2,24.8,0,16,0z"
        />
        <path
          fill="#fff"
          d="M24.9,14.4l-8.2-8.2c-0.3-0.3-0.9-0.3-1.3,0l-8.2,8.2c-0.3,0.3-0.3,0.9,0,1.3c0,0,0,0,0.1,0.1
	C7.6,16,8.1,16,8.3,15.7l6.7-6.7v15.3c0,0,0,0,0,0c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9V8.9l6.7,6.7
	c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.5-0.1,0.7-0.2C25.3,15.3,25.3,14.7,24.9,14.4z"
        />
      </>
    )}
  </CreateSvgIcon>
);
