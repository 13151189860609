import { Meteor } from "meteor/meteor";
import { Accounts } from "meteor/accounts-base";
import { observable, action } from "mobx";
import { i18n } from "services/i18n";
import { Encryption } from "lib";
import { storage } from "utils";
import { globalUIStore } from "global-stores/global-ui-store";

export class LoginFlowStore {
  @observable loading: boolean = false;
  @observable username: string = "";
  @observable email: string = "";
  @observable message: string = "";
  @observable warning: string = "";
  @observable usernameError?: string;
  @observable emailError?: string;

  _loginRegExp = /^[a-zA-Z]([_.-]?[a-zA-Z0-9]){4,20}$/;
  _emailRegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  @action
  setUsername(value: string) {
    const newValue = value.trim();
    if (newValue.length <= 20) {
      this.username = newValue;
      if (newValue.length >= 5) {
        this.checkUsername();
      } else {
        this.message = "";
        this.usernameError = undefined;
        this.warning = i18n.t("all:main_signup_msg_rules");
      }
    }
    if (!newValue) {
      this.message = "";
      this.warning = "";
      this.usernameError = undefined;
    }
  }

  @action
  async checkUsername() {
    if (!this.username.match(this._loginRegExp)) {
      this.message = "";
      this.warning = "";
      this.usernameError = i18n.t("all:main_signup_msg_login_uncorrect");
      return;
    }
    Meteor.call(
      "users.loginExistCheck",
      { username: this.username },
      (error: Meteor.Error, result: any) => {
        if (error) {
          this.usernameError = i18n.t("all:main_signup_msg_login_uncorrect");
          this.message = "";
          return;
        }
        if (result) {
          this.usernameError = i18n.t("all:main_signup_msg_login_busy");
          this.message = "";
        } else {
          this.message = i18n.t("all:main_signup_msg_login_free");
          this.usernameError = undefined;
        }
      }
    );
  }

  @action
  async occupyUsername(event: React.MouseEvent) {
    event.preventDefault();
    let e = new Encryption();

    const self = this;

    let publicKey = e.myPublicKeyBase64;
    let secretKey = e.mySecretKeyBase64;

    const occupyLoginData = {
      username: self.username,
      password: secretKey,
      profile: { publicKey: publicKey },
    };

    Accounts.createUser(occupyLoginData, (error: any) => {
      if (error) {
        if (error.error === 403) {
          self.usernameError = i18n.t("all:main_signup_msg_login_busy");
        } else {
          self.usernameError = i18n.t("all:main_signup_msg_login_busy");
        }
      }

      storage.write("login", self.username);
      storage.write("publicKey", publicKey);
      storage.write("secretKey", secretKey);
    });
  }

  @action
  setEmail(value: string) {
    const newValue = value.trim();
    if (newValue.length <= 150) {
      this.email = newValue;
      if (newValue.length >= 1) {
        this.checkEmail();
      } else {
        this.message = "";
        this.emailError = undefined;
        this.warning = i18n.t("all:verification_email_error");
      }
    }
    if (!newValue) {
      this.message = "";
      this.warning = "";
      this.emailError = undefined;
    }
  }

  @action
  async checkEmail() {
    if (!this.email.match(this._emailRegExp)) {
      this.emailError = i18n.t("all:verification_email_error");
      this.message = "";
      this.warning = "";
      return;
    } else {
      Meteor.call(
        "email.existCheck",
        { email: this.email },
        (error: Meteor.Error, result: any) => {
          if (error) {
            this.emailError = i18n.t("all:verification_email_error");
            this.message = "";
            return;
          }
          if (result) {
            this.emailError = i18n.t("all:verification_email_already_exist");
            this.message = "";
          } else {
            this.message = i18n.t("all:verification_email_ok_reg");
            this.warning = "";
            this.emailError = undefined;
          }
        }
      );
    }
  }

  @action
  async setNewEmail() {
    const self = this;
    if (this.email && !this.emailError && !this.warning) {
      Meteor.call(
        "emailVerification.init",
        { email: this.email, lang: i18n.language, link: true },
        (error: Meteor.Error, result: any) => {
          if (!error) {
            globalUIStore.setUserFilledEmail(true);
          }
          self.clear();
        }
      );
    } else {
      self.clear();
      globalUIStore.setUserFilledEmail(true);
    }
  }

  @action
  clear() {
    this.email = "";
    this.emailError = undefined;
    this.warning = "";
    this.message = "";
  }
}
