import { i18n } from "services/i18n";

const translateErrors = (message: string) => {
  switch (message) {
    case "AvatarLimit":
      return i18n.t("all:alert_avatar_limit_1");
    case "newPickup.equal":
      return i18n.t("all:alert_search_same");
    case "newPickup.notViplimit":
      return i18n.t("all:alert_search_more_3");
    case "newPickup.GlobalLimit":
      return i18n.t("all:alert_search_more_10");
    case "verification_alert_too_fast":
      return i18n.t("all:verification_alert_too_fast");
    case "verification_alert_send_email_error":
      return i18n.t("all:verification_alert_send_email_error");
    default:
      return message;
  }
};

export default translateErrors;
