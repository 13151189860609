import React from "react";
import { makeStyles } from "hooks/makeStyles";
import { Icons } from "../icons";

const useStyles = makeStyles((theme) => ({
  centered: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
  },
}));

export const Loader = () => {
  const css = useStyles();

  return (
    <div className={css.centered}>
      <Icons.PuffLoader width={32} height={32} viewBox="0 0 44 44" />
    </div>
  );
};
