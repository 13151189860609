import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const RevertArrow = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.RevertArrow}>
    {({ color }) => (
      <>
        <rect fill={color} />
        <path
          d="M993.488,1513.45a7.407,7.407,0,0,0-6.968-7.447v-.023h-4.363v-4.7l-5.907,5.816,5.907,5.816v-4.7h4.006v0a5.243,5.243,0,0,1,.011,10.479l-4.6-.011-.006,2.227,4.6.011h.043A7.406,7.406,0,0,0,993.488,1513.45Z"
          transform="translate(-969.249 -1495.276)"
          fill="#fff"
        />
      </>
    )}
  </CreateSvgIcon>
);
