import React, { useCallback, useContext, useEffect, useState } from "react";
import { makeStyles } from "hooks/makeStyles";
import { Message } from "lib";
import { Typography, Icons, Loader, Tooltip, Button } from "ui/shared";
import { Gif } from "@giphy/react-components";
import moment from "moment";
import Linkify from "react-linkify";

import leftTail from "assets/svg/bubble-tail-left.svg";
import rightTail from "assets/svg/bubble-tail-right.svg";
import { chatsStore } from "global-stores/chats-store";
import { observer } from "mobx-react";
import { userStore } from "global-stores/user-store";
import { encriptionStore } from "global-stores/encryption-store";
import { ChatContext } from "./chats";
import clsx from "clsx";
import { fade } from "utils";
import { lruCache } from "global-stores/lru-cache";
import URL from "utils/url";
import { giphyFetch } from "ui/pages/chats/gif-picker";
import calculateBoxSize from "utils/calculate-box-size";
import { modalManager } from "global-stores/modal-manager";
import { MODALS } from "lib/constants";
import { useTranslation } from "react-i18next";
import { cacheStore } from "global-stores/cache-store";
import translator from "global-stores/translator-store";
import { TranslationSettings } from "./stores/chat-store";
import translationCacheStore from "global-stores/translation-cache-store";
import configStore from "global-stores/config-store";
import { DotsLoader } from "ui/shared/icons/icons";

interface ChatBubbleProps {
  message: Message;
  isFriend?: boolean;
  translationSettings?: TranslationSettings;
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    width: "100%",
    justifyContent: ({ message }: ChatBubbleProps) =>
      message.my ? "flex-end" : "flex-start",
    alignItems: "flex-end",
  },
  bubble: {
    width: "auto",
    maxWidth: "60%",
    height: "auto",
    borderRadius: 15,
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    backgroundColor: ({ message }: ChatBubbleProps) =>
      !message.my ? theme.colors.common.white : theme.colors.purple.main,
    boxSizing: "border-box",
    zIndex: 2,
  },
  message: {
    wordBreak: "break-all",
    whiteSpace: "pre-line",
    color: ({ message }: ChatBubbleProps) =>
      !message.my ? theme.colors.common.black : theme.colors.common.white,
  },
  time: {
    color: theme.colors.textPrimary,
    padding: `0 ${theme.spacing(2)}`,
  },
  leftTail: {
    width: 12.4,
    height: 14,
    position: "relative",
    right: -8,
  },
  rightTail: {
    width: 12.4,
    height: 14,
    position: "relative",
    right: 8,
  },
  imageContainer: {
    maxWidth: "60%",
    borderRadius: 10,
    overflow: "hidden",
    position: "relative",
    border: 0,
  },
  fetching: {
    backgroundColor: fade(theme.colors.common.white, 0.1),
  },
  timeContainer: {
    display: "flex",
    position: "absolute",
    bottom: 6,
    left: ({ message }: ChatBubbleProps) => (message.my ? 6 : "none"),
    right: ({ message }: ChatBubbleProps) => (!message.my ? 6 : "none"),
    backgroundColor: "#222",
    padding: "5px 0",
    borderRadius: "6px",
    opacity: 0.7,
    zIndex: 1,
  },
  giphy: {
    maxWidth: "100%",
  },
  buttonWithoutStyles: {
    border: 0,
    cursor: "pointer",
    backgroundColor: "transparent",
    "&:focus, &:active": { outline: "none" },
  },
  defaultTimeContainer: { display: "flex", position: "relative" },
  statusWrapper: {
    position: "relative",
    top: -2,
    left: 4,
  },
  link: {
    color: ({ message }: ChatBubbleProps) =>
      message.my ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.6)",
  },
  linkTooltip: {
    left: ({ message }: ChatBubbleProps) => (message.my ? "unset" : -21),
    right: ({ message }: ChatBubbleProps) => (message.my ? -20 : "unset"),
    bottom: 30,
  },
  translateButton: {
    padding: 7,
  },
  textButton: {
    backgroundColor: "transparent",
    padding: 0,
    textTransform: "lowercase",
    "&:hover, &:focus, &:active": {
      backgroundColor: "transparent",
    },
  },
}));

const Default = observer((props: ChatBubbleProps) => {
  const { message, isFriend, translationSettings } = props;
  const { t } = useTranslation("all");
  const css = useStyles(props);

  const isTranslationEnabled =
    translationSettings?.useTranslate &&
    (userStore.isVIP || userStore.isVerfiedUser);
  const friendLang = translationSettings?.friendLang;
  const myLang = translationSettings?.myLang;

  const cachedShowOriginalMessage = translationCacheStore.cache[message._id];
  const useTranslateMessage =
    cachedShowOriginalMessage === true ? false : !!message.messageTextT;

  const decryptedMessageText = chatsStore.decryptMessage(message, undefined, {
    useTranslation: useTranslateMessage,
    dropCache: true,
  });

  const messageToShowUser = useTranslateMessage
    ? decryptedMessageText?.substring(2)
    : decryptedMessageText;

  const showTranslateButton =
    translator.isEnabled && isTranslationEnabled
      ? cachedShowOriginalMessage
        ? false
        : !message.messageTextT ||
          decryptedMessageText?.substring(0, 2) !== myLang
      : false;

  /**************************************************/

  const renderMessageStatus = React.useCallback(
    (status: number) => {
      const wrapper = (el: React.ReactNode, styles?: React.CSSProperties) => (
        <span className={css.statusWrapper} style={styles}>
          {el}
        </span>
      );

      switch (status) {
        case 0:
          return wrapper(<Icons.EyeClosed />, { top: 1 });
        case 2:
          return wrapper(<Icons.EyeOpened />);
        default:
          return null;
      }
    },
    [css.statusWrapper]
  );

  /**************************************************/

  return (
    <div className={css.container}>
      {message.my ? (
        <div className={css.defaultTimeContainer}>
          {renderMessageStatus(message.deliveryStatus)}
          <Typography font="light" size="caption(12px)" className={css.time}>
            {moment(message.createdAt).format("HH:mm")}
          </Typography>
        </div>
      ) : null}
      {!message.my ? (
        <img src={leftTail} className={css.leftTail} alt="" />
      ) : null}
      <div className={css.bubble}>
        <Linkify
          componentDecorator={(
            decoratedHref: string,
            decoratedText: string,
            key: string
          ) =>
            !message.my && !isFriend ? (
              <Tooltip
                key={key}
                preferCenter={false}
                preferRight={!message.my}
                title={t("all:dialog_hidden_link_alert")}
                overlayClassName={css.linkTooltip}
              >
                <a key={key} className={css.link}>
                  {t("all:dialog_hidden_link")}
                </a>
              </Tooltip>
            ) : (
              <a
                target="blank"
                href={decoratedHref}
                key={key}
                className={css.link}
              >
                {decoratedText}
              </a>
            )
          }
        >
          <Typography
            size="subtitle2(16px)"
            color="black"
            className={css.message}
            htmlTag="div"
            data-cy="chat-message"
          >
            {messageToShowUser}
          </Typography>
          {!message.my ? (
            translator.isTranslatingFriendMessage[message._id] ? (
              <div>
                <DotsLoader
                  width={32}
                  height={14}
                  viewBox="0 0 120 30"
                  fill="#222"
                />
              </div>
            ) : translator.isEnabled && isTranslationEnabled ? (
              <Button
                tabIndex={-1}
                onClick={() =>
                  showTranslateButton
                    ? translator.translateFriendMessage(
                        message,
                        myLang,
                        friendLang
                      )
                    : translationCacheStore.toggleShowOriginalMessage(
                        message._id
                      )
                }
                className={css.textButton}
              >
                <Typography noSelection size="caption(12px)" color="black">
                  {showTranslateButton ||
                  translationCacheStore.cache[message._id]
                    ? t("all:trans_show_trans")
                    : t("all:trans_show_original")}
                </Typography>
              </Button>
            ) : null
          ) : null}
        </Linkify>
      </div>
      {message.my ? (
        <img src={rightTail} className={css.rightTail} alt="" />
      ) : null}
      {!message.my ? (
        <>
          <Typography font="light" size="caption(12px)" className={css.time}>
            {moment(message.createdAt).format("HH:mm")}
          </Typography>
        </>
      ) : null}
    </div>
  );
});

const Typing = () => {
  const css = useStyles({ message: { type: "from" } });

  return (
    <div className={css.container}>
      <img src={leftTail} className={css.leftTail} alt="" />
      <div className={css.bubble}>
        <Icons.Typing height={18} viewBox="0 0 120 30" color="#2C2C2C" />
      </div>
    </div>
  );
};

const ImageBox = observer((props: ChatBubbleProps) => {
  const css = useStyles(props);
  const { message } = props;
  const [fetchingImage, setFetchingImage] = useState(true);
  const chatContext = useContext(ChatContext);

  const boxSize = calculateBoxSize(chatContext, message.boxSize);
  const fullSizeBox = calculateBoxSize(chatContext, message.boxSize, 0.9);

  const isImage = message.filesStatus === 1 || message.filesStatus === 2;

  /**************************************************/

  const renderMessageStatus = React.useCallback(
    (status: number) => {
      const wrapper = (el: React.ReactNode, styles?: React.CSSProperties) => (
        <span className={css.statusWrapper} style={styles}>
          {el}
        </span>
      );

      switch (status) {
        case 0:
          return wrapper(<Icons.EyeClosed />, { top: 1 });
        case 2:
          return wrapper(<Icons.EyeOpened />);
        default:
          return null;
      }
    },
    [css.statusWrapper]
  );

  /**************************************************/

  return (
    <div className={css.container}>
      <div
        className={clsx(css.imageContainer, {
          [css.fetching]: fetchingImage,
        })}
        style={{
          width: boxSize?.width,
          height: boxSize?.height,
        }}
      >
        <div className={css.timeContainer}>
          {message.my ? renderMessageStatus(message.deliveryStatus) : null}
          <Typography font="light" size="caption(12px)" className={css.time}>
            {moment(message.createdAt).format("HH:mm")}
          </Typography>
        </div>
        {isImage ? (
          <Image
            width={boxSize?.width || 200}
            height={boxSize?.height || 200}
            fullSizeWidth={fullSizeBox?.width || 400}
            fullSizeHeight={fullSizeBox?.height || 400}
            setFetchingImage={setFetchingImage}
            message={message}
          />
        ) : (
          <GIF
            width={boxSize?.width || 200}
            height={boxSize?.height || 200}
            fullSizeWidth={fullSizeBox?.width || 400}
            fullSizeHeight={fullSizeBox?.height || 400}
            setFetchingImage={setFetchingImage}
            message={message}
          />
        )}
      </div>
    </div>
  );
});

interface ChatGIFBubbleProps extends ChatBubbleProps {
  width: number;
  height: number;
  fullSizeWidth: number;
  fullSizeHeight: number;
  setFetchingImage: React.Dispatch<React.SetStateAction<boolean>>;
}

const GIF = observer((props: ChatGIFBubbleProps) => {
  const css = useStyles(props);
  const {
    message,
    width,
    height,
    fullSizeWidth,
    fullSizeHeight,
    setFetchingImage,
  } = props;

  const decrypted = chatsStore.decryptMessage(message);

  const gifUrl =
    decrypted &&
    decrypted
      .split("|")[0]
      .replace(/({"t":"giphy","i":")|({"giphy":")|("})/gi, "");

  const gif = cacheStore.cachedGifs[message._id];

  /**************************************************/

  const handleOpenFullSize = useCallback(() => {
    modalManager.open(MODALS.CHAT_FULLSIZE_MEDIA);
    chatsStore.setSelectedMedia({
      type: "GIF",
      media: gif,
      width,
      height,
      fullSizeWidth,
      fullSizeHeight,
    });
  }, [gif, width, height, fullSizeWidth, fullSizeHeight]);

  /**************************************************/

  useEffect(() => {
    const fetchGif = async () => {
      if (gifUrl && !gif) {
        lruCache.getItem(message._id).then(async (cached: any) => {
          if (!cached) {
            try {
              const { data } = await giphyFetch.gif(gifUrl);
              cacheStore.setCachedGIF(message._id, data);
              lruCache.setItem(message._id, data);
              setFetchingImage(false);
            } catch (err) {
              console.log(err);
            }
          } else {
            cacheStore.setCachedGIF(message._id, cached);
            setFetchingImage(false);
          }
        });
      } else {
        setFetchingImage(false);
      }
    };
    fetchGif();
  }, []);

  return gif ? (
    <>
      <button
        onClick={handleOpenFullSize}
        tabIndex={-1}
        className={css.buttonWithoutStyles}
      >
        <Gif
          gif={gif}
          width={width}
          noLink
          hideAttribution
          backgroundColor="transparent"
        />
      </button>
    </>
  ) : null;
});

interface ChatImageBubbleProps extends ChatBubbleProps {
  width: number;
  height: number;
  fullSizeWidth: number;
  fullSizeHeight: number;
  setFetchingImage: React.Dispatch<React.SetStateAction<boolean>>;
}

const Image = observer((props: ChatImageBubbleProps) => {
  const css = useStyles(props);
  const [imageUrl, setImageUrl] = useState<string>();
  const {
    message,
    setFetchingImage,
    width,
    height,
    fullSizeWidth,
    fullSizeHeight,
  } = props;

  /**************************************************/

  const handleOpenFullSize = useCallback(() => {
    if (imageUrl) {
      modalManager.open(MODALS.CHAT_FULLSIZE_MEDIA);
      chatsStore.setSelectedMedia({
        type: "Image",
        media: imageUrl,
        width,
        height,
        fullSizeWidth,
        fullSizeHeight,
      });
    }
  }, [imageUrl, width, height, fullSizeWidth, fullSizeHeight]);

  const decryptImage = useCallback(
    (data: any) => {
      const encryptor = encriptionStore.getEncryptor(message.friendId);
      if (!encryptor) return;
      const arr = new Uint8Array(data);
      const decrypted = !message.my
        ? encryptor.decryptImage(arr)
        : encryptor.decryptImageForMe(arr);
      return new Blob([decrypted], { type: "image/jpeg" });
    },
    [message]
  );

  const showImage = useCallback(
    (blob: Blob) => {
      var imageUrl = URL.createObjectURL(blob);
      setImageUrl(imageUrl);
      setFetchingImage(false);
    },
    [setImageUrl, setFetchingImage]
  );

  /**************************************************/

  useEffect(() => {
    if (
      message.filesStatus === 2 &&
      message.attachments &&
      message.attachments.length &&
      userStore &&
      userStore.user
    ) {
      const cachedImage = cacheStore.cachedImages[message._id];
      if (cachedImage) {
        // Show right away if there is an image in store (memory cache)
        showImage(cachedImage);
      } else {
        const img = message.attachments[0];
        // Get image from indexDB cache
        lruCache.getItem(`${img.id}/${message.userId}`).then((cached: any) => {
          if (!cached) {
            fetch(`${configStore.fileServer}/raw/${img.id}/${message.userId}`, {
              headers: {
                secret: encriptionStore.token!,
              },
            })
              .then((res) => res.arrayBuffer())
              .then((data) => {
                const blob = decryptImage(data);
                if (blob) {
                  showImage(blob);
                  // Store in memory and show
                  cacheStore.setCachedImage(message._id, blob);
                  lruCache.setItem(`${img.id}/${message.userId}`, blob);
                }
              });
          } else if (cached) {
            // Store in memory and show
            cacheStore.setCachedImage(message._id, cached);
            showImage(cached);
          }
        });
      }
    }
  }, [message]);

  return message.filesStatus === 1 || !imageUrl ? (
    <Loader />
  ) : (
    <>
      <button
        onClick={handleOpenFullSize}
        tabIndex={-1}
        className={css.buttonWithoutStyles}
      >
        <img width="100%" alt="" src={imageUrl} />
      </button>
    </>
  );
});

export { Default, Typing, ImageBox };
