import React from "react";
import { motion } from "framer-motion";

interface PhotoCameraProps {
  className?: string;
}

const PhotoCamera = ({ className }: PhotoCameraProps) => {
  return (
    <motion.svg
      width="97"
      height="77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      initial={{
        x: -20,
        opacity: 0,
        scale: 0.8,
      }}
      animate={{
        x: 0,
        opacity: 1,
        scale: 1,
      }}
      transition={{
        duration: 0.3,
        type: "spring",
        damping: 10,
        stiffness: 100,
      }}
      className={className}
    >
      <path
        d="m3.504 26.841 71.5-26c2.6-1 5.5.4 6.5 3l12.2 33.4c1 2.6-.4 5.5-3 6.5l-71.5 26c-2.6 1-5.5-.4-6.5-3l-12.3-33.4c-.9-2.7.4-5.6 3.1-6.5Z"
        fill="#45385B"
      />
      <path
        d="m14.653 22.708-5.545 2.017 15.66 43.038 5.544-2.017-15.66-43.038Z"
        fill="#655285"
      />
      <path
        d="m3.309 26.862-.2.1c-2.5.9-3.7 3.5-2.8 5.9l12.5 34.3c.9 2.4 3.5 3.6 5.9 2.8l.3-.1-15.7-43ZM75.347.646l-8.1 2.9 15.6 43 8.1-3c2.4-.9 3.6-3.5 2.8-5.9l-12.4-34.2c-.9-2.5-3.6-3.7-6-2.8Z"
        fill="#322941"
      />
      <path
        d="m75.41.646-72.3 26.3c-2.4.9-3.6 3.5-2.8 5.9l2 5.4c-.9-2.4.4-5.1 2.8-5.9l72.3-26.3c2.4-.9 5.1.4 5.9 2.8l-2-5.4c-.9-2.5-3.5-3.7-5.9-2.8ZM15.716 60.386l71.5-26c2.6-1 5.5.4 6.5 3l.5 1.3c1 2.6-.4 5.5-3 6.5l-71.5 26c-2.6 1-5.5-.4-6.5-3l-.5-1.3c-1-2.7.3-5.6 3-6.5Z"
        fill="#CFAB00"
      />
      <path
        d="M48.186 47.191c8.45 0 15.3-6.85 15.3-15.3 0-8.45-6.85-15.3-15.3-15.3-8.45 0-15.3 6.85-15.3 15.3 0 8.45 6.85 15.3 15.3 15.3Z"
        fill="#655285"
      />
      <path
        d="M45.582 44.915c11.156 0 20.2-9.044 20.2-20.2 0-11.156-9.044-20.2-20.2-20.2-11.156 0-20.2 9.044-20.2 20.2 0 11.156 9.044 20.2 20.2 20.2Z"
        fill="#322941"
      />
      <path
        d="M45.626 40.06c8.45 0 15.3-6.85 15.3-15.3 0-8.45-6.85-15.3-15.3-15.3-8.45 0-15.3 6.85-15.3 15.3 0 8.45 6.85 15.3 15.3 15.3Z"
        fill="#655285"
      />
      <path
        d="M45.532 34.365c5.357 0 9.7-4.343 9.7-9.7 0-5.357-4.343-9.7-9.7-9.7-5.357 0-9.7 4.343-9.7 9.7 0 5.357 4.343 9.7 9.7 9.7Z"
        fill="#45385B"
      />
      <path
        d="M45.614 31.948a7.2 7.2 0 1 0 0-14.4 7.2 7.2 0 0 0 0 14.4Z"
        fill="#F6E79E"
      />
      <path
        d="M38.401 24.674c0 3.9 3.1 7.2 7.1 7.2 4 .1 7.2-3.1 7.3-7.1 0-.8-.1-1.5-.3-2.2-.4.5-.9 1-1.4 1.4-3.5 3.5-9 3.8-12.7.7Z"
        fill="#CFAB00"
      />
      <path
        d="m17.381 64.961 71.5-26c2.6-1 5.5.4 6.5 3l.5 1.3c1 2.6-.4 5.5-3 6.5l-71.5 26c-2.6 1-5.5-.4-6.5-3l-.5-1.3c-1-2.7.3-5.6 3-6.5Z"
        fill="#7D718B"
      />
      <path
        d="M25.168 68.514c.5 1.5-.8 3.4-3 4.2-2.2.8-4.5.2-5-1.3s.8-3.4 3-4.2c2.2-.8 4.4-.2 5 1.3ZM93.234 45.467c.2.6-.3 1.3-1.2 1.6-.9.3-1.6.1-1.9-.5-.3-.6.3-1.3 1.1-1.6.8-.3 1.8-.1 2 .5ZM91.785 41.487c.2.6-.3 1.3-1.2 1.6-.9.3-1.6.1-1.9-.5-.3-.6.3-1.3 1.1-1.6.8-.3 1.8-.1 2 .5ZM10.227 27.441c.2.7-.1 1.4-.8 1.6-.7.2-1.4 0-1.6-.7-.3-.7.1-1.4.7-1.6.7-.3 1.4.1 1.7.7ZM15.02 25.696c.2.7-.1 1.4-.8 1.6-.7.2-1.4 0-1.6-.7-.3-.7.1-1.4.7-1.6.7-.3 1.4.1 1.7.7Z"
        fill="#45385B"
      />
    </motion.svg>
  );
};

export default PhotoCamera;
