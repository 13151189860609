import { IGif } from "@giphy/js-types";
import { action, observable } from "mobx";

interface CachedTranslation {
  use: boolean;
  value: string;
}

class CacheStore {
  @observable cachedImages: { [key: string]: Blob } = {};
  @observable cachedGifs: { [key: string]: IGif } = {};
  @observable cachedTranslations: { [key: string]: CachedTranslation } = {};

  @action
  setCachedImage(id: string, img: Blob) {
    this.cachedImages[id] = img;
  }

  @action
  setCachedGIF(id: string, gif: IGif) {
    this.cachedGifs[id] = gif;
  }

  @action
  setTranslation(id: string, value: CachedTranslation) {
    this.cachedTranslations[id] = value;
  }
}

export const cacheStore = new CacheStore();
