import { observable, action, computed, toJS } from "mobx";
import { Friend } from "lib";
import { Meteor } from "meteor/meteor";
import { storage } from "utils";
import translator from "global-stores/translator-store";

export enum ChatStoreState {
  NotReady = "NotReady",
  Initialized = "Initialized",
}

export interface TranslationSettings {
  useTranslate: boolean;
  autoTranslation: boolean;
  myLang: string;
  friendLang: string;
  hideTranslation: boolean;
}

const TRANSLATOR_PREFIX = "translator_";

const DEFAULT_TRANSLATION_SETTINGS = {
  useTranslate: false,
  autoTranslation: false,
  myLang: "",
  friendLang: "auto",
  hideTranslation: false,
};

export class ChatStore {
  constructor(friendId: string) {
    this.friendId = friendId;
    this.getStoredTranslationSettings();
  }

  @observable translationSettings?: TranslationSettings;

  @observable state: ChatStoreState = ChatStoreState.NotReady;
  @observable loading: boolean = false;
  @observable subscriptionsReady: boolean = false;

  @observable shouldScrollToBottom: boolean = false;

  @observable reading: boolean = false;

  @observable scrollHeight: number = 0;
  @observable scrollTop: number = 0;

  @observable friendId: string;

  @observable showScrollButton: boolean = false;
  @observable hasNewMessages: boolean = false;
  @observable storedMessagesLength?: number;

  @action
  setState(value: ChatStoreState) {
    this.state = value;
  }

  @action
  setSubscriptionsReady(value: boolean) {
    this.subscriptionsReady = value;
  }

  @action
  setLoading(value: boolean) {
    this.loading = value;
  }

  @action
  setShowScrollButton(friend: Friend, value: boolean) {
    if (!value && friend.newMessageCounter && !this.reading) {
      this.read(friend);
    }
    if (value !== this.showScrollButton) {
      this.showScrollButton = value;
    }
  }

  @action
  setShouldScrollToBottom(value: boolean) {
    this.shouldScrollToBottom = value;
  }

  @action
  setHasNewMessages(value: boolean) {
    this.hasNewMessages = value;
  }

  @action
  setStoredMessagesLength(value: number) {
    this.storedMessagesLength = value;
  }

  @action
  setScrollHeight(value: number) {
    this.scrollHeight = value;
  }

  @action
  setScrollTop(value: number) {
    this.scrollTop = value;
  }

  @computed
  get isReady() {
    return this.state === ChatStoreState.Initialized;
  }

  @action
  setTranslationSettings(settings: Partial<TranslationSettings>) {
    const updatedSettings = {
      ...this.translationSettings,
      ...settings,
    } as TranslationSettings;
    this.translationSettings = updatedSettings;
    storage.write(
      `${TRANSLATOR_PREFIX}${this.friendId}`,
      toJS(updatedSettings)
    );
  }

  @action
  async getStoredTranslationSettings() {
    const storedSettings = storage.read(
      `${TRANSLATOR_PREFIX}${this.friendId}`,
      true,
      true
    );
    this.translationSettings = storedSettings ?? {
      ...DEFAULT_TRANSLATION_SETTINGS,
      myLang: translator.myInitialLang,
    };
  }

  @action
  read(friend: Friend) {
    if (this.reading) return;
    try {
      this.reading = true;
      Meteor.call(
        "messages.read",
        {
          friendId: friend._id,
          friendIamId: friend.friendIamId,
        },
        (err: Meteor.Error) => {
          if (!err) {
            this.reading = false;
          }
        }
      );
      Meteor.call(
        "notifications.read",
        {
          friendId: friend._id,
        },
        (err: Meteor.Error) => {
          if (err) {
            console.log(err);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  }
}

export const getChatStore = (friendId: string) => new ChatStore(friendId);
