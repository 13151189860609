import React from "react";
import { makeStyles } from "hooks/makeStyles";
import { ToggleButtonGroup, ToggleButton } from "ui/shared";
import { useTranslation } from "react-i18next";
import { Gender } from "lib";
import { profileStore } from "../store.profile";

interface GenderModalProps {
  close: (force?: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 350,
    backgroundColor: theme.colors.background.paper.topbar,
    padding: theme.spacing(3.5),
    boxShadow: theme.shadows.dropdown,
    borderRadius: 10,
  },
}));

export const GenderModal = (props: GenderModalProps) => {
  const css = useStyles(props);
  const { t } = useTranslation("search");

  return (
    <div className={css.root} data-cy="gender.modal">
      <ToggleButtonGroup
        fullWidth
        exclusive
        value={profileStore.gender}
        onChange={(e, value) => {
          value != null && profileStore.setGender(value as Gender);
          props.close(true);
        }}
      >
        <ToggleButton value="" data-cy="gender.modal.button.anything">
          {t("all:reg_2_not_disclose")}
        </ToggleButton>
        <ToggleButton value="m" data-cy="gender.modal.button.m">
          {t("all:dialog_set_im_a_man")}
        </ToggleButton>
        <ToggleButton value="f" data-cy="gender.modal.button.f">
          {t("all:dialog_set_im_a_woman")}
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};
