import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Instagram = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Instagram}>
    {({ color }) => (
      <>
        <g transform="translate(0.364)">
          <g
            transform="translate(-0.364)"
            fill="none"
            stroke={color}
            strokeWidth="1"
          >
            <circle cx="16" cy="16" r="16" stroke="none" />
            <circle cx="16" cy="16" r="15.5" fill="#322941" />
          </g>
          <g transform="translate(-596.701 -364.429)">
            <path
              d="M616.481,372.063a4.238,4.238,0,0,1,4.233,4.233v8.276a4.238,4.238,0,0,1-4.233,4.233h-8.276a4.238,4.238,0,0,1-4.233-4.233V376.3a4.238,4.238,0,0,1,4.233-4.233h8.276m0-1.634h-8.276a5.867,5.867,0,0,0-5.867,5.867v8.276a5.867,5.867,0,0,0,5.867,5.867h8.276a5.867,5.867,0,0,0,5.867-5.867V376.3a5.867,5.867,0,0,0-5.867-5.867Z"
              fill={color}
            />
            <path
              d="M628.206,392.5a3.54,3.54,0,1,1-3.54,3.54,3.544,3.544,0,0,1,3.54-3.54m0-1.634a5.174,5.174,0,1,0,5.175,5.174,5.174,5.174,0,0,0-5.175-5.174Z"
              transform="translate(-15.863 -15.666)"
              fill={color}
            />
            <circle
              cx="1.203"
              cy="1.203"
              r="1.203"
              transform="translate(616.512 373.868)"
              fill={color}
            />
          </g>
        </g>
      </>
    )}
  </CreateSvgIcon>
);
