import { interestsObj, langsObj, langs } from "lib";
import { i18n } from "services/i18n";

export function getInterestsLabels(value: string[]) {
  return value.reduce((acc: string, item: string) => {
    return acc
      ? acc + " " + getInterestLabel((interestsObj as any)[+item])
      : getInterestLabel((interestsObj as any)[+item]);
  }, "");
}

function getInterestLabel(interest: any) {
  const lang = i18n.language.split("-")[0];
  if (interest && lang && interest[lang]) {
    return interest[lang] + "  " + interest.emoji;
  }
  return "";
}

export function getLanguageLabels(value: string[]) {
  if (!value.length) {
    return langs.map((item) => item.label).join(", ");
  }
  return value.map((code) => (langsObj as any)[code]).join(" ");
}
