const MAX_WIDTH = 1000;
const QUALITY = 0.9;

const readFile = async (file: File) => {
  const canvas = document.createElement("canvas");
  const img = document.createElement("img");

  // create img element from File object
  img.src = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => e.target && resolve(e.target.result as string);
    reader.readAsDataURL(file);
  });
  await new Promise((resolve) => {
    img.onload = resolve;
  });

  // draw image in canvas element
  canvas.width = img.width;
  canvas.height = img.height;
  const context = canvas.getContext("2d");
  if (context) {
    context.drawImage(img, 0, 0, canvas.width, canvas.height);
  }

  return canvas;
};

const scaleCanvas = (canvas: HTMLCanvasElement, scale: number) => {
  const scaledCanvas = document.createElement("canvas");
  scaledCanvas.width = canvas.width * scale;
  scaledCanvas.height = canvas.height * scale;
  const context = scaledCanvas.getContext("2d");

  if (context) {
    context.drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height);
  }

  return scaledCanvas;
};

const optimizeImage = async (
  file: File,
  maxWidth: number = MAX_WIDTH
): Promise<{ file: ArrayBuffer; size: { width: number; height: number } }> => {
  let canvas = await readFile(file);

  while (canvas.width >= 2 * maxWidth) {
    canvas = scaleCanvas(canvas, 0.5);
  }

  if (canvas.width > maxWidth) {
    canvas = scaleCanvas(canvas, maxWidth / canvas.width);
  }

  return new Promise((resolve, reject) => {
    const callback = (blob: any | null) => {
      if (blob) {
        blob.arrayBuffer().then((buffer: ArrayBuffer) =>
          resolve({
            file: buffer,
            size: {
              width: canvas.width,
              height: canvas.height,
            },
          })
        );
      } else {
        reject("Blob failed");
      }
    };
    canvas.toBlob(callback, "image/jpeg", QUALITY);
  });
};

export default optimizeImage;
