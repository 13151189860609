import { toastStore } from "global-stores/toasts-store";
import { i18n } from "services/i18n";

export const translationLangsValidation = (
  toLang?: string,
  fromLang?: string
): boolean => {
  if (!toLang) {
    toastStore.showToast({
      message: i18n.t("all:trans_alert_my_lang_text"),
    });
    return false;
  }
  if (toLang === fromLang) {
    toastStore.showToast({
      message: i18n.t("all:trans_alert_same_lang"),
    });
    return false;
  }

  return true;
};
