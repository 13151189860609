import React, { ButtonHTMLAttributes } from "react";
import { makeStyles } from "hooks/makeStyles";
import clsx from "clsx";
import { Button } from "../button/button";
import { fade } from "utils";

export interface IconButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    background: "transparent",
    padding: 0,
    "&$disabled svg": {
      fill: fade(theme.colors.common.white, 0.5)
    }
  },
  disabled: {}
}));

export const IconButton = React.forwardRef(
  (props: IconButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const css = useStyles();
    const { children, className, ...passthrough } = props;

    const cx = clsx(css.root, className, {
      [css.disabled]: passthrough.disabled
    });

    return (
      <Button ref={ref} {...passthrough} className={cx}>
        {children}
      </Button>
    );
  }
);
