import React from "react";
import { makeStyles } from "hooks/makeStyles";
import { fade } from "utils";
import clsx from "clsx";
import { observer } from "mobx-react";
import { Typography } from "../typography/typography";

interface DefaultPanelProps {
  header?: React.ReactNode;
  title?: string;
  children: React.ReactNode;
  withoutScroll?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    width: "100%",
    height: "inherit",
    minWidth: "200px",
    marginLeft: 0,
    backgroundColor: theme.colors.background.paper.topbar,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    maxHeight: "100%",
    "&:focus": {
      outline: 0,
    },
  },
  scrollContainer: {
    overflowY: "auto",
    height: "100%",
    boxSizing: "border-box",
  },
  header: {
    textAlign: "center",
    padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
    backgroundColor: theme.colors.background.paper.lighter,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  withoutScroll: {
    overflow: "visible",
  },
}));

export const DefaultPanel = observer((props: DefaultPanelProps) => {
  const { title, header, children, withoutScroll, className } = props;
  const css = useStyles();

  return (
    <div
      className={clsx(css.panelContainer, className, {
        [css.withoutScroll]: withoutScroll,
      })}
    >
      {header ? header : null}
      {title ? (
        <div className={css.header}>
          <Typography ellipsis size="subtitle1(18px)" align="center" uppercase>
            {title}
          </Typography>
        </div>
      ) : null}
      <div
        className={clsx(css.scrollContainer, {
          [css.withoutScroll]: withoutScroll,
        })}
      >
        {children}
      </div>
    </div>
  );
});
