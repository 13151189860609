import React, { useCallback, useState } from "react";
import { makeStyles } from "hooks/makeStyles";
import {
  Box,
  Typography,
  LayoutMessageBubble,
  Button,
  Avatar,
  ToggleButtonGroup,
  ToggleButton,
  Slider,
} from "ui/shared";
import { useTranslation } from "react-i18next";
import { AdditionalInfoStore } from "./store.additional-info";
import clsx from "clsx";
import { Gender, Age } from "lib";
import { userStore } from "global-stores/user-store";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { useEventCallback } from "hooks/useEventCallback";
import { getUserAvatar } from "utils/profile";
import { motion } from "framer-motion";
import AddAvatarDropdown from "ui/components/add-avatar-dropdown/add-avatar-dropdown";

interface AdditionalInfoProps {}

const useStyles = makeStyles((theme) => ({
  modal: {
    width: 591,
    height: "auto",
    "&:focus": {
      outline: 0,
    },
  },
  avatarContainer: {
    width: "fit-content",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  avatarDropdownContainer: {
    width: 150,
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1),
  },
  avatarDropdown: {
    minWidth: 250,
    backgroundColor: theme.colors.background.paper.topbar,
    padding: theme.spacing(3.5),
    boxShadow: theme.shadows.dropdown,
    borderRadius: 10,
    "& > *": {
      height: 46,
      backgroundColor: theme.colors.background.paper.lighter,
      "&:hover, &:active": {
        backgroundColor: theme.colors.purple.main,
        color: theme.colors.background.paper.main,
      },
    },
    "& > *:first-child": {
      marginBottom: theme.spacing(2.5),
    },
  },
  addAvatarBtn: {
    width: 32,
    height: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.purple.main,
  },
  stepContainer: {
    padding: theme.spacing(6.25),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 420,
    "@media screen and (max-height: 850px)": {
      width: "60vw",
      minWidth: 550,
      maxWidth: 1000,
    },
  },
  centerContainer: {
    width: "100%",
    "@media screen and (max-height: 850px)": {
      display: "flex",
      alignItems: "center",
      paddingRight: 40,
    },
  },
  leftContainer: {
    width: "100%",
    "@media screen and (max-height: 850px)": {
      flex: 2,
      width: "auto",
    },
  },
  rightContainer: {
    width: "100%",
    "@media screen and (max-height: 850px)": {
      flex: 3,
      width: "auto",
    },
  },
  title: {
    whiteSpace: "pre-wrap",
  },
  bubbleContainer: {
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  avatarBubble: {
    position: "absolute",
    left: "-100%",
    top: -25,
    color: theme.colors.common.black,
  },
  infoBubble: {
    position: "absolute",
    right: -132,
    bottom: 31,
    color: theme.colors.common.black,
  },
  chooseAge: {
    "&$opened": {
      height: "70px",
      opacity: 1,
      marginBottom: 24,
      "& > div": {
        visibility: "visible",
      },
    },
    height: 0,
    overflow: "hidden",
    opacity: 0,
    marginBottom: 0,
    transition: "height .4s, opacity .6s, margin .4s cubic-bezier(0, 0, 0, 1)",
    "& > div": {
      visibility: "hidden",
    },
  },
  submitContainer: {
    width: "50%",
  },
  opened: {},
  hidden: {
    display: "none",
  },
}));

export const AdditionalInfo = observer((props: AdditionalInfoProps) => {
  const { t } = useTranslation("all");
  const css = useStyles();
  const history = useHistory();

  const [additionalInfoStore] = useState(new AdditionalInfoStore());

  const handleSliderValueChange = useEventCallback(
    (e: React.MouseEvent, value: number | number[], activeIndex: number) => {
      additionalInfoStore.setAgeSliderValue(value as number[], activeIndex);
    }
  );

  const profile = userStore.user && userStore.user.profile;
  const avatar = getUserAvatar(profile);

  const variants = {
    hidden: {
      y: 50,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: { delay: 0.4 },
    },
  };

  /**************************************************/

  const handleGoToSearch = useCallback(() => {
    additionalInfoStore.handleSetAdditionalInfo();
    history.push("/search");
  }, [history, additionalInfoStore]);

  /**************************************************/

  return (
    <motion.div
      initial={"hidden"}
      animate={!userStore.loggingIn ? "visible" : "hidden"}
      variants={variants}
      className={css.stepContainer}
    >
      <Box mb={6}>
        <Typography size="h1(33px)" align="center" className={css.title}>
          {t("all:reg_2_text")}
        </Typography>
      </Box>
      <div className={css.centerContainer}>
        <div className={css.leftContainer}>
          <div className={css.avatarContainer}>
            <LayoutMessageBubble
              className={clsx(css.avatarBubble, {
                [css.hidden]: additionalInfoStore.isAwareOfChangingAvatar,
              })}
              data-cy="additionalInfo.messages.avatar"
            >
              {t("all:reg_3_text")}
            </LayoutMessageBubble>
            <Avatar.Big border img={avatar} />
            <AddAvatarDropdown
              isAdditionalInfo
              onTriggerClick={() =>
                additionalInfoStore.awareOfChangingAvatar(true)
              }
            />
          </div>
          <Box mb={6} mt={3}>
            <Typography
              size="subtitle1(18px)"
              align="center"
              data-cy="additionalInfo.labels.username"
            >
              {userStore.user ? userStore.user.username : null}
            </Typography>
          </Box>
        </div>
        <div className={css.rightContainer}>
          <Box mb={3}>
            <Typography size="body(14px)" align="center">
              {t("all:reg_2_gender")}
            </Typography>
          </Box>
          <div className={css.bubbleContainer}>
            <LayoutMessageBubble
              left
              className={clsx(css.infoBubble, {
                [css.hidden]:
                  additionalInfoStore.isAwareOfFillingAdditionalInfo,
              })}
            >
              {t("all:reg_2_text_2")}
            </LayoutMessageBubble>
            <ToggleButtonGroup
              fullWidth
              exclusive
              value={additionalInfoStore.gender}
              onChange={(e, value) =>
                additionalInfoStore.setGender(value as Gender)
              }
            >
              <ToggleButton
                value=""
                data-cy="additionalInfo.actions.gender.any"
              >
                {t("all:reg_2_not_disclose")}
              </ToggleButton>
              <ToggleButton value="m" data-cy="additionalInfo.actions.gender.m">
                {t("all:reg_2_gender_m")}
              </ToggleButton>
              <ToggleButton value="f" data-cy="additionalInfo.actions.gender.f">
                {t("all:reg_2_gender_f")}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <Box mt={6} mb={3}>
            <Typography size="body(14px)" align="center">
              {t("all:reg_2_age")}
            </Typography>
          </Box>
          <ToggleButtonGroup
            fullWidth
            exclusive
            value={additionalInfoStore.age}
            onChange={(e, value) => additionalInfoStore.setAge(value as Age)}
          >
            <ToggleButton value="" data-cy="additionalInfo.actions.age.any">
              {t("all:reg_2_not_disclose")}
            </ToggleButton>
            <ToggleButton
              value="choose"
              data-cy="additionalInfo.actions.age.choose"
            >
              {t("all:reg_2_age_choose")}
            </ToggleButton>
          </ToggleButtonGroup>
          <Box
            pr={6}
            pl={6}
            pt={6}
            mt={5}
            className={clsx(css.chooseAge, {
              [css.opened]: additionalInfoStore.age === "choose",
            })}
          >
            <Slider
              showBottomLabel
              value={additionalInfoStore.ageSliderValue}
              min={18}
              max={99}
              onChange={handleSliderValueChange}
              data-cy="additionalInfo.actions.age.slider"
            />
          </Box>
        </div>
      </div>
      <Box className={css.submitContainer}>
        <Button
          fullWidth
          primary
          onClick={handleGoToSearch}
          data-cy="additionalInfo.actions.toSearch"
        >
          {t("all:alert_review_button")}
        </Button>
      </Box>
    </motion.div>
  );
});
