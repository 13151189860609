import React from "react";

export interface DotButtonProps {
  width: number;
  selected: any;
}

export const DotButton = (props: DotButtonProps) => {
  const { selected, width } = props;

  return (
    <button
      className={`embla-mini__dot ${selected ? "is-selected" : ""}`}
      type="button"
      style={{ width: `calc(100% / ${width})` }}
    />
  );
};
