import React from "react";
import { useLatest } from "./useLatest";

export const useWindowResizeListener = (listener: (event: UIEvent) => any) => {
  const latestListener = useLatest(listener);

  React.useEffect(() => {
    const handler: typeof listener = event => {
      latestListener.current(event);
    };

    window.addEventListener("resize", handler);

    return () => {
      window.removeEventListener("resize", handler);
    };
  }, [latestListener, listener]);
};
