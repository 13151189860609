import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "hooks/makeStyles";
import { Avatar, IconButton, Icons, Button, Modal } from "ui/shared";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { profileStore } from "./store.profile";
import { InterestsStore } from "./modals/stores/interests-store";
import { userStore } from "global-stores/user-store";

import UsernameEditable from "./username-edit";
import EmailEditable from "./email-edit";
import GenderEditable from "./gender-edit";
import AgeEditable from "./age-edit";
import AboutEditable from "./about-edit";
import InterestsEditable from "./interests-edit";
import LanguageEditable from "./language-edit";
import { modalManager } from "global-stores/modal-manager";
import { MODALS } from "lib/constants";
import EmblaCarousel from "ui/components/embla-carousel/embla-carousel";
import { getUserAvatar } from "utils/profile";
import AddAvatarDropdown from "ui/components/add-avatar-dropdown/add-avatar-dropdown";

export interface ProfileProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  flex: {
    display: "flex",
  },
  sidebar: {
    flex: "0 1 35%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(12),
    minHeight: 610,
    paddingBottom: 40,
  },
  avatarContainer: {
    position: "relative",
  },
  verified: {
    position: "absolute",
    bottom: 24,
    left: 0,
  },
  exportButton: {
    width: 168,
    background: theme.colors.background.dark,
    marginTop: theme.spacing(8),
  },
  profileInfo: {
    flex: "0 1 65%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(12),
    minHeight: 610,
  },
  tooltipContainer: {
    minWidth: 200,
    fontSize: 12,
    "& > h1": {
      fontSize: 18,
      textAlign: "center",
    },
    "& > p": {
      "& > a": {
        color: theme.colors.primary,
      },
    },
  },
}));

export const Profile = observer((props: ProfileProps) => {
  const { t } = useTranslation("all");
  const css = useStyles();
  const [interestsStore] = useState(
    new InterestsStore(userStore.user?.profile.interests || [])
  );

  const profile = userStore.user && userStore.user.profile;
  const isOfficial = profile && profile.official;
  const isVerified = profile && profile.level === "ver";
  const isVIP = profile && profile.level === "vip";

  /**************************************************/

  const handleOpenMyAvatarsModal = useCallback(() => {
    modalManager.open(MODALS.PROFILE_MY_AVATARS);
  }, []);

  const handleExportAccount = useCallback(async () => {
    modalManager.open(MODALS.PROFILE_EXPORT_ACCOUNT);
  }, []);

  /**************************************************/

  useEffect(() => {
    profileStore.getVerificationEmail();
  }, []);

  useEffect(() => {
    if (userStore.user && userStore.user.profile) {
      if (userStore.user.profile.age) {
        profileStore.setAgeSliderValue(userStore.user.profile.age, 1);
      }
      if (userStore.user.profile.gender != null) {
        profileStore.setGender(userStore.user.profile.gender);
      }
    }
  }, []);

  useEffect(() => {
    profileStore.getVerificationEmail();
  }, [profile.email]);

  const avatar = getUserAvatar(profile);

  return (
    <>
      <aside className={css.sidebar}>
        <div>
          <div className={css.avatarContainer}>
            <IconButton
              data-cy="profile.actions.avatars"
              onClick={handleOpenMyAvatarsModal}
            >
              <Avatar.Big asButton img={avatar} />
            </IconButton>
            <AddAvatarDropdown />
            <div className={css.verified}>
              {isOfficial ? (
                <Icons.Official width={45} height={45} />
              ) : isVIP ? (
                <Icons.VIP width={54} height={54} />
              ) : isVerified ? (
                <Icons.Verified width={54} height={54} />
              ) : null}
            </div>
          </div>
          <div>
            <UsernameEditable />
            <EmailEditable />
            <GenderEditable />
            <AgeEditable />
          </div>
        </div>
        <Button
          secondary
          onClick={handleExportAccount}
          className={css.exportButton}
        >
          {t("all:profile_button_export")}
        </Button>
      </aside>
      <section className={css.profileInfo}>
        <div>
          <AboutEditable profile={profile} />
          <InterestsEditable
            profile={profile}
            interestsStore={interestsStore}
          />
          <LanguageEditable profile={profile} />
        </div>
        <Modal modalId={MODALS.PROFILE_MY_AVATARS}>
          {(visible, className) =>
            visible ? (
              <div className={className} style={{ minWidth: 590 }}>
                <EmblaCarousel />
              </div>
            ) : null
          }
        </Modal>
      </section>
    </>
  );
});
