import React from "react";
import { Blacklist } from "lib";
import { PeopleArchivePanelItem } from "./people-archive-panel-item";

export interface PeopleArchivePanelProps {
  items: Blacklist[];
  selected: string[];
  onSelectBlackList: (e: React.MouseEvent, item: string) => void;
}

export const PeopleArchivePanel = (props: PeopleArchivePanelProps) => {
  const { items, selected, onSelectBlackList } = props;

  return (
    <>
      {items.map((item) => (
        <PeopleArchivePanelItem
          key={item._id}
          item={item._id}
          login={item.blockedLogin}
          date={item.createdAt}
          checked={selected.includes(item._id)}
          onClick={(e, value) => {
            onSelectBlackList(e, value);
          }}
        />
      ))}
    </>
  );
};
