import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, DefaultPanel, Modal, Typography } from "ui/shared";
import {
  EditableContainer,
  wrappedLabel,
} from "../../shared/editable/editable";
import { userStore } from "global-stores/user-store";
import { Box } from "../../shared/box/box";
import { getInterestsLabels } from "utils";
import { makeStyles } from "hooks/makeStyles";
import { Interests, InterestsHeader } from "./modals/interests";
import { InterestsStore } from "./modals/stores/interests-store";
import { MODALS } from "lib/constants";
import { modalManager } from "global-stores/modal-manager";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  interestsPanel: {
    minWidth: 300,
    "&:focus": {
      outline: 0,
    },
  },
}));

interface InterestsEditableProps {
  interestsStore: InterestsStore;
  profile?: any;
}

const InterestsEditable = observer((props: InterestsEditableProps) => {
  const { t } = useTranslation("all");
  const css = useStyles();

  const { profile, interestsStore } = props;

  /**************************************************/

  const openInterestsModal = useCallback(() => {
    interestsStore.setInterests((profile && profile.interests) || []);
    modalManager.open(MODALS.PROFILE_INTERESTS);
  }, [profile, profile.interests]);

  const handleCloseInterestsModal = useCallback(() => {
    userStore.setProfileValue("interests", interestsStore.interests);
  }, []);

  const handleSelectInterest = useCallback(
    (e: React.MouseEvent, item: string) => {
      if (interestsStore.localInterests.length) {
        interestsStore.setLocalInterests([]);
      }

      if (interestsStore.interests.includes(item)) {
        interestsStore.setInterests(
          interestsStore.interests.filter(
            (interest: string) => interest !== item
          )
        );
      } else {
        interestsStore.setInterests([...interestsStore.interests, item]);
      }
    },
    []
  );

  const handleRevertInterest = useCallback(() => {
    interestsStore.setInterests(interestsStore.localInterests);
    interestsStore.setLocalInterests([]);
  }, []);

  /**************************************************/

  return (
    <Box mb={6}>
      {wrappedLabel(
        <Typography size="caption(12px)" color="secondary" lowercase>
          {t("all:profile_interests")}:
        </Typography>
      )}
      <EditableContainer>
        <Button
          onClick={openInterestsModal}
          data-cy="profile.actions.interests"
        >
          <Typography data-cy="profile.values.interests">
            {profile && profile.interests && profile.interests.length
              ? getInterestsLabels(profile.interests)
              : t("all:profile_placeholder_no")}
          </Typography>
        </Button>
      </EditableContainer>
      <Modal
        modalId={MODALS.PROFILE_INTERESTS}
        onClose={handleCloseInterestsModal}
      >
        {(visible, className) => (
          <DefaultPanel
            header={
              <InterestsHeader
                selected={interestsStore.interests}
                localInterests={interestsStore.localInterests}
                onClear={() => {
                  interestsStore.setLocalInterests(interestsStore.interests);
                  interestsStore.setInterests([]);
                }}
                onRevert={() => handleRevertInterest()}
                limit={userStore.interestsLimit}
              />
            }
            className={clsx(className, css.interestsPanel)}
          >
            <Interests
              selected={interestsStore.interests}
              onSelectInterest={handleSelectInterest}
            />
          </DefaultPanel>
        )}
      </Modal>
    </Box>
  );
});

export default InterestsEditable;
