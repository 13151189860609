import React, { useCallback } from "react";
import { Meteor } from "meteor/meteor";
import { makeStyles } from "hooks/makeStyles";
import { Typography, Button, Loader, Box } from "ui/shared";
import { useTranslation } from "react-i18next";
import { capitalize, storage } from "utils";
import { globalUIStore } from "global-stores/global-ui-store";
import { userStore } from "global-stores/user-store";
import { SettingsStore } from "../store/settings-store";
import { encriptionStore } from "global-stores/encryption-store";
import { modalManager } from "global-stores/modal-manager";
import { qrCodeStore } from "global-stores/qrcode-store";
import { MODALS } from "lib/constants";
import vipStore from "global-stores/vip-store";

export interface DeleteAccountHeaderProps {}

export interface DeleteAccountProps {
  settingsStore: SettingsStore;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
  },
  button: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },
  deleteAccountHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
    backgroundColor: theme.colors.background.paper.lighter,
  },
}));

export const DeleteAccountHeader = (props: DeleteAccountHeaderProps) => {
  const { t } = useTranslation("all");
  const css = useStyles();

  return (
    <div className={css.deleteAccountHeader}>
      <Typography size="subtitle1(18px)" align="center" uppercase>
        {capitalize(t("all:settings_del_account"))}
      </Typography>
    </div>
  );
};

export const DeleteAccount = (props: DeleteAccountProps) => {
  const { settingsStore, className } = props;
  const { t } = useTranslation("settings");
  const css = useStyles();

  const level =
    userStore.user && userStore.user.profile && userStore.user.profile.level;

  /**************************************************/

  const handleExportAccount = useCallback(async () => {
    const accountStr = encriptionStore.generateAccountString();
    if (accountStr) {
      modalManager.open(MODALS.PROFILE_EXPORT_ACCOUNT);
      await qrCodeStore.generateQRCode(accountStr);
    }
  }, []);

  const handleDeleteAccount = useCallback(() => {
    settingsStore.setDeleting(true);
    globalUIStore.setLoading(true);
    settingsStore.deleteAccount();
    vipStore.clear();
    userStore.user = null;
    Meteor.logout((err) => {
      if (!err) {
        storage.clearAll();
        encriptionStore.clear();
        settingsStore.setDeleting(false);
      }
    });
  }, []);

  /**************************************************/

  return (
    <div className={className}>
      {!settingsStore.deleting ? (
        <>
          <Typography
            size="subtitle2(16px)"
            align="center"
            className={css.title}
          >
            {t("all:system_dialog_delete_title")}
          </Typography>
          <Box mb={4}>
            <Typography>
              {level === "anon"
                ? t("all:system_dialog_anon_delete_text")
                : t("all:system_dialog_delete_text")}
            </Typography>
          </Box>
          <Button
            onClick={handleExportAccount}
            className={css.button}
            color="#45385B"
            data-cy="settings.deleteAccount.modal.confirmButton"
          >
            {t("all:profile_button_export")}
          </Button>
          <Button
            onClick={handleDeleteAccount}
            className={css.button}
            color="#B22222"
            data-cy="settings.deleteAccount.modal.confirmButton"
          >
            {t("all:settings_button_delete")}
          </Button>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};
