import React, { useCallback, useEffect } from "react";
import { makeStyles } from "hooks/makeStyles";
import {
  Icons,
  Typography,
  Box,
  DefaultPanel,
  AnimatedPage,
  Anchor,
  Button,
} from "ui/shared";
import { KarmaHistoryItem } from "./karma-history-item";
import { fade } from "utils";
import { useTranslation } from "react-i18next";
import { inviteStore } from "global-stores/invite-store";
import { userStore } from "global-stores/user-store";
import { observer } from "mobx-react";
import { Like } from "lib";
import { useTracker } from "hooks/useTracker";
import { Meteor } from "meteor/meteor";
import { Likes } from "collections/likes";
import { modalManager } from "global-stores/modal-manager";
import { MODALS } from "lib/constants";
import { Theme } from "lib/theme";
import { useTheme } from "react-jss";
import { spacedContainer } from "styles";
import clsx from "clsx";

const activeKarma = require("assets/svg/big-active-karma.svg");
const notActiveKarma = require("assets/svg/big-not-active-karma.svg");

export interface KarmaHistoryProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    background: theme.colors.background.paper.main,
  },
  side: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 0,
    flexShrink: 0,
    width: 350,
    padding: `${theme.spacing(5.5)}`,
  },
  karmaContainer: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
  },
  noKarmaContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  karmaBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    padding: theme.spacing(3),
    boxSizing: "border-box",
    height: 140,
    backgroundColor: theme.colors.background.paper.topbar,
    borderRadius: 10,
  },
  history: {
    flex: "0 1 100%",
    height: "auto",
    margin: `${theme.spacing(5.5)}`,
    marginLeft: 0,
    backgroundColor: fade(theme.colors.background.paper.topbar, 0.4),
    borderRadius: 10,
    overflow: "hidden",
  },
  inviteButton: {
    marginBottom: theme.spacing(2.5),
    border: 0,
    padding: theme.spacing(3.75),
  },
  enterButton: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2.5),
    border: 0,
    padding: theme.spacing(3.75),
  },
  helpLink: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  questionIcon: {
    marginLeft: theme.spacing(2),
  },
  ...spacedContainer(theme, 4),
}));

export const KarmaHistory = observer((props: KarmaHistoryProps) => {
  const { t } = useTranslation("all");
  const css = useStyles();
  const theme = useTheme() as Theme;

  const profile = userStore.user && userStore.user.profile;
  const isVIP = profile && profile.level === "vip";
  const inviteBonus = inviteStore.inviteBonus.toString();
  const inviteBonusFromRef = inviteStore.inviteBonusFromRef.toString();

  /**************************************************/

  const handleInviteModalOpen = useCallback(() => {
    modalManager.open(MODALS.PROFILE_INVITE);
  }, []);

  const handleInviteFriend = useCallback(() => {
    modalManager.open(MODALS.PROFILE_INVITE_FRIENDS);
  }, []);

  /**************************************************/

  useEffect(() => {
    inviteStore.getInviteParams();
  }, []);

  const { loading, likes }: { loading: boolean; likes: Like[] } = useTracker(
    () => {
      const handle1 = Meteor.subscribe("likes.my", {
        active: true,
        limit: 999,
      });
      const handle2 = Meteor.subscribe("likes.my", {
        active: false,
        limit: 999,
      });

      return {
        loading: !handle1.ready() || !handle2.ready(),
        likes: Likes.find().fetch(),
      };
    }
  );

  if (loading) return null;

  return (
    <AnimatedPage animation="vertical" containerClassName={css.root}>
      <div className={css.side}>
        <div>
          <div className={clsx(css.karmaContainer, css.spacedContainer)}>
            <div className={css.karmaBlock}>
              <img
                height={55}
                src={activeKarma}
                width="80%"
                alt="Active karma"
              />
              <Box mt={2} mb={2}>
                <Typography align="center" font="light">
                  {t("all:karma_active")}
                </Typography>
              </Box>
              <Typography size="h3(20px)" data-cy="karma.history.activeKarma">
                {userStore.user?.profile &&
                userStore.user?.profile.karma &&
                userStore.user?.profile.karma.activeKarma
                  ? userStore.user?.profile.karma.activeKarma
                  : 0}
              </Typography>
            </div>
            {!isVIP ? (
              <div className={css.karmaBlock}>
                <img
                  height={55}
                  src={notActiveKarma}
                  width="80%"
                  alt="Not active karma"
                />
                <Box mt={2} mb={2}>
                  <Typography align="center" font="light">
                    {t("all:karma_unactive")}
                  </Typography>
                </Box>
                <Typography
                  size="h3(20px)"
                  data-cy="karma.history.unActiveKarma"
                >
                  {userStore.user?.profile &&
                  userStore.user?.profile.karma &&
                  userStore.user?.profile.karma.unActiveKarma
                    ? userStore.user?.profile.karma.unActiveKarma
                    : 0}
                </Typography>
              </div>
            ) : null}
          </div>
          <Box mt={3}>
            <Anchor
              asLink
              onClick={() => modalManager.open(MODALS.HELPER, "karma_help")}
              className={css.helpLink}
            >
              {t("all:karma_help_link")}
              <Icons.QuestionCircle
                className={css.questionIcon}
                width={20}
                height={20}
                color={theme.colors.primary}
              />
            </Anchor>
          </Box>
          <Box mt={5}>
            <Button
              secondary
              className={css.inviteButton}
              onClick={handleInviteFriend}
              fullWidth
            >
              {t("all:invite_get_button")}
            </Button>
            <Typography size="caption(12px)" align="center">
              {t("all:karma_up").replace("$1", inviteBonusFromRef)}
            </Typography>
            {profile && profile.masterUserId ? null : (
              <>
                <Button
                  secondary
                  className={css.enterButton}
                  onClick={handleInviteModalOpen}
                  fullWidth
                >
                  {t("all:invite_button")}
                </Button>
                <Typography size="caption(12px)" align="center">
                  {t("all:karma_up").replace("$1", inviteBonus)}
                </Typography>
              </>
            )}
          </Box>
        </div>
      </div>
      <DefaultPanel
        title={t("all:karma_history_header")}
        className={css.history}
      >
        <>
          {typeof likes !== "undefined" && likes.length > 0 ? (
            likes.map((like, i) => (
              <KarmaHistoryItem key={`${like.userId}-${i}`} like={like} />
            ))
          ) : (
            <div className={css.noKarmaContainer}>
              <Typography font="script" align="center">
                {t("all:karma_empty_text")}
              </Typography>
            </div>
          )}
        </>
      </DefaultPanel>
    </AnimatedPage>
  );
});
