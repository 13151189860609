import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Translator = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Translator}>
    {({ color }) => (
      <>
        <path
          d="M21.1241 0.000305386H8.42589C7.87005 0.000130236 7.31963 0.109482 6.80606 0.322111C6.2925 0.534741 5.82587 0.846481 5.43283 1.23952C5.03979 1.63256 4.72805 2.09919 4.51542 2.61275C4.30279 3.12631 4.19344 3.67674 4.19362 4.23258V16.9867C3.99381 17.5037 3.62813 17.9397 3.15388 18.2264C2.15002 18.6278 1.08101 18.8415 0 18.8569C0.993543 20.1227 2.44257 20.9498 4.03766 21.1617C4.93692 21.2295 5.84013 21.0994 6.68366 20.7804C7.22769 21.0291 7.81842 21.159 8.41656 21.1617H21.1147C21.6712 21.1626 22.2224 21.0538 22.7368 20.8415C23.2512 20.6292 23.7187 20.3177 24.1126 19.9246C24.5066 19.5315 24.8192 19.0647 25.0326 18.5508C25.246 18.0368 25.356 17.4859 25.3563 16.9294V4.22991C25.3553 3.10802 24.9089 2.03241 24.1154 1.23936C23.3218 0.446305 22.246 0.000658128 21.1241 0.000305386V0.000305386Z"
          fill="#655285"
        />
        <path
          d="M19.7097 6.80393H20.2349V5.21766H14.9309V3.68071H13.3487V5.22566H8.04865V6.81326H18.0755C17.421 9.04539 16.1999 11.0701 14.531 12.6905C13.7291 11.4004 13.1891 9.96497 12.9421 8.46618L11.3638 8.64214C11.6289 10.445 12.2776 12.17 13.266 13.7009C12.099 14.5019 10.7853 15.0645 9.40031 15.3565L9.73222 16.9054C11.3482 16.5649 12.8768 15.8956 14.2231 14.9392C14.9585 15.7648 15.8362 16.4515 16.8145 16.9667L17.5516 15.5591C16.7699 15.1422 16.0689 14.5892 15.4815 13.9261C17.5067 11.9763 18.9676 9.51555 19.7097 6.80393V6.80393Z"
          fill="white"
        />
        <path
          d="M11.6837 12.1226H23.8967C24.9631 12.1094 25.9911 12.5204 26.7545 13.2651C27.5179 14.0098 27.9543 15.0272 27.9677 16.0936V28.0613C28.1623 28.5495 28.5141 28.959 28.9674 29.225C29.9348 29.6019 30.9619 29.8024 32 29.8169C31.0333 31.0124 29.6412 31.7873 28.1156 31.979C27.2516 32.0429 26.384 31.9211 25.5709 31.6217C25.0447 31.856 24.4754 31.9777 23.8994 31.979H11.6864C10.6202 31.9914 9.59266 31.5802 8.82937 30.8357C8.06609 30.0912 7.62949 29.0741 7.61543 28.008V16.0936C7.62914 15.0278 8.06527 14.011 8.82805 13.2664C9.59083 12.5219 10.6179 12.1105 11.6837 12.1226V12.1226Z"
          fill="white"
          stroke="#45385B"
          strokeWidth="0.6665"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.7063 28.8944L24.1313 28.3612L18.9739 15.4085L18.7806 14.9366H17.7262L12.5169 28.3745L13.9459 28.8931L15.7041 24.3609H20.9028L22.7063 28.8944ZM16.2786 22.8866L18.2714 17.7505L20.3176 22.8866H16.2786Z"
          fill="#655285"
        />
      </>
    )}
  </CreateSvgIcon>
);
