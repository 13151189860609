import { globalUIStore } from "global-stores/global-ui-store";
import React from "react";
import { motion } from "framer-motion";
import { makeStyles } from "hooks/makeStyles";
import clsx from "clsx";
import { Box, BoxProps } from "../box/box";
import { BackButton } from "../back-button/back-button";

interface BackProps {
  label?: string;
  path?: string;
  boxProps?: BoxProps;
}

interface AnimatedPageProps {
  animation: "horizontal" | "vertical";
  back?: BackProps;
  className?: string;
  containerClassName?: string;
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    height: "100%",
    overflowY: "auto",
  },
}));

const variants = {
  slide: {
    opacity: 1,
    x: 0,
  },
  slide_out: { opacity: 0, x: "10%" },
  slide_up: {
    opacity: 1,
    y: 0,
  },
  slide_down: { opacity: 0, y: "5%" },
};

export const AnimatedPage = (props: AnimatedPageProps) => {
  const css = useStyles();

  const { animation, back, className, containerClassName, children } = props;

  return (
    <motion.div
      initial={animation === "vertical" ? "slide_down" : "slide_out"}
      animate={animation === "vertical" ? "slide_up" : "slide"}
      exit={animation === "vertical" ? "slide_down" : "slide_out"}
      transition={{
        type: "spring",
        stiffness: 400,
        damping: 40,
      }}
      variants={variants}
      className={clsx(css.pageContainer, className)}
      onAnimationComplete={() => {
        globalUIStore.setIsAnimating(false);
      }}
    >
      <Box className={containerClassName}>
        {back ? (
          <BackButton label={back.label} path={back.path} {...back.boxProps} />
        ) : null}
        {children}
      </Box>
    </motion.div>
  );
};
