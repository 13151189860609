import { makeStyles } from "hooks/makeStyles";
import { Gender } from "lib";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import GenderButtonGroup from "ui/components/gender-button-group/gender-button-group";
import { Dropdown, IconButton, Icons, Tag } from "ui/shared";
import { capitalize } from "utils";
import { topStore } from "./top-store";

const useStyles = makeStyles((theme) => ({
  topFilterContainer: {
    backgroundColor: theme.colors.background.paper.lighter,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tagsContainer: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    padding: `0 ${theme.spacing(4)}`,
  },
  cleanMargin: {
    margin: 0,
  },
  genderModalContainer: {
    display: "flex",
    minWidth: 350,
    backgroundColor: theme.colors.background.paper.topbar,
    padding: theme.spacing(3.5),
    boxShadow: theme.shadows.dropdown,
    borderRadius: 10,
  },
}));

const TopUsersFilter = observer(() => {
  const css = useStyles();
  const { t } = useTranslation();

  const handleClearFilter = useCallback(() => {
    topStore.clearFilter();
  }, []);

  return (
    <div className={css.topFilterContainer}>
      <Dropdown
        preferRight
        render={(close) => (
          <div className={css.genderModalContainer}>
            <GenderButtonGroup
              labels={[
                t("all:top_filter_all"),
                t("all:main_button_gender_1"),
                t("all:main_button_gender_2"),
              ]}
              value={topStore.filter}
              onChange={(e, value) => {
                topStore.setTopFilter(value as Gender);
                close();
              }}
            />
          </div>
        )}
      >
        <IconButton>
          <Icons.Filters />
        </IconButton>
      </Dropdown>
      {topStore.filter ? (
        <div className={css.tagsContainer}>
          <Tag
            withLightBg
            text={`${t("all:friend_profile_gender")}: ${
              topStore.filter === "m"
                ? capitalize(t("all:gender_m"))
                : capitalize(t("all:gender_f"))
            }`}
            className={css.cleanMargin}
          />
        </div>
      ) : null}
      {topStore.filter ? (
        <IconButton onClick={handleClearFilter}>
          <Icons.Trash />
        </IconButton>
      ) : null}
    </div>
  );
});

export default TopUsersFilter;
