import React from "react";
import { makeStyles } from "hooks/makeStyles";
import { useTranslation } from "react-i18next";
import { Anchor, Icons, Typography } from "ui/shared";
import clsx from "clsx";
import logo from "assets/svg/logo-inside-me.svg";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      margin: 0,
      padding: 0,
    },
    html: {
      fontSize: "10px",
    },
  },
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.background.paper.topbar,
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 10,
    opacity: 1,
    width: "100%",
    maxWidth: 328,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(5),
    margin: `0 ${theme.spacing(5)}`,
  },
  logo: {
    width: 220,
    height: 128,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 116,
  },
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    background: "none",
  },
  small: {
    "&:not(:last-child)": {
      marginRight: 10,
    },
  },
  big: {
    marginBottom: 13,
  },
  title: {
    marginTop: theme.spacing(5.5),
    marginBottom: theme.spacing(3),
  },
  subtitle: { marginTop: 10 },
  socialLinks: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
}));

export const MobileLayout = () => {
  const css = useStyles();
  const { t } = useTranslation("all");

  return (
    <div className={css.root}>
      <div className={css.wrapper}>
        <img className={css.logo} src={logo} alt="Logo" />
        <Typography className={css.title} size="h3(20px)">
          Download App
        </Typography>
        <div className={css.container}>
          <Anchor
            href="https://play.google.com/store/apps/details?id=com.zebka.odigo"
            className={clsx(css.link, css.big)}
          >
            <Icons.GooglePlay viewBox="0 0 88 24" width={116} height={32} />
          </Anchor>
          <Anchor
            href="https://apps.apple.com/ru/app/id1400753003"
            className={clsx(css.link, css.big)}
          >
            <Icons.Apple viewBox="0 0 88 24" width={116} height={32} />
          </Anchor>
          <div className={css.socialLinks}>
            <Anchor
              href="https://vk.com/odi.chat"
              className={clsx(css.link, css.small)}
            >
              <Icons.VK />
            </Anchor>
            <Anchor
              href="https://www.facebook.com/odigo.chat"
              className={clsx(css.link, css.small)}
            >
              <Icons.Facebook />
            </Anchor>
            <Anchor
              href="https://www.instagram.com/odi.chat/"
              className={clsx(css.link, css.small)}
            >
              <Icons.Instagram />
            </Anchor>
          </div>
        </div>
        <Typography
          className={css.subtitle}
          size="caption(12px)"
          font="light"
          color="white"
        >
          Developed by zebka
        </Typography>
      </div>
    </div>
  );
};
