import React, { useCallback, useEffect, useRef } from "react";
import { makeStyles } from "hooks/makeStyles";
import { Typography, Icons, Button, Input } from "ui/shared";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import parse from "html-react-parser";

import { inviteStore } from "global-stores/invite-store";
import { userStore } from "global-stores/user-store";
import { modalManager } from "global-stores/modal-manager";
import { MODALS } from "lib/constants";
import { replaceAnchorsWithTargetBlank } from "utils/parseHtmlString";

const activeKarma = require("assets/svg/big-active-karma.svg");

export interface InviteCodeProps {
  visible?: boolean;
}

const useStyles = makeStyles((theme) => ({
  body: {
    padding: `${theme.spacing(5.5)}`,
  },
  caption: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    marginBottom: 19,
  },
  captionText: {
    marginBottom: 20,
    "& > a": {
      fontSize: "inherit",
      color: theme.colors.primary,
    },
  },
  activateButton: {
    width: 226,
    height: 46,
  },
  inputWrap: {
    marginBottom: 58,
  },
  codeInput: {
    flexGrow: "1",
    border: "none !important",
    backgroundColor: "inherit",
    textAlign: "start",
    padding: 10,
    textTransform: "uppercase",
    borderRadius: 0,
    "&:placeholder": {
      color: theme.colors.purple.main,
    },
    "&:focus": {
      outline: 0,
      border: "none",
    },
  },
  karmaWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(3.5),
  },
  iconText: {
    fontSize: 42,
  },
  wrapperBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  validText: {
    color: theme.colors.green.secondary,
    position: "absolute",
    bottom: -22,
  },
  errorText: {
    color: theme.colors.red.secondary,
    position: "absolute",
    bottom: -22,
  },
  helperText: {
    textAlign: "left",
  },
}));

export const InviteCode = observer(({ visible }: InviteCodeProps) => {
  const { t } = useTranslation("all");
  const css = useStyles();

  const inputRef = useRef<HTMLInputElement>(null);

  const profile = userStore.user && userStore.user.profile;
  const inviteTime = inviteStore.inviteTime;

  useEffect(() => {
    inviteStore.getInviteParams();
  }, []);

  useEffect(() => {
    if (inputRef && inputRef.current && visible) {
      inputRef.current.focus();
    }
  }, [inputRef, visible]);

  /**************************************************/

  const getTimeString = useCallback(
    (timeInMs: number) => {
      let minutes = Math.floor((timeInMs / (1000 * 60)) % 60).toString();
      let hours = Math.ceil((timeInMs / (1000 * 60 * 60)) % 60).toString();
      let lastCharHour = hours.slice(-1);
      let lastCharMin = minutes.slice(-1);

      if (hours === "0") {
        switch (lastCharMin) {
          case "1":
            return `${minutes} ${t("all:text_min1")}`;
          case "2":
          case "3":
          case "4":
            return `${minutes} ${t("all:text_min2")}`;
          default:
            return `${minutes} ${t("all:text_min3")}`;
        }
      } else {
        switch (lastCharHour) {
          case "1":
            return `${hours} ${t("all:text_hour1")}`;
          case "2":
          case "3":
          case "4":
            return `${hours} ${t("all:text_hour2")}`;
          default:
            return `${hours} ${t("all:text_hour3")}`;
        }
      }
    },
    [t]
  );

  const handleInviteCodeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value.split("-").join("");

      if (value.length >= 0) {
        let newValue =
          value
            .match(/.{1,4}/g)
            ?.join("-")
            .toUpperCase() || [];

        inviteStore.setInviteCode(newValue.toLocaleString());
      }
    },
    []
  );

  const handleActivateInviteCode = useCallback(() => {
    if (profile) {
      inviteStore.activateInviteCode();
    } else {
      modalManager.close(MODALS.LOGIN_INVITE_CODE);
      modalManager.close(MODALS.PROFILE_INVITE);
      inviteStore.setInvited(true);
    }
  }, [profile]);

  /**************************************************/

  if (profile && profile.masterUserId) {
    return (
      <div className={css.body}>
        <Typography size="subtitle2(16px)" align="center">
          {t("all:alert_invitation_activated_title")}
        </Typography>
        <Typography size="body(14px)" align="center">
          {t("all:alert_invitation_activated_text")}
        </Typography>
        <div className={css.karmaWrap}>
          <Icons.ActiveKarma />
          <Typography>+20</Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={css.body}>
      <div className={css.karmaWrap}>
        <img src={activeKarma} width="124px" alt="Active karma" />
        <Typography className={css.iconText} font="light">
          +{inviteStore.inviteTimeKarma}
        </Typography>
      </div>
      <Typography
        size="subtitle2(16px)"
        align="center"
        className={css.captionText}
      >
        {parse(t("all:invite_set_text"), {
          replace: replaceAnchorsWithTargetBlank,
        })}
      </Typography>
      {inviteTime ? (
        <div className={css.caption}>
          <Typography align="left">
            {t("all:invite_set_countdown_text").replace(
              "$1",
              getTimeString(inviteTime)
            )}
          </Typography>
        </div>
      ) : null}
      <div className={css.inputWrap}>
        <Input
          ref={inputRef}
          className={css.codeInput}
          value={inviteStore.inviteCode}
          onChange={handleInviteCodeInput}
          mt={2}
          placeholder={t("all:invite_set_placeholder")}
          error={!!inviteStore.inviteCodeError}
          succeed={!!inviteStore.message}
          helperText={
            inviteStore.inviteCodeError ||
            inviteStore.message ||
            inviteStore.warning
          }
          helperTextClassName={css.helperText}
        />
      </div>
      <div className={css.wrapperBtn}>
        <Button
          primary
          disabled={
            !inviteStore.inviteCode ||
            !!inviteStore.inviteCodeError ||
            !!inviteStore.warning
          }
          className={css.activateButton}
          onClick={() => handleActivateInviteCode()}
        >
          {t("all:invite_set_button")}
        </Button>
      </div>
    </div>
  );
});
