import React, { useCallback, useState } from "react";
import { Anchor, Box, Button, Input, Typography } from "ui/shared";
import { useTranslation } from "react-i18next";
import { RestoreAccountStore } from "./stores/restore-account-store";
import { observer } from "mobx-react";

interface RestoreAccountDialogProps {
  className?: string;
}

export const RestoreAccountDialog = observer(
  (props: RestoreAccountDialogProps) => {
    const { className } = props;
    const { t } = useTranslation("all");

    const [store] = useState(new RestoreAccountStore());

    /**************************************************/

    const handleEmailChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        store.setEmail(e.target.value);
      },
      []
    );

    const handleCodeChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        store.setCode(e.target.value.toUpperCase());
      },
      []
    );

    const handleSendRestoreCode = useCallback(() => {
      store.sendCode();
    }, []);

    const handleConfirmReset = useCallback(() => {
      store.confirmReset();
    }, []);

    /**************************************************/

    return (
      <Box pt={8} pl={8} pr={8} className={className}>
        {store.isCodeSent ? (
          <Input
            autoFocus
            placeholder={t("all:reset_code_placeholder")}
            value={store.codeValue}
            onChange={handleCodeChange}
          />
        ) : (
          <Input
            autoFocus
            placeholder={t("all:reset_email_placeholder")}
            value={store.emailValue}
            onChange={handleEmailChange}
          />
        )}
        <Box mt={4} mb={!store.isCodeSent ? 4 : 0}>
          <Button
            fullWidth
            primary
            disabled={
              store.isCodeSent
                ? !store.codeValue || !!store.error
                : !store.emailValue || !!store.error
            }
            onClick={
              store.isCodeSent ? handleConfirmReset : handleSendRestoreCode
            }
          >
            {store.isCodeSent
              ? t("all:reset_button_reset")
              : t("all:reset_button_send")}
          </Button>
        </Box>
        <Box mt={5} mb={5}>
          {store.error ? (
            <Typography color="red">{store.error}</Typography>
          ) : store.isCodeSent ? (
            <Box>
              <Typography>
                {t("all:reset_text_code_was_sent").replace(
                  "$1",
                  store.emailValue
                )}
              </Typography>
              {store.timer ? (
                store.countDownTimer ? (
                  <Typography>
                    {t("all:verification_code_repeat_after").replace(
                      "$1",
                      `${store.countDownTimer}`
                    )}
                  </Typography>
                ) : null
              ) : (
                <Anchor asLink onClick={handleSendRestoreCode}>
                  {t("all:verification_code_repeat")}
                </Anchor>
              )}
            </Box>
          ) : null}
        </Box>
      </Box>
    );
  }
);
