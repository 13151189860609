import { chatsStore } from "global-stores/chats-store";
import { modalManager } from "global-stores/modal-manager";
import translator from "global-stores/translator-store";
import { makeStyles } from "hooks/makeStyles";
import { MODALS } from "lib/constants";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "ui/shared";

const useStyles = makeStyles((theme) => ({
  translationHideAlertContainer: {
    maxWidth: 350,
    padding: `${theme.spacing(8)} ${theme.spacing(5)}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  hideButton: {
    border: 0,
    padding: theme.spacing(3.75),
    backgroundColor: theme.colors.background.paper.lighter,
    marginTop: theme.spacing(5),
  },
}));

const TranslateHideAlertModal = observer(() => {
  const css = useStyles();
  const { t } = useTranslation("all");

  const hideTranslator = useCallback(() => {
    const chatStore = chatsStore.selectedChatStore;
    if (chatStore) {
      chatStore.setTranslationSettings({
        hideTranslation: true,
      });
    }
    modalManager.close(MODALS.TRANSLATE_HIDE_ALERT);
  }, []);

  return (
    <div className={css.translationHideAlertContainer}>
      <Typography size="subtitle1(18px)" align="center">
        {t("all:trans_alert_hide")}
      </Typography>
      <Button secondary onClick={hideTranslator} className={css.hideButton}>
        {t("all:trans_alert_hide_button")}
      </Button>
    </div>
  );
});

export default TranslateHideAlertModal;
