import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Apple = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Apple}>
    {({ color }) => (
      <>
        <g transform="translate(-739 -736)">
          <g
            transform="translate(739 736)"
            fill="none"
            stroke="#fff"
            strokeWidth="1"
          >
            <rect width="88" height="24" rx="12" stroke="none" />
            <rect
              x="0.5"
              y="0.5"
              width="87"
              height="23"
              rx="11.5"
              fill="none"
            />
          </g>
          <g transform="translate(753 741)">
            <path
              d="M880.061,2881.772a3.128,3.128,0,0,0,.374,5.522c-.07.189-.134.383-.211.572a8.8,8.8,0,0,1-1.5,2.4,1.729,1.729,0,0,1-1.583.714,3.937,3.937,0,0,1-.957-.29,2.771,2.771,0,0,0-1.842-.146,5.459,5.459,0,0,0-.646.242,1.731,1.731,0,0,1-2.3-.519,8.836,8.836,0,0,1-2.089-4.234,6.087,6.087,0,0,1,.047-2.916,3.525,3.525,0,0,1,2.524-2.608,2.758,2.758,0,0,1,1.825.139c.2.082.406.157.606.244a1.409,1.409,0,0,0,1.139,0,9.474,9.474,0,0,1,1.339-.456A3.21,3.21,0,0,1,880.061,2881.772Z"
              transform="translate(-869.154 -2877.011)"
              fill="#fff"
            />
            <g transform="translate(16.702 2.773)">
              <path
                d="M926.2,2884.068h.979l.072.817a.968.968,0,0,0,.075-.087,2.047,2.047,0,0,1,3.28-.065,2.757,2.757,0,0,1,.545,1.4,3.752,3.752,0,0,1-.244,1.942,2.2,2.2,0,0,1-2.569,1.316,1.648,1.648,0,0,1-.97-.678v2.754H926.2Zm1.134,2.68.033,0c0,.17-.007.34,0,.51a1.281,1.281,0,0,0,1.021,1.249,1.221,1.221,0,0,0,1.4-.774,2.574,2.574,0,0,0,0-2.088,1.186,1.186,0,0,0-1.272-.754,1.257,1.257,0,0,0-1.075.961A7.843,7.843,0,0,0,927.333,2886.748Z"
                transform="translate(-919.096 -2881.908)"
                fill="#fff"
              />
              <path
                d="M942.114,2888.714v2.751h-1.159v-7.393h.975l.07.809a.756.756,0,0,0,.077-.087,2.047,2.047,0,0,1,3.391.091,3.256,3.256,0,0,1,.458,1.987,3.15,3.15,0,0,1-.4,1.459,2.18,2.18,0,0,1-2.445,1.06,1.444,1.444,0,0,1-.849-.544C942.206,2888.811,942.173,2888.779,942.114,2888.714Zm2.631-2.029a.968.968,0,0,0,0-.188,4.811,4.811,0,0,0-.176-.785,1.2,1.2,0,0,0-1.3-.822,1.232,1.232,0,0,0-1.083,1.019,7.627,7.627,0,0,0-.05,1.427,1.245,1.245,0,0,0,2.345.53A2.349,2.349,0,0,0,944.745,2886.686Z"
                transform="translate(-927.853 -2881.905)"
                fill="#fff"
              />
              <path
                d="M908.727,2886.516l.8-2.512q.732-2.306,1.462-4.613c.044-.141.1-.2.257-.194.373.014.746.008,1.12,0a.15.15,0,0,1,.172.124q1.13,3.553,2.264,7.1c.006.017.008.035.019.087H914.1c-.5,0-.5,0-.654-.488s-.318-.992-.468-1.491c-.037-.121-.084-.171-.217-.169q-1.007.011-2.016,0a.188.188,0,0,0-.219.164c-.175.61-.366,1.214-.541,1.823a.187.187,0,0,1-.22.164C909.427,2886.51,909.092,2886.516,908.727,2886.516Zm3.972-3.049-.968-3.205-.933,3.205h1.9Z"
                transform="translate(-908.727 -2879.092)"
                fill="#fff"
              />
              <path
                d="M962.6,2880.239a2.785,2.785,0,0,0-1.629-.314,1.49,1.49,0,0,0-.5.145.884.884,0,0,0-.191,1.478,5.275,5.275,0,0,0,.916.531c.319.17.657.3.966.487a2.05,2.05,0,0,1-.431,3.718,3.884,3.884,0,0,1-2.875-.164c-.148-.067-.177-.149-.126-.3.083-.249.144-.506.2-.722.394.124.771.271,1.161.359a2.134,2.134,0,0,0,1.162-.046,1.052,1.052,0,0,0,.312-1.861,5.122,5.122,0,0,0-.839-.443,4.73,4.73,0,0,1-1.205-.667,1.9,1.9,0,0,1,.18-3.1,2.411,2.411,0,0,1,1.1-.377,3.939,3.939,0,0,1,1.906.236c.135.055.179.112.122.263C962.747,2879.707,962.683,2879.963,962.6,2880.239Z"
                transform="translate(-938.39 -2878.936)"
                fill="#fff"
              />
              <path
                d="M983.2,2889.445a2.436,2.436,0,0,1-2.481-2.274,3.59,3.59,0,0,1,.128-1.492,2.482,2.482,0,0,1,2.809-1.72,2.43,2.43,0,0,1,2.133,2.519,4.037,4.037,0,0,1-.157,1.226A2.4,2.4,0,0,1,983.2,2889.445Zm-1.322-2.752a2.307,2.307,0,0,0,.351,1.327,1.146,1.146,0,0,0,1.987,0,2.447,2.447,0,0,0,.036-2.63,1.164,1.164,0,0,0-2.045,0A2.394,2.394,0,0,0,981.881,2886.692Z"
                transform="translate(-951.439 -2881.898)"
                fill="#fff"
              />
              <path
                d="M1008.435,2886.953h-3.454a1.4,1.4,0,0,0,1.135,1.507,3.419,3.419,0,0,0,1.694-.128l.19-.061c.055.24.113.47.155.7a.178.178,0,0,1-.084.148,3.921,3.921,0,0,1-2.7.1,2.254,2.254,0,0,1-1.49-1.935,3.382,3.382,0,0,1,.254-1.974,2.349,2.349,0,0,1,2.844-1.314,1.77,1.77,0,0,1,.922.609A3.042,3.042,0,0,1,1008.435,2886.953Zm-1.064-.846c-.01-.121-.011-.233-.032-.342a1.127,1.127,0,0,0-.722-.97,1.086,1.086,0,0,0-1.174.248,1.638,1.638,0,0,0-.481,1.063Z"
                transform="translate(-965.169 -2881.883)"
                fill="#fff"
              />
              <path
                d="M974.423,2886.682c0,.277,0,.54,0,.8a.157.157,0,0,1-.1.108,2.448,2.448,0,0,1-1.308.021,1.281,1.281,0,0,1-.906-1.214,8.115,8.115,0,0,1-.039-.886c-.005-.767,0-1.535,0-2.322h-.742v-.876h.733v-.976l1.147-.35v1.313h1.264v.872h-1.251c-.005.073-.012.128-.012.183,0,.78,0,1.561,0,2.342a2.383,2.383,0,0,0,.058.523.591.591,0,0,0,.651.5C974.066,2886.719,974.214,2886.7,974.423,2886.682Z"
                transform="translate(-945.877 -2880.156)"
                fill="#fff"
              />
              <path
                d="M995.722,2884.082h.975c.017.319.035.636.054,1.013l.108-.221a1.455,1.455,0,0,1,1.46-.9c.056,0,.156.062.157.1.012.338.007.677.007,1.025-.129-.009-.24-.022-.35-.022a1.08,1.08,0,0,0-1.093.724,2.129,2.129,0,0,0-.146.713c-.018.867-.008,1.737-.009,2.6v.22h-1.164Z"
                transform="translate(-960.356 -2881.922)"
                fill="#fff"
              />
            </g>
            <path
              d="M885.688,2872.107a2.886,2.886,0,0,1-2.731,3.2C882.571,2874.049,884.338,2871.98,885.688,2872.107Z"
              transform="translate(-877.314 -2872.102)"
              fill="#fff"
            />
          </g>
        </g>
      </>
    )}
  </CreateSvgIcon>
);
