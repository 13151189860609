import { storage } from "utils";
import { Encryption, Friend } from "lib";
import { observable } from "mobx";
import { Meteor } from "meteor/meteor";
import md5 from "md5";

const util = require("tweetnacl-util");

class EncryptionStore {
  @observable isInitialized?: boolean;

  constructor() {
    this.getKeys();
  }

  private keys?: {
    publicKey: string;
    secretKey: string;
  };

  private _token?: string;

  private _encryption: { [key: string]: Encryption } = {};

  async getKeys(force?: boolean) {
    if (this.keys && !force) return;
    const publicKey = await storage.read("publicKey");
    const secretKey = await storage.read("secretKey");
    const token = await storage.read("Meteor.loginToken", false);
    if (publicKey && secretKey && token) {
      this.keys = {
        publicKey,
        secretKey,
      };
      this._token = token;
      this.isInitialized = true;
    } else {
      this.isInitialized = false;
    }
  }

  createEncryption(friend: Friend) {
    if (
      this.keys &&
      this.keys.publicKey &&
      this.keys.secretKey &&
      friend.friendPublicKey &&
      !this._encryption[friend._id]
    ) {
      this._encryption[friend._id] = new Encryption(
        this.keys.publicKey,
        this.keys.secretKey,
        friend.friendPublicKey
      );
    }
  }

  get token() {
    return this._token;
  }

  getEncryptor(id: string) {
    return this._encryption[id];
  }

  generateAccountString() {
    const id = Meteor.userId();
    if (!this.keys || !id) return;

    const pub = util.decodeBase64(this.keys.publicKey);
    const pri = util.decodeBase64(this.keys.secretKey);
    const uid = util.decodeUTF8(id);
    const bytes = new Uint8Array([...pub, ...pri, ...uid]);
    const md5hex = md5(bytes).substr(0, 8);

    const accountString = `0${this.keys.publicKey}|${
      this.keys.secretKey
    }|${Meteor.userId()}|${md5hex}`;

    return accountString;
  }

  decodeAccountString(str: string) {
    const cleared = str.substr(1);
    // public | secret | userId | md5(pk+sk+userId).substr(0,8)
    const parsed = cleared.split("|");

    if (parsed.length === 4) {
      const parsedValues = {
        public: parsed[0],
        secret: parsed[1],
        userId: parsed[2],
        md5: parsed[3],
      };
      const pub = util.decodeBase64(parsedValues.public);
      const pri = util.decodeBase64(parsedValues.secret);
      const uid = util.decodeUTF8(parsedValues.userId);
      const bytes = new Uint8Array([...pub, ...pri, ...uid]);
      const md5hex = md5(bytes).substr(0, 8);

      if (md5hex === parsedValues.md5) {
        return parsedValues;
      }

      return;
    }

    return;
  }

  clear() {
    this.isInitialized = false;
    this.keys = undefined;
    this._encryption = {};
    this._token = undefined;
  }
}

export const encriptionStore = new EncryptionStore();
