import clsx from "clsx";
import { globalUIStore } from "global-stores/global-ui-store";
import { makeStyles } from "hooks/makeStyles";
import { first } from "lodash";
import { observer } from "mobx-react";
import React, { useCallback, useEffect } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { fade } from "utils";
import { Icons } from "../icons";
import { Typography } from "../typography/typography";
import { DropZoneStore, UploadedFile } from "./dropzone-store";
import URL from "utils/url";
import { vipGuard } from "../acl-guard/acl-guard";

const TOO_MANY_FILES_CODE = "too-many-files";

interface DropZoneProps {
  store: DropZoneStore;
  onFilesChange?: (files: UploadedFile[]) => void;
  renderPreview?: (file: File, index: number) => React.ReactNode;
  renderEmptyScreen?: () => React.ReactNode;
  wrapperClassName?: string;
}

const useStyles = makeStyles((theme) => ({
  baseStyles: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10,
    "&:focus, &:active": {
      outline: "none",
    },
  },
  uploadDragnDrop: {
    backgroundColor: fade(theme.colors.background.dark, 0.8),
  },
  dropZone: {
    borderRadius: 10,
    border: `1px dashed ${fade(theme.colors.common.white, 0.4)}`,
    margin: "5%",
    width: "90%",
    height: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "auto",
  },
  uploaded: {
    border: 0,
  },
  dragLabel: {
    marginBottom: theme.spacing(2),
  },
  previewContainer: {
    height: "100%",
    position: "relative",
    borderRadius: 10,
    overflow: "hidden",
    "&:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
    "& > img": {
      objectFit: "contain",
    },
  },
  close: {
    position: "absolute",
    top: 20,
    right: 20,
    width: 40,
    height: 40,
  },
  hidden: {
    visibility: "hidden",
  },
}));

export const DropZone = observer((props: DropZoneProps) => {
  const css = useStyles();
  const { t } = useTranslation("chat");
  const {
    wrapperClassName,
    store,
    onFilesChange,
    renderPreview,
    renderEmptyScreen,
  } = props;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
    open,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    accept: ["image/*"],
  });

  /**************************************************/

  const updateFiles = useCallback(
    (acceptedFiles: File[]) => {
      // Calculate ratio and resize
      let mappedFiles: UploadedFile[] = [];
      if (acceptedFiles && acceptedFiles.length) {
        mappedFiles = acceptedFiles.map((file) => {
          return { file };
        });
      }
      store.setFiles(mappedFiles);
      if (onFilesChange) {
        onFilesChange(mappedFiles);
      }
    },
    [onFilesChange, store]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (acceptedFiles.length) {
        updateFiles(acceptedFiles);
      } else if (fileRejections.length) {
        const firstValidFile = first(
          fileRejections.filter(
            (rejection) => rejection.errors[0].code === TOO_MANY_FILES_CODE
          )
        );
        if (firstValidFile && firstValidFile.file) {
          updateFiles([firstValidFile.file]);
        }
      }
    },
    [updateFiles]
  );

  const renderDefaultEmptyScreen = useCallback(() => {
    return (
      <>
        <Typography className={css.dragLabel}>
          {t("chat:upload.emptyLabel")}
        </Typography>
        <Icons.Photo width={50} height={50} />
      </>
    );
  }, []);

  const renderDefaultPreview = useCallback(
    (file: File, index: number) => {
      return (
        <li key={index} className={css.previewContainer}>
          <img
            width="100%"
            height="100%"
            alt=""
            src={URL.createObjectURL(file)}
          />
        </li>
      );
    },
    [css.previewContainer]
  );

  /**************************************************/

  useEffect(() => {
    store.init(open);
  }, [store, open]);

  useEffect(() => {
    if (acceptedFiles.length || fileRejections.length) {
      vipGuard(() => onDrop(acceptedFiles, fileRejections));
    }
  }, [acceptedFiles, fileRejections]);

  const defaultWrapperClasses = clsx(css.baseStyles, {
    [css.uploadDragnDrop]: isDragActive || store.files.length,
  });

  return (
    <div
      {...getRootProps()}
      className={clsx(
        wrapperClassName ? wrapperClassName : defaultWrapperClasses,
        {
          [css.hidden]: !globalUIStore.isDragOver && !store.files.length,
        }
      )}
    >
      <input {...getInputProps()} />
      {isDragActive || store.files.length ? (
        <div
          className={clsx(css.dropZone, {
            [css.uploaded]: !!store.files.length,
          })}
        >
          {store.files.length ? (
            <ul style={{ height: "inherit" }}>
              {store.files.map((file, index) =>
                renderPreview
                  ? renderPreview(file.file, index)
                  : renderDefaultPreview(file.file, index)
              )}
            </ul>
          ) : renderEmptyScreen ? (
            renderEmptyScreen()
          ) : (
            renderDefaultEmptyScreen()
          )}
        </div>
      ) : null}
    </div>
  );
});
