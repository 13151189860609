import * as CSS from "csstype";
import { Theme } from "../lib/theme";
import { createUseStyles } from "react-jss";

/**
 * Just right types for createUseStyles for autocompletion
 */

type JSSFuntion<P> = (props: P) => string | number;
type JSS<Props = any> = { [key: string]: JSSFuntion<Props> | any };

export interface CSSProperties extends CSS.Properties<number | string> {
  // Allow pseudo selectors and media queries
  [k: string]:
    | CSS.Properties<number | string>[keyof CSS.Properties]
    | CSSProperties;
}

export type StyleRules<
  Props extends object,
  ClassKey extends string = string
> = Record<
  ClassKey,
  CSSProperties | ((props: Props) => CSSProperties) | JSS<Props>
>;

export type StyleRulesCallback<
  Theme,
  Props extends object,
  ClassKey extends string = string
> = (theme: Theme) => StyleRules<Props, ClassKey>;

export type ClassNameMap<ClassKey extends string = string> = Record<
  ClassKey,
  string
>;

export type ClassKeyOfStyles<S, P extends object> = S extends string
  ? S
  : S extends StyleRulesCallback<any, P, infer K>
  ? K
  : S extends StyleRules<P, infer K>
  ? K
  : never;

export type Styles<Theme, Props extends {}, ClassKey extends string = string> =
  | StyleRules<Props, ClassKey>
  | StyleRulesCallback<Theme, Props, ClassKey>;

type Props<T> = T extends { [key: string]: infer R }
  ? { [key: string]: R }
  : any;

export type StylesHook = <P = any>(
  props?: Props<P>
) => ClassNameMap<ClassKeyOfStyles<Styles<any, Props<P>>, Props<P>>>;

export type makeStyles = (
  styles: Styles<Theme, {}, string>,
  options?: Theme
) => StylesHook;

export const makeStyles = createUseStyles as makeStyles;
