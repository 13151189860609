import { makeStyles } from "hooks/makeStyles";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "ui/shared";
import { EditableDropdown, wrappedLabel } from "../../shared/editable/editable";
import { profileStore } from "./store.profile";
import { userStore } from "global-stores/user-store";
import { AgeModal } from "./modals/age-modal";

const useStyles = makeStyles((theme) => ({
  loginText: {
    textTransform: "none",
  },
  editInfoInputWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    width: "100%",
  },
}));

const AgeEditable = observer(() => {
  const { t } = useTranslation("all");
  const css = useStyles();

  /**************************************************/

  const ageRender = useCallback((items: number[]) => {
    const newItems = items
      .filter((item, i) => items.indexOf(item) === i)
      .join("-");

    if (items[0] !== 0 && items[1] !== 0) {
      return newItems;
    } else {
      return t("all:profile_placeholder_no");
    }
  }, []);

  /**************************************************/

  return (
    <EditableDropdown
      label={wrappedLabel(
        <Typography size="caption(12px)" color="secondary" lowercase>
          {t("all:profile_age")}:
        </Typography>
      )}
      onCloseDropdown={() => profileStore.updateAge()}
      dropdownRender={(close) => <AgeModal close={close} />}
      trigger={
        <Button data-cy="age.button">
          {userStore.user &&
          userStore.user.profile &&
          userStore.user.profile.age
            ? ageRender(userStore.user.profile.age)
            : t("all:profile_placeholder_no")}
        </Button>
      }
    />
  );
});

export default AgeEditable;
