import React from "react";
import { motion } from "framer-motion";

const variants = {
  slide: {
    y: 0,
    opacity: 1,
  },
  unslide: {
    y: 100,
    opacity: 0,
  },
};

export const AnimatedWrapper = React.forwardRef((props: any, ref) => {
  const { open, children, id, ...passthrough } = props;

  return (
    <motion.div
      animate={open ? "slide" : "unslide"}
      variants={variants}
      ref={ref}
      {...passthrough}
    >
      {children}
    </motion.div>
  );
});
