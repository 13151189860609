import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Option = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Option}>
    {({ color }) => (
      <>
        <rect width="32" height="32" fill="none" opacity="0.32" />
        <g transform="translate(3.4 3.3)">
          <g>
            <path
              d="M16.3,25.2H6.9A6.338,6.338,0,0,1,.6,18.9V9.8A5.956,5.956,0,0,1,4.2,4.3,6.264,6.264,0,0,1,9.8.7H19A6.58,6.58,0,0,1,25.5,7v9a6.264,6.264,0,0,1-3.6,5.6A6.377,6.377,0,0,1,16.3,25.2ZM7,5A4.6,4.6,0,0,0,2.3,9.8v9.3a4.739,4.739,0,0,0,4.8,4.8h9.1A4.888,4.888,0,0,0,21,19V9.8A4.738,4.738,0,0,0,16.2,5ZM7,3.6h9.2a6.338,6.338,0,0,1,6.3,6.3v9.8a4.675,4.675,0,0,0,1.3-3.4V6.9A4.739,4.739,0,0,0,19,2.1H10A4.706,4.706,0,0,0,6.7,3.5.367.367,0,0,0,7,3.6Z"
              fill="#fff"
            />
          </g>
        </g>
      </>
    )}
  </CreateSvgIcon>
);
