import React from "react";

interface MediumBubbleThirdProps {
  className?: string;
}

const MediumBubbleThird = ({ className }: MediumBubbleThirdProps) => {
  return (
    <svg
      width="38"
      height="42"
      viewBox="0 0 38 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity="0.434"
        d="M25.6 41C28.7 41.4 31.8 39.6 34.1 36.2C37.8 30.7 38.4 22.6 37.3 19.5C36.3 17 34.8 14.7 32.8 12.9C29.8 9.90003 13.3 -2.60002 5.00001 0.59998C2.00001 1.69998 -0.499966 4.90002 0.400034 12.7C1.00003 17.6 4 22.6 7.8 27.2C12.8 33 21.5 40.5 25.6 41Z"
        fill="#FDF8DD"
      />
    </svg>
  );
};

export default MediumBubbleThird;
