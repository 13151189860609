import React, { useCallback } from "react";
import { makeStyles } from "hooks/makeStyles";
import { Typography } from "ui/shared";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Like } from "lib";
import { daysTranslatedLabel, hoursTranslatedLabel } from "utils/strings";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
    boxSizing: "border-box",
    backgroundColor: theme.colors.background.paper.topbar,
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.colors.background.paper.main}`,
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  textContainer: { paddingRight: theme.spacing(3), flexGrow: 1 },
  between: {
    justifyContent: "space-between",
  },
  flex: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  padded: {
    marginTop: theme.spacing(2),
  },
}));

export interface KarmaHistoryItemProps {
  like: Like;
}

export const KarmaHistoryItem = (props: KarmaHistoryItemProps) => {
  const { login, description, createdAt, value, reason, deadTime } = props.like;
  const { t, i18n } = useTranslation("all");
  const css = useStyles();

  /**************************************************/

  const generateDescription = useCallback(() => {
    const like = value > 0 && !reason ? "👍" : value <= 0 ? "👎" : "";
    const translatedReason = reason ? `${t(`all:${reason}`)}.` : "";
    const desc = description ? description : "";

    return `${like} ${translatedReason} ${desc}`;
  }, [value, reason, description]);

  const calculateDaysAndHours = useCallback(() => {
    if (!deadTime) return;
    const now = Date.now();

    // get total seconds between the times
    let delta = Math.abs(deadTime.getTime() - now) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    return {
      days: { value: days, label: daysTranslatedLabel(i18n.language, days) },
      hours: {
        value: hours,
        label: hoursTranslatedLabel(i18n.language, hours),
      },
    };
  }, [deadTime, i18n.language]);

  /**************************************************/

  const destroyAfter = deadTime && calculateDaysAndHours();

  return (
    <div className={css.root}>
      <div className={clsx(css.container, css.textContainer)}>
        <Typography size="subtitle2(16px)" data-cy="karma.history.login">
          {login}
        </Typography>
        {deadTime ? (
          <Typography size="caption(12px)" color="secondary">
            {t("all:anonym")}
          </Typography>
        ) : null}
        {!reason && !description ? null : (
          <Typography
            size="caption(12px)"
            font="light"
            className={css.padded}
            data-cy="karma.history.reason"
          >
            {generateDescription()}
          </Typography>
        )}
        <Typography
          size="caption(12px)"
          font="light"
          color="disabled"
          className={css.padded}
          data-cy="karma.history.reason"
        >
          {deadTime && destroyAfter
            ? t("all:karma_like_death_time")
                .replace(
                  /\$1/,
                  `${destroyAfter.days.value} ${destroyAfter.days.label}`
                )
                .replace(
                  /\$2/,
                  `${destroyAfter.hours.value} ${destroyAfter.hours.label}`
                )
            : null}
        </Typography>
      </div>
      <div className={clsx(css.container, css.between)}>
        <div className={css.flex}>
          {value ? (
            <Typography
              size="subtitle2(16px)"
              align="right"
              data-cy="karma.history.likeValue"
            >
              {value > 0 ? `+${value}` : value}
            </Typography>
          ) : null}
        </div>
        <Typography
          size="tiny(10px)"
          font="light"
          align="right"
          color="secondary"
          data-cy="karma.history.date"
        >
          {moment(createdAt).format("DD.MM.YYYY")}
        </Typography>
      </div>
    </div>
  );
};
