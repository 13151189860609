import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, DefaultPanel, Modal, Typography } from "ui/shared";
import {
  EditableContainer,
  wrappedLabel,
} from "../../shared/editable/editable";
import { profileStore } from "./store.profile";
import { userStore } from "global-stores/user-store";
import { Box } from "../../shared/box/box";
import { getLanguageLabels } from "utils";
import { makeStyles } from "hooks/makeStyles";
import { Languages, LanguagesHeader } from "./modals/languages";
import { langs } from "lib";
import { MODALS } from "lib/constants";
import { modalManager } from "global-stores/modal-manager";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  languagesPanel: {
    extend: "interestsPanel",
    height: "auto",
    maxHeight: "60%",
    "&:focus": {
      outline: 0,
    },
  },
}));

interface LanguageEditableProps {
  profile?: any;
}

const LanguageEditable = observer((props: LanguageEditableProps) => {
  const { t } = useTranslation("all");
  const css = useStyles();

  const { profile } = props;

  /**************************************************/

  const openLanguagesModal = useCallback(() => {
    profileStore.setLanguages(
      (profile && profile.language) || langs.map((lang) => lang.code)
    );
    modalManager.open(MODALS.PROFILE_LANGUAGES);
  }, [profile, profile.language]);

  const handleCloseLanguagesModal = useCallback(() => {
    userStore.setProfileValue("language", profileStore.languages);
  }, []);

  const handleClearLanguages = useCallback(() => {
    if (profileStore.languages.length === langs.length) {
      profileStore.setLanguages([]);
    } else {
      profileStore.setLanguages(langs.map((lang) => lang.code));
    }
  }, []);

  const handleSelectLanguage = useCallback(
    (e: React.MouseEvent, item: string) => {
      if (profileStore.languages.includes(item)) {
        profileStore.setLanguages(
          profileStore.languages.filter((lang: string) => lang !== item)
        );
      } else {
        profileStore.setLanguages([...profileStore.languages, item]);
      }
    },
    []
  );

  /**************************************************/

  return (
    <Box mb={6}>
      {wrappedLabel(
        <Typography size="caption(12px)" color="secondary" lowercase>
          {t("all:profile_langs")}:
        </Typography>
      )}
      <EditableContainer>
        <Button
          onClick={openLanguagesModal}
          data-cy="profile.actions.languages"
        >
          <Typography data-cy="profile.values.languages">
            {getLanguageLabels((profile && profile.language) || [])}
          </Typography>
        </Button>
      </EditableContainer>
      <Modal
        modalId={MODALS.PROFILE_LANGUAGES}
        onClose={handleCloseLanguagesModal}
      >
        {(visible, className) => (
          <DefaultPanel
            header={
              <LanguagesHeader
                selected={profileStore.languages}
                max={langs.length}
                onClear={handleClearLanguages}
              />
            }
            className={clsx(className, css.languagesPanel)}
          >
            <Languages
              selected={profileStore.languages}
              onSelectLanguage={handleSelectLanguage}
            />
          </DefaultPanel>
        )}
      </Modal>
    </Box>
  );
});

export default LanguageEditable;
