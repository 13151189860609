import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Official = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Official}>
    {({ color }) => (
      <>
        <path
          d="M16.7 32l-.4-.3c-1.1-.7-2.1-1.6-3.1-2.6-1.4.5-2.8.8-4.2 1h-.4l-.2-.4c-.6-1.2-1-2.5-1.3-3.8-1.5-.1-2.9-.6-4.2-1.2l-.4-.2v-.4c.1-1.3.4-2.7.7-3.9-1.1-.9-2.2-2-3-3.1l-.2-.4.2-.4c.7-1.1 1.6-2.1 2.6-3.1-.5-1.3-.8-2.7-.9-4.1l-.1-.4.4-.2c1.2-.6 2.5-1 3.8-1.3.2-1.5.7-2.9 1.2-4.2l.2-.4h.4c1.3.1 2.7.4 3.9.7.9-1.1 1.9-2.1 3.1-2.9l.6-.4.4.2c1.1.7 2.1 1.6 3.1 2.6 1.4-.5 2.8-.8 4.2-1h.4l.2.4c.6 1.2 1 2.5 1.3 3.8 1.4.2 2.8.7 4.2 1.2l.4.2v.4c-.1 1.3-.4 2.7-.7 3.9 1.1.9 2.1 2 2.9 3.1l.3.4-.2.4c-.7 1.1-1.6 2.1-2.6 3.1.5 1.4.8 2.8 1 4.1v.4l-.4.2c-1.2.6-2.5 1-3.8 1.3-.2 1.4-.7 2.8-1.2 4.1l-.2.4h-.4c-1.3-.1-2.7-.4-3.9-.7-.9 1.1-1.9 2.2-3 3l-.7.5z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#fff"
        />
        <path
          fill="#394b58"
          d="M25.6 11.8l-1.7-1.6-9.5 9.2-5.2-5L7.6 16l6.8 6.6v-.1z"
        />
      </>
    )}
  </CreateSvgIcon>
);
