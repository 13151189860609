import React, { useCallback, useRef, useState } from "react";
import {
  OverlayPositioner,
  OverlayPositionerProps,
} from "../dropdown/overlay-positioner";
import { Typography } from "../typography/typography";
import uniqueId from "lodash/uniqueId";
import noop from "lodash/noop";
import { makeStyles } from "hooks/makeStyles";
import clsx from "clsx";

interface TooltipProps extends Partial<OverlayPositionerProps> {
  title?: React.ReactNode;
  multiline?: boolean;
  children: React.ReactNode;
  renderText?: () => React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  tooltipRootContainer: {
    position: "relative",
  },
  tooltipContainer: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    borderRadius: 10,
    backgroundColor: theme.colors.background.paper.lighter,
  },
  singleLine: {
    whiteSpace: "nowrap",
  },
  multiline: {
    whiteSpace: "unset",
    minWidth: 200,
  },
}));

export const Tooltip = ({
  title,
  multiline,
  children,
  renderText,
  ...overlayProps
}: TooltipProps) => {
  const [open, setOpen] = useState(false);
  const overlayRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const firstChild = React.Children.toArray(
    children
  )[0] as React.ReactElement<any>;

  const css = useStyles();

  const overlayId = uniqueId("overlay-");

  /**************************************************/

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  /**************************************************/

  return (
    <div className={css.tooltipRootContainer}>
      {React.cloneElement(firstChild, { ref: triggerRef })}
      <OverlayPositioner
        preferCenter
        preferTop
        trigger={triggerRef}
        open={handleOpen}
        close={handleClose}
        visible={open}
        overlayId={overlayId}
        onOverlayKeyDown={noop}
        onTriggerKeyUp={noop}
        onOverlayBlur={noop}
        on="hover"
        role="tooltip"
        ref={overlayRef}
        {...overlayProps}
      >
        <div className={css.tooltipContainer}>
          {title ? (
            <Typography
              size="caption(12px)"
              align="center"
              className={clsx({
                [css.singleLine]: !multiline,
                [css.multiline]: multiline,
              })}
            >
              {title}
            </Typography>
          ) : renderText ? (
            renderText()
          ) : null}
        </div>
      </OverlayPositioner>
    </div>
  );
};
