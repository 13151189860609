import React, { useCallback } from "react";
import { makeStyles } from "hooks/makeStyles";
import {
  Icons,
  IconButton,
  Typography,
  Dropdown,
  Button,
  Modal,
  DefaultPanel,
  Skeleton,
} from "ui/shared";
import { ProfileModal } from "../chats/modals/profile-modal";
import { DeleteDialogModal } from "../chats/modals/delete-dialog-modal";
import { Friend } from "lib";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { chatsStore } from "global-stores/chats-store";
import { deleteDialogStore } from "ui/pages/chats/stores/delete-dialog-store";
import clsx from "clsx";
import { MODALS } from "lib/constants";
import { modalManager } from "global-stores/modal-manager";
import { observer } from "mobx-react";

export interface ChatTopBarProps {
  friend: Friend;
  loading?: boolean;
}

interface ChatAction {
  label: string;
  onClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.colors.background.paper.darker,
    boxSizing: "border-box",
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    flexShrink: 0,
  },
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  topbarUserInfo: {
    textTransform: "none",
    backgroundColor: "transparent",
  },
  side: {
    width: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  overlay: {
    width: "auto",
    minWidth: 240,
    backgroundColor: theme.colors.background.paper.topbar,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    overflow: "hidden",
    marginTop: 14,
    marginRight: -12,
    boxShadow: "0px 3px 6px #00000029",
  },
  dropdownButton: {
    border: "none",
    width: "100%",
    whiteSpace: "nowrap",
    borderRadius: 0,
    color: theme.colors.common.white,
    "&:hover": {
      backgroundColor: `${theme.colors.background.paper.lighter} !important`,
      color: `${theme.colors.textPrimary} !important`,
    },
    "&:focus": {
      backgroundColor: theme.colors.background.paper.topbar,
      color: theme.colors.common.white,
    },
  },
  forceFocus: {
    "&:focus": {
      backgroundColor: theme.colors.purple.main,
      color: theme.colors.textPrimary,
    },
  },
  chatDeleteModal: {
    minWidth: 272,
  },
  profileModal: {
    maxWidth: 353,
  },
}));

export const ChatTopBar = observer((props: ChatTopBarProps) => {
  const { friend, loading } = props;
  const css = useStyles();
  const { t } = useTranslation("all");

  /**************************************************/

  const getChatActions = useCallback(() => {
    let actions: {
      label: string;
      onClick: () => void;
      icon?: React.ReactNode;
    }[] = [
      {
        label: t("all:friend_dialog_clear_dialog"),
        onClick: () => chatsStore.clearHistory(friend._id),
      },
      {
        label: t("all:friend_dialog_delete"),
        onClick: () => {
          if (friend.finish === "deleted") {
            deleteDialogStore.delete(friend._id);
          } else {
            modalManager.open(MODALS.CHAT_DELETE_DIALOG);
          }
        },
      },
    ];

    if (!friend.friend && friend.finish === "") {
      actions.splice(1, 0, {
        label: t("all:friend_dialog_leave"),
        onClick: () => chatsStore.leave(friend._id),
      });
    }
    if (
      !friend.friend &&
      (friend.finish.length === 0 ||
        friend.finish === "leave" ||
        friend.finish === "kick")
    ) {
      if (friend.friendship) {
        actions.unshift({
          label: t("all:friend_dialog_accept_friend"),
          onClick: () => chatsStore.sendFriendRequest(friend._id),
        });
      } else if (friend.save !== 2) {
        actions.unshift({
          label: t("all:friend_dialog_send_friend"),
          onClick: () => chatsStore.sendFriendRequest(friend._id),
        });
      }
    }
    if (friend.finish === "") {
      actions.push({
        label: t("all:trans_settings_title"),
        icon: <Icons.Translator width={24} height={24} />,
        onClick: () => {
          modalManager.open(MODALS.TRANSLATE_SETTINGS);
        },
      });
    }

    return actions;
  }, [friend, t]);

  const handleOpenProfile = useCallback(() => {
    modalManager.open(MODALS.CHAT_PARTNER_PROFILE);
  }, []);

  const renderLogin = useCallback(() => {
    return friend.country ? (
      <>
        {friend.country} {friend.friendLogin}
      </>
    ) : (
      friend.friendLogin
    );
  }, [friend]);

  if (loading) {
    return (
      <div className={css.root}>
        <div className={css.side}></div>
        <div className={css.main} style={{ width: 150 }}>
          <Skeleton.Line />
          <Skeleton.Line size="small" />
        </div>
        <div className={css.side}></div>
      </div>
    );
  }

  /**************************************************/

  const actions = getChatActions();

  return (
    <div className={css.root}>
      <div className={css.side}></div>
      <Button
        onClick={handleOpenProfile}
        className={css.topbarUserInfo}
        data-cy="chat-topbar-friend-username-button"
      >
        <div className={css.main}>
          <Typography font="light" data-cy="chat-topbar-friend-username">
            {renderLogin()}
          </Typography>
          <Typography
            size="tiny(10px)"
            color={
              friend.online || friend.friendLogin === "ODI.chat"
                ? "yellow"
                : "disabled"
            }
          >
            {friend.online || friend.friendLogin === "ODI.chat"
              ? t("all:friend_online")
              : moment(friend.lastActivity.date).fromNow()}
          </Typography>
        </div>
      </Button>
      <div className={css.side}>
        <Dropdown
          items={actions}
          renderItem={(item, index, forceFocus) => (
            <Button
              key={item.label}
              className={clsx(css.dropdownButton, {
                [css.forceFocus]: forceFocus,
              })}
              onClick={item.onClick}
              leftIcon={item.icon}
              data-cy={`chat-topbar-options-item-${index}`}
            >
              {item.label}
            </Button>
          )}
          overlayClassName={css.overlay}
        >
          <IconButton data-cy="chat-topbar-options">
            <Icons.Dots />
          </IconButton>
        </Dropdown>
      </div>
      <Modal modalId={MODALS.CHAT_PARTNER_PROFILE}>
        {(visible, className) => (
          <DefaultPanel
            title={t("all:friend_profile_titile")}
            className={clsx(className, css.profileModal)}
          >
            <ProfileModal friend={friend} visible={visible} />
          </DefaultPanel>
        )}
      </Modal>
      <Modal
        modalId={MODALS.CHAT_DELETE_DIALOG}
        onClose={() => {
          deleteDialogStore.setIsOpenDislike(false);
          deleteDialogStore.clear();
        }}
      >
        {(_, className) => (
          <DefaultPanel
            title={t("all:friend_dialog_delete_title")}
            className={clsx(className, css.chatDeleteModal)}
          >
            <DeleteDialogModal friend={friend} />
          </DefaultPanel>
        )}
      </Modal>
    </div>
  );
});
