import React, { useEffect, useState, HTMLAttributes, useCallback } from "react";
import uniqueId from "lodash/uniqueId";
import { makeStyles } from "hooks/makeStyles";
import { Portal } from "./portal";
import { BackDrop } from "./backdrop";
import { TrapFocus } from "./trap-focus";
import { modalManager } from "../../../global-stores/modal-manager";
import clsx from "clsx";
import { observer } from "mobx-react";
import { AnimatedWrapper } from "./animated";
import { IconButton, Icons } from "ui/shared";

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  modalId: string;
  onClose?: (e?: React.MouseEvent | React.KeyboardEvent) => void;
  onBackdropClick?: (e: React.MouseEvent) => void;
  invisibleBackdrop?: boolean;
  children: (
    visible: boolean,
    defaultPanelClassName: string
  ) => React.ReactNode;
  withCloseButton?: boolean;
  onCrossClick?: (e: React.MouseEvent) => void;
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1200,
  },
  modalContainer: {
    width: "100%",
    height: "100%",
  },
  hidden: {
    display: "none",
  },
  relative: {
    position: "relative",
  },
  cross: {
    position: "absolute",
    top: -28,
    right: -28,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  defaultPanel: {
    width: "auto",
    minWidth: 320,
    maxHeight: "80vh",
  },
  noOutline: {
    "&:focus": {
      outline: 0,
    },
  },
  animatedContainer: {
    maxWidth: "80%",
  },
}));

export const Modal = observer((props: ModalProps) => {
  const [visible, setVisible] = useState(false);

  const {
    modalId,
    onClose,
    onBackdropClick,
    invisibleBackdrop,
    withCloseButton = false,
    onCrossClick,
    children,
    ...passthrough
  } = props;
  const css = useStyles();

  const isOpen = !!modalManager.opened[modalId];

  /**************************************************/

  const handleOpen = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const handleClose = useCallback(() => {
    modalManager.close(modalId);
    setVisible(false);
  }, [modalId, setVisible]);

  const handleBackdropClick = useCallback(
    (e: React.MouseEvent) => {
      if (e.target !== e.currentTarget) {
        return;
      }

      if (onBackdropClick) {
        onBackdropClick(e);
      }

      if (onClose) {
        onClose(e);
      }
      handleClose();
    },
    [onBackdropClick, onClose, handleClose]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key !== "Escape" || !modalManager.isTopModal(modalId)) {
        return;
      }

      e.stopPropagation();

      if (onClose) {
        onClose(e);
      }
      handleClose();
    },
    [onClose, handleClose]
  );

  const handleCrossClick = useCallback(
    (e: React.MouseEvent) => {
      if (onCrossClick) {
        onCrossClick(e);
      }

      if (onClose) {
        onClose(e);
      }
      handleClose();
    },
    [onCrossClick, onClose, handleClose]
  );

  /**************************************************/

  useEffect(() => {
    if (isOpen) {
      handleOpen();
    }

    return () => {
      if (isOpen) {
        handleClose();
      }
    };
  }, [isOpen, modalId]);

  const cx = clsx(css.dialog, {
    [css.hidden]: !isOpen,
  });

  return (
    <Portal>
      <div
        key={modalId}
        role="dialog"
        aria-modal
        onKeyDown={handleKeyDown}
        className={cx}
        style={{
          zIndex:
            99 + modalManager.modals.findIndex((modal) => modal === modalId),
        }}
      >
        <BackDrop
          open={visible}
          onClick={handleBackdropClick}
          invisible={invisibleBackdrop}
        />
        <TrapFocus
          key="trap"
          open={isOpen}
          isActive={modalManager.isTopModal(modalId)}
        >
          <AnimatedWrapper
            open={visible}
            id={modalId}
            {...passthrough}
            className={clsx(css.relative, css.noOutline, css.animatedContainer)}
          >
            <>
              {withCloseButton ? (
                <IconButton
                  className={css.cross}
                  onClick={handleCrossClick}
                  data-cy="modal.closeButton"
                >
                  <Icons.Cross height={20} width={20} />
                </IconButton>
              ) : null}
              {children(visible, clsx(css.defaultPanel, css.noOutline))}
            </>
          </AnimatedWrapper>
        </TrapFocus>
      </div>
    </Portal>
  );
});
