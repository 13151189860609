import React from "react";
import { makeStyles } from "hooks/makeStyles";
import { Icons } from "../icons";
import { Typography } from "../typography/typography";

export interface KarmaProps {
  value: number;
  gray?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    top: -2,
  },
  value: {
    marginTop: -5,
    marginLeft: -2,
  },
}));

export const Karma = (props: KarmaProps) => {
  const { value, gray } = props;
  const css = useStyles();

  return (
    <div className={css.root}>
      <Icons.Karma gray={gray} height={34} />
      <Typography size="caption(12px)" font="medium" className={css.value}>
        {value}
      </Typography>
    </div>
  );
};
