import React from "react";
import { makeStyles } from "hooks/makeStyles";
import clsx from "clsx";
import { motion } from "framer-motion";

interface BackDropProps {
  open: boolean;
  invisible?: boolean;
  onClick: (e: React.MouseEvent) => void;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -1,
    touchAction: "none",
    backgroundColor: theme.colors.backdrop,
    // backdropFilter: "blur(2px)"
  },
  invisible: {
    backgroundColor: "transparent",
  },
}));

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

export const BackDrop = ({ open, invisible, onClick }: BackDropProps) => {
  const css = useStyles();
  const cx = clsx(css.backdrop, {
    [css.invisible]: invisible,
  });

  return (
    <motion.div
      animate={open ? "visible" : "hidden"}
      variants={variants}
      onClick={onClick}
      className={cx}
    />
  );
};
