import clsx from "clsx";
import { makeStyles } from "hooks/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";
import { centered, spacedContainer } from "styles";
import { Box, Button, Typography } from "ui/shared";
import { VIPOption } from "lib/common-interfaces/types";
import { euroPrice, getCurrencySymbol } from "utils/price";

const useStyles = makeStyles((theme) => ({
  vipOptionCardContainer: {
    position: "relative",
    backgroundColor: theme.colors.background.paper.lighter,
    borderRadius: 23,
    padding: theme.spacing(2),
    "&:not(:last-child)": {
      marginRight: theme.spacing(4),
    },
    paddingTop: theme.spacing(5),
    flex: 1,
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  ...centered(),
  ...spacedContainer(theme),
  spaced: {
    marginBottom: theme.spacing(3),
  },
  chooseButton: {
    height: 46,
    backgroundColor: theme.colors.common.white,
    color: theme.colors.background.paper.darker,
    "&:hover, &:active, &:focus": {
      backgroundColor: theme.colors.common.white,
      boxShadow: "0px 3px 6px #00000029",
    },
  },
  oldPrice: {
    color: "#DBCCED",
    textDecoration: "line-through",
  },
  sale: {
    width: 42,
    height: 42,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#C93D27",
    position: "absolute",
    top: 6,
    right: 6,
  },
  active: {
    backgroundColor: theme.colors.yellow.main,
    flex: 1.1,
  },
}));

interface VipOptionCardProps {
  option: VIPOption;
  onClick: (option: VIPOption) => void;
}

const VipOptionCard = (props: VipOptionCardProps) => {
  const css = useStyles();
  const { t } = useTranslation("all");

  const { duration, price, oldPrice, payPrice, sale, active, currency } =
    props.option;

  return (
    <Box
      className={clsx(css.vipOptionCardContainer, {
        [css.active]: active,
      })}
    >
      {sale ? (
        <Box className={css.sale}>
          <Typography size="body(14px)">{sale}%</Typography>
        </Box>
      ) : null}
      <Typography size="subtitle2(16px)" align="center" className={css.spaced}>
        {t(`all:${duration}`)}
      </Typography>
      <Box className={clsx(css.flex, css.centered, css.spaced)}>
        <Typography size="h2(23px)">{euroPrice(price)}</Typography>
        <Typography size="h3(20px)">
          {getCurrencySymbol(currency)} {t("all:pay_per_month")}
        </Typography>
      </Box>
      <Box
        className={clsx(
          css.flex,
          css.centered,
          css.spaced,
          css.spacedContainer
        )}
      >
        <Typography size="subtitle2(16px)">
          {t("all:pay_full_price")}
        </Typography>
        {sale ? (
          <Typography size="subtitle2(16px)" className={css.oldPrice}>
            {euroPrice(oldPrice)} {getCurrencySymbol(currency)}
          </Typography>
        ) : null}
        <Typography size="subtitle2(16px)">
          {euroPrice(payPrice)} {getCurrencySymbol(currency)}
        </Typography>
      </Box>
      <Button
        fullWidth
        className={css.chooseButton}
        onClick={() => props.onClick(props.option)}
      >
        {t("all:pay_i_want")}
      </Button>
    </Box>
  );
};

export default VipOptionCard;
