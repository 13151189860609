import { makeStyles } from "hooks/makeStyles";
import React, { useCallback, useContext } from "react";
import { Dropdown, IconButton, Icons } from "ui/shared";
import { ChatContext } from "../chats";
import { GifPicker } from "../gif-picker";

interface GIFDropdownProps {
  onClose: () => void;
  onSend?: () => void;
  panelHeight?: number;
  isTranslatePanelShown: boolean;
}

const useStyles = makeStyles((theme) => ({
  giphy: {
    position: "relative",
    margin: `0 ${theme.spacing(4)}`,
  },
}));

const GIFDropdown = React.memo((props: GIFDropdownProps) => {
  const css = useStyles();
  const { onClose, onSend, panelHeight, isTranslatePanelShown } = props;
  const chatContext = useContext(ChatContext);

  const renderGiphy = useCallback(
    (close) => {
      if (!chatContext) return null;

      return (
        <GifPicker
          height={chatContext.height}
          width={chatContext.width}
          onClose={close}
          onSend={onSend}
          panelHeight={panelHeight}
          isTranslatePanelShown={isTranslatePanelShown}
        />
      );
    },
    [chatContext, onClose, onSend, panelHeight, isTranslatePanelShown]
  );

  return (
    <Dropdown
      preferTop
      preferRight
      render={renderGiphy}
      onClose={onClose}
      useVisibility={true}
      className={css.giphy}
    >
      <IconButton data-cy="chat-action-panel-giphy-button">
        <Icons.Gif />
      </IconButton>
    </Dropdown>
  );
});

export default GIFDropdown;
