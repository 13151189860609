import React from "react";
import { makeStyles } from "hooks/makeStyles";
import { Button, Icons } from "ui/shared";
import clsx from "clsx";

interface ImportAccountDialogProps {
  handleImportProfileFromWebcam: (e: React.MouseEvent) => void;
  handleImportProfileFromImage: (e: React.MouseEvent) => void;
  webcamLabel: string;
  imageLabel: string;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
  },
  button: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 160,
    height: 160,
    backgroundColor: theme.colors.background.paper.lighter,
    borderRadius: 10,
    padding: theme.spacing(4),
    border: "none",
    cursor: "pointer",
    "&:first-child": {
      marginRight: theme.spacing(2),
    },
  },
  icon: {
    marginBottom: theme.spacing(4),
  },
}));

export const ImportAccountDialog = (props: ImportAccountDialogProps) => {
  const {
    handleImportProfileFromWebcam,
    handleImportProfileFromImage,
    webcamLabel,
    imageLabel,
    className,
  } = props;
  const css = useStyles();

  return (
    <div className={clsx(css.flex, className)}>
      <Button
        onClick={handleImportProfileFromImage}
        className={css.button}
        data-cy="login.import.modal.fromImage"
      >
        <Icons.Folder width={60} height={60} className={css.icon} />
        {imageLabel}
      </Button>
      <Button
        onClick={handleImportProfileFromWebcam}
        className={css.button}
        data-cy="login.import.modal.fromWebcam"
      >
        <Icons.Photo width={60} height={60} className={css.icon} />
        {webcamLabel}
      </Button>
    </div>
  );
};
