import React from "react";
import { makeStyles } from "hooks/makeStyles";
import { Typography, Icons, IconButton } from "ui/shared";
import { InterestItem } from "ui/pages/search/interest-item";
import { useTranslation } from "react-i18next";
import { interests } from "lib";
import { i18n } from "services/i18n";

export interface InterestsHeaderProps {
  selected: string[];
  onClear: (e: React.MouseEvent) => void;
  onRevert: (e: React.MouseEvent) => void;
  localInterests: string[];
  limit: number;
}

export interface InterestsProps {
  selected: string[];
  onSelectInterest: (e: React.MouseEvent, item: string) => void;
}

const useStyles = makeStyles((theme) => ({
  interestsHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
    backgroundColor: theme.colors.background.paper.lighter,
  },
  headerItem: {
    width: 40,
  },
  emptyTrash: {
    width: 32,
    height: 32,
  },
}));

export const InterestsHeader = (props: InterestsHeaderProps) => {
  const { selected, onClear, onRevert, localInterests, limit } = props;
  const { t } = useTranslation("search");
  const css = useStyles();

  return (
    <div className={css.interestsHeader}>
      <Typography
        className={css.headerItem}
        data-cy="interests.header.countLabel"
      >
        {selected.length}/{limit === 0 ? "∞" : limit}
      </Typography>
      <Typography size="subtitle1(18px)" align="center" uppercase>
        {t("all:profile_interests_title")}
      </Typography>
      {localInterests.length ? (
        <IconButton onClick={onRevert}>
          <Icons.RevertArrow />
        </IconButton>
      ) : selected.length ? (
        <IconButton onClick={onClear} data-cy="interests.header.clearButton">
          <Icons.Trash />
        </IconButton>
      ) : (
        <div className={css.emptyTrash} />
      )}
    </div>
  );
};

export const Interests = (props: InterestsProps) => {
  const { selected, onSelectInterest } = props;

  return (
    <>
      {interests.map((item) => (
        <InterestItem
          key={item.id}
          item={item.id}
          checked={selected.includes(item.id)}
          label={item[i18n.language.split("-")[0]] + " " + item["emoji"]}
          onClick={(e, value) => {
            onSelectInterest(e, value);
          }}
          data-cy={`interests.items.item-${item.id}`}
        />
      ))}
    </>
  );
};
