import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const ActiveKarma = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.ActiveKarma}>
    {({ color }) => (
      <g>
        <g>
          <g>
            <path
              d="M17.35,21.69a8.93,8.93,0,0,1-1.23-.08,10.64,10.64,0,0,1-.5-1.86A6.53,6.53,0,0,1,15.5,18,4.2,4.2,0,0,1,16,16.25a8,8,0,0,1,2.27-2.46A11.52,11.52,0,0,1,21,12.39a14.72,14.72,0,0,1,3.13-.75,14.72,14.72,0,0,1,0,3.26,12.09,12.09,0,0,1-.78,3,7,7,0,0,1-1.93,2.7,4.34,4.34,0,0,1-1.91.87A9.54,9.54,0,0,1,17.35,21.69Z"
              fill="#cfab00"
            />
            <path
              d="M17.35,21.49a10.23,10.23,0,0,0,2-.19,4.42,4.42,0,0,0,1.84-.82,7.2,7.2,0,0,0,1.88-2.63,11.58,11.58,0,0,0,.77-3,15.7,15.7,0,0,0,.08-3,14.51,14.51,0,0,0-2.93.72A11.41,11.41,0,0,0,18.32,14a7.18,7.18,0,0,0-2.22,2.39A4.06,4.06,0,0,0,15.66,18a7.15,7.15,0,0,0,.13,1.74,15.23,15.23,0,0,0,.45,1.73,10,10,0,0,0,1.11,0m0,.36A12.68,12.68,0,0,1,16,21.78s-1.43-3.45-.22-5.6c2.39-4.22,8.5-4.73,8.5-4.73s.82,6.16-2.8,9.29A6.6,6.6,0,0,1,17.35,21.85Z"
              fill="#fff"
            />
          </g>
          <g>
            <path
              d="M14.42,21.69h0a10.8,10.8,0,0,1-2.08-.2,4,4,0,0,1-1.9-.87,7.62,7.62,0,0,1-1.93-2.7,10.75,10.75,0,0,1-.8-3,15.39,15.39,0,0,1-.07-3.26,14.76,14.76,0,0,1,3.14.75,12.18,12.18,0,0,1,2.77,1.4,7.41,7.41,0,0,1,2.27,2.48A3.94,3.94,0,0,1,16.28,18a7.78,7.78,0,0,1-.12,1.76,12.5,12.5,0,0,1-.51,1.86A8.67,8.67,0,0,1,14.42,21.69Z"
              fill="#cfab00"
            />
            <path
              d="M14.42,21.49a9.84,9.84,0,0,0,1.11,0A8.87,8.87,0,0,0,16,19.73,7.5,7.5,0,0,0,16.11,18a4,4,0,0,0-.43-1.62h0A7.37,7.37,0,0,0,13.46,14a10.9,10.9,0,0,0-2.73-1.37,15.17,15.17,0,0,0-2.92-.73,13.81,13.81,0,0,0,.09,3,11.61,11.61,0,0,0,.78,2.94,6.9,6.9,0,0,0,1.88,2.63,4,4,0,0,0,1.83.82,12.42,12.42,0,0,0,2,.17h0m0,.36a6.62,6.62,0,0,1-4.1-1.11c-3.65-3.13-2.83-9.29-2.83-9.29s6.13.48,8.52,4.76c1.24,2.14-.21,5.57-.21,5.57A12.76,12.76,0,0,1,14.42,21.85Z"
              fill="#fff"
            />
          </g>
          <g>
            <path
              d="M15.89,22.9a13.85,13.85,0,0,1-1.81-1.31,9.17,9.17,0,0,1-1.4-1.49,4.79,4.79,0,0,1-.82-1.86h0a8.88,8.88,0,0,1,.27-3.87,12.94,12.94,0,0,1,1.47-3.25,16,16,0,0,1,2.29-2.95,17.12,17.12,0,0,1,2.3,3,12.66,12.66,0,0,1,1.44,3.26,8.28,8.28,0,0,1,.22,3.84A4.53,4.53,0,0,1,19,20.05a9.94,9.94,0,0,1-1.38,1.52C16.88,22.22,16.13,22.73,15.89,22.9Z"
              fill="#cfab00"
            />
            <path
              d="M15.89,22.68a15.07,15.07,0,0,0,1.67-1.28,6.71,6.71,0,0,0,2.15-3.21,8.42,8.42,0,0,0-.22-3.74,12.53,12.53,0,0,0-1.43-3.21,17.42,17.42,0,0,0-2.14-2.83,18.19,18.19,0,0,0-2.13,2.78,13,13,0,0,0-1.45,3.21,8.8,8.8,0,0,0-.26,3.79h0A4.52,4.52,0,0,0,12.85,20a7.91,7.91,0,0,0,1.35,1.47,12.34,12.34,0,0,0,1.69,1.23m0,.43s-3.76-2.05-4.2-4.87c-.92-5.53,4.2-10.36,4.2-10.36S21,12.81,20,18.24C19.56,20.87,15.89,23.11,15.89,23.11Z"
              fill="#fff"
            />
          </g>
          <g>
            <path
              d="M20.64,23.94a13.88,13.88,0,0,1-4.49-1,10.3,10.3,0,0,1,.12-1.91,7.22,7.22,0,0,1,.53-1.72,4,4,0,0,1,1.13-1.44,9.34,9.34,0,0,1,3.53-1.62,15.36,15.36,0,0,1,3.64-.41,19.85,19.85,0,0,1,3.5.31,13.91,13.91,0,0,1-1.25,3,11.18,11.18,0,0,1-2,2.56A8.46,8.46,0,0,1,22.3,23.7,5.17,5.17,0,0,1,20.64,23.94Z"
              fill="#cfab00"
            />
            <path
              d="M20.64,23.77a4.68,4.68,0,0,0,1.54-.22,8.25,8.25,0,0,0,3-1.88,10.93,10.93,0,0,0,1.93-2.51,13.5,13.5,0,0,0,1.21-2.8,20.33,20.33,0,0,0-3.26-.26,14.87,14.87,0,0,0-3.59.41,9.13,9.13,0,0,0-3.46,1.59h0A4,4,0,0,0,17,19.48a7.35,7.35,0,0,0-.51,1.64,9.2,9.2,0,0,0-.14,1.73,13.09,13.09,0,0,0,4.32.92m0,.36a13.89,13.89,0,0,1-4.66-1s-.29-3.7,1.83-5.31a11.93,11.93,0,0,1,7.27-2.08,18.77,18.77,0,0,1,3.72.36s-1.38,6-6.5,7.82A5.25,5.25,0,0,1,20.64,24.13Z"
              fill="#fff"
            />
          </g>
          <g>
            <path
              d="M11.38,23.94h0a4.76,4.76,0,0,1-1.62-.24,8.92,8.92,0,0,1-3.09-1.93,10.37,10.37,0,0,1-1.95-2.56,13.36,13.36,0,0,1-1.28-3,18.7,18.7,0,0,1,3.43-.29,16.08,16.08,0,0,1,3.66.41A9.17,9.17,0,0,1,14.08,18a4.16,4.16,0,0,1,1.14,1.45,6.45,6.45,0,0,1,.53,1.72A9.16,9.16,0,0,1,15.87,23c-.22.07-.72.26-1.38.46A12.71,12.71,0,0,1,11.38,23.94Z"
              fill="#cfab00"
            />
            <path
              d="M11.38,23.77a13.21,13.21,0,0,0,4.32-.92c0-.79,0-3.5-1.71-4.78h0a9.07,9.07,0,0,0-3.48-1.59,15.72,15.72,0,0,0-3.62-.41,19.62,19.62,0,0,0-3.21.27,14.48,14.48,0,0,0,1.21,2.8,11.15,11.15,0,0,0,1.93,2.51,8,8,0,0,0,3,1.88,3.94,3.94,0,0,0,1.52.24h0m0,.36a5.35,5.35,0,0,1-1.67-.24c-5.11-1.81-6.49-7.84-6.49-7.84a19.1,19.1,0,0,1,3.65-.34,12.21,12.21,0,0,1,7.33,2.07C16.33,19.43,16,23.1,16,23.1A14.12,14.12,0,0,1,11.38,24.13Z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    )}
  </CreateSvgIcon>
);
