import React, { useState, useEffect, useCallback } from "react";
import { useEmblaCarousel } from "embla-carousel/react";
import { DotButton } from "./embla-carousel-mini-thumb";
import "./embla-mini.css";
import { useTracker } from "hooks/useTracker";
import { Meteor } from "meteor/meteor";
import { Avatars } from "collections/avatars";
import { Avatar, TopUserAvatar } from "lib";
import { observer } from "mobx-react";
import { makeStyles } from "hooks/makeStyles";

export interface EmblaCarouselMiniProps {
  userId: string;
  profileAvatar: Avatar | TopUserAvatar;
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: "100%",
    height: 345,
    objectFit: "cover",
  },
}));

const EmblaCarouselMini = observer((props: EmblaCarouselMiniProps) => {
  const { userId, profileAvatar } = props;
  const css = useStyles();

  const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  const { myLoading, userAvatars, avatarsLength } = useTracker(() => {
    const handle = Meteor.subscribe("avatars.u", { userId });

    const avatars = Avatars.find(
      { userId },
      { sort: { updateAt: -1 } }
    ).fetch();

    return {
      myLoading: !handle.ready(),
      userAvatars: avatars,
      avatarsLength: avatars.length,
    };
  }, []);

  useEffect(() => {
    embla?.reInit();

    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [userAvatars, embla, setScrollSnaps, onSelect]);

  return (
    <>
      <div className="embla-mini">
        <div className="embla-mini__viewport" ref={viewportRef}>
          {userAvatars.length === 0 ? (
            <img
              className={css.avatar}
              src={profileAvatar.high || profileAvatar.low}
              alt="User avatar"
            />
          ) : (
            <div className="embla-mini__container">
              {userAvatars.map((avatar: Avatar) => (
                <div className="embla-mini__slide" key={avatar._id}>
                  <div
                    className="embla-mini__slide__inner"
                    style={{ width: "100%", height: 345 }}
                  >
                    <div className="embla__gradient-mini"></div>
                    <img
                      className="embla-mini__slide__img"
                      src={avatar.high || avatar.low}
                      alt="User avatar"
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {avatarsLength > 1 ? (
        <div className="embla-mini__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              selected={index === selectedIndex}
              width={avatarsLength}
            />
          ))}
        </div>
      ) : null}
    </>
  );
});

export default EmblaCarouselMini;
