import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Cross = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Cross}>
    {({ color }) => (
      <polygon
        points="28.36 5.16 27.29 4.08 16.35 15.02 5.26 4.08 4.18 5.16 15.12 16.25 4.18 27.18 5.26 28.26 16.35 17.32 27.29 28.26 28.36 27.18 17.43 16.25 28.36 5.16"
        fill={color}
      />
    )}
  </CreateSvgIcon>
);
