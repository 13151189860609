import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Search = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Apple}>
    {({ color }) => (
      <>
        <path
          d="M22.273,21.235l-6.087-6.086A7.534,7.534,0,0,0,18,10.1,7.9,7.9,0,1,0,10.1,18a8.251,8.251,0,0,0,5.18-1.942l6.087,6.086c.129.129.2.14.536.153s.458-.242.509-.471A.989.989,0,0,0,22.273,21.235ZM3.5,10.1a6.6,6.6,0,1,1,6.6,6.6A6.632,6.632,0,0,1,3.5,10.1Z"
          transform="translate(0.699 0.699)"
          fill="#bdc6cc"
        />
        <rect width="26" height="26" fill="none" />
      </>
    )}
  </CreateSvgIcon>
);
