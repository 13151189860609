import { observable, action } from "mobx";
import { Avatar, AvatarCategory, ToastType } from "lib";
import { Meteor } from "meteor/meteor";
import { encriptionStore } from "global-stores/encryption-store";
import { userStore } from "global-stores/user-store";
import { Random } from "meteor/random";
import { toastStore } from "global-stores/toasts-store";
import translateErrors from "utils/translate-errors";
import optimizeImage from "utils/resizer";
import configStore from "global-stores/config-store";

export class AvatarsStore {
  @observable loading: boolean = false;
  @observable classicAvatars: Avatar[] = [];
  @observable nefritAvatars: Avatar[] = [];
  @observable error?: string = "";
  @observable activeUpload?: {
    file: Blob;
    error?: Meteor.Error;
  };

  @observable activeTab: number = 1;

  @action
  setActiveTab(value: number) {
    this.activeTab = value;
  }

  @action getAvatars() {
    const self = this;
    self.loading = true;
    Meteor.call("avatars.get", (err: Meteor.Error, res: boolean) => {
      if (err) {
        console.log(err);
        self.error = err.message;
      }
      if (Array.isArray(res)) {
        self.classicAvatars = res.filter(
          (avatar) => avatar.cat === AvatarCategory.Classic
        );
        self.nefritAvatars = res.filter(
          (avatar) => avatar.cat === AvatarCategory.Nefrit
        );
      }
      self.loading = false;
    });
  }

  @action moveToMyAvatar(id: string, setMain?: boolean, one?: boolean) {
    const self = this;
    self.loading = true;
    Meteor.call(
      "avatars.col2my",
      { avatarId: id, setMain, one },
      (err: Meteor.Error, res: boolean) => {
        if (err) {
          console.log(err);
          self.error = err.message;
        }
        self.loading = false;
      }
    );
  }

  @action setMainAvatar(id: string) {
    const self = this;
    self.loading = true;
    Meteor.call(
      "avatars.setMain",
      { avatarId: id },
      (err: Meteor.Error, res: boolean) => {
        if (err) {
          console.log(err);
          self.error = err.message;
        }
        self.loading = false;
      }
    );
  }

  @action
  deleteMyAvatar(id: string) {
    const self = this;
    self.loading = true;
    Meteor.call(
      "avatars.deleteMy",
      { avatarId: id },
      (err: Meteor.Error, res: boolean) => {
        if (err) {
          console.log(err);
          self.error = err.message;
        }
        self.loading = false;
      }
    );
  }

  @action
  startUpload(file: Blob) {
    this.activeUpload = {
      file,
    };
  }

  @action
  finishUpload(error?: Meteor.Error) {
    if (error) {
      toastStore.showToast({
        type: ToastType.Error,
        message: translateErrors(error.message),
      });
    }
    this.activeUpload = undefined;
  }

  @action
  async uploadAvatar(file: Blob, additionalHeaders?: any) {
    if (!encriptionStore.token || !userStore.user || !file) return;

    const optimizedBinary = await optimizeImage(file as File);
    const optimized = new Blob([new Uint8Array(optimizedBinary.file)]);

    const body = new FormData();
    const avatarId = Random.id();

    body.append(avatarId, optimized);

    this.startUpload(optimized);
    this.setActiveTab(0);

    const self = this;

    fetch(`${configStore.fileServer}/up/ava/${userStore.user._id}`, {
      method: "POST",
      headers: {
        secret: encriptionStore.token,
        ...additionalHeaders,
      },
      body,
    })
      .then(async (res) => {
        if (!res.ok) {
          // Show error
          const data = await res.json();
          if (data && data.message) {
            toastStore.showToast({
              type: ToastType.Error,
              message: translateErrors(data.message),
            });
          }
        } else {
          // Set new uploaded avatar as main
          const data = await res.json();
          if (data && data.length) {
            const ava = data[0];
            self.setMainAvatar(ava.id);
          }
        }
        self.activeUpload = undefined;
      })
      .catch((err) => self.finishUpload(err));
  }
}

export const avatarsStore = new AvatarsStore();
