import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const BackArrow = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.BackArrow}>
    {({ color }) => (
      <path
        id="path"
        d="M24.1,12.925H8.545l7.14-7.14L13.9,4,3.7,14.2,13.9,24.4l1.785-1.785-7.14-7.14H24.1v-2.55Z"
        transform="translate(1.8 2)"
        fill={color}
        fillRule="evenodd"
      />
    )}
  </CreateSvgIcon>
);
