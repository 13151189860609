// Store boolean values - messageText | messageTextT

import { action, observable } from "mobx";

class TranslationCacheStore {
  @observable cache: { [key: string]: boolean } = {};

  @action
  toggleShowOriginalMessage(messageId: string) {
    this.cache[messageId] = !this.cache[messageId];
  }
}

const translationCacheStore = new TranslationCacheStore();

export default translationCacheStore;
