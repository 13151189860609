import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Rocket = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Send}>
    {({ color }) => (
      <>
        <path
          fill={color}
          d="M16 15.1c-.9 0-1.7.8-1.7 1.7 0 .9.8 1.7 1.7 1.7.9 0 1.7-.8 1.7-1.7 0-1-.8-1.7-1.7-1.7z"
        />
        <path
          fill={color}
          d="M23.4 21.7c-.2-1.2-.8-2.3-1.5-3.2-.1-.1-.2-.3-.2-.5 0-2.2-.4-4.3-1.1-6.3C19.5 9.2 18 7 16.2 5.1c-.1-.1-.3-.1-.4 0-1.8 1.9-3.3 4.1-4.3 6.5-.7 2.1-1.1 4.2-1.1 6.4 0 .2-.1.4-.2.5-.8.9-1.3 2-1.5 3.2-.2 1.7-.2 3.4 0 5.1 0 .1.1.2.2.1l3-1.9c1.3-.8 2.7-1.3 4.2-1.4 1.5 0 3 .5 4.2 1.4l3 1.8c.1.1.2 0 .2-.1.1-1.6.1-3.3-.1-5zM16 19.8c-1.6 0-3-1.3-3-3s1.3-3 3-3c1.6 0 3 1.3 3 3 0 1.6-1.4 3-3 3z"
        />
        <path fill="none" d="M0 0h32v32H0z" />
      </>
    )}
  </CreateSvgIcon>
);
