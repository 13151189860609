import { GiphyFetch } from "@giphy/js-fetch-api";
import { Grid } from "@giphy/react-components";
import React, { useCallback, useState } from "react";
import useDebounce from "react-use/lib/useDebounce";
import { observer } from "mobx-react";
import { makeStyles } from "hooks/makeStyles";
import { Icons, Input, Typography } from "ui/shared";
import { chatsStore } from "global-stores/chats-store";
import { useTranslation } from "react-i18next";

interface GifPickerProps {
  onClose?: () => void;
  onSend?: () => void;
  width: number;
  height: number;
  panelHeight?: number;
  isTranslatePanelShown: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "auto",
    backgroundColor: theme.colors.background.dark,
    padding: `${theme.spacing(0)} ${theme.spacing(1.75)}`,
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    position: "absolute",
    bottom: 13,
    left: -16,
  },
  header: {
    position: "sticky",
    top: 0,
    padding: `${theme.spacing(1.75)} ${theme.spacing(0)}`,
    zIndex: 1,
    backgroundColor: "inherit",
  },
  input: {
    padding: `${theme.spacing(1.75)} ${theme.spacing(10)} ${theme.spacing(
      1.75
    )} ${theme.spacing(2)}`,
    textAlign: "left",
    borderRadius: 5,
    border: "none",
    fontSize: 17,
    "&:focus": {
      border: "none",
    },
    "&::placeholder": {
      color: theme.colors.grey.main,
    },
  },
  icon: {
    position: "absolute",
    right: 4,
    top: 4,
  },
}));

export const giphyFetch = new GiphyFetch(process.env.REACT_APP_GIPHY_KEY!);

const SEARCH_DEBOUNCE = 500;

export const GifPicker = observer((props: GifPickerProps) => {
  const { t } = useTranslation("all");

  const { height, width, onClose, onSend, panelHeight, isTranslatePanelShown } =
    props;
  const css = useStyles();
  const [debouncedInput, setDebouncedInput] = useState<string>("");
  const [term, setTerm] = useState("");

  /**************************************************/

  useDebounce(() => setTerm(debouncedInput), SEARCH_DEBOUNCE, [debouncedInput]);
  const fetchGifs = useCallback(
    (offset: number) => {
      if (term === "") {
        return giphyFetch.trending({ offset, limit: 10 });
      } else {
        return giphyFetch.search(term, { offset, limit: 10 });
      }
    },
    [term]
  );

  const onGifClick = useCallback(
    (gif: any, e: any) => {
      e.preventDefault();

      chatsStore.sendGif(
        gif.id,
        gif.bitly_gif_url,
        gif.images.original.width,
        gif.images.original.height,
        onSend
      );

      setDebouncedInput("");

      if (onClose) {
        onClose();
      }
    },
    [onSend, onClose, setDebouncedInput]
  );

  /**************************************************/

  const defaultPickerOffset = isTranslatePanelShown ? 26 : 13; // Depends of translate panel
  const panelOffset = panelHeight ? panelHeight / 2 - 16 : defaultPickerOffset; // Minus half of icon size

  return (
    <div
      className={css.container}
      style={{
        height: `${height * 0.6}px`,
        bottom: panelOffset,
      }}
    >
      <div className={css.header}>
        <div style={{ position: "relative" }}>
          <Input
            className={css.input}
            placeholder={t("all:nav_search")}
            onChange={({ target: { value } }) => setDebouncedInput(value)}
            value={debouncedInput}
          />
          <Icons.Search className={css.icon} />
        </div>
      </div>
      <Grid
        columns={2}
        gutter={6}
        noResultsMessage={
          <Typography size="body(14px)" align="center" font="script">
            No Results for {term}
          </Typography>
        }
        key={term}
        fetchGifs={fetchGifs}
        width={width > 353 ? 353 : width}
        onGifClick={(gif, e) => {
          onGifClick(gif, e);
        }}
        noLink
        hideAttribution
        backgroundColor="transparent"
      />
    </div>
  );
});
