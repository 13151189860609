import clsx from "clsx";
import { makeStyles } from "hooks/makeStyles";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "../button/button";

interface CollapsedContainerProps {
  collapsedHeight: number;
  children: React.ReactElement;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  collapsedContainer: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    width: "100%",
    borderRadius: 0,
  },
}));

export const CollapsedContainer = ({
  collapsedHeight,
  children,
  className,
}: CollapsedContainerProps) => {
  const css = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [originHeight, setOriginHeight] = useState<number>();

  useEffect(() => {
    if (ref.current && ref.current.clientHeight) {
      setOriginHeight(ref.current.clientHeight - 20);
    }
  }, [ref]);

  return (
    <div
      ref={ref}
      className={clsx(css.collapsedContainer, { [className!]: !!className })}
    >
      {React.cloneElement(children, {
        style: {
          overflow: "hidden",
          transition: "height .2s",
          height: !open
            ? ref.current && ref.current.clientHeight > collapsedHeight + 40
              ? collapsedHeight
              : "100%"
            : originHeight,
        },
      })}
      {ref.current && ref.current.clientHeight > collapsedHeight + 40 ? (
        <div style={{ height: 40 }}>
          <Button className={css.button} onClick={() => setOpen(!open)}>
            {open ? "Hide" : "Show All"}
          </Button>
        </div>
      ) : null}
    </div>
  );
};
