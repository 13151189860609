import interestsObj from "lib/interests.json";
import langsObj from "lib/langs.json";

const interests = getInterestsArray();
const langs = getLangsArray();

export { interests, interestsObj, langs, langsObj };
export { Encryption } from "./encription";
export { KEY_CODES } from "./key-codes";
export * from "./common-interfaces/types";
export * from "./storage-keys";

/**************************************************/

function getInterestsArray() {
  let arr = [];
  for (const key in interestsObj) {
    arr.push({
      id: key,
      ...(interestsObj as any)[key]
    });
  }
  return arr;
}

function getLangsArray() {
  let arr = [];
  for (const key in langsObj) {
    arr.push({
      code: key,
      label: (langsObj as any)[key]
    });
  }
  return arr;
}
