import React, { useCallback } from "react";
import { makeStyles } from "hooks/makeStyles";
import { useHistory } from "react-router-dom";
import { IconButton } from "../icon-button/icon-button";
import { Icons } from "../icons";
import { Box, BoxProps } from "../box/box";
import { Typography } from "../typography/typography";

export interface BackButtonProps extends Partial<BoxProps> {
  label?: string;
  path?: string;
}

const useStyles = makeStyles((theme) => ({
  backContainer: {
    display: "flex",
    width: "100%",
    height: 60,
    flexShrink: 0,
    boxSizing: "border-box",
    alignItems: "center",
    cursor: "pointer",
    "& p": {
      color: theme.colors.textPrimary,
    },
  },
}));

export const BackButton = (props: BackButtonProps) => {
  const { label, path, ...passthrough } = props;
  const css = useStyles();
  const history = useHistory();

  /**************************************************/

  const handleBack = useCallback(() => {
    if (path) {
      history.push(path);
    } else {
      history.goBack();
    }
  }, [path, history]);

  /**************************************************/

  return (
    <Box {...passthrough}>
      <div className={css.backContainer} onClick={handleBack}>
        <IconButton>
          <Icons.BackArrow />
        </IconButton>
        {label ? (
          <Box ml={3}>
            <Typography size="h3(20px)" uppercase font="light">
              {label}
            </Typography>
          </Box>
        ) : null}
      </div>
    </Box>
  );
};
