import { observable, action, when, reaction } from "mobx";
import { Gender, Age, Area, Pickup, ToastType } from "lib";
import { callWithPromise } from "utils";
import { toastStore } from "global-stores/toasts-store";
import translateErrors from "utils/translate-errors";

export class SearchStore {
  @observable loading?: boolean;

  @observable hGender: Gender = "";
  @observable hAge: Age = "";
  @observable hAgeValue: number[] = [20, 30];
  @observable area: Area = "everywhere";

  @observable wantToCreateNewSearch: boolean = false;
  @observable pickups: Pickup[] = [];

  @observable captchaReady?: boolean = false;
  @observable needRobotCheck?: boolean = false;

  @observable isActiveSearch: boolean = false;

  @action
  setLoading(value: boolean) {
    this.loading = value;
  }

  @action
  setHGender(value: Gender) {
    if (value == null) return;
    this.hGender = value;
  }

  @action
  setHAge(value: Age) {
    if (value == null) return;
    this.hAge = value;
  }

  @action
  setHAgeValue(value: number[]) {
    this.hAgeValue = value;
  }

  @action
  setArea(value: Area) {
    if (!value) return;
    this.area = value;
  }

  @action
  setCaptchaReady(value: boolean) {
    this.captchaReady = value;
  }

  @action
  setWantToCreateNewSearch(value: boolean) {
    this.wantToCreateNewSearch = value;
  }

  @action
  setNeedRobotCheck(value: boolean) {
    this.needRobotCheck = value;
  }

  @action
  setIsActiveSearch(value: boolean) {
    this.isActiveSearch = value;
  }

  @action
  setPickups(value: Pickup[]) {
    this.pickups = value;
  }

  @action
  async startSearch(interests?: string[], callback?: () => void) {
    this.loading = true;
    const attr = {
      hGender: this.hGender,
      hAgeRange: this.hAge === "choose" ? this.hAgeValue : [0, 99],
      nearby: this.area === "nearby",
      interests,
      multi: true,
    };

    try {
      await callWithPromise("pickups.newPickup", attr);
      if (callback) callback();
    } catch (err) {
      console.error(err);
      toastStore.showToast({
        message: translateErrors(err.error),
        type: ToastType.Error,
      });
    }
    this.loading = false;
  }

  activateCaptcha() {
    when(
      () => !!this.needRobotCheck && !!this.captchaReady,
      () => {
        if ((window as any).grecaptcha) {
          (window as any).grecaptcha.execute();
        }
      }
    );
  }

  activateNeedRobotCheck() {
    reaction(
      () => this.pickups.length,
      () => {
        this.setNeedRobotCheck(
          this.pickups.some((pickup) => pickup.needRobotCheck)
        );
      }
    );
  }

  deletePickup(id: string) {
    try {
      callWithPromise("pickups.deletePickup", { pickupId: id });
    } catch (err) {
      console.error(err.message);
    }
  }

  disablePickup(id: string) {
    try {
      callWithPromise("pickups.disablePickup", { pickupId: id });
    } catch (err) {
      console.error(err.message);
    }
  }

  enablePickup(id: string) {
    try {
      callWithPromise("pickups.enablePickup", { pickupId: id });
    } catch (err) {
      console.error(err.message);
    }
  }

  clear() {
    this.hGender = "";
    this.hAge = "";
    this.hAgeValue = [20, 30];
    this.area = "everywhere";
  }
}

export const searchStore = new SearchStore();
