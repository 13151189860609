import { modalManager } from "global-stores/modal-manager";
import { makeStyles } from "hooks/makeStyles";
import { TopUserDetails } from "lib";
import { MODALS } from "lib/constants";
import { Meteor } from "meteor/meteor";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";
import { AnimatedPage } from "ui/shared";
import { topStore } from "./top-store";
import TopUserProfileComponent from "./top-user-profile";
import TopUsersList from "./top-users-list";
import TopUsersFilter from "./top-users-filter";

const useStyles = makeStyles((theme) => ({
  topUsersContainer: {
    width: "100%",
    maxWidth: 700,
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    margin: "0 auto",
  },
}));

export const Top = observer(() => {
  const css = useStyles();

  useEffect(() => {
    if (!topStore.users.length) topStore.getTopUsers();
  }, []);

  const handleClickTopUser = useCallback((item: TopUserDetails) => {
    modalManager.open(MODALS.TOP_USER_PROFILE);
    Meteor.call(
      "users.getOne",
      {
        userId: item._id,
      },
      (err: Meteor.Error, res: TopUserDetails) => {
        if (err) {
          console.error(err);
          return;
        }

        if (res) {
          topStore.setSelectedUser(res);
        }
      }
    );
  }, []);

  const handleCloseTopUserProfileModal = useCallback(() => {
    topStore.setSelectedUser(undefined);
  }, []);

  return (
    <AnimatedPage animation="vertical">
      <div className={css.topUsersContainer}>
        <TopUsersFilter />
        <TopUsersList
          users={topStore.users}
          onSelectItem={handleClickTopUser}
        />
      </div>
      <TopUserProfileComponent
        selectedUser={topStore.selectedUser}
        onClose={handleCloseTopUserProfileModal}
      />
    </AnimatedPage>
  );
});
