import React, { useCallback } from "react";
import { makeStyles } from "hooks/makeStyles";
import {
  Typography,
  Icons,
  IconButton,
  Tag,
  Box,
  Switch,
  CollapsedContainer,
} from "ui/shared";
import { useTranslation } from "react-i18next";
import { Friend, Pickup } from "lib";
import { getInterestsLabels } from "utils";
import { searchStore } from "../stores/search-store";
import { useTheme } from "react-jss";
import { Theme } from "lib/theme";
import { useTracker } from "hooks/useTracker";
import { Friends } from "collections/friends";

interface MySearchItemProps {
  pickup: Pickup;
  onTogglePickup: (value: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(4),
    "&:not(first-child)": {
      borderTop: `0.5px solid ${theme.colors.background.paper.main}`,
    },
  },
  title: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  deletePickup: {
    marginLeft: theme.spacing(3),
    padding: theme.spacing(1),
  },
  found: {
    marginTop: 20,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    borderTop: `0.5px solid ${theme.colors.background.paper.main}`,
    paddingTop: 10,
  },
  foundTitle: {
    marginBottom: 10,
    marginRight: 10,
  },
  truncated: {
    height: 90,
    overflow: "hidden",
  },
  tagRoot: {
    display: "flex",
    justifyContent: "center",
  },
}));

export const MySearchItem = (props: MySearchItemProps) => {
  const { pickup, onTogglePickup } = props;
  const { t } = useTranslation("all");
  const css = useStyles();
  const theme = useTheme() as Theme;

  const foundUsers = useTracker(() => {
    return pickup.found
      ? pickup.found.map((user) => {
          return Friends.findOne({ _id: user.id });
        })
      : [];
  });

  /**************************************************/

  const handleDeletePickup = useCallback((id: string) => {
    searchStore.deletePickup(id);
  }, []);

  /**************************************************/

  return (
    <div className={css.body}>
      <Box className={css.title} mb={4.25}>
        <Typography size="subtitle2(16px)">
          {pickup.interests && pickup.interests.length
            ? t("all:search_interests")
            : t("all:search_regular")}
        </Typography>
        <div className={css.flex}>
          {!pickup.disable ? (
            <Box mr={4}>
              <Icons.PuffLoader width={32} height={32} viewBox="0 0 44 44" />
            </Box>
          ) : null}
          <Switch
            checked={!pickup.disable}
            onChange={onTogglePickup}
            defaultBgColor={theme.colors.purple.main}
          />
          <IconButton
            onClick={() => handleDeletePickup(pickup._id)}
            data-cy="search.my.actions.delete"
            className={css.deletePickup}
          >
            <Icons.Trash width={32} height={32} />
          </IconButton>
        </div>
      </Box>
      <Box>
        <Tag
          withBg
          text={
            pickup.hGender
              ? t(`all:gender_${pickup.hGender}`)
              : t("all:search_top_info_any_gender")
          }
          data-cy="search.my.tags.gender"
        />
        <Tag
          withBg
          text={
            pickup.hAgeRange[0] === 0 && pickup.hAgeRange[1] === 99
              ? t("all:search_top_info_any_age")
              : pickup.hAgeRange.join(" - ")
          }
          data-cy="search.my.tags.age"
        />
        <Tag
          withBg
          text={
            pickup.nearby
              ? t("all:search_top_info_nearby")
              : t("all:search_top_info_earth")
          }
          data-cy="search.my.tags.area"
        />
        {pickup.interests && pickup.interests.length ? (
          <>
            <br />
            <Tag
              withBg
              text={getInterestsLabels(pickup.interests)}
              data-cy="search.my.tags.interests"
            />
          </>
        ) : null}
        {foundUsers.length ? (
          <CollapsedContainer collapsedHeight={94}>
            <div className={css.found}>
              {foundUsers.map((user: Friend, index: number) => {
                return user ? (
                  <div className={css.tagRoot} key={index}>
                    <Tag
                      text={user.friendLogin}
                      img={user.avatar && user.avatar.low}
                    />
                  </div>
                ) : null;
              })}
            </div>
          </CollapsedContainer>
        ) : null}
      </Box>
    </div>
  );
};
