import { makeStyles } from "hooks/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  "@keyframes loading": {
    "0%": {
      boxShadow: "inset 0px 0px 0px #fff",
    },
    "100%": {
      boxShadow: "inset 100px 0px 0px #fff",
    },
  },
  loaderContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  progressLoader: {
    width: 40,
    height: 10,
    border: `1px solid ${theme.colors.common.white}`,
    borderRadius: 2,
    animationName: "$loading",
    animationDuration: "5s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
  },
}));

export const ProgressLoader = () => {
  const css = useStyles();

  return (
    <div className={css.loaderContainer}>
      <div className={css.progressLoader}></div>
    </div>
  );
};
