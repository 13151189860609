import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const EyeOpened = (props: IconProps) => (
  <CreateSvgIcon
    {...props}
    width={14}
    height={14}
    viewBox="0 0 14.2 14.2"
    data-cy={IconNames.EyeOpened}
  >
    {({ color }) => (
      <g transform="translate(0 1)">
        <path
          d="M7,12.1c-2.7,0-4-1.4-5.3-3C1.5,8.9,1.3,8.7,1.2,8.6c-0.1-0.1-0.1-0.3,0-0.5l0.3-0.3
              c1.5-1.6,3-3.3,5.7-3.3s4,1.4,5.3,3c0.2,0.2,0.3,0.4,0.5,0.6l0,0c0.1,0.1,0.1,0.3,0,0.5l0,0c0,0,0,0,0,0l-0.3,0.3
              C11.2,10.4,9.7,12.1,7,12.1z M1.8,8.3C1.9,8.4,2,8.6,2.2,8.7c1.3,1.5,2.5,2.8,4.9,2.8s3.8-1.5,5.3-3.1l0,0C12.2,8.2,12.1,8.1,12,8
              c-1.3-1.5-2.5-2.8-4.9-2.8S3.3,6.7,1.8,8.3L1.8,8.3z"
          fill={color}
        />
        <path
          d="M7.1,3.4c-0.2,0-0.3-0.1-0.3-0.3c0,0,0,0,0,0V0.4c0-0.2,0.2-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3
              v2.7C7.4,3.3,7.3,3.4,7.1,3.4z"
          fill={color}
        />
        <path
          d="M10.3,4.4c-0.1,0-0.2,0-0.2-0.1C9.9,4.2,9.9,4,10,3.8l1.8-2c0.1-0.1,0.3-0.1,0.5,0
              c0.1,0.1,0.1,0.3,0,0.4l-1.8,2C10.5,4.3,10.4,4.4,10.3,4.4z"
          fill={color}
        />
        <path
          d="M4.1,4.4c-0.1,0-0.2,0-0.3-0.1l-1.7-2C2.1,2.1,2.1,1.9,2.3,1.8c0.1-0.1,0.3-0.1,0.4,0l1.7,2
              c0.1,0.1,0.1,0.3,0,0.5C4.3,4.4,4.2,4.4,4.1,4.4z"
          fill={color}
        />
        <path
          d="M7.1,10.5c-1.2,0-2.2-1-2.2-2.2C4.8,7,5.8,6,7.1,6c1.2,0,2.2,1,2.2,2.2C9.3,9.5,8.3,10.5,7.1,10.5
              C7.1,10.5,7.1,10.5,7.1,10.5z M7.1,6.7c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6C8.7,7.4,8,6.7,7.1,6.7z"
          fill={color}
        />
      </g>
    )}
  </CreateSvgIcon>
);

export const EyeClosed = (props: IconProps) => (
  <CreateSvgIcon
    {...props}
    width={14}
    height={14}
    viewBox="0 0 14.2 14.2"
    data-cy={IconNames.EyeClosed}
  >
    {({ color }) => (
      <g transform="translate(12 18) rotate(180)">
        <path
          d="M-1,12.9c0.2,0.2,0.3,0.3,0.5,0.5c1.4,1.3,2.7,2.5,5.3,2.5c2.7,0,4.2-1.4,5.7-2.8l0.3-0.2c0,0,0,0,0,0c0,0,0,0,0,0
          c0.1-0.1,0.1-0.3,0-0.4l0,0c-0.2-0.2-0.3-0.3-0.5-0.5c-0.7-0.7-1.5-1.3-2.4-1.8L10,7.8c0,0,0,0,0,0c0.1-0.2,0.1-0.4,0-0.5
          c-0.2-0.1-0.4-0.1-0.5,0L7.2,9.9c0,0,0,0,0,0C6.7,9.7,6.1,9.6,5.3,9.5V6.3c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4v3.2
          C4,9.6,3.4,9.7,2.9,9.8L0.7,7.2c-0.1-0.1-0.3-0.2-0.5,0C0.1,7.3,0,7.6,0.2,7.7l2,2.4c-1.1,0.5-2,1.3-2.9,2.1L-1,12.5
          c0,0-0.1,0.1-0.1,0.2C-1.1,12.8-1.1,12.9-1,12.9z M-0.3,12.7c1.5-1.3,2.9-2.6,5.3-2.6s3.5,1.1,4.9,2.3c0.1,0.1,0.2,0.2,0.3,0.3l0,0
          c-1.5,1.3-2.9,2.6-5.3,2.6S1.3,14.3,0,13C-0.1,12.9-0.2,12.8-0.3,12.7L-0.3,12.7z"
          fill={color}
        />
      </g>
    )}
  </CreateSvgIcon>
);
