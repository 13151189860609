import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { makeStyles } from "hooks/makeStyles";
import { useEventCallback } from "hooks/useEventCallback";
import clsx from "clsx";
import { observer } from "mobx-react";
import debounce from "lodash/debounce";
import { Friend, Message } from "lib";
import { IconButton, Icons, Loader } from "ui/shared";
import { MessagesList } from "./messages";
import { ChatStore, ChatStoreState } from "./stores/chat-store";
import { useTracker } from "hooks/useTracker";
import { Messages } from "collections/messages";
import { globalUIStore } from "global-stores/global-ui-store";
import { ChatContext } from "./chats";
import {
  ChatSubscription,
  subscriptionsStore,
} from "global-stores/subscriptions-store";
import translator from "global-stores/translator-store";
import { chatsStore } from "global-stores/chats-store";
import { userStore } from "global-stores/user-store";

interface ChatProps {
  loading?: boolean;
  friend: Friend;
  store: ChatStore;
  scrollToBottom: (smooth?: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: "100%",
    overflow: "hidden",
    "&$hidden": {
      visibility: "hidden",
    },
  },
  wrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    overflowX: "hidden",
    overflowY: "auto",
    width: "100%",
    height: "100%",
  },
  scrollButtonContainer: {
    position: "absolute",
    bottom: 15,
    right: 0,
    zIndex: 1000,
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 20px",
    opacity: 1,
    transform: "scale(1, 1)",
    transition: "opacity .2s, transform .2s",
    "&$hidden": {
      opacity: 0,
      transform: "scale(0, 0)",
    },
  },
  scrollButton: {
    position: "relative",
    textTransform: "none",
    fontSize: "1.2rem",
    // opacity: 0.7
  },
  messageCounter: {
    position: "absolute",
    bottom: -8,
    left: -8,
    width: 20,
    height: 20,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "10px",
    color: theme.colors.textPrimary,
    backgroundColor: theme.colors.primary,
  },
  loadingMoreIndicator: {
    position: "absolute",
    top: 60,
    left: 0,
    zIndex: 1000,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  typingContainer: {
    width: 45,
    height: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.background.paper.darker,
    borderRadius: 999,
    marginTop: 10,
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: `${theme.spacing(4)} 0`,
  },
  hidden: {},
  hideScroll: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
}));

export const Chat = observer(
  React.forwardRef((props: ChatProps, ref: React.Ref<HTMLDivElement>) => {
    const { friend, scrollToBottom, store } = props;

    const [chatSubscription, setChatSubscription] = useState<
      ChatSubscription | any
    >({});

    const css = useStyles();
    const chatContext = useContext(ChatContext);

    const { messages } = useTracker(() => {
      if (!chatSubscription) return { messages: [] };
      const queriedMessages = Messages.find(
        { friendId: friend._id },
        { sort: { createdAt: 1 }, limit: chatSubscription.cacheLimit }
      ).fetch() as Message[];

      const startIndex = queriedMessages.length - chatSubscription.limit;

      return {
        messages: queriedMessages.slice(startIndex < 0 ? 0 : startIndex),
      };
    }, [chatSubscription.cacheLimit, friend, chatSubscription.limit]);

    /**************************************************/

    const debouncedMoreMessages = debounce(() => {
      subscriptionsStore.getMoreMessages(friend);
    }, 30);

    const handleScroll = useEventCallback((e: any) => {
      const wrapper = ref as React.RefObject<HTMLDivElement>;
      if (!messages.length || !wrapper.current || globalUIStore.switchingChat)
        return;
      const hasMore = friend.messageCounter > chatSubscription.limit;

      if (!store.loading && wrapper.current.scrollTop < 5 && hasMore) {
        store.setLoading(true);
        setTimeout(() => {
          debouncedMoreMessages();
        }, 1000);
      }

      if (store.loading) {
        store.setScrollTop(wrapper.current.scrollTop);
        store.setScrollHeight(wrapper.current.scrollHeight);
      }

      const breakpoint =
        wrapper.current.scrollHeight - wrapper.current.clientHeight - 300;
      store.setShowScrollButton(friend, wrapper.current.scrollTop < breakpoint);
    });

    /**************************************************/

    useLayoutEffect(() => {
      const wrapper = ref as React.RefObject<HTMLDivElement>;
      if (
        wrapper.current &&
        !props.loading &&
        store.isReady &&
        chatSubscription.limit &&
        chatSubscription.limit !== store.storedMessagesLength
      ) {
        if (store.loading) {
          /**
           * After load more messages set calculated scrollTop to avoid
           * scroll flickering
           */
          store.loading = false;
          const offset = globalUIStore.isFirefox ? 120 : 60;
          wrapper.current.scrollTop =
            wrapper.current.scrollHeight -
            store.scrollHeight -
            offset +
            store.scrollTop;
        } else {
          /**
           * Has new messages without loading more (send or recieve new from friend)
           */
          const lastMessage = messages[messages.length - 1];
          if (lastMessage && lastMessage.my) {
            // Scroll to bottom if I send message
            store.setShouldScrollToBottom(true);
          } else if (!store.showScrollButton) {
            // Scroll if already in the bottom and recieve message from friend
            store.setShouldScrollToBottom(true);
            store.read(friend);
          }
        }
        store.setStoredMessagesLength(chatSubscription.limit);
      }
    }, [
      store.showScrollButton,
      chatSubscription.limit,
      store.storedMessagesLength,
    ]);

    useEffect(() => {
      if (store.shouldScrollToBottom) {
        setTimeout(() => {
          scrollToBottom(true);
          store.setShouldScrollToBottom(false);
        }, 50);
      }
    }, [store.shouldScrollToBottom]);

    useEffect(() => {
      /**
       * If recieve new messages need to increment limits to
       * load new ones and still has old ones
       */
      if (store.isReady) {
        subscriptionsStore.incrementLimit(friend._id);
      }
      const lastMessage = messages[messages.length - 1] as Message;
      if (
        lastMessage &&
        !lastMessage.my &&
        globalUIStore.initializedDate &&
        lastMessage.createdAt > globalUIStore.initializedDate &&
        translator.isEnabled &&
        (userStore.isVIP || userStore.isVerfiedUser)
      ) {
        // Autotranslate message if it's on
        const myLang =
          store.translationSettings && store.translationSettings.myLang;
        const friendLang =
          store.translationSettings && store.translationSettings.friendLang;
        const isAutotranslateEnabled =
          store.translationSettings &&
          store.translationSettings.useTranslate &&
          store.translationSettings.autoTranslation &&
          myLang;

        if (isAutotranslateEnabled) {
          translator.translateFriendMessage(lastMessage, myLang!, friendLang);
        }
      }
    }, [friend.messageCounter, store]);

    useEffect(() => {
      if (friend.newMessageCounter) {
        store.read(friend);
      }
      subscriptionsStore.addSubscription(friend._id);
      setChatSubscription(subscriptionsStore.chatSubscriptions[friend._id]);
      globalUIStore.setSwitchingChat(false);
    }, []);

    return (
      <div className={css.root}>
        <>
          <div
            className={clsx(css.scrollButtonContainer, {
              [css.hidden]: !store.showScrollButton,
            })}
          >
            <IconButton
              onClick={() => {
                scrollToBottom(true);
                store.read(friend);
              }}
              className={css.scrollButton}
              tabIndex={store.showScrollButton ? undefined : -1}
              data-cy="chat-scroll-to-bottom-button"
            >
              <Icons.ArrowWithoutTail viewBox="0 0 40 40" />
              {friend.newMessageCounter ? (
                <div className={css.messageCounter}>
                  {friend.newMessageCounter >= 100
                    ? "99+"
                    : friend.newMessageCounter}
                </div>
              ) : (
                ""
              )}
            </IconButton>
          </div>
          <div
            className={clsx(css.wrapper, {
              [css.hideScroll]: !store.isReady,
            })}
            ref={ref}
            onScroll={handleScroll}
            data-cy="chat-scroll-container"
          >
            {!props.loading && !globalUIStore.switchingChat && chatContext ? (
              <MessagesList
                isFriend={friend.friend}
                typing={friend.typing}
                messages={messages}
                friendId={friend._id}
                store={store}
                scrollToBottom={scrollToBottom}
                initializedCallback={() => {
                  store.setState(ChatStoreState.Initialized);
                }}
                visible={!globalUIStore.switchingChat}
              />
            ) : (
              <Loader />
            )}
          </div>
        </>
      </div>
    );
  })
);
