import React, { useCallback, useRef, useState } from "react";
import { Meteor } from "meteor/meteor";
import {
  Typography,
  Modal,
  DefaultPanel,
  Box,
  Anchor,
  IconButton,
  Icons,
} from "ui/shared";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { makeStyles } from "hooks/makeStyles";
import { storage } from "utils";
import { globalUIStore } from "global-stores/global-ui-store";
import { qrCodeStore } from "global-stores/qrcode-store";
import { ADDITIONAL_INFO_KEY, ToastType } from "lib";
import { ImportAccountDialog } from "./import-account-dialog";
import { RestoreAccountDialog } from "./restore-account-dialog";
import { toastStore } from "global-stores/toasts-store";
import { inviteStore } from "global-stores/invite-store";
import { InviteCode } from "../profile/modals/invite-code-modal";
import { MODALS } from "lib/constants";
import { modalManager } from "global-stores/modal-manager";
import clsx from "clsx";
import { Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import { LoginLayout } from "./login-layout";
import ModalLayoutContainer from "ui/components/modal-layout-container/modal-layout-container";
import QrScanner from "qr-scanner";

const useStyles = makeStyles((theme) => ({
  modal: {
    background: theme.colors.background.paper.lighter,
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  hidden: {
    width: 0,
    height: 0,
    visibility: "hidden",
  },
  importAccountModalContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.colors.background.paper.topbar,
    borderRadius: theme.sizes.borderRadius.medium,
    padding: `${theme.spacing(5)}`,
    "&:focus": {
      outline: "none",
    },
  },
  defaultPanel: {
    maxWidth: 268,
    "&:focus": {
      outline: 0,
    },
  },
  checkbox: {
    "& > p": {
      color: theme.colors.primary,
    },
  },
  linkWrapper: {
    "& > a": {
      color: theme.colors.textPrimary,
    },
  },
  importContainer: {
    textAlign: "center",
  },
  padded: {
    padding: `0 ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
  },
}));

export const LoginDesktop = observer(() => {
  const css = useStyles();
  const { t } = useTranslation("all");

  const videoRef = useRef<HTMLVideoElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  /**************************************************/

  const handleOpenResetHelp = useCallback(() => {
    modalManager.open(MODALS.HELPER, "security_help");
  }, []);

  const stopStream = useCallback(() => {
    if (videoRef.current) {
      const stream = videoRef.current.srcObject as MediaStream;
      if (stream) {
        const tracks = stream.getTracks();

        tracks.forEach(function (track) {
          track.stop();
        });

        videoRef.current.srcObject = null;
      }
    }
  }, []);

  const handleVideoModalClose = useCallback(() => {
    stopStream();
    qrCodeStore.stop();
  }, [stopStream]);

  const onDecode = useCallback(
    (result: QrScanner.ScanResult) => {
      if (result.data) {
        // Parse qrcode string
        const parsed = qrCodeStore.parseQRCodeString(result.data);
        handleVideoModalClose();
        if (parsed) {
          // Login user with parsed keys
          Meteor.loginWithPassword(
            { id: parsed.userId },
            parsed.secret,
            (err) => {
              if (err) {
                // Handle login error
                console.log(err);
                toastStore.showToast({
                  message: err.message,
                  type: ToastType.Error,
                });
              }
            }
          );
          storage.write("publicKey", parsed.public);
          storage.write("secretKey", parsed.secret);
          storage.write(ADDITIONAL_INFO_KEY, true);

          globalUIStore.setUserFilledAdditionalInfo(true);
        } else {
          // Wrong string
          toastStore.showToast({
            message: t("all:import_bad_qr"),
            type: ToastType.Error,
          });
        }
      }
    },
    [handleVideoModalClose, t]
  );

  const handleFileUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const files = Array.from(e.target.files);

        if (files.length) {
          const img = files[0];
          qrCodeStore.scanImage(img, onDecode);
        }
      }
    },
    [onDecode]
  );

  const handleImportProfileFromImage = useCallback((e: React.MouseEvent) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const handleImportProfileFromWebcam = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      modalManager.open(MODALS.LOGIN_VIDEO_STREAM);

      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            width: 640,
            height: 480,
          },
        })
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.onloadedmetadata = function (e) {
              if (videoRef.current) {
                videoRef.current.play();

                // init QRCode store
                qrCodeStore.initScanner(videoRef.current, onDecode);

                // Start scanning QRCode
                qrCodeStore.scan();
              }
            };
          }
        })
        .catch((err) => {
          toastStore.showToast({
            message: t("all:alert_access_camera_text"),
            type: ToastType.Error,
          });
          modalManager.close(MODALS.LOGIN_VIDEO_STREAM);
          handleVideoModalClose();
        });
    },
    [handleVideoModalClose, t, onDecode]
  );

  /**************************************************/

  return (
    <>
      <Switch>
        <Route
          path="/import"
          render={() => (
            <ModalLayoutContainer>
              <Box className={css.importContainer}>
                <Typography
                  size="subtitle1(18px)"
                  font="light"
                  className={css.padded}
                >
                  {t("all:import_text")}
                </Typography>
                <Typography
                  size="subtitle1(18px)"
                  font="light"
                  className={css.padded}
                >
                  {t("all:import_text_2")}
                </Typography>
                <ImportAccountDialog
                  handleImportProfileFromImage={handleImportProfileFromImage}
                  handleImportProfileFromWebcam={handleImportProfileFromWebcam}
                  webcamLabel={t("all:import_photo_button")}
                  imageLabel={t("all:import_file_button")}
                  className={css.importAccountModalContainer}
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  className={css.hidden}
                  onChange={handleFileUpload}
                />
                <Link to="/restore">
                  <Typography font="light" size="caption(12px)">
                    {t("all:import_next_button")}
                  </Typography>
                </Link>
              </Box>
            </ModalLayoutContainer>
          )}
        />
        <Route
          path="/restore"
          render={() => (
            <ModalLayoutContainer>
              <Box className={css.importContainer}>
                <Typography
                  size="subtitle1(18px)"
                  font="light"
                  className={css.padded}
                >
                  {t("all:reset_recovery_text")}
                </Typography>
                <Typography
                  size="body(14px)"
                  font="light"
                  className={css.padded}
                >
                  {t("all:reset_text")}
                </Typography>
                <Anchor asLink onClick={handleOpenResetHelp}>
                  {t("all:reset_help_link")}
                </Anchor>
                <RestoreAccountDialog />
                <Link to="/">
                  <Typography font="light" size="caption(12px)">
                    {t("all:reset_back_button")}
                  </Typography>
                </Link>
              </Box>
            </ModalLayoutContainer>
          )}
        />
        <Route path="/" component={LoginLayout} />
      </Switch>
      <Modal
        modalId={MODALS.LOGIN_INVITE_CODE}
        onClose={() => {
          if (!inviteStore.invited) {
            inviteStore.clear();
          }
        }}
      >
        {(visible, className) => (
          <DefaultPanel
            title={t("all:invite_set_placeholder")}
            className={clsx(className, css.defaultPanel)}
          >
            <InviteCode visible={visible} />
          </DefaultPanel>
        )}
      </Modal>
      <Modal
        modalId={MODALS.LOGIN_VIDEO_STREAM}
        onClose={handleVideoModalClose}
      >
        {() => (
          <video
            ref={videoRef}
            width={640}
            height={480}
            className={css.modal}
          />
        )}
      </Modal>
    </>
  );
});
