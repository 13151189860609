import React, { useCallback, useEffect } from "react";
import { makeStyles } from "hooks/makeStyles";
import { Typography, Button, AnimatedPage, Loader } from "ui/shared";
import { useHistory, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { searchStore } from "./stores/search-store";
import { observer } from "mobx-react";
import useWindowSize from "hooks/useWindowSize";
import clsx from "clsx";
import SmallBubble from "./interests-button-elements/small-bubble";
import SmallBubbleSecond from "./interests-button-elements/small-bubble-second";
import MediumBubbleFirst from "./interests-button-elements/medium-bubble-first";
import MediumBubbleSecond from "./interests-button-elements/medium-bubble-second";
import MediumBubbleThird from "./interests-button-elements/medium-bubble-third";
import BigBubble from "./interests-button-elements/big-bubble";
import Camera from "./interests-button-elements/photo-camera";
import Books from "./interests-button-elements/books";
import Gamepad from "./interests-button-elements/gamepad";
import { AnimatePresence } from "framer-motion";

export interface SearchIntroProps {}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: theme.colors.background.paper.main,
  },
  button: {
    width: "80%",
    maxWidth: 750,
    display: "block",
    height: 200,
    backgroundColor: theme.colors.background.paper.topbar,
    borderRadius: 10,
    padding: theme.spacing(10),
    margin: "0 auto",
    border: "none",
    cursor: "pointer",
    textAlign: "left",
    "&:first-child": {
      marginBottom: theme.spacing(8),
    },
  },
  regularSearch: {
    background:
      "transparent linear-gradient(113deg, #BA6FE2 0%, #D56565 100%) 0% 0% no-repeat padding-box;",
  },
  interestsSearch: {
    position: "relative",
    background:
      "transparent linear-gradient(110deg, #D6915D 0%, #BCAC34 100%) 0% 0% no-repeat padding-box;",
  },
  minContent: {
    width: "min-content",
    marginLeft: "auto",
    marginRight: theme.spacing(8),
  },
  smallBubble: {
    position: "absolute",
    bottom: 17,
    left: 17,
  },
  smallBubbleSecond: {
    position: "absolute",
    top: 40,
    left: 384,
  },
  mediumBubbleFirst: {
    position: "absolute",
    bottom: 73,
    left: 17,
  },
  mediumBubbleSecond: {
    position: "absolute",
    top: 26,
    left: 225,
  },
  mediumBubbleThird: {
    position: "absolute",
    bottom: 20,
    left: 315,
  },
  bigBubble: {
    position: "absolute",
    bottom: 0,
    left: 47,
  },
  camera: {
    position: "absolute",
    left: 42,
    top: 32,
    willChange: "transform",
  },
  gamepad: {
    position: "absolute",
    left: 150,
    bottom: 14,
    willChange: "transform",
  },
  books: {
    position: "absolute",
    top: 14,
    left: 250,
    willChange: "transform",
  },
}));

export const SearchIntro = observer((props: SearchIntroProps) => {
  const css = useStyles();
  const history = useHistory();
  const { t } = useTranslation("all");

  const windowSize = useWindowSize();
  const windowWidth = windowSize && windowSize.width;
  const fontSize = windowWidth && windowWidth > 1300 ? "h1(33px)" : "h2(23px)";

  /**************************************************/

  const handleClickRegularSearch = useCallback(() => {
    history.push("/search/regular");
  }, [history]);

  const handleClickSearchByInterests = useCallback(() => {
    history.push("/search/interests");
  }, [history]);

  /**************************************************/

  useEffect(() => {
    return () => {
      if (searchStore.wantToCreateNewSearch) {
        searchStore.setWantToCreateNewSearch(false);
      }
    };
  }, []);

  if (searchStore.loading) {
    return <Loader />;
  } else if (searchStore.pickups.length && !searchStore.wantToCreateNewSearch) {
    return <Redirect to="/search/my" />;
  }

  return (
    <AnimatedPage animation="vertical" containerClassName={css.container}>
      <Button
        onClick={handleClickRegularSearch}
        className={clsx(css.button, css.regularSearch)}
        data-cy="search.regularSearchButton"
      >
        <Typography size={fontSize} align="center">
          {t("all:search_regular")}
        </Typography>
      </Button>
      <Button
        onClick={handleClickSearchByInterests}
        className={clsx(css.button, css.interestsSearch)}
        data-cy="search.searchByInterestsButton"
      >
        <AnimatePresence>
          <SmallBubble key="small-bubble" className={css.smallBubble} />
          <SmallBubbleSecond
            key="small-bubble-2"
            className={css.smallBubbleSecond}
          />
          <MediumBubbleFirst
            key="medium-bubble"
            className={css.mediumBubbleFirst}
          />
          <MediumBubbleSecond
            key="medium-bubble-2"
            className={css.mediumBubbleSecond}
          />
          <MediumBubbleThird
            key="medium-bubble-3"
            className={css.mediumBubbleThird}
          />
          <BigBubble key="big-bubble" className={css.bigBubble} />
          <Camera key="camera" className={css.camera} />
          <Gamepad key="gamepad" className={css.gamepad} />
          <Books key="books" className={css.books} />
          <Typography size={fontSize} align="right" className={css.minContent}>
            {t("all:search_interests")}
          </Typography>
        </AnimatePresence>
      </Button>
    </AnimatedPage>
  );
});
