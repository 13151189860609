import { makeStyles } from "hooks/makeStyles";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Anchor, AnimatedPage, Box, Typography } from "ui/shared";
import VipOptionCard from "./vip-option-card";
import VipTable from "./vip-table";
import ActiveSubscription from "./vip-active-subscription";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { VIPOption } from "lib/common-interfaces/types";
import vipStore from "global-stores/vip-store";
import PromoLink from "../../components/promo-link/promo-link";
import { i18n } from "services/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    background: theme.colors.background.paper.main,
    padding: `0 ${theme.spacing(6)}`,
    marginRight: 8,
  },
  activeSubContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  minHeight: {
    minHeight: 860,
  },
  title: {
    textAlign: "center",
    textDecoration: "uppercase",
    marginTop: `${theme.spacing(5.75)}`,
    marginBottom: `${theme.spacing(5.5)}`,
  },
  optionsContainer: {
    display: "flex",
  },
  link: {
    fontSize: 12,
  },
}));

const VIPPage = observer(() => {
  const css = useStyles();
  const { t } = useTranslation("all");
  const history = useHistory();

  const browserLanguage = navigator.language;
  const isUserRu = browserLanguage === "ru-RU" || i18n.language === "ru";

  /**************************************************/

  const handleSelectOption = useCallback(
    async (option: VIPOption) => {
      history.push(`vip/${option.id}`);
    },
    [history]
  );

  /**************************************************/

  return (
    <AnimatedPage animation="vertical" containerClassName={css.root}>
      {vipStore.successfullyPaid && !vipStore.activeSubscriptions.length ? (
        <Box mt={6} mb={2} className={css.activeSubContainer}>
          <Typography size="subtitle1(18px)">
            {t("all:pay_process_text")}
          </Typography>
        </Box>
      ) : vipStore.loadingSubscriptions ? (
        <Box mt={6} mb={2} className={css.activeSubContainer}>
          <Typography size="subtitle1(18px)">{t("all:loading")}</Typography>
        </Box>
      ) : !vipStore.loadingSubscriptions &&
        vipStore.activeSubscriptions.length ? (
        <Box mt={6} mb={2} className={css.activeSubContainer}>
          <Typography size="subtitle1(18px)">
            {t("all:pay_header_vip")}
          </Typography>
        </Box>
      ) : !vipStore.loadingSubscriptions &&
        !vipStore.activeSubscriptions.length ? (
        <Box>
          <Typography uppercase size="subtitle1(18px)" className={css.title}>
            {t("all:pay_header")}
          </Typography>
          {/* {isUserRu ? ( */}
          <Box mb={4}>
            <PromoLink />
          </Box>
          {/* ) : null} */}
          <Box className={css.optionsContainer}>
            {vipStore.subscriptionOptions.map((option) => (
              <VipOptionCard
                key={`option-${option.duration}`}
                option={option}
                onClick={handleSelectOption}
              />
            ))}
          </Box>
        </Box>
      ) : null}
      <VipTable />
      {vipStore.loadingSubscriptions && <ActiveSubscription loading={true} />}
      {!vipStore.loadingSubscriptions && vipStore.activeSubscriptions.length
        ? vipStore.activeSubscriptions.map((sub) => (
            <ActiveSubscription
              key={sub._id}
              subscription={vipStore.activeSubscriptions[0]}
            />
          ))
        : null}
      <Box mb={5} mt={6}>
        <Typography size="caption(12px)" align="center">
          {t("all:pay_ios_bottom_text")}{" "}
          <Anchor asLink className={css.link}>
            {t("all:pay_ios_link_terms")}
          </Anchor>
        </Typography>
      </Box>
    </AnimatedPage>
  );
});

export default VIPPage;
