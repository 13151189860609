import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "ui/shared";
import { EditableDropdown, wrappedLabel } from "../../shared/editable/editable";
import { GenderModal } from "./modals/gender-modal";
import { userStore } from "global-stores/user-store";

const GenderEditable = observer(() => {
  const { t } = useTranslation("all");

  return (
    <EditableDropdown
      label={wrappedLabel(
        <Typography size="caption(12px)" color="secondary" lowercase>
          {t("all:profile_gender")}:
        </Typography>
      )}
      dropdownRender={(close) => <GenderModal close={close} />}
      trigger={
        <Button data-cy="gender.button">
          {userStore.user &&
          userStore.user.profile &&
          userStore.user.profile.gender
            ? t(`all:gender_${userStore.user.profile.gender}`)
            : t("all:profile_placeholder_no")}
        </Button>
      }
    />
  );
});

export default GenderEditable;
