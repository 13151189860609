import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Telegram = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Telegram}>
    {({ color }) => (
      <>
        <g fill="none" stroke={color} strokeWidth="1">
          <circle cx="16" cy="16" r="16" stroke="none" />
          <circle cx="16" cy="16" r="15.5" fill="#322941" />
        </g>
        <path
          transform="translate(-150.843 141.27)"
          fill={color}
          d="M175.1-134l-19.2,7.5c-0.4,0.1-0.5,0.5-0.4,0.9c0.1,0.2,0.2,0.4,0.5,0.4l4.9,1.4
		l1.8,5.8c0.1,0.4,0.6,0.7,1.1,0.5c0.1,0,0.3-0.1,0.3-0.2l2.5-2.6l4.9,3.6c0.5,0.3,1.1,0.2,1.4-0.2c0.1-0.1,0.1-0.3,0.2-0.4
		l3.3-15.7c0.1-0.5-0.2-1.1-0.8-1.2C175.4-134.1,175.3-134.1,175.1-134z M172.7-130.6l-8.9,7.9c-0.1,0.1-0.1,0.2-0.2,0.3l-0.3,3.1
		c0,0.1-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1-0.1l-1.4-4.6c-0.1-0.2,0-0.4,0.2-0.5l10.5-6.5c0.1-0.1,0.2-0.1,0.3,0
		C172.8-130.8,172.8-130.6,172.7-130.6L172.7-130.6L172.7-130.6z"
        />
      </>
    )}
  </CreateSvgIcon>
);
