import { Reason } from "lib";
import { observable, action } from "mobx";
import { callWithPromise } from "utils";

export class PeopleArchiveStore {
  @observable loading?: boolean;
  @observable selectedItems: string[] = [];

  @action
  setSelectedItems(value: string[]) {
    this.selectedItems = value;
    console.log(this.selectedItems);
  }

  @action
  deletePeopleArchive() {
    const self = this;
    this.loading = true;
    try {
      callWithPromise("blacklist.removeById", {
        id: self.selectedItems
      });
    } catch (err) {
      console.log(err.message);
    }
    this.loading = false;
  }

  @action
  clearPeopleArchive() {
    this.loading = true;
    try {
      callWithPromise("blacklist.removeAll", {
        reason: [Reason.Old]
      });
    } catch (err) {
      console.log(err.message);
    }
    this.loading = false;
  }
}
