import avatarMock from "assets/svg/avatar-mock.png";

enum Quality {
  Low = "low",
  Medium = "medium",
  High = "high",
}

interface AvatarObject {
  low?: string;
  medium?: string;
  high?: string;
}

const getAvatar = (profile: any) => {
  const avatarP =
    profile && profile.avatarP && getAvailableQuality(profile.avatarP);
  const avatar =
    profile && profile.avatar && getAvailableQuality(profile.avatar);

  return avatarP || avatar;
};

const getAvailableQuality = (avatar: AvatarObject) => {
  return avatar[Quality.Medium] || avatar[Quality.High] || avatar[Quality.Low];
};

export const getUserAvatar = (profile: any) => {
  const avatar = getAvatar(profile);

  return avatar || avatarMock;
};
