import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import { Theme } from "lib/theme";
import { makeStyles } from "hooks/makeStyles";
import { Header } from "./header/header";
import { ACLGuard, Anchor, Toasts, Icons, Typography, Box } from "ui/shared";
import { Login } from "ui/pages/login/login";
import { userStore } from "global-stores/user-store";
import { globalUIStore } from "global-stores/global-ui-store";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { SearchIntro } from "ui/pages/search/search";
import { RegularSearch } from "ui/pages/search/regular-search";
import { SearchByInterests } from "ui/pages/search/search-by-interests";
import ProfilePage from "ui/pages/profile/profile-page";
import { KarmaHistory } from "ui/pages/karma-history/karma-history";
import { Settings } from "ui/pages/settings/settings";
import { BlackList } from "ui/pages/settings/blacklist";
import { PeopleArchive } from "ui/pages/settings/people-archive";
import { Chats } from "ui/pages/chats/chats";
import { useTranslation } from "react-i18next";
import { AdditionalInfo } from "./additional-info/additional-info";
import { Email } from "ui/pages/email/email";
import { MySearch } from "ui/pages/search/my-search/my-search";
import { Top } from "ui/pages/top/top";
import { Friend, Pickup, ToastAlign } from "lib";
import { encriptionStore } from "global-stores/encryption-store";
import { useTracker } from "hooks/useTracker";
import { Meteor } from "meteor/meteor";
import { chatsStore } from "global-stores/chats-store";
import { Friends } from "collections/friends";
import { searchStore } from "ui/pages/search/stores/search-store";
import { Portal } from "ui/shared/modal/portal";
import { motion, AnimatePresence } from "framer-motion";
import { Pickups } from "collections/pickups";
import { modalManager } from "global-stores/modal-manager";
import { MODALS } from "lib/constants";
import VIPPage from "ui/pages/vip/vip";
import VIPPayForm from "ui/pages/vip/vip-pay-form";
import vipStore from "global-stores/vip-store";
import { Subscriptions } from "collections/subscriptions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // transition: "all .1s",
    position: "relative",
    zIndex: 1,
  },
  appContainer: {
    width: "70%",
    height: "80%",
    borderRadius: 10,
    marginTop: theme.spacing(6.5),
    marginBottom: theme.spacing(3.75),
    position: "relative",
    boxShadow: theme.shadows.big,
    "@media screen and (max-width: 1000px)": {
      width: "100%",
      height: "100vh",
      maxHeight: "100vh",
      borderRadius: 0,
      margin: 0,
      boxShadow: "none",
    },
  },
  loginContainer: {
    background: "transparent",
    position: "relative",
    backgroundColor: theme.colors.background.paper.topbar,
    borderRadius: 10,
    boxShadow: "0px 3px 6px #00000029",
  },
  main: {
    height: `calc(100% - ${theme.sizes.header}px)`,
    overflow: "hidden",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    "@media screen and (max-width: 1000px)": {
      borderRadius: 0,
    },
  },
  animating: {},
  footer: {
    position: "fixed",
    bottom: 0,
    left: "15%",
    right: "15%",
    width: "70%",
    padding: `0 ${theme.spacing(1)}`,
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media screen and (max-width: 1000px)": {
      display: "none",
    },
  },
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    background: "none",
    "&:not(:last-child)": {
      marginRight: theme.spacing(1.5),
    },
  },
  linksContainer: {
    display: "flex",
    flexWrap: "nowrap",
  },
  storesContainer: {
    display: "flex",
    flexWrap: "nowrap",
  },
  subtitle: { flexGrow: 0, width: "100%", maxWidth: 168 },
  withBackground: {
    background: theme.colors.background.paper.main,
  },
  hiddenInput: {
    visibility: "hidden",
  },
  helpContainer: {
    display: "flex",
    alignItems: "center",
    width: "auto",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  helpLink: {
    color: theme.colors.common.white,
    textDecoration: "underline",
    width: 168,
  },
}));

export const DesktopLayout = observer(() => {
  const css = useStyles();
  const { t } = useTranslation("shell");
  const location = useLocation();
  const history = useHistory();

  useTracker(() => {
    const handle = Meteor.subscribe("friends.my", { ver: 1 });
    const searchHandle = Meteor.subscribe("pickups.my");

    const fetchedPickups = Pickups.find().fetch() as Pickup[];
    searchStore.setLoading(!searchHandle.ready());
    searchStore.setPickups(fetchedPickups);
    searchStore.setIsActiveSearch(
      fetchedPickups.some((pickup) => !pickup.disable)
    );

    chatsStore.friends = Friends.find(
      {},
      { sort: { "lastMessage.createdAt": -1, "lastActivity.date": -1 } }
    ).fetch() as Friend[];

    chatsStore.loading = !handle.ready();

    chatsStore.friends.forEach((friend) => {
      encriptionStore.createEncryption(friend);
    });
  }, []);

  useTracker(() => {
    // Loading active subscriptions
    const activeSubsctiptionsHandle = Meteor.subscribe("subscriptions.my");

    const subs = Subscriptions.find().fetch();

    vipStore.setLoadingSubscriptions(!activeSubsctiptionsHandle.ready());
    vipStore.setActiveSubscriptions(subs);
  }, []);

  useEffect(() => {
    if (userStore.user) {
      vipStore.init();
    }
  }, [userStore.user]);

  useEffect(() => {
    searchStore.activateCaptcha();
    searchStore.activateNeedRobotCheck();

    setTimeout(() => {
      globalUIStore.setAnimationReady(true);
    }, 1500);
    history.listen(() => {
      globalUIStore.setIsAnimating(true);
    });
  }, [history]);

  const hasEmails =
    userStore.user &&
    userStore.user.profile &&
    userStore.user.profile.emails &&
    userStore.user.profile.emails.length;

  const cx = clsx({
    [css.root]: true,
    [css.appContainer]:
      !globalUIStore.loading &&
      !!userStore.user &&
      globalUIStore.userFilledAdditionalInfo &&
      (globalUIStore.userFilledEmail || hasEmails),
    [css.loginContainer]:
      !globalUIStore.loading &&
      (!userStore.user ||
        !globalUIStore.userFilledAdditionalInfo ||
        !(globalUIStore.userFilledEmail || hasEmails)),
    [css.withBackground]:
      !globalUIStore.loading &&
      !location.pathname.startsWith("/chats") &&
      userStore.user &&
      globalUIStore.userFilledAdditionalInfo &&
      (globalUIStore.userFilledEmail || hasEmails),
  });

  /**************************************************/

  const renderRoutes = useCallback(() => {
    return (
      <motion.div
        layout
        initial={{
          y: 50,
          opacity: 0,
        }}
        animate={{
          y: 0,
          opacity: 1,
        }}
        exit={{ y: 50, opacity: 0, transition: { duration: 150 } }}
        transition={{ duration: 0.2 }}
        className={cx}
      >
        <ACLGuard fallback={<Login />}>
          {vipStore.initConfig || vipStore.initError ? (
            globalUIStore.userFilledAdditionalInfo &&
            encriptionStore.isInitialized ? (
              globalUIStore.userFilledEmail || hasEmails ? (
                <>
                  <Header isActiveSearch={searchStore.isActiveSearch} />
                  <React.Suspense fallback={null}>
                    <main
                      className={clsx(css.main, {
                        [css.animating]: globalUIStore.isAnimating,
                      })}
                    >
                      <AnimatePresence exitBeforeEnter initial={false}>
                        <Switch>
                          <Route exact path="/search" component={SearchIntro} />
                          <Route
                            exact
                            path="/search/regular"
                            component={RegularSearch}
                          />
                          <Route
                            exact
                            path="/search/interests"
                            component={SearchByInterests}
                          />
                          <Route exact path="/search/my" component={MySearch} />
                          <Route
                            exact
                            path="/profile"
                            component={ProfilePage}
                          />
                          <Route exact path="/karma" component={KarmaHistory} />
                          <Route exact path="/settings" component={Settings} />
                          <Route exact path="/vip" component={VIPPage} />
                          <Route
                            exact
                            path="/vip/:optionId"
                            component={VIPPayForm}
                          />
                          <Route
                            exact
                            path="/settings/blacklist"
                            component={BlackList}
                          />
                          <Route
                            exact
                            path="/settings/archive"
                            component={PeopleArchive}
                          />
                          <Route path="/chats/:id?" component={Chats} />
                          <Route path="/top" component={Top} />
                          <Route path="/">
                            <Redirect to="/chats" />
                          </Route>
                          <Route>
                            <div>{t("shell:notFound")}</div>
                          </Route>
                        </Switch>
                      </AnimatePresence>
                    </main>
                  </React.Suspense>
                </>
              ) : (
                <Email />
              )
            ) : (
              <AdditionalInfo />
            )
          ) : null}
        </ACLGuard>
      </motion.div>
    );
  }, [hasEmails, searchStore.isActiveSearch, cx, css.animating, css.main, t]);

  /**************************************************/

  return (
    <>
      {!globalUIStore.loading && globalUIStore.notificationsReady ? (
        <AnimatePresence exitBeforeEnter>{renderRoutes()}</AnimatePresence>
      ) : null}
      <footer className={css.footer}>
        <Anchor
          asLink
          onClick={() => modalManager.open(MODALS.HELPER)}
          className={css.helpLink}
        >
          <Box className={css.helpContainer}>
            <Typography font="light">{t("all:help")}</Typography>
            <Icons.QuestionCircle
              className={css.questionIcon}
              width={20}
              height={20}
            />
          </Box>
        </Anchor>
        <div className={css.linksContainer}>
          <Anchor
            href="https://vk.com/InsideMeApp"
            className={css.link}
            target="_blank"
          >
            <Icons.VK width={24} height={24} />
          </Anchor>
          <Anchor
            href="https://www.facebook.com/InsideMeApp"
            className={css.link}
            target="_blank"
          >
            <Icons.Facebook width={24} height={24} />
          </Anchor>
          <Anchor
            href="https://www.instagram.com/inside_me_app"
            className={css.link}
            target="_blank"
          >
            <Icons.Instagram width={24} height={24} />
          </Anchor>
          <Anchor
            href="https://t.me/joinchat/rzvrYEMHxRk5MmVi"
            className={css.link}
            target="_blank"
          >
            <Icons.Telegram width={24} height={24} />
          </Anchor>
        </div>
        <div className={css.storesContainer}>
          <Anchor
            href="https://apps.apple.com/ru/app/id1400753003"
            className={css.link}
            target="_blank"
          >
            <Icons.Apple viewBox="0 0 88 24" width={80} height={24} />
          </Anchor>
          <Anchor
            href="https://play.google.com/store/apps/details?id=com.zebka.odigo"
            className={css.link}
            target="_blank"
          >
            <Icons.GooglePlay viewBox="0 0 88 24" width={82} height={24} />
          </Anchor>
        </div>
      </footer>
      <Portal>
        <Toasts align={ToastAlign.Right} />
      </Portal>
    </>
  );
});
