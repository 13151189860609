import React from "react";

interface MediumBubbleFirstProps {
  className?: string;
}

const MediumBubbleFirst = ({ className }: MediumBubbleFirstProps) => {
  return (
    <svg
      width="78"
      height="82"
      viewBox="0 0 78 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity="0.376"
        d="M11.7 77.8C17.5 81.8 27 83 37.8 81.1C55.2 78.1 71 68.1001 74.3 63.0001C76.8 59.0001 77.4 54.3 76.8 49.3C75.9 41.9 64.3 6.90007 41.6 1.60007C33.4 -0.299935 22.5 0.900071 10.6 12.2001C3.00001 19.3001 0.300007 29.3 0.100007 39.6C-0.299993 52.8 4.10001 72.5 11.7 77.8Z"
        fill="#FDF8DD"
      />
    </svg>
  );
};

export default MediumBubbleFirst;
