import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Like = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Like}>
    {({ color, secondaryColor }) => (
      <>
        <path
          d="M21.035 42.035c11.598 0 21-9.402 21-21s-9.402-21-21-21-21 9.402-21 21 9.402 21 21 21z"
          fill={color}
        />
        <path
          d="M31.766 21.587c.6-.6.9-1.3.9-2.1 0-.7-.3-1.4-.9-1.9-.6-.5-1.3-.8-2.1-.8h-4.3c0-.1 0-.3.1-.3s.1-.2.1-.3c0-.1.2-.3.2-.3.2-.3.3-.5.4-.8l.3-.9c.1-.3.1-.7.1-1.1v-.5c0-.2 0-.4-.1-.7-.1-.3-.1-.5-.2-.7-.1-.2-.2-.4-.4-.7-.2-.3-.4-.5-.6-.6-.3-.2-.6-.3-.9-.4-.3 0-.7-.1-1.2-.1-.3 0-.5.1-.7.3-.2.2-.4.5-.5.7-.1.2-.2.5-.3.7-.1.3-.2.6-.2.9 0 .3-.1.6-.2.9-.1.2-.2.5-.3.7-.1.3-.3.5-.5.7-.6.5-1.1 1.1-1.6 1.7-.5.6-1.1 1.2-1.6 1.7-.2.4-.6.7-1.1.9-.2 0-.5.1-.6.3-.2.1-.3.3-.3.5v9.1c0 .3.1.5.3.7.1.2.4.3.7.3.8.1 1.7.3 2.5.6.6.2 1.2.4 1.8.5.6.2 1.3.3 1.9.4.7.1 1.5.2 2.2.2h2.1c1.1.1 2.2-.3 3.1-1.1.6-.7.9-1.7.8-2.6.4-.3.7-.8.9-1.3.1-.5.1-1.1 0-1.7.5-.5.7-1.2.6-1.9-.2-.2-.2-.5-.4-1zM13.266 18.587h-4.5c-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7v9.1c0 .3.1.5.3.7.2.2.5.3.7.3h4.5c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7v-9.2c0-.3-.1-.5-.3-.7-.2-.1-.5-.2-.7-.2z"
          fill={secondaryColor}
        />
      </>
    )}
  </CreateSvgIcon>
);
