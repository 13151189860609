import React from "react";
import { makeStyles } from "hooks/makeStyles";
import clsx from "clsx";

interface SkeletonLineProps {
  width?: string | number;
  height?: string | number;
  size?: "small" | "medium" | "large";
  borderRadius?: number;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  "@keyframes placeHolderShimmer": {
    "0%": {
      backgroundPosition: "-468px 0",
      opacity: 0.3,
    },
    "50%": {
      opacity: 0.6,
    },
    "100%": {
      backgroundPosition: "468px 0",
      opacity: 0.3,
    },
  },
  line: {
    width: "100%",
    borderRadius: 4,
    margin: "4px 0",
    "&$small": {
      height: 8,
    },
    "&$medium": {
      height: 12,
    },
    "&$large": {
      height: 15,
    },
  },
  circle: {
    borderRadius: "50%",
    "&$small": {
      width: 34,
      height: 34,
    },
    "&$medium": {
      width: 60,
      height: 60,
    },
    "&$large": {
      width: 150,
      height: 150,
    },
  },
  skeleton: {
    animationDuration: "1s",
    animationFillMode: "forwards",
    animationIterationCount: "infinite",
    animationName: "$placeHolderShimmer",
    animationTimingFunction: "linear",
    background: "#d8d8d8",
    opacity: 0.3,
    backgroundImage:
      "linear-gradient(to right, #d8d8d8 0%, #bdbdbd 20%, #d8d8d8 40%, #d8d8d8 100%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "800px 104px",
    position: "relative",
  },
  small: {},
  medium: {},
  large: {},
}));

export const Circle = (props: SkeletonLineProps) => {
  const { width, height, size = "medium", className } = props;
  const css = useStyles();

  return (
    <div
      className={clsx(css.circle, css.skeleton, {
        [css.small]: size === "small",
        [css.medium]: size === "medium",
        [css.large]: size === "large",
        [className!]: !!className,
      })}
      style={{
        width,
        height,
      }}
    />
  );
};

export const Line = (props: SkeletonLineProps) => {
  const {
    width = "100%",
    height,
    size = "medium",
    borderRadius,
    className,
  } = props;
  const css = useStyles();

  return (
    <div
      className={clsx(css.line, css.skeleton, {
        [css.small]: size === "small",
        [css.medium]: size === "medium",
        [css.large]: size === "large",
        [className!]: !!className,
      })}
      style={{
        width,
        height,
        borderRadius,
      }}
    />
  );
};
