import React from "react";
import { makeStyles } from "hooks/makeStyles";
import clsx from "clsx";
import { Typography } from "../typography/typography";
import { Avatar } from "..";
import { Box } from "../box/box";

export interface TagProps {
  className?: string;
  text?: string;
  img?: string;
  withBg?: boolean;
  withLightBg?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "inline-flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
    alignItems: "center",
    borderRadius: 13,
    marginRight: `${theme.spacing(2.5)}`,
    marginBottom: `${theme.spacing(2.5)}`,
  },
  withBg: {
    backgroundColor: theme.colors.background.paper.lighter,
  },
  withLightBg: {
    backgroundColor: theme.colors.purple.main,
  },
}));

export const Tag = (props: TagProps) => {
  const css = useStyles(props as any);
  const { text, img, withBg, withLightBg, className, ...passthrough } = props;

  const cx = clsx(css.container, {
    [className!]: !!className,
    [css.withBg]: withBg,
    [css.withLightBg]: withLightBg,
  });

  return (
    <div className={cx}>
      {img ? (
        <Box mr={2}>
          <Avatar.Minified width={20} height={20} img={img} />
        </Box>
      ) : null}
      <Typography font="light" className={css.text} {...passthrough}>
        {text}
      </Typography>
    </div>
  );
};

export function pickTagProps(props: any): { tagProps: TagProps; other: any } {
  const { ...other } = props;

  return {
    tagProps: {},
    other,
  };
}
