import { extendPrototypeResult as localforage } from "localforage-indexes";
import lruDriver from "localforage-lru-driver";

interface LRUCacheInstance {
  setItem: (key: string, value: any) => Promise<LRUCacheInstance>;
  getItem: (key: string) => Promise<LRUCacheInstance>;
}

class LRUCache {
  constructor(private _cacheSize: number = 500) {
    this.init();
  }

  private _lruCache?: LRUCacheInstance;

  private init() {
    localforage.defineDriver(lruDriver).then(() => {
      const lf = localforage.createInstance({
        driver: "lruStorage",
        cacheSize: this._cacheSize,
      });

      this._lruCache = lf;
    });
  }

  setItem(key: string, value: any) {
    return new Promise((resolve, reject) => {
      if (!this._lruCache) {
        return reject();
      }
      return resolve(this._lruCache.setItem(key, value));
    });
  }

  getItem(key: string) {
    return new Promise((resolve, reject) => {
      if (!this._lruCache) {
        return reject();
      }
      return resolve(this._lruCache.getItem(key));
    });
  }
}

export const lruCache = new LRUCache();
