import { action, observable } from "mobx";

class ModalManager {
  @observable modals: string[] = [];
  @observable opened: { [key: string]: any } = {};
  @observable stack: string[] = [];

  @action
  open(modalId: string, key?: string, props?: { [key: string]: any }) {
    if (key) {
      this.push(key);
    }
    this.opened[modalId] = props ? props : true;
    this.add(modalId);
  }

  @action
  close(modalId: string) {
    this.modals = this.modals.filter((modal) => modal !== modalId);
    this.remove(modalId);
  }

  @action
  add(modalId: string) {
    if (this.modals.indexOf(modalId) === -1) {
      this.modals.push(modalId);
    }
  }

  @action
  remove(modalId: string) {
    this.opened[modalId] = false;
  }

  @action
  push(key: string) {
    const existed = this.stack.indexOf(key);
    if (existed !== -1) {
      this.stack = [...this.stack.filter((item) => item !== key), key];
      return;
    }
    this.stack.push(key);
  }

  @action
  pop() {
    this.stack.pop();
  }

  @action
  clearStack() {
    this.stack = [];
  }

  getCurrentModal() {
    return this.modals.length && this.modals[this.modals.length - 1];
  }

  isTopModal(modalId: string) {
    return (
      !!this.modals.length && this.modals[this.modals.length - 1] === modalId
    );
  }
}

export const modalManager = new ModalManager();
