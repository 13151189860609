import { modalManager } from "global-stores/modal-manager";
import { makeStyles } from "hooks/makeStyles";
import { MODALS } from "lib/constants";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AvatarResizer,
  Button,
  DefaultPanel,
  Dropdown,
  IconButton,
  Icons,
  Modal,
  UploadFile,
} from "ui/shared";
import { ProgressLoader } from "ui/shared/progress-loader/progress-loader";
import { avatarsStore } from "../modals/avatars-modal/store.avatars-modal";
import PhotoCapture from "ui/components/photo-capture/photo-capture";
import { DropdownProps } from "ui/shared/dropdown/dropdown";

interface AddAvatarDropdownProps {
  isAdditionalInfo?: boolean;
}

const useStyles = makeStyles((theme) => ({
  avatarDropdownContainer: {
    width: 150,
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1),
  },
  avatarDropdown: {
    minWidth: 250,
    backgroundColor: theme.colors.background.paper.topbar,
    padding: theme.spacing(3.5),
    boxShadow: theme.shadows.dropdown,
    borderRadius: 10,
    "& > *": {
      height: 46,
      backgroundColor: theme.colors.background.paper.lighter,
      "&:hover, &:active": {
        backgroundColor: theme.colors.purple.main,
        color: theme.colors.background.paper.main,
      },
    },
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(2.5),
    },
  },
  addAvatarBtn: {
    width: 32,
    height: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.purple.main,
  },
}));

const AddAvatarDropdown = (
  props: AddAvatarDropdownProps & Partial<DropdownProps>
) => {
  const css = useStyles();
  const { t } = useTranslation("all");

  const { isAdditionalInfo, ...dropdownProps } = props;

  const [isCapturingPhoto, setIsCapturingPhoto] = useState(false);
  const [draftAvatar, setDraftAvatar] = useState<File | string>();

  const handleUploadFile = useCallback((files: FileList | null) => {
    if (files && files.length) {
      modalManager.open(MODALS.AVATAR_RESIZER);
      setDraftAvatar(files[0]);
    }
  }, []);

  const handleOpenAvatarsModal = useCallback(() => {
    if (isAdditionalInfo) {
      modalManager.open(MODALS.PROFILE_AVATARS, undefined, {
        isAdditionalInfo: true,
      });
    } else {
      modalManager.open(MODALS.PROFILE_AVATARS);
    }
  }, [isAdditionalInfo]);

  const handleUploadAvatar = useCallback(
    (file: Blob | null) => {
      if (file) {
        if (isAdditionalInfo) {
          avatarsStore.uploadAvatar(file, { one: "true" });
        } else {
          avatarsStore.uploadAvatar(file);
        }
        modalManager.close(MODALS.AVATAR_RESIZER);
      }
    },
    [isAdditionalInfo]
  );

  const handleCapturePhotoOpen = useCallback(() => {
    setIsCapturingPhoto(true);
  }, [setIsCapturingPhoto]);

  const handleCapturePhoto = useCallback((photo: string) => {
    setDraftAvatar(photo);
    modalManager.open(MODALS.AVATAR_RESIZER);
  }, []);

  const handleCaptureModalClosed = useCallback(() => {
    setIsCapturingPhoto(false);
  }, [setIsCapturingPhoto]);

  return (
    <>
      <Dropdown
        preferRight
        render={() => (
          <div className={css.avatarDropdown}>
            <Button fullWidth onClick={handleOpenAvatarsModal}>
              {t("all:avatars_collections_button")}
            </Button>
            <UploadFile onUpload={handleUploadFile}>
              <Button disabled={!!avatarsStore.activeUpload} fullWidth>
                {avatarsStore.activeUpload ? (
                  <ProgressLoader />
                ) : (
                  t("all:avatars_upload_button")
                )}
              </Button>
            </UploadFile>
            <Button fullWidth onClick={handleCapturePhotoOpen}>
              {t("all:avatars_photo_button")}
            </Button>
          </div>
        )}
        className={css.avatarDropdownContainer}
        {...dropdownProps}
      >
        <IconButton className={css.addAvatarBtn}>
          <Icons.Add color="#fff" />
        </IconButton>
      </Dropdown>
      <PhotoCapture
        open={isCapturingPhoto}
        onCapturePhoto={handleCapturePhoto}
        onClose={handleCaptureModalClosed}
      />
      <Modal modalId={MODALS.AVATAR_RESIZER}>
        {(_, className) => (
          <DefaultPanel
            title={t("all:avarars_edit_title")}
            className={className}
          >
            {draftAvatar ? (
              <AvatarResizer image={draftAvatar!} onSave={handleUploadAvatar} />
            ) : null}
          </DefaultPanel>
        )}
      </Modal>
    </>
  );
};

export default AddAvatarDropdown;
