import { Meteor } from "meteor/meteor";

export const callWithPromise = <T = any>(
  method: string,
  ...args: any[]
): Promise<T> => {
  return new Promise((resolve, reject) => {
    Meteor.call(method, ...args, (error: Meteor.Error, result: any) => {
      if (error) reject(error);
      resolve(result);
    });
  });
};
