import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Gif = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Gif}>
    {({ color }) => (
      <>
        <g transform="translate(-857 -574)">
          <g transform="translate(858 575)">
            <g transform="translate(3.753 9.676)">
              <path
                d="M18.94,38.365H16.716V36.741h4.2V42.46a14.768,14.768,0,0,1-3.627.438c-3.228,0-4.78-1.245-4.78-5.4s1.74-5.248,5.059-5.248a13.685,13.685,0,0,1,3.069.381l-.393,1.683a10.429,10.429,0,0,0-2.529-.3c-2.31,0-3.144.381-3.144,3.51s.834,3.624,3.054,3.624A6.178,6.178,0,0,0,18.943,41Z"
                transform="translate(-12.51 -32.25)"
                fill="#fff"
              />
              <path
                d="M50.249,33.09v10.2H48.32V33.09Z"
                transform="translate(-37.576 -32.838)"
                fill="#fff"
              />
              <path
                d="M70.582,33.062V34.73H65.259v2.88h3.9v1.65h-3.9v3.99H63.33V33.05Z"
                transform="translate(-48.082 -32.81)"
                fill="#fff"
              />
            </g>
            <path
              d="M25.2,1.5a3.3,3.3,0,0,1,3.3,3.3V25.2a3.3,3.3,0,0,1-3.3,3.3H4.8a3.3,3.3,0,0,1-3.3-3.3V4.8A3.3,3.3,0,0,1,4.8,1.5H25.2m0-1.5H4.8A4.8,4.8,0,0,0,0,4.8V25.2A4.8,4.8,0,0,0,4.8,30H25.2A4.8,4.8,0,0,0,30,25.2V4.8A4.8,4.8,0,0,0,25.2,0Z"
              fill="#fff"
            />
          </g>
        </g>
        <rect width="32" height="32" fill="none" />
      </>
    )}
  </CreateSvgIcon>
);
