import { chatsStore } from "global-stores/chats-store";
import { modalManager } from "global-stores/modal-manager";
import translator from "global-stores/translator-store";
import { userStore } from "global-stores/user-store";
import { makeStyles } from "hooks/makeStyles";
import { Friend } from "lib";
import { MODALS } from "lib/constants";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Languages } from "ui/pages/profile/modals/languages";
import { Anchor, Box, Button, Dropdown, Switch, Typography } from "ui/shared";
import { capitalize } from "utils";
import { TranslationSettings } from "../stores/chat-store";

interface TranslateSettingsModalProps {
  friend: Friend;
}

const useStyles = makeStyles((theme) => ({
  translateSettingsContainer: {
    minWidth: 400,
  },
  switchContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 46,
  },
  changeButton: {
    padding: "5px 9px",
    paddingBottom: 7,
  },
  vipWarning: {
    backgroundColor: "#F6E79E",
    textAlign: "center",
    padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
  },
  vipAnchor: {
    color: theme.colors.textBlack,
    textDecoration: "underline",
    lineHeight: 2,
  },
  languagesContainer: {
    backgroundColor: theme.colors.background.paper.lighter,
    borderRadius: 10,
    minWidth: 144,
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}));

const TranslateSettingsModal = observer(
  (props: TranslateSettingsModalProps) => {
    const { friend } = props;
    const css = useStyles();
    const { t } = useTranslation("all");
    const history = useHistory();

    const translationSettings =
      chatsStore.selectedChatStore &&
      chatsStore.selectedChatStore.translationSettings;

    const updateTranslation = useCallback((value: boolean) => {
      const updates: Partial<TranslationSettings> = {
        useTranslate: value,
      };
      if (!value) {
        updates.autoTranslation = false;
      }
      if (value) {
        if (!userStore.isVIP && !userStore.isVerfiedUser) {
          modalManager.open(MODALS.TRANSLATE_VER_ALERT);
          return;
        }
        updates.hideTranslation = false;
      }
      updateTranslationSettings(updates);
      if (!translator.isGlobalTranslationEnabled) {
        translator.setIsGlobalTranslationEnabled(true);
      }
    }, []);

    const updateAutoTranslation = useCallback(
      (value: boolean) => {
        const updates: Partial<TranslationSettings> = {
          autoTranslation: value,
        };
        if (value) {
          if (!userStore.isVIP && !userStore.isVerfiedUser) {
            modalManager.open(MODALS.TRANSLATE_VER_ALERT);
            return;
          }
          if (translationSettings && !translationSettings.useTranslate) {
            updates.useTranslate = true;
          }
        }

        updateTranslationSettings(updates);
      },
      [translationSettings]
    );

    const updateMyLang = useCallback((value: string) => {
      updateTranslationSettings({ myLang: value });
    }, []);

    const updateFriendLang = useCallback((value: string) => {
      updateTranslationSettings({ friendLang: value });
    }, []);

    const updateTranslationSettings = useCallback(
      (settings: Partial<TranslationSettings>) => {
        if (chatsStore.selectedChatStore) {
          chatsStore.selectedChatStore.setTranslationSettings(settings);
        }
      },
      [chatsStore.selectedChatStore]
    );

    const screenHeight = document.body.clientHeight;

    return translationSettings ? (
      <div className={css.translateSettingsContainer}>
        <Box pb={4} mt={4} pr={4} pl={4}>
          <Typography size="caption(12px)">
            {t("all:trans_settings_for")} {friend.friendLogin}
          </Typography>
        </Box>
        <Box pr={4} pl={4} className={css.switchContainer}>
          <Typography>{t("all:trans_settings_switch")}</Typography>
          <Switch
            defaultBgColor="#655285"
            checked={
              (translator.isGlobalTranslationEnabled &&
                translationSettings.useTranslate) ??
              false
            }
            onChange={updateTranslation}
          />
        </Box>
        <Box pr={4} pl={4} className={css.switchContainer}>
          <Typography>{t("all:trans_settings_auto_switch")}</Typography>
          <Switch
            defaultBgColor="#655285"
            checked={translationSettings.autoTranslation ?? false}
            onChange={updateAutoTranslation}
          />
        </Box>
        {userStore.isVIP || !userStore.isVerfiedUser ? null : (
          <Box mt={2} mb={2} className={css.vipWarning}>
            <Typography color="black">{t("all:trans_settings_vip")}</Typography>
            <Anchor
              asLink
              className={css.vipAnchor}
              onClick={() => history.push("/vip")}
            >
              {t("all:profile_vip_button")}
            </Anchor>
          </Box>
        )}
        <Box pr={4} pl={4} className={css.switchContainer}>
          <Typography size="subtitle2(16px)">
            {capitalize(t("all:trans_settings_lang"))}:{" "}
            {translationSettings.friendLang}
          </Typography>
          <Dropdown
            preferCenter
            render={(close) => (
              <div className={css.languagesContainer}>
                <Languages
                  includeAuto
                  selected={[translationSettings.friendLang]}
                  onSelectLanguage={(e, value) => {
                    updateFriendLang(value);
                    close();
                  }}
                />
              </div>
            )}
            overlayStyles={{ top: -screenHeight * 0.5 * 0.6 }}
          >
            <Button secondary className={css.changeButton}>
              <Typography size="body(14px)" lowercase>
                {t("all:main_button_avatar_edit")}
              </Typography>
            </Button>
          </Dropdown>
        </Box>
        <Box pr={4} pl={4} pb={4} className={css.switchContainer}>
          <Typography size="subtitle2(16px)">
            {capitalize(t("all:trans_settings_my_lang"))}:{" "}
            {translationSettings.myLang}
          </Typography>
          <Dropdown
            preferCenter
            render={(close) => (
              <div className={css.languagesContainer}>
                <Languages
                  selected={[
                    chatsStore.selectedChatStore!.translationSettings!.myLang,
                  ]}
                  onSelectLanguage={(e, value) => {
                    updateMyLang(value);
                    close();
                  }}
                />
              </div>
            )}
            overlayStyles={{ top: -screenHeight * 0.5 * 0.6 }}
          >
            <Button secondary className={css.changeButton}>
              <Typography size="body(14px)" lowercase>
                {t("all:main_button_avatar_edit")}
              </Typography>
            </Button>
          </Dropdown>
        </Box>
      </div>
    ) : null;
  }
);

export default TranslateSettingsModal;
