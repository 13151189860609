import { observable, action } from "mobx";
import { Gender, Age, ADDITIONAL_INFO_KEY } from "lib";
import { storage, callWithPromise } from "utils";
import { globalUIStore } from "global-stores/global-ui-store";

const defaultAge = [20, 30];

export class AdditionalInfoStore {
  @observable gender: Gender = "";
  @observable age: Age = "";
  @observable isAvatarModalShown: boolean = false;
  @observable ageSliderValue: number[] = defaultAge;

  @observable isAwareOfChangingAvatar: boolean = false;
  @observable isAwareOfFillingAdditionalInfo: boolean = false;

  @action
  setGender(value: Gender) {
    if (value !== this.gender && value != null) {
      this.gender = value;
    }
    this.isAwareOfFillingAdditionalInfo =
      this.gender !== "" || this.age !== "" ? true : false;
  }

  @action
  setAge(value: Age) {
    if (value !== this.age && value != null) {
      this.age = value;
    }
    this.isAwareOfFillingAdditionalInfo =
      this.gender !== "" || this.age !== "" ? true : false;
  }

  @action
  awareOfChangingAvatar(value: boolean) {
    this.isAwareOfChangingAvatar = value;
  }

  @action
  awareOfFillingAdditionalInfo(value: boolean) {
    this.isAwareOfFillingAdditionalInfo = value;
  }

  @action
  setAgeSliderValue(value: number[], activeIndex: number) {
    /**
     * Normalize value depends of activeIndex(active thumb of the slider) to
     * to satisfy the condition = max - min <= 10
     */
    if (activeIndex === 0) {
      if (value[1] - value[0] > 10) {
        const normalizedValue = [value[0], value[0] + 10];
        this.ageSliderValue = normalizedValue;
      } else {
        this.ageSliderValue = value;
      }
    } else {
      if (value[1] - value[0] > 10) {
        const normalizedValue = [value[1] - 10, value[1]];
        this.ageSliderValue = normalizedValue;
      } else {
        this.ageSliderValue = value;
      }
    }
  }

  @action
  showAvatarModal() {
    this.isAvatarModalShown = true;
  }

  @action
  hideAvatarModal() {
    this.isAvatarModalShown = false;
  }

  @action
  handleSetAdditionalInfo() {
    // Update selected avatar

    // Update user profile with additional data
    if (this.gender) {
      callWithPromise("users.setProfileValue", {
        name: "gender",
        value: this.gender,
      });
    }
    if (this.age) {
      callWithPromise("users.setProfileValue", {
        name: "age",
        value: this.ageSliderValue,
      });
    }

    // Store flag into local storage
    storage.write(ADDITIONAL_INFO_KEY, true);
    globalUIStore.setUserFilledAdditionalInfo(true);
  }
}
