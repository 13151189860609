import { action } from "mobx";
import { callWithPromise } from "utils";

export class ChatFlowStore {
  @action
  async setLike(id: string) {
    try {
      await callWithPromise("friends.like", {
        friendId: id
      });
    } catch (err) {
      console.log(err);
    }
  }

  @action
  async setUnLike(id: string) {
    try {
      await callWithPromise("friends.unLike", {
        friendId: id
      });
    } catch (err) {
      console.log(err);
    }
  }
}
