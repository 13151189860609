import { makeStyles } from "hooks/makeStyles";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AnimatedPage, Box, Button, Input, Typography } from "ui/shared";
import { observer } from "mobx-react";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import VipSelectedOptionCard from "./vip-selected-option-card";
import {
  CardElement,
  useElements,
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
import { fade } from "utils";
import vipStore from "global-stores/vip-store";
import PayformUIStore from "./payform-ui-store";
import { ToastType } from "lib";
import { toastStore } from "global-stores/toasts-store";
import SquareLoader from "ui/shared/square-loader/square-loader";
import clsx from "clsx";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { euroPrice, getCurrencySymbol } from "utils/price";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    background: theme.colors.background.paper.main,
  },
  payFormContainer: {
    width: "100%",
    maxWidth: "45%",
    marginTop: theme.spacing(5),
    position: "relative",
  },
  cardInfoContainer: {
    display: "flex",
    alignItems: "center",
    height: 46,
    backgroundColor: fade(theme.colors.background.paper.lighter, 0.4),
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(1),
    borderBottom: `2px solid ${fade(theme.colors.common.white, 0.6)}`,
    "& > *:first-child": {
      width: "100%",
    },
    "&$error": {
      borderBottom: `2px solid ${theme.colors.red.main} !important`,
    },
  },
  emailInput: {
    border: 0,
    borderRadius: 0,
    borderBottom: `2px solid ${fade(theme.colors.common.white, 0.6)}`,
    backgroundColor: fade(theme.colors.background.paper.lighter, 0.4),
    color: theme.colors.common.white,
    "&:focus, &:active": {
      border: 0,
      borderRadius: 0,
      borderBottom: `2px solid ${theme.colors.common.white}`,
    },
    "&::placeholder": {
      color: fade(theme.colors.common.white, 0.4),
    },
    marginBottom: theme.spacing(4),
  },
  emailError: {
    borderBottom: `2px solid ${theme.colors.red.main} !important`,
  },
  paymentInProgress: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: fade(theme.colors.background.dark, 0.9),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    "& > *:first-child": {
      marginBottom: theme.spacing(3),
    },
  },
  payButton: {
    marginTop: theme.spacing(5),
    height: 46,
  },
  title: {
    textAlign: "center",
    textDecoration: "uppercase",
    marginTop: `${theme.spacing(5.75)}`,
    marginBottom: `${theme.spacing(5.5)}`,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(5),
  },
  link: {
    fontSize: 12,
  },
  error: {},
}));

interface VIPPayFormProps extends RouteComponentProps<{ optionId: string }> {}

const CARD_OPTIONS: any = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#45385B",
      color: "#fff",
      fontFamily: "SFUIText-Regular, Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      ":-webkit-autofill": {
        color: "#15131E",
      },
      "::placeholder": {
        color: "rgba(255,255,255,0.4)",
      },
    },
    invalid: {
      iconColor: "#FF4E00",
      color: "#FF4E00",
      borderBottom: "2px solid red",
    },
  },
};

const VIPPayForm = observer((props: VIPPayFormProps) => {
  const css = useStyles();
  const { t } = useTranslation("all");
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const [store] = useState(new PayformUIStore());

  const {
    match: { params },
  } = props;

  const option = vipStore.selectedOptionById(params.optionId);

  useEffect(() => {
    if (!option) return;

    vipStore.createStripeSubscription(option.priceId);
  }, [option]);

  /**************************************************/

  const onChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      store.setEmail(e.target.value);
    },
    [store]
  );

  const onChangeCardInput = useCallback(
    (e: StripeCardElementChangeEvent) => {
      if (e.error && store.cardError !== e.error) {
        store.setCardError(e.error);
      } else if (!e.error && store.cardError) {
        store.setCardError();
      }
    },
    [store]
  );

  const handlePayment = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (!stripe || !elements || !vipStore.selectedSubscription) return;

      const cardElement = elements.getElement(CardElement);

      if (!cardElement) return;

      store.setInProgress(true);

      stripe
        .confirmCardPayment(vipStore.selectedSubscription.clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              email: store.email,
            },
          },
        })
        .then((result) => {
          if (result.error) {
            toastStore.showToast({
              type: ToastType.Error,
              message: result.error.message || "Unknown error from stripe",
            });
          } else {
            // Successful subscription payment
            vipStore.setSuccessfullyPaid(true);
            history.push("/vip");
          }
          store.setInProgress(false);
        });
    },
    [stripe, elements, store, history]
  );

  /**************************************************/

  if (!option) return <Redirect to="/vip" />;

  return (
    <AnimatedPage
      animation="horizontal"
      containerClassName={css.root}
      back={{
        label: t("all:subscription_pay"),
        path: "/vip",
        boxProps: {
          pl: 5,
        },
      }}
    >
      <Box className={css.container}>
        <VipSelectedOptionCard option={option} />
        <form onSubmit={() => {}} className={css.payFormContainer}>
          {store.inProgress ? (
            <Box className={css.paymentInProgress}>
              <SquareLoader />
              <Typography>{t("all:pay_process_text")}</Typography>
            </Box>
          ) : null}
          <Input
            placeholder={t("all:verification_title") + "*"}
            className={css.emailInput}
            onChange={onChangeEmail}
            value={store.email}
            error={!!store.emailError}
            errorClassName={css.emailError}
          />
          <Box
            className={clsx(css.cardInfoContainer, {
              [css.error]: !!store.cardError,
            })}
          >
            <CardElement options={CARD_OPTIONS} onChange={onChangeCardInput} />
          </Box>
          <Button
            fullWidth
            type="submit"
            disabled={
              !stripe ||
              !elements ||
              !vipStore.selectedSubscription ||
              !store.email ||
              !!store.emailError ||
              store.inProgress
            }
            className={css.payButton}
            onClick={handlePayment}
          >
            Pay {euroPrice(option.payPrice)}{" "}
            {getCurrencySymbol(option.currency)}
          </Button>
        </form>
      </Box>
    </AnimatedPage>
  );
});

const InjectedVIPPayForm = observer((props: VIPPayFormProps) => {
  return vipStore.stripe ? (
    <Elements stripe={vipStore.stripe}>
      <VIPPayForm {...props} />
    </Elements>
  ) : null;
});

export default InjectedVIPPayForm;
