import { userStore } from "global-stores/user-store";
import { observable, action } from "mobx";

export class InterestsStore {
  constructor(interests: string[]) {
    this.interests = interests;
  }

  @observable interests: string[] = [];
  @observable localInterests: string[] = [];

  @action
  setInterests(value: string[]) {
    const limit = userStore.interestsLimit;
    if (!value || (limit !== 0 && value.length > limit)) return;
    this.interests = value;
  }

  @action setLocalInterests(value: string[]) {
    const limit = userStore.interestsLimit;
    if (!value || (limit !== 0 && value.length > limit)) return;
    this.localInterests = value;
  }
}
