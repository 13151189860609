import memoizee from "memoizee";
import { ChatContext } from "ui/pages/chats/chats";

const calculateBoxSize = memoizee(
  (context?: ChatContext | null, boxSize?: string, k = 0.4) => {
    if (context && boxSize) {
      const parsedSize = boxSize
        .replace(/[[\]]/g, "")
        .split(",")
        // for now only first 2 elements
        .slice(0, 2)
        .reduce((acc, value, index) => {
          return {
            ...acc,
            [index === 0 ? "width" : "height"]: +value,
          };
        }, {}) as { width: number; height: number };
      const imageRatio = parsedSize.width / parsedSize.height;
      // Has paddings = 24px
      const maxImageHeight = (context.height - 48) * k;
      const maxImageWidth = (context.width - 48) * k;

      if (parsedSize.width >= maxImageWidth) {
        const tempHeight = maxImageWidth / imageRatio;

        return tempHeight >= maxImageHeight
          ? {
              width: maxImageHeight * imageRatio,
              height: maxImageHeight,
            }
          : {
              width: maxImageWidth,
              height: tempHeight,
            };
      }

      const calculatedHeight =
        parsedSize.height >= maxImageHeight
          ? maxImageHeight
          : parsedSize.height;
      const calculatedWidth = calculatedHeight * imageRatio;
      return {
        width: calculatedWidth,
        height: calculatedHeight,
      };
    }
  },
  { max: 150, length: 3 }
);

export default calculateBoxSize;
