import React from "react";
import { makeStyles } from "hooks/makeStyles";
import clsx from "clsx";

export interface SliderLabelProps {
  value: number;
  open: boolean;
  className?: string;
  displayValue?: "on" | "off";
  children: React.ReactElement;
}

const useStyles = makeStyles(theme => ({
  thumb: {
    "&$open": {
      "& $container": {
        transform: "scale(1) translateY(-10px)"
      }
    }
  },
  open: {},
  container: {
    zIndex: 1,
    top: -25,
    lineHeight: 1.2,
    transformOrigin: "bottom center",
    transform: "scale(0)",
    position: "absolute",
    transition: "transform .2s ease-in-out"
  },
  label: {
    color: theme.colors.textPrimary,
    fontSize: "1.6rem"
  }
}));

export const SliderLabel = (props: SliderLabelProps) => {
  const { value, open, className, displayValue, children } = props;
  const css = useStyles();

  if (displayValue === "off") {
    return children;
  }

  return React.cloneElement(
    children,
    {
      className: clsx(
        children.props.className,
        {
          [css.open]: open || displayValue === "on"
        },
        css.thumb
      )
    },
    <span className={clsx(css.container, className)}>
      <span className={css.label}>{value}</span>
    </span>
  );
};
