import { observable, action } from "mobx";
import { callWithPromise } from "utils";

export class SettingsStore {
  @observable deleting: boolean = false;
  @observable displayItems: string[] = [];

  @action
  setDisplayItems(value: string[]) {
    this.displayItems = value;
  }

  @action
  setDeleting(value: boolean) {
    this.deleting = value;
  }

  @action
  async deleteAccount() {
    try {
      await callWithPromise("users.clearWebDeviceToken");
    } catch (err) {
      console.log(err);
    }
  }
}
