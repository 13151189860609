import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "hooks/makeStyles";
import {
  Typography,
  Icons,
  IconButton,
  Button,
  Input,
  Loader,
} from "ui/shared";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { inviteStore } from "global-stores/invite-store";
import { clipboardStore } from "global-stores/clipboard-store";
import { qrCodeStore } from "global-stores/qrcode-store";
import { modalManager } from "global-stores/modal-manager";
import { MODALS } from "lib/constants";

export interface InviteFriendProps {
  code: string;
}

const useStyles = makeStyles((theme) => ({
  body: {
    height: "inherit",
    padding: `${theme.spacing(5.5)}`,
  },
  caption: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 19,
  },
  captionText: {
    marginRight: 10,
  },
  inputWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    marginBottom: 20,
    position: "relative",
  },
  questionButton: {},
  codeInput: {
    flexGrow: "1",
    border: "none !important",
    backgroundColor: "inherit",
    textAlign: "center",
    padding: "12px 12px 12px 0",
    textTransform: "uppercase",
    "&:placeholder": {
      color: theme.colors.purple.main,
    },
    "&:focus": {
      outline: 0,
      border: "none",
    },
  },
  karmaWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: "0",
  },
  wrapperBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  validText: {
    color: theme.colors.green.secondary,
    position: "absolute",
    bottom: -22,
  },
  errorText: {
    color: theme.colors.red.secondary,
    position: "absolute",
    bottom: -22,
  },
  helperText: {
    textAlign: "left",
  },
  qrcodeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 40,
  },
  codePanel: {
    width: 320,
    padding: `${theme.spacing(5.5)}`,
    "&:focus": {
      outline: 0,
    },
  },
}));

export const InviteFriend = observer((props: InviteFriendProps) => {
  const { t } = useTranslation("all");
  const css = useStyles();

  const [qrcode, setQRCode] = useState<string>();

  const { code } = props;

  const myCode = code || inviteStore.myInviteCode;

  /**************************************************/

  const handleSendInviteCode = useCallback(
    (e: any) => {
      e.preventDefault();

      const title = t("all:infomation_button_share_text").replace(/\$1/, "");
      const text = t("all:infomation_button_share_text_w_code")
        .replace(/\$1/, "https://odi.page.link/K2YS")
        .replace(
          /\$2/,
          code.substring(0, 4).toUpperCase() +
            "-" +
            code.substring(4).toUpperCase()
        );
      const url = "https://odi.page.link/K2YS";

      if ((navigator as any).share) {
        (navigator as any)
          .share({
            title,
            text,
            url,
          })
          .then(() => console.log("Successful share"))
          .catch((error: any) => console.log("Error sharing", error));
      } else {
        console.log("Web Share API is not supported in your browser.");
        clipboardStore.copy(`${text}`);
      }
    },
    [code, t]
  );

  /**************************************************/

  useEffect(() => {
    async function generateQRCode() {
      if (!myCode) {
        inviteStore.getMyInviteCode();
      } else {
        const generatedQRCode = await qrCodeStore.generateQRCode(myCode);
        if (generatedQRCode) {
          setQRCode(generatedQRCode);
        }
      }
    }

    generateQRCode();
  }, [code]);

  if (!myCode) {
    return <Loader />;
  }

  return (
    <div className={css.body}>
      <div className={css.caption}>
        <Typography className={css.captionText}>
          {t("all:invite_you_code")}
        </Typography>
        <IconButton
          className={css.questionButton}
          onClick={() => modalManager.open(MODALS.HELPER, "invite_help")}
        >
          <Icons.QuestionCircle width={20} height={20} />
        </IconButton>
      </div>
      <div className={css.inputWrap}>
        <Input
          autoFocus
          className={css.codeInput}
          value={myCode.substr(0, 4) + "-" + myCode.substr(4)}
          mt={2}
          readOnly
        />
      </div>
      <div className={css.qrcodeContainer}>
        <img src={qrcode} alt="Invite qrcode" />
      </div>
      <div className={css.wrapperBtn}>
        <Button primary onClick={(e) => handleSendInviteCode(e)}>
          {t("all:invite_share_button")}
        </Button>
      </div>
    </div>
  );
});
