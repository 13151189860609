import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const GooglePlay = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.GooglePlay}>
    {({ color }) => (
      <>
        <g fill="none">
          <path d="M12 0h64c6.6 0 12 5.4 12 12s-5.4 12-12 12H12C5.4 24 0 18.6 0 12S5.4 0 12 0z" />
          <path
            d="M12 .5h64C82.4.5 87.5 5.6 87.5 12h0c0 6.4-5.1 11.5-11.5 11.5H12C5.6 23.5.5 18.4.5 12h0C.5 5.6 5.6.5 12 .5z"
            stroke="#fff"
          />
        </g>
        <path
          d="M43.759 9.935c-1.3 0-2.3 1-2.3 2.2v.1c0 1.3 1 2.3 2.3 2.3 1.3 0 2.3-1 2.3-2.2v-.1c0-1.2-.9-2.3-2.2-2.3h-.1zm0 3.7c-.8-.1-1.4-.7-1.3-1.5.1-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3v.1c0 .8-.6 1.4-1.3 1.4zm-5.1-3.7c-1.3 0-2.3 1-2.3 2.2v.1c0 1.3 1 2.3 2.3 2.3 1.3 0 2.3-1 2.3-2.2v-.1c0-1.2-.9-2.3-2.2-2.3h-.1zm0 3.7c-.8-.1-1.4-.7-1.3-1.5.1-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3v.1c0 .8-.5 1.4-1.3 1.4zm-6-3v1h2.3c0 .5-.2.9-.5 1.2-.5.5-1.1.7-1.8.7-1.4 0-2.5-1.1-2.6-2.5v-.1c0-1.4 1.1-2.6 2.5-2.6.7 0 1.3.3 1.8.7l.7-.7c-.7-.6-1.5-1-2.4-1-2 0-3.6 1.5-3.6 3.5s1.5 3.6 3.5 3.6h.1c.9.1 1.9-.3 2.5-1 .6-.6.9-1.4.9-2.3 0-.2 0-.4-.1-.6l-3.3.1zm24.6.8c-.3-.9-1.1-1.4-2-1.5-1.2 0-2.2 1-2.2 2.2v.1c0 1.3 1 2.3 2.2 2.3h.1c.8 0 1.5-.4 1.9-1l-.8-.5c-.2.4-.7.6-1.1.6-.5 0-.9-.3-1.1-.7l3.1-1.3-.1-.2zm-3.1.8c0-.7.5-1.3 1.2-1.4.4 0 .7.2.9.5l-2.1.9zm-2.6 2.2h1v-6.8h-1v6.8zm-1.6-4c-.3-.3-.8-.5-1.2-.5-1.2.1-2.2 1.1-2.2 2.3 0 1.2 1 2.3 2.2 2.3.5 0 .9-.2 1.2-.5h.1v.3c.1.7-.4 1.3-1.1 1.3-.6.1-1.1-.3-1.3-.8l-.9.4c.4.8 1.2 1.4 2.1 1.4 1.2.1 2.1-.8 2.2-2v-4.5h-1l-.1.3zm-1.2 3.2c-.8-.1-1.4-.7-1.3-1.5.1-.7.6-1.3 1.3-1.3.8.1 1.3.8 1.2 1.6 0 .6-.5 1.1-1.2 1.2zm13.3-6h-2.4v6.8h1v-2.5h1.4c1.2.1 2.2-.8 2.2-2s-.8-2.2-2-2.2c-.1-.1-.2-.1-.2-.1zm0 3.3h-1.5v-2.3h1.5c.6 0 1.2.5 1.2 1.1 0 .6-.5 1.1-1.2 1.2zm6.3-1c-.8-.1-1.5.3-1.8 1l.9.4c.2-.3.5-.5.9-.5.5 0 1 .3 1.1.9v.1c-.3-.2-.7-.3-1-.3-1 0-2 .5-2 1.5 0 .9.8 1.6 1.7 1.5.5 0 1-.2 1.3-.6h.1v.5h1v-2.6c-.2-1.1-1.1-2-2.2-1.9zm-.1 3.7c-.3 0-.8-.2-.8-.6 0-.5.6-.7 1.1-.7.3 0 .6.1.9.2-.1.6-.6 1.1-1.2 1.1zm5.7-3.6l-1.1 2.9h-.1l-1.2-2.9h-1.1l1.8 4.1-1 2.3h1l2.8-6.4h-1.1zm-9.2 4.4h1v-6.8h-1v6.8z"
          fill="#fff"
        />
        <g transform="translate(13 5)">
          <linearGradient
            id="google-a"
            gradientUnits="userSpaceOnUse"
            x1="-149.3111"
            y1="438.2908"
            x2="-149.3821"
            y2="438.2548"
            gradientTransform="matrix(7.0656 0 0 -13.593 1626.4307 5407.5059)"
          >
            <stop offset="0" stopColor="#00a0ff" />
            <stop offset=".007" stopColor="#00a1ff" />
            <stop offset=".26" stopColor="#00beff" />
            <stop offset=".512" stopColor="#00d2ff" />
            <stop offset=".76" stopColor="#00dfff" />
            <stop offset="1" stopColor="#00e3ff" />
          </linearGradient>
          <path
            d="M.2.2C0 .4 0 .7 0 1v12c0 .3.1.6.3.8l.1.1L7.1 7v-.1L.2.2z"
            fill="url(#google-a)"
          />
          <linearGradient
            id="google-b"
            gradientUnits="userSpaceOnUse"
            x1="-125.9371"
            y1="339.5194"
            x2="-126.065"
            y2="339.5194"
            gradientTransform="matrix(5.5121 0 0 -4.6543 1220.2192 1587.2147)"
          >
            <stop offset="0" stopColor="#ffe000" />
            <stop offset=".409" stopColor="#ffbd00" />
            <stop offset=".775" stopColor="orange" />
            <stop offset="1" stopColor="#ff9c00" />
          </linearGradient>
          <path
            d="M9.2 9.3L7 7.1v-.2l2.2-2.2.1.1L12 6.3c.4.1.6.6.5 1-.1.2-.3.4-.5.5L9.2 9.3z"
            fill="url(#google-b)"
          />
          <linearGradient
            id="google-c"
            gradientUnits="userSpaceOnUse"
            x1="-166.1107"
            y1="461.0791"
            x2="-166.1848"
            y2="460.9841"
            gradientTransform="matrix(9.069 0 0 -7.0576 2159.5615 2617.824)"
          >
            <stop offset="0" stopColor="#ff3a44" />
            <stop offset="1" stopColor="#c31162" />
          </linearGradient>
          <path
            d="M9.3 9.2L7 6.9.2 13.7c.3.3.7.3 1.1.1l8-4.6"
            fill="url(#google-c)"
          />
          <linearGradient
            id="google-d"
            gradientUnits="userSpaceOnUse"
            x1="-264.8932"
            y1="404.087"
            x2="-264.8602"
            y2="404.045"
            gradientTransform="matrix(9.069 0 0 -7.0305 2159.5615 2599.293)"
          >
            <stop offset="0" stopColor="#32a071" />
            <stop offset=".069" stopColor="#2da771" />
            <stop offset=".476" stopColor="#15cf74" />
            <stop offset=".801" stopColor="#06e775" />
            <stop offset="1" stopColor="#00f076" />
          </linearGradient>
          <path
            d="M9.3 4.7L1.4.2C1-.1.5-.1.2.2L7 7l2.3-2.3z"
            fill="url(#google-d)"
          />
          <path
            d="M9.2 9.2l-7.9 4.5c-.3.2-.8.2-1.1 0l-.1.1.1.1c.4.1.8.1 1.2-.1l7.8-4.6z"
            opacity=".2"
          />
          <path
            d="M.2 13.7c-.2-.2-.2-.5-.2-.8v.1c0 .2.1.5.2.7zm11.7-6L9.2 9.2l.1.1 2.6-1.6c.3-.1.5-.4.5-.7.1.2-.1.4-.5.7z"
            opacity=".12"
          />
          <path
            d="M1.4.2L12 6.3c.3.2.5.4.5.7 0-.3-.2-.6-.5-.8L1.4.2C.6-.2 0 .1 0 1 0 .2.6-.2 1.4.2z"
            opacity=".25"
            fill="#fff"
          />
        </g>
      </>
    )}
  </CreateSvgIcon>
);
