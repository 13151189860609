import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Bell = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Bell}>
    {({ color }) => (
      <>
        <g clipPath="url(#clip0)">
          <path
            d="M10.6826 4.32886C10.5856 4.30467 10.4644 4.37751 10.3432 4.45034C8.50064 5.63993 7.07069 7.24157 6.27157 9.20662C6.22318 9.40068 6.1748 9.59474 6.24763 9.71596C6.32047 9.83718 6.46615 10.0796 6.56318 10.1038L6.66021 10.128C6.95129 10.2006 7.29077 10.0791 7.48457 9.71518C8.21111 8.04122 9.49565 6.60945 11.0957 5.56553C11.2169 5.49269 11.3624 5.32283 11.4107 5.12877C11.4591 4.93471 11.4105 4.71646 11.3376 4.59524C11.2648 4.47401 11.0707 4.42563 10.8767 4.37725C10.8767 4.37725 10.7797 4.35305 10.6826 4.32886Z"
            fill={color}
          />
          <path
            d="M16.2138 6.53243C13.0124 6.55871 10.1033 8.71912 9.30498 11.9211L8.50664 15.1231C8.16795 16.4815 7.00438 17.4281 5.59784 17.6958C5.28256 17.7202 5.01592 17.9629 4.94335 18.254C4.70143 19.2243 4.75059 20.2672 5.13923 21.1886C5.57652 22.3282 6.59572 23.2007 7.76007 23.491L10.865 24.2652L10.8166 24.4592C10.6473 25.1384 10.7932 25.7932 11.1574 26.3993C11.5216 27.0054 12.1282 27.4658 12.8074 27.6352C14.1659 27.9739 15.6205 27.0998 15.9592 25.7414L16.0076 25.5474L19.1125 26.3215C20.3739 26.636 21.6834 26.3441 22.6046 25.5432C23.2832 24.8879 23.8163 23.9902 24.0582 23.0199C24.1308 22.7288 24.0093 22.3893 23.7424 22.2197C23.2086 21.8805 22.9172 21.3956 22.6501 20.8137C22.5286 20.4742 22.0434 20.3533 21.704 20.4748C21.3645 20.5962 21.2435 21.0814 21.365 21.4209C21.6322 22.0028 22.0206 22.5119 22.4331 22.9239L22.603 23.0693L22.5546 23.2634C22.3366 23.7243 22.0458 24.0641 21.755 24.4038C21.1005 24.9621 20.3245 25.1808 19.5724 24.8903L8.41405 22.1082C6.86157 21.7211 6.1332 20.5089 6.27783 19.1021L6.32621 18.908L6.44744 18.8352C6.78691 18.7137 7.12638 18.5922 7.49005 18.3737C8.67807 17.7423 9.57475 16.6261 9.88925 15.3647L10.6876 12.1628C11.4375 9.15484 14.4923 7.23687 17.5002 7.98683L17.6943 8.03522C20.7022 8.78517 22.5959 11.9369 21.846 14.9449L21.5315 16.2062C21.4347 16.5944 21.6532 16.958 22.0414 17.0548C22.2354 17.1032 22.4537 17.0545 22.5749 16.9817C22.6961 16.9089 22.8415 16.739 22.8899 16.5449L23.2044 15.2835C24.0511 11.8875 22.1571 8.32344 18.785 6.96737L18.5909 6.91899L18.978 5.36651C19.0748 4.97839 18.8563 4.61473 18.4681 4.51796C18.2741 4.46958 18.0558 4.51822 17.9346 4.59106C17.7892 4.76093 17.668 4.83376 17.6196 5.02782L17.2325 6.58029L17.0385 6.53191C16.7232 6.55636 16.5291 6.50798 16.2138 6.53243ZM13.3644 26.2281C13.2673 26.2039 13.1703 26.1797 13.0733 26.1556C12.4669 26.1074 12.1269 25.4043 12.2721 24.8221L12.3447 24.531L14.6734 25.1116L14.6008 25.4027C14.4798 25.8879 13.9707 26.2763 13.3644 26.2281Z"
            fill={color}
          />
          <path
            d="M25.0672 7.81227C24.9702 7.78807 24.849 7.86091 24.7277 7.93375C24.3641 8.15226 24.2673 8.54038 24.4858 8.90404C25.4085 10.577 25.8705 12.4442 25.7019 14.3603C25.7022 14.7726 25.9449 15.0393 26.333 15.136C26.7453 15.1358 27.012 14.8931 27.1087 14.505C27.2528 12.2735 26.7664 10.091 25.698 8.17567C25.6252 8.05445 25.4553 7.90903 25.2613 7.86065C25.1642 7.83646 25.1642 7.83646 25.0672 7.81227Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="24"
              height="24"
              fill={color}
              transform="translate(4 4)"
            />
          </clipPath>
        </defs>
      </>
    )}
  </CreateSvgIcon>
);
