import React, { useCallback, useState } from "react";
import { makeStyles } from "hooks/makeStyles";
import { Button, Box, DefaultPanel, AnimatedPage } from "ui/shared";
import { useTranslation } from "react-i18next";
import { BasicSearch } from "./basic-search/basic-search";
import { Interests, InterestsHeader } from "ui/pages/profile/modals/interests";
import { observer } from "mobx-react";
import { searchStore } from "./stores/search-store";
import { userStore } from "global-stores/user-store";
import { useHistory } from "react-router-dom";
import { InterestsStore } from "../profile/modals/stores/interests-store";

export interface SearchByInterestsProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    background: theme.colors.background.paper.main,
  },
  container: {
    display: "flex",
    flexGrow: 1,
    maxHeight: `calc(100% - ${theme.sizes.header}px - 77px)`,
  },
  panel: {
    maxHeight: "100%",
  },
  side: {
    flex: "0 1 40%",
    padding: `${theme.spacing(6)}`,
  },
  main: {
    flex: "1 1 60%",
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  buttonContainer: {
    padding: theme.spacing(9),
    paddingTop: 0,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export const SearchByInterests = observer((props: SearchByInterestsProps) => {
  const { t } = useTranslation("search");
  const css = useStyles();
  const history = useHistory();
  const [interestsStore] = useState(
    new InterestsStore(userStore.user?.profile.interests || [])
  );

  /**************************************************/

  const handleStartSearch = useCallback(() => {
    searchStore.startSearch(interestsStore.interests, () =>
      history.push("/search/my")
    );
  }, [history]);

  const handleSelectInterest = useCallback(
    (e: React.MouseEvent, item: string) => {
      if (interestsStore.localInterests.length) {
        interestsStore.setLocalInterests([]);
      }

      if (interestsStore.interests.includes(item)) {
        interestsStore.setInterests(
          interestsStore.interests.filter(
            (interest: string) => interest !== item
          )
        );
      } else {
        interestsStore.setInterests([...interestsStore.interests, item]);
      }
    },
    []
  );

  const handleRevertInterest = useCallback(() => {
    interestsStore.setInterests(interestsStore.localInterests);
    interestsStore.setLocalInterests([]);
  }, []);

  const clear = useCallback((e: React.MouseEvent) => {
    interestsStore.setLocalInterests(interestsStore.interests);
    interestsStore.setInterests([]);
  }, []);

  /**************************************************/

  return (
    <AnimatedPage
      animation="horizontal"
      containerClassName={css.root}
      back={{
        label: t("all:reg_prev_button"),
        path: "/search",
        boxProps: {
          pl: 5,
        },
      }}
    >
      <div className={css.container}>
        <div className={css.side}>
          <BasicSearch store={searchStore} />
        </div>
        <div className={css.main}>
          <DefaultPanel
            header={
              <InterestsHeader
                onClear={clear}
                onRevert={handleRevertInterest}
                selected={interestsStore.interests}
                localInterests={interestsStore.localInterests}
                limit={userStore.interestsLimit}
              />
            }
            className={css.panel}
          >
            <Interests
              selected={interestsStore.interests}
              onSelectInterest={handleSelectInterest}
            />
          </DefaultPanel>
        </div>
      </div>
      <Box className={css.buttonContainer}>
        <Button
          primary
          onClick={handleStartSearch}
          data-cy="searchByInterests.start"
        >
          {t("all:main_button_start_search")}
        </Button>
      </Box>
    </AnimatedPage>
  );
});
