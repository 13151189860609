import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const VK = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.VK}>
    {({ color }) => (
      <>
        <g fill="none" stroke={color} strokeWidth="1">
          <circle cx="16" cy="16" r="16" stroke="none" />
          <circle cx="16" cy="16" r="15.5" fill="#322941" />
        </g>
        <path
          d="M9.55,11.132h1.143a1.123,1.123,0,0,0,.514-.229.847.847,0,0,0,.171-.514s0-1.543.686-1.772c.743-.229,1.658,1.543,2.629,2.172a1.859,1.859,0,0,0,1.315.4l2.629-.057s1.372-.057.743-1.143a8.622,8.622,0,0,0-1.943-2.229c-1.658-1.543-1.429-1.258.572-3.887C19.21,2.272,19.668,1.3,19.553.9,19.382.5,18.524.614,18.524.614H15.609a.561.561,0,0,0-.4.057,1.023,1.023,0,0,0-.286.343A14.782,14.782,0,0,1,13.837,3.3C12.523,5.53,12.008,5.644,11.78,5.53c-.457-.4-.343-1.372-.343-2.058,0-2.172.343-3.029-.629-3.258A6.272,6.272,0,0,0,9.436.1,7.267,7.267,0,0,0,6.978.329C6.635.5,6.407.843,6.521.9a1.165,1.165,0,0,1,.857.457,2.95,2.95,0,0,1,.286,1.315s.171,2.515-.4,2.858c-.4.171-.915-.286-2.058-2.286A16.6,16.6,0,0,1,4.12,1.072.648.648,0,0,0,3.892.729.725.725,0,0,0,3.434.557H.634A.983.983,0,0,0,.062.729c-.114.171,0,.514,0,.514S2.234,6.388,4.749,8.96a6.551,6.551,0,0,0,4.8,2.172"
          transform="translate(6.032 10.208)"
          fill={color}
        />
      </>
    )}
  </CreateSvgIcon>
);
