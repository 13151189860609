import React, { useCallback, useRef } from "react";

interface UploadFileProps {
  onUpload?: (files: FileList | null) => void;
  children: React.ReactElement;
}

export const UploadFile = (props: UploadFileProps) => {
  const ref = useRef<HTMLInputElement>(null);

  /**************************************************/

  const handleFileUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onUpload) {
        props.onUpload(e.target.files);
      }
      // Clear input to be able to upload same file again
      if (ref.current) {
        ref.current.files = null;
        ref.current.value = "";
      }
    },
    [ref.current, props.onUpload]
  );

  const handleClickTrigger = useCallback(() => {
    if (ref && ref.current) ref.current.click();
  }, [ref.current]);

  /**************************************************/

  return (
    <>
      <input
        ref={ref}
        onChange={handleFileUpload}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
      />
      {ref.current
        ? React.cloneElement(props.children, { onClick: handleClickTrigger })
        : null}
    </>
  );
};
