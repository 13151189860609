import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const SadFace = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.SadFace}>
    {({ color }) => (
      <>
        <path
          d="M4.31032 11.6206C6.69084 11.6206 8.62063 9.69084 8.62063 7.31032C8.62063 4.92979 6.69084 3 4.31032 3C1.92979 3 0 4.92979 0 7.31032C0 9.69084 1.92979 11.6206 4.31032 11.6206Z"
          fill="white"
        />
        <path
          d="M27.6897 11.6206C30.0702 11.6206 32 9.69084 32 7.31032C32 4.92979 30.0702 3 27.6897 3C25.3092 3 23.3794 4.92979 23.3794 7.31032C23.3794 9.69084 25.3092 11.6206 27.6897 11.6206Z"
          fill="white"
        />
        <path
          d="M2.50041 28.7257C3.19799 28.716 3.86427 28.4346 4.35768 27.9414C7.52105 24.8663 11.7673 23.1592 16.179 23.1891C20.5906 23.2191 24.8134 24.9836 27.9347 28.1014C28.3212 28.4957 28.8483 28.7205 29.4004 28.7267C29.8413 28.7292 30.273 28.6009 30.6409 28.3582C31.0089 28.1154 31.2967 27.7691 31.4679 27.3629C31.6392 26.9566 31.6861 26.5088 31.6029 26.0758C31.5197 25.6429 31.3101 25.2444 31.0005 24.9306C29.0188 22.9675 26.6697 21.4143 24.0873 20.3599C21.5049 19.3054 18.74 18.7704 15.9507 18.7854C13.1614 18.8004 10.4024 19.3651 7.83153 20.4472C5.26063 21.5293 2.92828 23.1076 0.967848 25.0918C0.672969 25.3928 0.473622 25.7742 0.394857 26.1881C0.316092 26.602 0.361422 27.03 0.525152 27.4183C0.688882 27.8065 0.963707 28.1377 1.31509 28.3702C1.66648 28.6027 2.07875 28.7262 2.5001 28.7251L2.50041 28.7257Z"
          fill="white"
        />
      </>
    )}
  </CreateSvgIcon>
);
