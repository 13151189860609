import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "hooks/makeStyles";
import { Typography, Box, Anchor } from "ui/shared";
import { useTranslation } from "react-i18next";
import { Maybe } from "lib";
import logo from "assets/svg/logo-bw.svg";
import { qrCodeStore } from "global-stores/qrcode-store";
import { encriptionStore } from "global-stores/encryption-store";

const useStyles = makeStyles((theme) => ({
  inviteCodeModalHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
    backgroundColor: theme.colors.background.paper.lighter,
  },
  headerItem: {
    width: 40,
  },
  body: {
    padding: `${theme.spacing(5.5)}`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: 320,
  },
  flex: {
    display: "flex",
    justifyContent: "center",
  },
  hidden: {
    visibility: "hidden",
    position: "absolute",
  },
}));

interface ExportAccountProps {
  loading: boolean;
  userName: Maybe<string>;
}

export const ExportAccount = (props: ExportAccountProps) => {
  const { t } = useTranslation("all");
  const css = useStyles();
  const canvas = useRef<HTMLCanvasElement>(null);

  const { loading, userName } = props;

  const [qrcode, setQRcode] = useState<string>();
  const [exportedCode, setExportedCode] = useState<any>();

  const downloadFilename = `inside.me_${userName}.jpg`;

  useEffect(() => {
    async function generateRecoveryKey() {
      const accountStr = encriptionStore.generateAccountString();
      if (accountStr) {
        const qrcode = await qrCodeStore.generateQRCode(accountStr);

        if (canvas.current && qrcode && userName) {
          setQRcode(qrcode);
          const ctx = canvas.current.getContext("2d");
          if (ctx) {
            const logoImg = new Image();
            const image = new Image();

            logoImg.onload = function () {
              if (canvas.current) {
                ctx.fillStyle = "#fff";
                ctx.fillRect(0, 0, canvas.current.width, canvas.current.height);
              }
              ctx.fillStyle = "#222";
              ctx.drawImage(logoImg, 86, 20, 148, 86.1);
              ctx.drawImage(image, 0, 210, 320, 320);

              ctx.font = "20px sans-serif";
              ctx.textAlign = "center";
              ctx.textBaseline = "top";
              ctx.fillText("Your account recovery key", 160, 140);

              ctx.font = "24px sans-serif";
              ctx.textAlign = "center";
              ctx.textBaseline = "top";
              ctx.fillText(userName, 160, 184);

              ctx.font = "20px sans-serif";
              ctx.textAlign = "center";
              ctx.textBaseline = "top";
              ctx.fillText("https://inside.me", 160, 530);

              const exportedQRCode =
                canvas.current && canvas.current.toDataURL("image/jpeg");

              setExportedCode(exportedQRCode);
            };

            logoImg.src = logo;
            image.src = qrcode;
          }
        }
      }
    }

    generateRecoveryKey();
  }, [canvas, userName]);

  return (
    <div className={css.body}>
      <canvas
        ref={canvas}
        width="320px"
        height="568px"
        className={css.hidden}
      />
      <Box mb={4}>
        <Typography align="center">
          {t("all:export_alert_qr_warning_text")}
        </Typography>
      </Box>
      <img src={qrcode} alt="Account qrcode" />
      <Box mt={4} className={css.flex}>
        {exportedCode ? (
          <Anchor href={exportedCode} download={downloadFilename} primary>
            {t("all:export_save")}
          </Anchor>
        ) : null}
      </Box>
    </div>
  );
};
