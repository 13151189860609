import React, { useCallback, useRef, useEffect, useLayoutEffect } from "react";
import { makeStyles } from "hooks/makeStyles";
import {
  IconButton,
  Icons,
  Textarea,
  Skeleton,
  Button,
  vipGuard,
  Anchor,
  Typography,
} from "ui/shared";
import { useTranslation } from "react-i18next";
import { chatsStore } from "global-stores/chats-store";
import { observer } from "mobx-react";
import { useEventCallback } from "hooks/useEventCallback";
import clsx from "clsx";
import { toastStore } from "global-stores/toasts-store";
import { globalUIStore } from "global-stores/global-ui-store";
import { DropZoneStore } from "ui/shared/dropzone/dropzone-store";
import GIFDropdown from "./gif-dropdown";
import SmilesDropdown from "./smiles-dropdown";

import throttle from "lodash/throttle";
import translator from "global-stores/translator-store";
import { modalManager } from "global-stores/modal-manager";
import { MODALS } from "lib/constants";
import { userStore } from "global-stores/user-store";
import { prepareAndSplitMessage } from "utils/message-validation";

interface ChatActionPanelProps {
  onSend?: () => void;
  scrollToBottom: (smooth?: boolean) => void;
  loading?: boolean;
  dropzoneStore: DropZoneStore;
  hidden?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: "auto",
    padding: `${theme.spacing(2)} 0`,
    backgroundColor: theme.colors.background.dark,
    flexShrink: 0,
    position: "relative",
    transition: "all .2s ease-in-out",
  },
  uploading: {
    justifyContent: "flex-end",
  },
  icon: {
    margin: `0 ${theme.spacing(4)}`,
  },
  skeletonContainer: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    alignItems: "center",
  },
  skeletonBox: {
    margin: "0 4px",
  },
  textareaContainer: {
    width: "100%",
  },
  textarea: {
    resize: "none",
    lineHeight: "22px",
  },
  dropdownButton: {
    backgroundColor: theme.colors.common.white,
    border: "none",
    width: "100%",
    borderRadius: 0,
    color: theme.colors.common.black,
    "&:hover": {
      backgroundColor: `${theme.colors.purple.main} !important`,
      color: `${theme.colors.textPrimary} !important`,
    },
    "&:focus": {
      backgroundColor: theme.colors.common.white,
      color: theme.colors.common.black,
    },
  },
  forceFocus: {
    "&:focus": {
      backgroundColor: theme.colors.purple.main,
      color: theme.colors.textPrimary,
    },
  },
  overlay: {
    width: 240,
    backgroundColor: theme.colors.common.white,
    borderRadius: 10,
    overflow: "hidden",
  },
  sendFile: {
    backgroundColor: theme.colors.primary,
    border: `2px solid ${theme.colors.common.white}`,
  },
  uploadButtonsContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "& > *:first-child": {
      marginRight: theme.spacing(2),
    },
  },
  translateButton: {
    padding: 6,
  },
  translateActionsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 24,
  },
  translateActionsRightContainer: {
    display: "flex",
    alignItems: "center",
  },
  autoTranslationLink: {
    position: "relative",
    marginRight: theme.spacing(5),
  },
  translationSettingsButton: {
    padding: theme.spacing(1),
  },
  hideTranslationLink: {
    color: "#898989",
  },
  auto: {
    "&:after": {
      content: "''",
      width: 6,
      height: 6,
      borderRadius: "50%",
      backgroundColor: theme.colors.grey.main,
      position: "absolute",
      top: 4,
      marginLeft: 4,
    },
    "&$active": {
      "&:after": {
        backgroundColor: theme.colors.primary,
      },
    },
  },
  hidden: {
    display: "none",
  },
  nonActionableSidebar: {
    background: theme.colors.background.paper.main,
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  active: {},
}));

const handleChangeTypingStatus = () => {
  if (chatsStore.selectedFriend) {
    chatsStore.typing(chatsStore.selectedFriend.friendIamId);
  }
};

const trottledChangeTypingStatus = throttle(handleChangeTypingStatus, 2000);

export const ChatActionPanel = observer((props: ChatActionPanelProps) => {
  const { onSend, loading, scrollToBottom, dropzoneStore, hidden } = props;
  const css = useStyles();
  const { t } = useTranslation("all");
  const textarea = useRef<HTMLTextAreaElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const [panelHeight, setPanelHeight] = React.useState<number>();
  const [isSendingMessage, setIsSendingMessage] = React.useState(false);

  const hasFiles = !!dropzoneStore.files.length;

  const handleEnter = useEventCallback((e) => {
    if (e.key === "Enter" && dropzoneStore.files.length) {
      onUpload();
    }
  });

  useEffect(() => {
    document.addEventListener("keydown", handleEnter);
  }, [handleEnter]);

  useLayoutEffect(() => {
    if (globalUIStore.shouldFocusChatTextarea && textarea.current) {
      textarea.current.focus();
      globalUIStore.setShouldFocusChatTextarea(false);
    }
  });

  const translationSettings =
    chatsStore.selectedChatStore &&
    chatsStore.selectedChatStore.translationSettings;

  /**************************************************/

  const sendMessage = useCallback(async () => {
    if (
      !chatsStore.messageInput ||
      !chatsStore.messageInput.replace(/\s/g, "").length
    ) {
      chatsStore.setMessageInput("");
      return;
    }

    setIsSendingMessage(true);

    let message = chatsStore.messageInput;

    chatsStore.setMessageInput("");

    if (
      translator.isEnabled &&
      chatsStore.selectedChatStore &&
      chatsStore.selectedChatStore.translationSettings &&
      chatsStore.selectedChatStore.translationSettings.autoTranslation &&
      !chatsStore.isMessageFullyTranslated
    ) {
      message = await translator.translateMessageWithValidation(message);
    }

    const messages = message ? prepareAndSplitMessage(message) : [];

    for (const message of messages) {
      await chatsStore.sendMessage(message, onSend);
    }

    setIsSendingMessage(false);
    if (textarea.current) {
      textarea.current.focus();
    }
  }, [onSend]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.keyCode === 13) {
        if (!e.shiftKey) {
          e.preventDefault();
          sendMessage();
        }
      }
    },
    [sendMessage]
  );

  const onClose = useCallback(() => {
    if (textarea && textarea.current) {
      textarea.current.focus();
      setTimeout(() => {
        if (textarea && textarea.current) {
          textarea.current.setSelectionRange(
            chatsStore.cursorIndex,
            chatsStore.cursorIndex
          );
        }
      }, 0);
    }
  }, []);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      chatsStore.setMessageInput(e.target.value);
      chatsStore.cursorIndex = e.target.selectionStart;
      trottledChangeTypingStatus();
    },
    []
  );

  const handleHeightChange = useCallback(() => {
    scrollToBottom();
    if (panelRef.current) {
      setPanelHeight(panelRef.current.clientHeight);
    }
  }, [scrollToBottom]);

  const onUpload = useCallback(async () => {
    if (dropzoneStore.files.length) {
      const uploadedFile = dropzoneStore.files[0];
      dropzoneStore.clear();
      const file = await chatsStore.optimizeFile(uploadedFile);
      if (typeof file !== "string") {
        const messageInfo = await chatsStore.sendImageMessage(file);
        if (typeof messageInfo !== "string") {
          chatsStore.upload(messageInfo, file);
        }
      }
    }
  }, [dropzoneStore]);

  const toggleAutoTranslation = useCallback(() => {
    if (
      chatsStore.selectedChatStore &&
      chatsStore.selectedChatStore.translationSettings
    ) {
      const friendLang =
        chatsStore.selectedChatStore.translationSettings.friendLang;

      if (friendLang === "auto") {
        toastStore.showToast({
          message: t("all:trans_alert_partner_lang_text"),
        });
        return;
      }

      chatsStore.selectedChatStore?.setTranslationSettings({
        autoTranslation: !translationSettings?.autoTranslation,
      });
    }
  }, [t, translationSettings]);

  const showTranslateButton =
    chatsStore.messageInput.trim() &&
    translator.isEnabled &&
    !chatsStore.isMessageFullyTranslated &&
    !translator.failedToInitialize;

  const showOriginMessage = () => {
    showTranslateButton
      ? translator.translateMyMessage()
      : chatsStore.toggleShowOriginalMessage();
  };

  /**************************************************/

  if (loading) {
    return (
      <div className={clsx(css.root, css.skeletonContainer)}>
        <Skeleton.Circle size="small" className={css.skeletonBox} />
        <Skeleton.Circle size="small" className={css.skeletonBox} />
        <Skeleton.Line width="80%" height={26} className={css.skeletonBox} />
        <Skeleton.Circle size="small" className={css.skeletonBox} />
      </div>
    );
  }

  const showOriginTextButton =
    showTranslateButton || chatsStore.originMessageBeforeTranslate;

  // Trigger rerender on GIF picker to update panel height change
  if (
    !translationSettings ||
    translator.failedToInitialize ||
    !translator.isGlobalTranslationEnabled
  ) {
    setTimeout(() => {
      if (panelRef.current && panelHeight !== panelRef.current.clientHeight) {
        setPanelHeight(panelRef.current.clientHeight);
      }
    }, 0);
  }

  if (chatsStore.selectedFriend && chatsStore.selectedFriend.official) {
    return (
      <div className={css.nonActionableSidebar}>
        <Typography align="center">{t("all:dialog_not_available")}</Typography>
      </div>
    );
  }

  return (
    <div
      ref={panelRef}
      className={clsx(css.root, {
        [css.uploading]: hasFiles,
        [css.hidden]: hidden,
      })}
    >
      {hasFiles ? (
        <div className={css.uploadButtonsContainer}>
          <Button onClick={() => dropzoneStore.removeFile(0)}>
            {t("all:dialog_cancel_button")}
          </Button>
          <Button primary onClick={onUpload}>
            {t("all:feedback_button")}
          </Button>
        </div>
      ) : (
        <>
          <GIFDropdown
            onClose={onClose}
            onSend={onSend}
            panelHeight={panelHeight}
            isTranslatePanelShown={
              !!translationSettings && !translator.failedToInitialize
            }
          />
          <SmilesDropdown ref={textarea} onClose={onClose} />
          {dropzoneStore.open ? (
            <IconButton
              disabled={isSendingMessage}
              onClick={() => vipGuard(dropzoneStore.open!)}
              className={css.icon}
            >
              <Icons.Photo />
            </IconButton>
          ) : (
            <IconButton disabled onClick={() => {}} className={css.icon}>
              <Icons.Photo />
            </IconButton>
          )}
          <div className={css.textareaContainer}>
            <Textarea
              disabled={translator.isTranslatingMyMessage || isSendingMessage}
              ref={textarea}
              value={chatsStore.messageInput}
              onChange={handleInputChange}
              maxRows={5}
              placeholder={t("all:friend_dialog_placeholder")}
              onKeyDown={handleKeyDown}
              onHeightChange={handleHeightChange}
              data-cy="chat-action-panel-textarea"
              className={css.textarea}
            />
            {!translator.failedToInitialize &&
            translator.isGlobalTranslationEnabled &&
            !translationSettings?.hideTranslation ? (
              <div className={css.translateActionsContainer}>
                {translationSettings ? (
                  translationSettings.useTranslate &&
                  !translator.isDailyLimitReached &&
                  (userStore.isVIP || userStore.isVerfiedUser) ? (
                    <>
                      {translator.isTranslatingMyMessage ? (
                        <div>
                          <Icons.DotsLoader
                            width={32}
                            height={14}
                            viewBox="0 0 120 30"
                            fill="#fff"
                          />
                        </div>
                      ) : showOriginTextButton ? (
                        <Anchor asLink onClick={showOriginMessage}>
                          <Typography size="caption(12px)">
                            {showTranslateButton ||
                            chatsStore.showOriginalMessage
                              ? t("all:trans_show_trans")
                              : t("all:trans_show_original")}
                          </Typography>
                        </Anchor>
                      ) : (
                        <span />
                      )}
                      <div className={css.translateActionsRightContainer}>
                        <Anchor
                          asLink
                          tabIndex={0}
                          onClick={toggleAutoTranslation}
                          className={css.autoTranslationLink}
                        >
                          <Typography
                            size="caption(12px)"
                            lowercase
                            className={clsx(css.auto, {
                              [css.active]:
                                translationSettings?.autoTranslation,
                            })}
                          >
                            {!translationSettings?.autoTranslation
                              ? t("all:trans_auto_on")
                              : t("all:trans_auto_off")}
                          </Typography>
                        </Anchor>
                        <IconButton
                          onClick={() =>
                            modalManager.open(MODALS.TRANSLATE_SETTINGS)
                          }
                          className={css.translationSettingsButton}
                        >
                          <Icons.SettingsSmall width={16} height={16} />
                        </IconButton>
                      </div>
                    </>
                  ) : (
                    <>
                      <Anchor
                        asLink
                        onClick={() => {
                          if (translator.isDailyLimitReached) {
                            modalManager.open(MODALS.TRANSLATE_VIP_ALERT);
                            return;
                          }
                          if (!userStore.isVIP && !userStore.isVerfiedUser) {
                            modalManager.open(MODALS.TRANSLATE_VER_ALERT);
                            return;
                          }
                          chatsStore.selectedChatStore?.setTranslationSettings({
                            useTranslate: true,
                          });
                        }}
                      >
                        <Typography size="caption(12px)">
                          {t("all:trans_turn_on")}
                        </Typography>
                      </Anchor>
                      <div className={css.translateActionsRightContainer}>
                        <Anchor
                          asLink
                          onClick={() => {
                            modalManager.open(MODALS.TRANSLATE_HIDE_ALERT);
                          }}
                          className={css.autoTranslationLink}
                        >
                          <Typography
                            size="caption(12px)"
                            className={css.hideTranslationLink}
                          >
                            {t("all:trans_hide")}
                          </Typography>
                        </Anchor>
                      </div>
                    </>
                  )
                ) : (
                  <Skeleton.Line height={14} />
                )}
              </div>
            ) : null}
          </div>
          <IconButton
            disabled={isSendingMessage}
            className={css.icon}
            onClick={sendMessage}
            data-cy="chat-action-panel-send-button"
          >
            <Icons.Send />
          </IconButton>
        </>
      )}
    </div>
  );
});

// globalUIStore.hasCamera ? (
//   <Dropdown
//     preferTop
//     preferRight
//     withArrow
//     items={[
//       {
//         label: t("chat:upload.actions.camera"),
//         onClick: () => {},
//       },
//       {
//         label: t("chat:upload.actions.upload"),
//         onClick: dropzoneStore.open,
//         // onClick: () => vipGuard(openUploadDialog),
//       },
//     ]}
//     renderItem={(item, index, forceFocus) => (
//       <Button
//         key={item.label}
//         className={clsx(css.dropdownButton, {
//           [css.forceFocus]: forceFocus,
//         })}
//         onClick={item.onClick}
//         data-cy={`chat-send-image-options-${index}`}
//       >
//         {item.label}
//       </Button>
//     )}
//     overlayClassName={css.overlay}
//     className={css.emoji}
//   >
//     <IconButton>
//       <Icons.Photo />
//     </IconButton>
//   </Dropdown>
// ) : (
//   <IconButton onClick={(e) => vipGuard(dropzoneStore.open!, e)}>
//     <Icons.Photo />
//   </IconButton>
// )
