import { Picker } from "emoji-mart";
import { chatsStore } from "global-stores/chats-store";
import { makeStyles } from "hooks/makeStyles";
import React, { useCallback } from "react";
import { Dropdown, IconButton, Icons } from "ui/shared";

interface SmilesDropdownProps {
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  emoji: {
    position: "relative",
  },
}));

const SmilesDropdown = React.memo(
  React.forwardRef(
    ({ onClose }: SmilesDropdownProps, ref: React.Ref<HTMLTextAreaElement>) => {
      const css = useStyles();

      /**************************************************/

      const renderPicker = useCallback(
        (close) => (
          <Picker
            set="apple"
            title="Pick your emoji…"
            emoji="point_up_2"
            style={{
              position: "absolute",
              bottom: 14,
              left: -40,
            }}
            onSelect={(emoji) => {
              if (ref) {
                const currentCursorIndex = chatsStore.cursorIndex;
                if (currentCursorIndex != null) {
                  const newValue =
                    chatsStore.messageInput.slice(0, currentCursorIndex) +
                    (emoji as any).native +
                    chatsStore.messageInput.slice(currentCursorIndex);
                  chatsStore.setMessageInput(newValue);
                  chatsStore.setCursorIndex(
                    currentCursorIndex + (emoji as any).native.length
                  );
                }
              }
              // close(true);
            }}
            data-cy="emoji-picker"
          />
        ),
        [ref]
      );

      /**************************************************/

      return (
        <Dropdown
          preferTop
          render={renderPicker}
          onClose={onClose}
          useVisibility={true}
          className={css.emoji}
        >
          <IconButton data-cy="chat-action-panel-emoji-button">
            <Icons.Smile />
          </IconButton>
        </Dropdown>
      );
    }
  )
);

export default SmilesDropdown;
