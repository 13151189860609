import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Add = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Add}>
    {({ color = "#7b8c99" }) => (
      <g>
        <polygon
          points="22 15 17 15 17 10 15 10 15 15 10 15 10 17 15 17 15 22 17 22 17 17 22 17 22 15"
          fill={color}
        />
      </g>
    )}
  </CreateSvgIcon>
);
