import { observable, action } from "mobx";
import { i18n } from "services/i18n";
import { storage } from "utils";
import { ADDITIONAL_INFO_KEY, EMAIL_INFO_KEY } from "lib";
import moment from "moment";
import { Meteor } from "meteor/meteor";
import getTimeZone from "utils/getTimeZone";
import { DDP } from "meteor/ddp";

const APP_VER = 0.2;

class GlobalUIStore {
  constructor() {
    if (navigator.userAgent.indexOf("Firefox") !== -1) {
      this.isFirefox = true;
    }
    this.getUserFilledAdditionalInfo();
    this.getUserFilledEmail();
    this.checkCamera();

    this.initializedDate = new Date();
  }

  @observable initializedDate?: Date;

  @observable connectionStatus?: DDP.Status;

  @observable loading: boolean = true;
  @observable notificationsReady = false;
  @observable isAnimating: boolean = false;
  @observable animationReady: boolean = false;
  @observable loginInProgress?: boolean;
  @observable userFilledAdditionalInfo: boolean = false;
  @observable userFilledEmail: boolean = false;
  @observable isDragOver: boolean = false;

  @observable switchingChat: boolean = false;
  @observable shouldFocusChatTextarea: boolean = false;

  @observable isFirefox: boolean = false;
  @observable hasCamera?: boolean;

  @observable mobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  @action
  setConnectionStatus(status: DDP.Status) {
    this.connectionStatus = status;
  }

  @action
  setLoading(value: boolean) {
    this.loading = value;
  }

  @action
  setLoginInProgress(value: boolean) {
    this.loginInProgress = value;
  }

  @action
  setAnimationReady(value: boolean) {
    this.animationReady = value;
  }

  @action
  setSwitchingChat(value: boolean) {
    this.switchingChat = value;
  }

  @action
  setShouldFocusChatTextarea(value: boolean) {
    this.shouldFocusChatTextarea = value;
  }

  @action
  initializeLang() {
    moment.locale(i18n.language);
    i18n.changeLanguage(i18n.language.split("-")[0] || "en");
  }

  @action
  initializeNotifications() {
    const self = this;

    Meteor.call(
      "users.setWebDeviceToken",
      {
        lang: i18n.language,
        timeZoneOffset: getTimeZone(),
        appVer: APP_VER,
      },
      (err: Meteor.Error, res: any) => {
        self.notificationsReady = true;
      }
    );
  }

  @action
  setLanguage(value: string) {
    i18n.changeLanguage(value);
    moment.locale(value);
  }

  @action
  setUserFilledAdditionalInfo(value: boolean) {
    this.userFilledAdditionalInfo = value;
  }

  @action
  setUserFilledEmail(value: boolean) {
    this.userFilledEmail = value;
  }

  @action
  setIsAnimating(value: boolean) {
    this.isAnimating = value;
  }

  @action
  setIsDragOver(value: boolean) {
    this.isDragOver = value;
  }

  checkCamera() {
    if (!navigator.mediaDevices) {
      this.hasCamera = false;
    }
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        this.hasCamera = devices.some((device) => device.kind === "videoinput");
      })
      .catch(() => {
        this.hasCamera = false;
      });
  }

  async getUserFilledEmail() {
    const stored = await storage.read(EMAIL_INFO_KEY);
    if (stored != null) {
      this.userFilledEmail = stored;
    }
  }

  async getUserFilledAdditionalInfo() {
    const stored = await storage.read(ADDITIONAL_INFO_KEY);
    if (stored != null) {
      this.userFilledAdditionalInfo = stored;
    }
  }
}

export const globalUIStore = new GlobalUIStore();
