import * as React from "react";
import { userStore } from "global-stores/user-store";
import { observer } from "mobx-react";
import { modalManager } from "global-stores/modal-manager";
import { MODALS } from "lib/constants";
import { Loader } from "ui/shared";

export interface ACLGuardProps {
  fallback?: React.ReactElement<any> | null;
  children: React.ReactNode;
}

// Guard for VIP logic

export const vipGuard = (fn: Function, ...args: any) => {
  if (userStore.user && userStore.user.profile.level === "vip") {
    fn(...args);
  } else {
    // Show VIP modal
    modalManager.open(MODALS.VIP_GUARD);
  }
};

// Guard for authentication

export const ACLGuard = observer((props: ACLGuardProps) => {
  const isAuthenticated = !!userStore.user;

  const { fallback, children } = props;

  const renderContent = () => {
    if (isAuthenticated) {
      return children;
    } else {
      if (userStore.loggingIn) return <Loader />;
      return fallback || null;
    }
  };

  return <React.Suspense fallback={null}>{renderContent()}</React.Suspense>;
});
