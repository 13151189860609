import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const WarningCircle = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.WarningCircle}>
    {({ color }) => (
      <g>
        <circle cx="15.86" cy="15.67" r="13" fill="#cfab00" />
        <g>
          <path
            d="M15.5,18.89a28.9,28.9,0,0,1-1.22-7.33c0-1.45.56-2.24,1.59-2.24s1.58.79,1.58,2.24a28.9,28.9,0,0,1-1.22,7.33Zm1.88,2.51a1.55,1.55,0,0,1-3.1,0,1.53,1.53,0,0,1,1.53-1.52h0a1.51,1.51,0,0,1,1.55,1.49Z"
            transform="translate(0 0.02)"
            fill="#fff"
          />
        </g>
      </g>
    )}
  </CreateSvgIcon>
);
