import React from "react";

interface MediumBubbleSecondProps {
  className?: string;
}

const MediumBubbleSecond = ({ className }: MediumBubbleSecondProps) => {
  return (
    <svg
      width="101"
      height="72"
      viewBox="0 0 101 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity="0.228"
        d="M4.20001 42.8C10.8 54.4 28.3 61.8 41.7 66.2C60.1 72.3 88.1 75.5 98.4 59.4C105.9 47.7 88.1 27.9 65.2 14C56.8 8.80003 47.9 4.70003 38.5 1.60003C11.9 -6.89997 -7.89999 21.6 4.20001 42.8Z"
        fill="#FAF7CF"
      />
    </svg>
  );
};

export default MediumBubbleSecond;
