import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Facebook = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Facebook}>
    {({ color }) => (
      <>
        <g transform="translate(0.182)">
          <g
            transform="translate(-0.182)"
            fill="none"
            stroke={color}
            strokeWidth="1"
          >
            <circle cx="16" cy="16" r="16" stroke="none" />
            <circle cx="16" cy="16" r="15.5" fill="#322941" />
          </g>
          <path
            d="M0,5.611H1.886v-1.7a4.172,4.172,0,0,1,.6-2.622A3.448,3.448,0,0,1,5.427,0a12.651,12.651,0,0,1,3.4.322l-.46,2.622a6.252,6.252,0,0,0-1.518-.23c-.736,0-1.38.23-1.38.92V5.611h2.99l-.23,2.53H5.427v8.877H1.886V8.187H0Z"
            transform="translate(11.375 7.465)"
            fill={color}
          />
        </g>
      </>
    )}
  </CreateSvgIcon>
);
