import React, { useCallback, useState } from "react";
import { makeStyles } from "hooks/makeStyles";
import clsx from "clsx";
import {
  Button,
  Box,
  Typography,
  DefaultPanel,
  AnimatedPage,
  Modal,
} from "ui/shared";
import { useTranslation } from "react-i18next";
import { SettingsStore } from "./store/settings-store";
import { observer } from "mobx-react";
import {
  DisplayInProfilePanel,
  ProfileOption,
} from "./display-in-profile-panel";

import { userStore } from "global-stores/user-store";
import { useHistory } from "react-router-dom";
import { MODALS } from "lib/constants";
import { modalManager } from "global-stores/modal-manager";
import { DeleteAccount } from "./modals/delete-account";
import { DisplayInProfilePanelItem } from "./display-in-profile-panel-item";
import { LanguageDropdown } from "ui/components/language-dropdown/language-dropdown";
import { centered, spacedContainer } from "styles";
import translator from "global-stores/translator-store";

export interface SettingsProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    background: theme.colors.background.paper.main,
  },
  title: {
    textAlign: "center",
    textDecoration: "uppercase",
    marginTop: `${theme.spacing(5.75)}`,
    marginBottom: `${theme.spacing(5.5)}`,
  },
  container: {
    display: "flex",
    flexGrow: 1,
    maxHeight: "100%",
  },
  side: {
    flex: "0 1 40%",
    maxWidth: "350px",
    paddingRight: `${theme.spacing(6)}`,
    paddingLeft: `${theme.spacing(6)}`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  main: {
    flex: "0 1 60%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    alignContent: "flex-start",
    marginRight: `${theme.spacing(6)}`,
    marginBottom: `${theme.spacing(6)}`,
  },
  middle: {
    marginRight: theme.spacing(6),
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  buttonContainer: {
    padding: `${theme.spacing(0)}`,
    paddingTop: 0,
    display: "flex",
    flexDirection: "column",
  },
  button: {
    paddingTop: `${theme.spacing(3.75)}`,
    paddingBottom: `${theme.spacing(3.75)}`,
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(3)}`,
    },
  },
  deleteBtnContainer: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: `${theme.spacing(5)}`,
    paddingLeft: `${theme.spacing(6)}`,
    paddingRight: `${theme.spacing(6)}`,
  },
  deleteBtn: {
    width: 210,
    backgroundColor: "transparent",
    paddingRight: `${theme.spacing(7)}`,
    paddingLeft: `${theme.spacing(7)}`,
    paddingTop: `${theme.spacing(2.5)}`,
    paddingBottom: `${theme.spacing(2.5)}`,
  },
  panel: {
    maxWidth: "300px",
    maxHeight: "275px",
    marginBottom: theme.spacing(2),
  },
  deleteAccountContainer: {
    maxWidth: 240,
    backgroundColor: theme.colors.background.paper.topbar,
    borderRadius: theme.sizes.borderRadius.medium,
    padding: `${theme.spacing(5)}`,
    "&:focus": {
      outline: "none",
    },
  },
  langButtonWrap: {
    display: "flex",
    justifyContent: "flex-end",
    width: 210,
  },
  subscribeArrow: {
    transform: "scaleX(-1)",
  },
  ...spacedContainer(theme),
  ...centered(),
}));

const VERSION = "1.2";

export const Settings = observer((props: SettingsProps) => {
  const { t } = useTranslation("all");
  const css = useStyles();
  const history = useHistory();

  const [settingsStore] = useState(new SettingsStore());

  const displayOptions: ProfileOption[] = [
    {
      label: "gender",
      prop: "hiddenFields.gender",
      value:
        userStore.user &&
        userStore.user.profile &&
        userStore.user.profile.hiddenFields &&
        userStore.user.profile.hiddenFields.gender,
    },
    {
      label: "age",
      prop: "hiddenFields.age",
      value:
        userStore.user &&
        userStore.user.profile &&
        userStore.user.profile.hiddenFields &&
        userStore.user.profile.hiddenFields.age,
    },
    {
      label: "interests",
      prop: "hiddenFields.interests",
      value:
        userStore.user &&
        userStore.user.profile &&
        userStore.user.profile.hiddenFields &&
        userStore.user.profile.hiddenFields.interests,
    },
    {
      label: "vip_icon",
      prop: "hideLevelIcon",
      value:
        userStore.user &&
        userStore.user.profile &&
        userStore.user.profile.hideLevelIcon,
    },
  ];

  /**************************************************/

  const openDeleteAccountModal = useCallback(() => {
    modalManager.open(MODALS.SETTINGS_DELETE_ACCOUNT);
  }, []);

  const handleSelectItem = useCallback(
    (value: boolean, item: ProfileOption) => {
      userStore.setProfileValue(item.prop, value);
    },
    []
  );

  const handleBlackList = useCallback(() => {
    history.push("/settings/blacklist");
  }, [history]);

  const handlePeopleArchive = useCallback(() => {
    history.push("/settings/archive");
  }, [history]);

  /**************************************************/

  return (
    <AnimatedPage animation="vertical" containerClassName={css.root}>
      <Typography uppercase size="subtitle1(18px)" className={css.title}>
        {t("all:settings_title")}
      </Typography>
      <div className={css.container}>
        <div className={css.side}>
          <Box className={css.buttonContainer}>
            <Button
              className={css.button}
              onClick={handleBlackList}
              data-cy="settings.blacklist.page"
            >
              {t("all:settings_blacklist")}
            </Button>
            {/* TODO Language settings */}
            {/* <Button className={css.button}>{t("all:settings_language")}</Button> */}
            <Button
              className={css.button}
              onClick={handlePeopleArchive}
              data-cy="settings.pepleArchive.page"
            >
              {t("all:settings_already_chat")}
            </Button>
            {/* <Button className={css.button}>
              <div className={clsx(css.spacedContainer, css.centered)}>
                <Typography font="light">
                  {t("all:subscription_title")}
                </Typography>
                <Icons.BackArrow
                  width={18}
                  height={18}
                  className={css.subscribeArrow}
                />
              </div>
            </Button> */}
          </Box>
        </div>
        <div className={css.main}>
          <div className={css.middle}>
            <DefaultPanel
              className={css.panel}
              title={t("all:settings_hide_text")}
            >
              <DisplayInProfilePanel
                items={displayOptions}
                onSelectItem={handleSelectItem}
              />
            </DefaultPanel>
            <DefaultPanel className={css.panel}>
              <DisplayInProfilePanelItem
                key={"iam_in_top"}
                checked={
                  userStore.user &&
                  userStore.user.profile &&
                  userStore.user.profile.top
                }
                label={t(`all:settings_iam_in_top`)}
                onClick={(value) =>
                  handleSelectItem(value, {
                    value,
                    prop: "top",
                    label: "top",
                  })
                }
              />
            </DefaultPanel>
          </div>
          <DefaultPanel className={css.panel}>
            <DisplayInProfilePanelItem
              withSwitch
              checked={translator.isGlobalTranslationEnabled}
              label={t("all:settings_trans").toUpperCase()}
              onClick={(value) =>
                translator.setIsGlobalTranslationEnabled(value)
              }
            />
          </DefaultPanel>
        </div>
      </div>
      <Box className={css.deleteBtnContainer}>
        <Button
          secondary
          className={css.deleteBtn}
          onClick={openDeleteAccountModal}
          data-cy="settings.deleteAccount.openModalButton"
        >
          {t("all:settings_button_delete")}
        </Button>
        <Typography>v{VERSION}</Typography>
        <div className={css.langButtonWrap}>
          <LanguageDropdown />
        </div>
      </Box>
      <Modal
        modalId={MODALS.SETTINGS_DELETE_ACCOUNT}
        data-cy="settings.deleteAccount.modal"
      >
        {(_, className) => (
          <DeleteAccount
            settingsStore={settingsStore}
            className={clsx(className, css.deleteAccountContainer)}
          />
        )}
      </Modal>
    </AnimatedPage>
  );
});
