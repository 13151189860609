import clsx from "clsx";
import { makeStyles } from "hooks/makeStyles";
import React from "react";
import { Box } from "../box/box";
import { Button } from "../button/button";
import { Dropdown } from "../dropdown/dropdown";
import { Typography } from "../typography/typography";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(8),
  },
  modalTrigger: {
    backgroundColor: "transparent",
    textTransform: "capitalize",
    padding: "5px 12px",
  },
  modalTriggerOnText: {
    textAlign: "left",
    padding: 0,
    borderRadius: 3,
  },
  baseText: {
    fontSize: "14px",
    textTransform: "none",
    marginTop: theme.spacing(1),
  },
  warningText: {
    color: theme.colors.primary,
  },
  succeedText: {
    color: theme.colors.green.main,
  },
  errorText: {
    color: theme.colors.red.main,
  },
}));

export enum HelperMessageType {
  Warning = "warning",
  Error = "error",
  Success = "success",
}

interface EditableProps {
  label: React.ReactNode;
  isEdit?: boolean;
  view: React.ReactElement;
  message?: React.ReactNode;
  messageType?: HelperMessageType;
  onEdit?: () => void;
}

export const Editable = (props: EditableProps) => {
  const css = useStyles();
  const { label, isEdit = false, view, message, onEdit, messageType } = props;

  const messageCx = clsx(css.baseText, {
    [css.warningText]: messageType === HelperMessageType.Warning,
    [css.errorText]: messageType === HelperMessageType.Error,
    [css.succeedText]: messageType === HelperMessageType.Success,
  });

  return (
    <div className={css.container}>
      {label}
      <EditableContainer active={isEdit}>
        {!isEdit ? <Button onClick={onEdit}>{view}</Button> : <div>{view}</div>}
      </EditableContainer>
      {typeof message === "string" ? (
        <Typography className={messageCx}>{message}</Typography>
      ) : (
        message
      )}
    </div>
  );
};

interface EditableDropdownProps {
  label: React.ReactNode;
  dropdownRender: (
    close: (force?: boolean | undefined) => void
  ) => React.ReactNode;
  onCloseDropdown?: () => void;
  trigger: React.ReactElement;
}

export const EditableDropdown = (props: EditableDropdownProps) => {
  const css = useStyles();
  const { label, dropdownRender, trigger, onCloseDropdown } = props;

  return (
    <div className={css.container}>
      {label}
      <Dropdown
        preferTop
        preferRight
        render={dropdownRender}
        onClose={onCloseDropdown}
      >
        {React.cloneElement(trigger, {
          className: clsx(
            trigger.props.className,
            css.modalTrigger,
            css.modalTriggerOnText
          ),
        })}
      </Dropdown>
    </div>
  );
};

interface EditableContainerProps {
  active?: boolean;
  children: React.ReactElement;
}

export const EditableContainer = (props: EditableContainerProps) => {
  const css = useStyles(props);

  return React.cloneElement(props.children, {
    className: clsx(
      props.children.props.className,
      css.modalTrigger,
      css.modalTriggerOnText
    ),
  });
};

export const wrappedLabel = (el: React.ReactElement) => {
  return <Box mb={2}>{el}</Box>;
};
