import React, { useEffect, useRef } from "react";
import { makeStyles } from "hooks/makeStyles";
import { gsap, Power1, TimelineLite } from "gsap";
import { globalUIStore } from "global-stores/global-ui-store";
import MorphSVGPlugin from "gsap/MorphSVGPlugin";

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: "none",
  },
  hide: {
    opacity: 0,
  },
}));

export const AppLoaderNew = () => {
  const css = useStyles();

  const t1 = new TimelineLite();
  const t2 = new TimelineLite();
  const t3 = new TimelineLite();
  const t4 = new TimelineLite();

  const start = useRef(null);
  const start2 = useRef(null);
  const end = useRef(null);
  const end2 = useRef(null);

  const i = useRef(null);
  const n = useRef(null);
  const s = useRef(null);
  const i2 = useRef(null);
  const d = useRef(null);
  const e = useRef(null);
  const m = useRef(null);
  const e2 = useRef(null);

  const chari = useRef(null);
  const charn = useRef(null);
  const chars = useRef(null);
  const chari2 = useRef(null);
  const chard = useRef(null);
  const chare = useRef(null);
  const charm = useRef(null);
  const chare2 = useRef(null);

  const stroke = useRef(null);

  useEffect(() => {
    globalUIStore.setLoading(true);
    gsap.registerPlugin(MorphSVGPlugin);

    t1.to(start.current, 0.68, { morphSVG: start2.current })
      .to(start.current, 0.68, { morphSVG: start.current })
      .repeat(1);
    t2.to(end.current, 0.68, { morphSVG: end2.current })
      .to(end.current, 0.68, { morphSVG: end.current })
      .repeat(1);

    t4.to(i.current, 0.4, { opacity: 1, morphSVG: chari.current })
      .to(n.current, 0.4, { opacity: 1, morphSVG: charn.current, delay: -0.1 })
      .to(s.current, 0.4, { opacity: 1, morphSVG: chars.current, delay: -0.15 })
      .to(i2.current, 0.4, {
        opacity: 1,
        morphSVG: chari2.current,
        delay: -0.2,
      })
      .to(d.current, 0.4, { opacity: 1, morphSVG: chard.current, delay: -0.25 })
      .to(e.current, 0.4, { opacity: 1, morphSVG: chare.current, delay: -0.25 })
      .to(m.current, 0.4, { opacity: 1, morphSVG: charm.current, delay: -0.3 })
      .to(e2.current, 0.4, {
        opacity: 1,
        morphSVG: chare2.current,
        delay: -0.3,
      })
      .to(stroke.current, 2, { opacity: 1 });

    t3.add([t1, t2])
      .add(t4, "-=0.5")
      .call(() => globalUIStore.setLoading(false))
      .play();
  }, []);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="150px"
      viewBox="0 0 172.03 100.01"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="85.98"
          y1="100.32"
          x2="85.98"
          y2="-7.61"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#792954" />
          <stop offset="0.45" stopColor="#00002c" />
          <stop offset="0.58" stopColor="#1b0733" />
          <stop offset="1" stopColor="#942652" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="121.62"
          y1="-12.72"
          x2="46.67"
          y2="117.09"
          gradientTransform="matrix(1, 0, 0, -1, 0, 100)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#08022e" />
          <stop offset="0.62" stopColor="#611945" />
          <stop offset="1" stopColor="#942652" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="85.98"
          y1="100.32"
          x2="85.98"
          y2="-7.61"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#792954" />
          <stop offset="0.45" stopColor="#00002c" />
          <stop offset="0.58" stopColor="#1b0733" />
          <stop offset="1" stopColor="#942652" />
        </linearGradient>
      </defs>

      <g id="Layer_2" data-name="Layer 2"></g>
      <g id="Layer_4" data-name="Layer 4">
        <path
          id="start"
          ref={start}
          d="M126.34,57.16a43.66,43.66,0,0,1,35.38,18,46.89,46.89,0,0,0-38.2-74.1s-20-1.58-36.85,18c-19,22-35.41,21.8-35.41,21.8a43.69,43.69,0,0,1-37.91-22,46.89,46.89,0,0,0,35.09,78c15.82,0,33.28-3.23,49.08-25.26C110.1,54.91,126.34,57.16,126.34,57.16Z"
          transform="translate(0.03 0)"
          fill="url(#linear-gradient)"
        />
        <path
          id="end"
          ref={end}
          d="M100.7,23.25C81.9,4.05,64.26,3.08,48.44,3.08a46.89,46.89,0,0,0-35.09,78C20.9,68,32.91,61.94,49.13,61.94c0,0,19.38-2,35.53,19.53,14.68,19.62,38.86,17.42,38.86,17.42a46.89,46.89,0,0,0,38.2-74.1S132.25,55.56,100.7,23.25Z"
          transform="translate(0.03 0)"
          fill="url(#linear-gradient-2)"
        />
        <path
          id="start-2"
          ref={start2}
          d="M100.7,23.25C81.9,4.05,64.26,3.08,48.44,3.08a46.89,46.89,0,0,0-35.09,78C20.9,68,32.91,61.94,49.13,61.94c0,0,19.38-2,35.53,19.53,14.68,19.62,38.86,17.42,38.86,17.42a46.89,46.89,0,0,0,38.2-74.1S132.25,55.56,100.7,23.25Z"
          transform="translate(0.03 0)"
          fill="url(#linear-gradient-2)"
          className={css.hidden}
        />
        <path
          id="end-2"
          ref={end2}
          d="M126.34,57.16a43.66,43.66,0,0,1,35.38,18,46.89,46.89,0,0,0-38.2-74.1s-20-1.58-36.85,18c-19,22-35.41,21.8-35.41,21.8a43.69,43.69,0,0,1-37.91-22,46.89,46.89,0,0,0,35.09,78c15.82,0,33.28-3.23,49.08-25.26C110.1,54.91,126.34,57.16,126.34,57.16Z"
          transform="translate(0.03 0)"
          fill="url(#linear-gradient)"
          className={css.hidden}
        />
      </g>
      <g id="Слой_1" data-name="Слой 1">
        <g id="Слой_1-2" data-name="Слой 1">
          <g id="Сгруппировать_3888" data-name="Сгруппировать 3888">
            <path
              id="stroke"
              ref={stroke}
              d="M172,47.2a50.18,50.18,0,0,0-6-23A43,43,0,0,0,147.9,4.8,46.24,46.24,0,0,0,127.6,0c-8-.1-12.6,1.7-18.5,4.1A124.74,124.74,0,0,1,81,11.9,51.16,51.16,0,0,0,65.7,3.4,49.83,49.83,0,0,0,40.3,1.6c-17.5,3.2-35,19.9-39,37.3A50.05,50.05,0,0,0,7.7,77c1.7,3,8.5,14.2,21.1,19.6A45.61,45.61,0,0,0,45,100c8.8.2,14.3-3.4,20.6-7.6C70.9,89,76.8,85,86.2,82.2a49.74,49.74,0,0,0,15.1,11,51.39,51.39,0,0,0,20,4.7h.2a49.13,49.13,0,0,0,35.8-14.5A50.06,50.06,0,0,0,172,47.2Zm-61.2-39c5.8-2.3,9.6-3.9,16.8-3.7,8,.1,14.6,1.9,20.2,5.5,4.9,3.1,9.1,7.7,13,14a46.11,46.11,0,0,1-14.9,11.8,43.57,43.57,0,0,1-20.7,4.6C114,40.1,104,35.1,94.4,24.9c-1.1-1.1-2.2-2.2-3.5-3.4q-2.25-2.1-4.5-4.5h0l-.6-.6h0l-.6-.6A136.66,136.66,0,0,0,110.8,8.2ZM63.2,88.7c-6.2,4.1-10.7,7-18.2,6.9C31.4,95.3,21.3,89.3,12.4,76a45.86,45.86,0,0,1,3.7-3.9c.2-.1.3-.3.4-.4a.1.1,0,0,0,.1-.1,43.17,43.17,0,0,1,10.5-7.1A44.29,44.29,0,0,1,47.7,60c12.3.2,21.1,4.8,35.1,18.8C73.8,81.6,68.1,85.4,63.2,88.7Zm91-8.6a44.75,44.75,0,0,1-32.6,13.2h-.2A45,45,0,0,1,88.7,78.2l-.1-.1C72.3,61.4,62,55.6,47.8,55.4a48.88,48.88,0,0,0-20,3.9,18.89,18.89,0,0,0-2.5,1.1,51.31,51.31,0,0,0-11,7.3L14,68a1.76,1.76,0,0,1-.5.4,5.72,5.72,0,0,1-.8.7l-.1.1A23.67,23.67,0,0,0,9.9,72,44.74,44.74,0,0,1,5.6,39.9C9.1,24.3,25.3,8.8,41,5.9A45.18,45.18,0,0,1,82.2,19.1v.1a2.65,2.65,0,0,0,.5.6c1.7,1.9,3.5,3.6,5,5C88.9,25.9,90,27,91,28c10.4,11.1,21.5,16.6,33.8,16.8a49.33,49.33,0,0,0,22.7-5.1A49.93,49.93,0,0,0,162.8,28a46,46,0,0,1,4.6,19.3A43.88,43.88,0,0,1,154.2,80.1Z"
              transform="translate(0.03 0)"
              fill="#fff"
              className={css.hide}
            />
            <path
              id="char-i"
              ref={chari}
              d="M12.3,63.4a92.55,92.55,0,0,1,9.8-8A96.37,96.37,0,0,1,25.5,18,108.25,108.25,0,0,0,15,25.2C10.8,37.6,10.4,50.7,12.3,63.4Z"
              transform="translate(0.03 0)"
              fill="#fff"
              className={css.hide}
            />
            <path
              id="char-n"
              ref={charn}
              d="M50,11.4c-1,7.3-1.9,14.7-2.7,22-.1,2-.2,3-.2,4.9-.2-1.3-.4-2-.6-3.3-1.8-7.7-3.3-15.3-4.7-23.1a60.81,60.81,0,0,0-11.7,3.6,108.62,108.62,0,0,0-3.6,37.2,57.75,57.75,0,0,1,9.1-3.2,125.13,125.13,0,0,1,1.1-22.9c.2-2.4.3-3.6.6-5.8.1,1.5.2,2.2.4,3.8a183.14,183.14,0,0,0,6.7,24.1,48.14,48.14,0,0,1,11.3,1.6C56,37.5,58,24.9,58.9,12.2A65.42,65.42,0,0,0,50,11.4Z"
              transform="translate(0.03 0)"
              fill="#fff"
              className={css.hide}
            />
            <path
              id="char-s"
              ref={chars}
              d="M74.6,18c-5.7-3.8-12.2-2-13,6a13.22,13.22,0,0,0,1.5,8.3c1.9,3,4.8,5.4,7.4,7.8s7.5,6.4,7.4,10.3c0,1.6-1.3,2-3.7.1a27.55,27.55,0,0,1-8-11.1c-2.8,1.7-5.3,3.5-7.9,5.4A43.44,43.44,0,0,0,63.9,52,87.71,87.71,0,0,0,73,60.1c2.8,2.1,5.9,5,9.7,5.1,3.2.1,3.8-3.8,4-5.9,1-8.5-5.5-17.5-11.5-23.6-1.9-1.9-5.7-4.7-5.5-7.7.1-1.6,1.3-2.4,3.8-.6,3.9,2.8,6.2,7.7,7.6,12.1,3.1-.6,4.6-.8,7.6-1.2C85.3,30.7,81.1,22.4,74.6,18Z"
              transform="translate(0.03 0)"
              fill="#fff"
              className={css.hide}
            />
            <path
              id="char-i-2"
              ref={chari2}
              d="M89.8,74.2a96.72,96.72,0,0,0,10.8,8.3c.7-13.6,1.4-27.1,1.3-40.6a98.46,98.46,0,0,1-10.8-8.5C91.2,47,90.3,60.6,89.8,74.2Z"
              transform="translate(0.03 0)"
              fill="#fff"
              className={css.hide}
            />
            <path
              id="char-d"
              ref={chard}
              d="M121.8,49.1a51.23,51.23,0,0,1-14.4-3.9c-.2,13.4-.9,26.9-1.9,40.3a32.66,32.66,0,0,0,13.1,2.3c11-.2,16.4-12.5,16.7-21.7C135.6,56.7,131.8,49.9,121.8,49.1Zm4.7,18.6c-.3,4.4-2,11.1-7.7,11.1h-2.2s1.4-14,1.9-21.1a12.1,12.1,0,0,0,1.9.1c5.5.2,6.4,5.3,6.1,9.9Z"
              transform="translate(0.03 0)"
              fill="#fff"
              className={css.hide}
            />
            <path
              id="char-e"
              ref={chare}
              d="M138.6,47.8a190,190,0,0,1-2.1,36.9,69.31,69.31,0,0,0,21.1-12.5c.8-3.5,1.5-7,2-10.6a84.87,84.87,0,0,1-13.9,9.8c.2-2.3.4-3.6.5-5.9a85.16,85.16,0,0,0,10.6-7.1q.45-4,.6-8.1a78,78,0,0,1-10.8,7.2c.1-2.2.1-3.3.1-5.3a79.45,79.45,0,0,0,14-9.7c.1-2.5,0-4.9-.1-7.4C154,40.5,146.9,45.4,138.6,47.8Z"
              transform="translate(0.03 0)"
              fill="#fff"
              className={css.hide}
            />
            <path
              id="char-e-up"
              ref={chare2}
              d="M125.6,36.6c7.4-3.2,11.3-5.5,19.6-11.8a26.78,26.78,0,0,1-5.7-3.2c-4.4,3.4-8.9,5.6-12.5,7.9a15.85,15.85,0,0,1-4.1-2.6,78.55,78.55,0,0,0,10-6.1c-1.9-1.2-2.5-1.7-4.7-3.3a56.65,56.65,0,0,1-11.3,5.6,15.21,15.21,0,0,1-4.6-3c4.2-1.8,9.3-3.4,14.2-6.4-2.5-1.6-2.9-2.4-4.8-3.8-5.8,3.4-14.3,5.8-21,7.8A57.83,57.83,0,0,0,125.6,36.6Z"
              transform="translate(0.03 0)"
              fill="#fff"
              className={css.hide}
            />
            <path
              id="char-m"
              ref={charm}
              d="M50.6,82.2a17.1,17.1,0,0,0,1.1,1.4c-.6-.4-1-.7-1.7-1.1a148,148,0,0,1-13.7-9.8c-4.6,1-9.2,1.4-13.8,2.3C27,82.1,33.3,87.4,39.4,93c2.6-.9,5.2-1.9,7.8-2.9C43.6,86.5,40,82.7,36.4,79c-1.1-1-1.6-1.5-2.7-2.6,1.5,1.1,2.3,1.7,3.7,2.6,4.6,3.2,9.2,6.3,14,9.2A27.34,27.34,0,0,0,58,84.3c-2.8-3.7-5.4-7.5-7.9-11.4-.7-1.1-1.1-1.6-1.7-2.7.8.9,1.2,1.3,2,2.1a107.38,107.38,0,0,0,11.1,9.1c2.3-2,3.4-3.1,5.6-5.2a106.91,106.91,0,0,1-16-12.4,42.87,42.87,0,0,1-8.2,5.7C45.4,73.9,47.9,78.1,50.6,82.2Z"
              transform="translate(0.03 0)"
              fill="#fff"
              className={css.hide}
            />
          </g>
        </g>
      </g>
      <g id="Layer_5" data-name="Layer 5">
        <path
          id="i"
          ref={i}
          d="M14.41,40.65a2.63,2.63,0,0,1,3.95-1.95c3.35,1.43,0,3.14,0,3.14A2.86,2.86,0,0,1,14.41,40.65Z"
          transform="translate(0.03 0)"
          fill="#fff"
          className={css.hide}
        />
        <path
          id="n"
          ref={n}
          d="M39.82,32.42a2.63,2.63,0,0,1,3.94-2c3.35,1.43,0,3.14,0,3.14A2.85,2.85,0,0,1,39.82,32.42Z"
          transform="translate(0.03 0)"
          fill="#fff"
          className={css.hide}
        />
        <path
          id="s"
          ref={s}
          d="M70.78,41a2.63,2.63,0,0,1,4-1.94c3.35,1.43,0,3.14,0,3.14A2.86,2.86,0,0,1,70.78,41Z"
          transform="translate(0.03 0)"
          fill="#fff"
          className={css.hide}
        />
        <path
          id="i-2"
          ref={i2}
          d="M93.14,58.32a2.63,2.63,0,0,1,3.94-2c3.35,1.43,0,3.14,0,3.14A2.85,2.85,0,0,1,93.14,58.32Z"
          transform="translate(0.03 0)"
          fill="#fff"
          className={css.hide}
        />
        <path
          id="d"
          ref={d}
          d="M118.86,68.62a2.63,2.63,0,0,1,3.94-2c3.35,1.43,0,3.14,0,3.14A2.85,2.85,0,0,1,118.86,68.62Z"
          transform="translate(0.03 0)"
          fill="#fff"
          className={css.hide}
        />
        <path
          id="e"
          ref={e}
          d="M145.91,60.27a2.63,2.63,0,0,1,3.94-2c3.35,1.43,0,3.14,0,3.14A2.85,2.85,0,0,1,145.91,60.27Z"
          transform="translate(0.03 0)"
          fill="#fff"
          className={css.hide}
        />
        <path
          id="e-up"
          ref={e2}
          d="M120.23,23.62a2.63,2.63,0,0,1,4-1.95c3.35,1.43,0,3.14,0,3.14A2.86,2.86,0,0,1,120.23,23.62Z"
          transform="translate(0.03 0)"
          fill="#fff"
          className={css.hide}
        />
        <path
          id="m"
          ref={m}
          d="M47.17,79.2a2.63,2.63,0,0,1,3.95-1.94c3.35,1.42,0,3.14,0,3.14A2.86,2.86,0,0,1,47.17,79.2Z"
          transform="translate(0.03 0)"
          fill="#fff"
          className={css.hide}
        />
      </g>
    </svg>
  );
};
