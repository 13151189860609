import memoizee from "memoizee";
import { action, observable } from "mobx";

export interface UploadedFile {
  file: File;
  size?: {
    width: number;
    height: number;
  };
  buffer?: ArrayBuffer;
}

export class DropZoneStore {
  @observable files: UploadedFile[] = [];
  @observable open?: () => void;

  init(open: () => void) {
    this.open = open;
  }

  @action
  setFiles(files: UploadedFile[]) {
    this.files = files;
  }

  @action
  addFile(file: UploadedFile) {
    return this.files.push(file);
  }

  @action
  removeFile(index: number) {
    this.files = this.files.filter((file, i) => i !== index);
  }

  @action
  clear() {
    this.files = [];
  }
}

export const getDropZoneStore = memoizee(
  (friendId: string) => new DropZoneStore(),
  { max: 10, length: 1 }
);
