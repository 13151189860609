import { AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { chatsStore } from "global-stores/chats-store";
import { globalUIStore } from "global-stores/global-ui-store";
import { makeStyles } from "hooks/makeStyles";
import { Friend } from "lib";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Button, Typography } from "ui/shared";
import ArrowKeyFocuser from "ui/shared/arrow-key-focuser/arrow-key-focuser";
import { ChatItem } from "./chat-item";

interface ChatsSidebarProps {
  loading?: boolean;
  chats: Friend[];
  selectedFriendId?: string;
  selectChatCallback: (friend: Friend) => void;
}

const useStyles = makeStyles((theme) => ({
  side: {
    background: theme.colors.background.paper.main,
    overflowY: "auto",
    overflowX: "hidden",
  },
  blankContainer: {
    width: "inherit",
    height: "inherit",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

const ChatsSidebar = observer(
  ({
    loading,
    chats,
    selectedFriendId,
    selectChatCallback,
  }: ChatsSidebarProps) => {
    const css = useStyles();
    const history = useHistory();
    const { t } = useTranslation("all");

    /**************************************************/

    const handleStartSearching = useCallback(() => {
      history.push("/search");
    }, [history]);

    /**************************************************/

    return (
      <div className={css.side}>
        <AnimateSharedLayout>
          <AnimatePresence>
            {!loading && chats ? (
              <ArrowKeyFocuser
                items={chats}
                ItemComponent={ChatItem}
                onSelectItem={selectChatCallback}
                itemProps={{
                  selectedFriendId,
                  loading: !globalUIStore.animationReady,
                }}
              />
            ) : !loading && !chatsStore.friends.length ? (
              <div className={css.blankContainer}>
                <Typography color="disabled">
                  {t("all:friends_empty_text")}
                </Typography>
                <Button
                  primary
                  className={css.findButton}
                  onClick={handleStartSearching}
                  data-cy="chat-sidebar-start-search-button"
                >
                  {t("chat:actions.find")}
                </Button>
              </div>
            ) : null}
          </AnimatePresence>
        </AnimateSharedLayout>
      </div>
    );
  }
);

export default ChatsSidebar;
