import React, { useCallback, useEffect } from "react";
import { makeStyles } from "hooks/makeStyles";
import {
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Slider,
  Button,
} from "ui/shared";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Age } from "lib";
import { userStore } from "global-stores/user-store";
import { profileStore } from "../store.profile";
import { useEventCallback } from "hooks/useEventCallback";
import { observer } from "mobx-react";

interface AgeModalProps {
  close: (force?: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minWidth: 300,
    backgroundColor: theme.colors.background.paper.topbar,
    padding: theme.spacing(3.5),
    boxShadow: theme.shadows.dropdown,
    borderRadius: 10,
    alignItems: "center",
  },
  chooseAge: {
    "&$opened": {
      height: "70px",
      opacity: 1,
      marginTop: theme.spacing(5),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      paddingTop: theme.spacing(6),
      "& > div": {
        visibility: "visible",
      },
    },
    height: 0,
    overflow: "hidden",
    opacity: 0,
    marginTop: 0,
    paddingTop: 0,
    transition:
      "padding .4s, margin .4s, height .4s, opacity .6s cubic-bezier(0, 0, 0, 1)",
    "& > div": {
      visibility: "hidden",
    },
  },
  opened: {},
}));

export const AgeModal = observer((props: AgeModalProps) => {
  const css = useStyles(props);
  const { t } = useTranslation("search");
  const { close } = props;

  const handleSliderValueChange = useEventCallback(
    (e: React.MouseEvent, value: number | number[], activeIndex: number) => {
      profileStore.setAgeSliderValue(value as number[], activeIndex);
    }
  );

  const handleSaveMyAge = useCallback(() => {
    close(true);
  }, [close]);

  useEffect(() => {
    if (
      userStore.user &&
      userStore.user.profile &&
      userStore.user.profile.age
    ) {
      profileStore.setAge("choose");
    } else {
      profileStore.setAge("");
    }
  }, [profileStore]);

  return (
    <div className={css.root} data-cy="age.modal">
      <ToggleButtonGroup
        fullWidth
        exclusive
        value={profileStore.age}
        onChange={(e, value) => {
          profileStore.setAge(value as Age);
          profileStore.updateAge(true);

          if (value === "") {
            close(true);
          }
        }}
      >
        <ToggleButton value="" data-cy="age.modal.button.anything">
          {t("all:reg_2_not_disclose")}
        </ToggleButton>
        <ToggleButton value="choose" data-cy="age.modal.button.choose">
          {t("all:main_placeholder_age")}
        </ToggleButton>
      </ToggleButtonGroup>
      <Box
        pr={6}
        pl={6}
        pt={6}
        mt={5}
        mb={profileStore.age === "choose" ? 5 : 0}
        className={clsx(css.chooseAge, {
          [css.opened]: profileStore.age === "choose",
        })}
      >
        <Slider
          showBottomLabel
          value={profileStore.ageSliderValue}
          min={18}
          max={99}
          onChange={handleSliderValueChange}
          data-cy="age.modal.slider"
        />
      </Box>
      {profileStore.age === "choose" ? (
        <Button primary onClick={handleSaveMyAge}>
          {t("all:button_save")}
        </Button>
      ) : null}
    </div>
  );
});
