import { Gender, TopUser, TopUserDetails } from "lib";
import { Meteor } from "meteor/meteor";
import { action, observable } from "mobx";

class TopStore {
  @observable users: TopUser[] = [];
  @observable selectedUser?: TopUserDetails;
  @observable filter: Gender = "";

  @action
  setSelectedUser(user?: TopUserDetails) {
    this.selectedUser = user;
  }

  @action
  setTopFilter(value: Gender) {
    this.filter = value;
    this.getTopUsers();
  }

  @action
  clearFilter() {
    this.filter = "";
    this.getTopUsers();
  }

  @action
  getTopUsers() {
    Meteor.call(
      "users.get",
      { gender: this.filter },
      (err: Meteor.Error, res: TopUser[]) => {
        if (err) {
          return;
        }

        this.users = res;
      }
    );
  }
}

export const topStore = new TopStore();
