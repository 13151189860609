import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const PauseCircle = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.PauseCircle}>
    {({ color }) => (
      <>
        <rect width="32" height="32" fill="none" />
        <g transform="translate(-1044 -198)">
          <g
            transform="translate(1049 203)"
            fill="none"
            stroke="#fff"
            strokeWidth="1"
          >
            <circle cx="11" cy="11" r="11" stroke="none" />
            <circle cx="11" cy="11" r="10.5" fill="none" />
          </g>
          <rect
            width="1"
            height="10"
            transform="translate(1057 209)"
            fill="#fff"
          />
          <rect
            width="1"
            height="10"
            transform="translate(1062 209)"
            fill="#fff"
          />
        </g>
      </>
    )}
  </CreateSvgIcon>
);
