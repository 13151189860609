import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Settings = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Settings}>
    {({ color }) => (
      <>
        <rect width="32" height="32" fill="none" />
        <g transform="translate(29 5.074)">
          <g transform="translate(-24 0.126)">
            <path
              d="M21.765,8.741a3.221,3.221,0,0,1-2-.235A3.335,3.335,0,0,1,19,8.035l-.294-.294a1.23,1.23,0,0,1-.235-.353.85.85,0,0,1-.118-.412.872.872,0,0,1-.059-.412,4.541,4.541,0,0,1,.118-.882,3.777,3.777,0,0,1,1.059-1.706,10.682,10.682,0,0,0-3.706-2.941h0a3.612,3.612,0,0,1-1.412,1.412,1.752,1.752,0,0,1-.824.294,1.944,1.944,0,0,1-.824,0,2.241,2.241,0,0,1-1.353-1A3.482,3.482,0,0,1,10.706-.2,10.941,10.941,0,0,0,6.118.859a3.432,3.432,0,0,1,.235,2,1.976,1.976,0,0,1-.294.824,1.893,1.893,0,0,1-.471.765,2.592,2.592,0,0,1-1.647.471,3.389,3.389,0,0,1-1.882-.706A10.408,10.408,0,0,0,0,8.506H0A3.39,3.39,0,0,1,1.706,9.565a2.075,2.075,0,0,1,.471.765,1.628,1.628,0,0,1,.176.765,2.076,2.076,0,0,1-.176.824,2.694,2.694,0,0,1-.471.765A3.39,3.39,0,0,1,0,13.741a10.945,10.945,0,0,0,2.059,4.235,3.042,3.042,0,0,1,1.882-.647,2.326,2.326,0,0,1,1.588.471,2.83,2.83,0,0,1,.529.647,3.4,3.4,0,0,1,.294.824,3.432,3.432,0,0,1-.235,2h0a10.941,10.941,0,0,0,4.588,1.059h0a3.644,3.644,0,0,1,.647-1.941,2.5,2.5,0,0,1,1.353-1,1.938,1.938,0,0,1,.824,0,4.611,4.611,0,0,1,.824.294,3.612,3.612,0,0,1,1.412,1.412h0a11.251,11.251,0,0,0,3.706-2.941,2.987,2.987,0,0,1-1.059-1.706,4.541,4.541,0,0,1-.118-.882,1,1,0,0,1,.059-.412,1.091,1.091,0,0,1,.118-.412,1.231,1.231,0,0,1,.235-.353A.709.709,0,0,1,19,14.094a2.418,2.418,0,0,1,.765-.471,3.776,3.776,0,0,1,2-.235A10.3,10.3,0,0,0,22,11.035,9.8,9.8,0,0,0,21.765,8.741ZM11,15.271a4.294,4.294,0,1,1,4.294-4.294A4.28,4.28,0,0,1,11,15.271Z"
              fill="none"
              stroke="#fff"
              strokeWidth="1"
            />
          </g>
        </g>
      </>
    )}
  </CreateSvgIcon>
);
