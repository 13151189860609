import { Meteor } from "meteor/meteor";
import { observable, action, computed } from "mobx";
import { i18n } from "services/i18n";
import { storage, callWithPromise } from "utils";
import { Gender, Age, ToastType } from "lib";
import translateErrors from "utils/translate-errors";
import { toastStore } from "global-stores/toasts-store";

class ProfileStore {
  @observable usernameBeforeEdit: string = "";
  @observable username: string = "";
  @observable message: string = "";
  @observable warning: string = "";
  @observable usernameError?: string;

  @observable emailBeforeEdit: string = "";
  @observable email: string = "";
  @observable verifcationEmail: string = "";
  @observable setVerif: boolean = false;
  @observable emailError?: string;

  @observable gender: Gender = "";
  @observable age: Age = "";
  @observable ageSliderValue: number[] = [20, 30];
  @observable about: string = "";
  @observable languages: string[] = [];

  _loginRegExp = /^[a-zA-Z]([_.-]?[a-zA-Z0-9]){4,20}$/;
  _emailRegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  @action
  setUsernameBeforeEdit(value: string) {
    this.usernameBeforeEdit = value;
  }

  @action
  setEmailBeforeEdit(value: string) {
    this.emailBeforeEdit = value;
  }

  @action
  setEmail(value: string, isCheckNeeded?: boolean) {
    this.email = value;
    if (value.length) {
      if (isCheckNeeded) {
        this.checkEmail();
      } else if (isCheckNeeded === false) {
        this.message = "";
        this.warning = "";
        this.emailError = undefined;
      }
    } else {
      this.message = "";
      this.emailError = undefined;
      this.warning = i18n.t("all:verification_email_error");
    }
    if (!value) {
      this.message = "";
      this.warning = "";
      this.emailError = undefined;
    }
  }

  @action
  async checkEmail() {
    if (!this.email.match(this._emailRegExp)) {
      this.emailError = i18n.t("all:verification_email_error");
      this.message = "";
      this.warning = "";
      return;
    } else {
      Meteor.call(
        "email.existCheck",
        { email: this.email },
        (error: Meteor.Error, result: any) => {
          if (error) {
            this.emailError = i18n.t("all:verification_email_error");
            this.message = "";
            return;
          }
          if (result) {
            this.emailError = i18n.t("all:verification_alert_already_exist");
            this.message = "";
          } else {
            this.message = i18n.t("all:verification_email_ok_profile");
            this.warning = "";
            this.emailError = undefined;
          }
        }
      );
    }
  }

  @action
  async setNewEmail() {
    const self = this;
    if (this.email && !this.emailError && !this.warning) {
      Meteor.call(
        "emailVerification.init",
        { email: this.email, lang: i18n.language, link: true },
        (error: Meteor.Error, result: any) => {
          if (!error) {
            self.getVerificationEmail();
          } else {
            toastStore.showToast({
              message: translateErrors(
                error.error === "verification_alert_too_fast"
                  ? error.error
                  : "verification_alert_send_email_error"
              ),
              type: ToastType.Error,
            });
          }
        }
      );
    } else {
      this.email = "";
    }
  }

  @computed
  get isEmailDirty() {
    return this.emailBeforeEdit !== this.email;
  }

  @computed
  get isUsernameDirty() {
    return this.usernameBeforeEdit !== this.username;
  }

  @action
  setUsername(value: string, isCheckNeeded?: boolean) {
    if (value.length <= 20) {
      this.username = value;
      if (value.length >= 5) {
        if (isCheckNeeded) {
          this.checkUsername();
        } else if (isCheckNeeded === false) {
          this.message = "";
          this.warning = "";
          this.usernameError = undefined;
        }
      } else {
        this.message = "";
        this.usernameError = undefined;
        this.warning = i18n.t("all:main_signup_msg_rules");
      }
    }
    if (!value) {
      this.message = "";
      this.warning = "";
      this.usernameError = undefined;
    }
  }

  @action
  async checkUsername() {
    if (!this.username.match(this._loginRegExp)) {
      this.usernameError = i18n.t("all:main_signup_msg_login_uncorrect");
      return;
    }
    Meteor.call(
      "users.loginExistCheck",
      { username: this.username },
      (error: Meteor.Error, result: any) => {
        if (error) {
          this.usernameError = i18n.t("all:main_signup_msg_login_uncorrect");
          this.message = "";
          return;
        }
        if (result) {
          this.usernameError = i18n.t("all:main_signup_msg_login_busy");
          this.message = "";
        } else {
          this.message = i18n.t("all:main_signup_msg_login_free");
          this.warning = "";
          this.usernameError = undefined;
        }
      }
    );
  }

  @action
  async setNewUserName() {
    if (this.username && !this.usernameError && !this.warning) {
      Meteor.call(
        "users.editLogin",
        { newUsername: this.username },
        (error: Meteor.Error, result: any) => {
          if (!error) {
            storage.write("login", this.username);
          }
        }
      );
    }
  }

  @action
  setGender(value: Gender) {
    this.gender = value;
    callWithPromise("users.setProfileValue", {
      name: "gender",
      value: this.gender,
    });
  }

  @action
  setAge(value: Age) {
    if (value != null) {
      this.age = value;
    }
  }

  @action
  setAgeSliderValue(value: number[], activeIndex: number) {
    /**
     * Normalize value depends of activeIndex(active thumb of the slider) to
     * to satisfy the condition = max - min <= 10
     */
    if (activeIndex === 0) {
      if (value[1] - value[0] > 10) {
        const normalizedValue = [value[0], value[0] + 10];
        this.ageSliderValue = normalizedValue;
      } else {
        this.ageSliderValue = value;
      }
    } else {
      if (value[1] - value[0] > 10) {
        const normalizedValue = [value[1] - 10, value[1]];
        this.ageSliderValue = normalizedValue;
      } else {
        this.ageSliderValue = value;
      }
    }
  }

  @action updateAge(reset?: boolean) {
    if (this.age === "choose") {
      this.saveAge();
    }
    if (reset) {
      try {
        callWithPromise("users.setProfileValue", {
          name: "age",
          value: [0, 0],
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  @action async getVerificationEmail() {
    const self = this;
    Meteor.call("emailVerification.get", (error: Meteor.Error, result: any) => {
      if (!error) {
        this.verifcationEmail = result;
        this.email = "";
      }
    });
  }

  @action deleteVerificationEmail() {
    Meteor.call("emailVerification.del", (error: Meteor.Error, result: any) => {
      if (!error) {
        console.log(result);
        this.email = "";
        this.verifcationEmail = "";
      }
    });
  }

  @action
  saveAge() {
    try {
      callWithPromise("users.setProfileValue", {
        name: "age",
        value: this.ageSliderValue,
      });
    } catch (err) {
      console.log(err);
    }
  }

  @action
  setAboutMe(value: string) {
    this.about = value;
  }

  @action
  setLanguages(value: string[]) {
    this.languages = value;
  }

  @action
  clearErrors() {
    this.emailError = undefined;
    this.usernameError = undefined;
    this.warning = "";
    this.message = "";
  }
}

export const profileStore = new ProfileStore();
