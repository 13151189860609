import { toastStore } from "./toasts-store";

class ClipboardStore{
  copy(text: string){
    if(navigator.clipboard){
      navigator.clipboard.writeText(text);
      toastStore.showToast({message: "Copied"})
    }
  }
} 

export const clipboardStore = new ClipboardStore();