import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Dots = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Dots}>
    {({ color }) => (
      <>
        <circle cx="10" cy="16" r="2.5" fill="#fff" />
        <circle cx="16" cy="16" r="2.5" fill="#fff" />
        <circle cx="22" cy="16" r="2.5" fill="#fff" />
      </>
    )}
  </CreateSvgIcon>
);
