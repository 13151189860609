import React from "react";
import Media from "react-media";
import { LoginDesktop } from "./login-desktop";

export const Login = () => {
  return (
    <Media query={`(max-width: 768px)`}>
      {matches => (matches ? null : <LoginDesktop />)}
    </Media>
  );
};
