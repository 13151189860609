import { makeStyles } from "hooks/makeStyles";
import React from "react";

interface ModalLayoutContainerProps {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  modalLayoutContainer: {
    width: 420,
    padding: theme.spacing(6.25),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

const ModalLayoutContainer = ({ children }: ModalLayoutContainerProps) => {
  const css = useStyles();

  return <div className={css.modalLayoutContainer}>{children}</div>;
};

export default ModalLayoutContainer;
