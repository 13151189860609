export const capitalize = (str: string) =>
  str && str[0].toUpperCase() + str.slice(1);

export const clearMessage = (message: string) => {
  const reg = /([0-9]+[|])/g;
  if (reg.test(message)) {
    const splitted = message.split("|");
    const cleared = splitted.length > 1 ? splitted[1] : splitted[0];
    return cleared;
  } else if (typeof message === "string" && message.startsWith("|")) {
    return message.replace(/\|/g, "");
  }
  return message;
};

export const validateBase64 = (str: string) =>
  !!/^(?:[A-Za-z0-9+\/]{2}[A-Za-z0-9+\/]{2})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$/.test(
    str
  );

export const daysTranslatedLabel = (lang: string, value: number) => {
  if (lang === "en") {
    return value > 1 ? "days" : "day";
  } else if (lang === "ru") {
    const diff = value % 10;
    switch (true) {
      case value >= 5 && value <= 20:
        return "дней";
      case diff === 1:
        return "день";
      case diff >= 2 && diff <= 4:
        return "дня";
      default:
        return "дней";
    }
  }
};

export const hoursTranslatedLabel = (lang: string, value: number) => {
  if (lang === "en") {
    return value > 1 ? "hours" : "hour";
  } else if (lang === "ru") {
    const diff = value % 10;
    switch (true) {
      case value >= 5 && value <= 20:
        return "часов";
      case diff === 1:
        return "час";
      case diff >= 2 && diff <= 4:
        return "часа";
      default:
        return "часов";
    }
  }
};
