import { makeStyles } from "hooks/makeStyles";
import { Notification, NotificationType } from "lib";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Button, Typography } from "ui/shared";
import moment from "moment";
import clsx from "clsx";
import { Meteor } from "meteor/meteor";
import { useHistory } from "react-router";
import { chatsStore } from "global-stores/chats-store";

const useStyles = makeStyles((theme) => ({
  notificationContainer: {
    display: "flex",
    padding: 10,
    outline: "none",
    background: "none",
    border: 0,
    textAlign: "left",
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.colors.background.paper.lighter,
    },
  },
  notificationTextBlock: {
    paddingLeft: 10,
  },
  padded: {
    marginBottom: 3,
  },
  full: {
    width: "100%",
    height: 120,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  disabled: {
    cursor: "default",
    pointerEvents: "none",
  },
  clearAllContainer: {
    position: "sticky",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.colors.background.paper.lighter,
    padding: `${theme.spacing(2)} 0`,
  },
  clearButton: {
    fontSize: theme.sizes.typography.caption,
    padding: theme.spacing(2),
  },
}));

interface NotificationsListProps {
  loading?: boolean;
  notifications: Notification[];
  closeOverlay: (force?: boolean | undefined) => void;
}

const NotificationsList = observer(
  ({ loading, notifications, closeOverlay }: NotificationsListProps) => {
    const { t } = useTranslation("all");
    const css = useStyles();

    const handleClearAll = useCallback(() => {
      Meteor.call("notifications.read", {}, (err: Meteor.Error) => {
        if (err) {
          console.log(err);
        }
        closeOverlay();
      });
    }, [closeOverlay]);

    return notifications.length ? (
      <div>
        {notifications.map((notification) => (
          <NotificationItem
            key={notification._id}
            notification={notification}
            closeOverlay={closeOverlay}
          />
        ))}
        <div className={css.clearAllContainer}>
          <Button onClick={handleClearAll} className={css.clearButton}>
            {t("all:notification_clear")}
          </Button>
        </div>
      </div>
    ) : (
      <div className={clsx(css.center, css.full)}>
        <Typography>{t("all:profile_no_notifications")}</Typography>
      </div>
    );
  }
);

interface NotificationItemProps {
  notification: Notification;
  closeOverlay: (force?: boolean | undefined) => void;
}

const NotificationItem = ({
  notification,
  closeOverlay,
}: NotificationItemProps) => {
  const css = useStyles();
  const { t } = useTranslation("all");
  const history = useHistory();

  const needTitleLocalization = notification.type === NotificationType.Search;

  const needBodyLocalization = [
    NotificationType.Like,
    NotificationType.New,
    NotificationType.Deleled,
    NotificationType.Message,
    NotificationType.Search,
  ].some((type) => type === notification.type);

  /**************************************************/

  const handleClickNotification = useCallback(() => {
    switch (notification.type) {
      case NotificationType.Like:
        history.push("/karma");
        break;
      case NotificationType.Search:
        history.push("/search");
        break;
      case NotificationType.New:
      case NotificationType.Message:
        const path = `/chats/${notification.friendId}`;
        if (history.location.pathname !== path) {
          chatsStore.selectedChatStore = undefined;
          chatsStore.selectedFriend = undefined;
        }
        history.push(path);
        break;
    }
    closeOverlay(true);
    Meteor.call(
      "notifications.read",
      {
        id: notification._id,
      },
      (err: Meteor.Error) => {
        if (err) {
          console.log(err);
        }
      }
    );
  }, [notification, closeOverlay, history]);

  /**************************************************/

  return (
    <button
      className={clsx(css.notificationContainer, {
        [css.disabled]: notification.type === NotificationType.Deleled,
      })}
      onClick={handleClickNotification}
    >
      {notification.avatarUrl ? (
        <Avatar.Minified img={notification.avatarUrl} />
      ) : null}
      <div className={css.notificationTextBlock}>
        <Typography size="body(14px)" className={css.padded}>
          {needTitleLocalization
            ? t(`all:${notification.body}`)
            : notification.title}
        </Typography>
        <Typography size="body(14px)" className={css.padded}>
          {needBodyLocalization
            ? t(`all:${notification.body}`)
            : notification.body}
        </Typography>
        <Typography color="secondary" size="caption(12px)">
          {moment(notification.createdAt).fromNow()}
        </Typography>
      </div>
    </button>
  );
};

export default NotificationsList;
