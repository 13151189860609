import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const OK = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.OK}>
    {({ color }) => (
      <>
        <circle cx="16.8" cy="16.8" r="12.36" fill="#cfab00" />
        <path
          d="M-.73-1h34V33h-34Z"
          transform="translate(0.73 1)"
          fill="none"
        />
        <g>
          <path
            d="M12.8,11.73a5,5,0,0,1,1.67.31,3.5,3.5,0,0,1,1.36.92,4.36,4.36,0,0,1,.92,1.36A3.58,3.58,0,0,1,17.06,16a4.29,4.29,0,0,1-.55,2.16A3.74,3.74,0,0,1,15,19.7a4.16,4.16,0,0,1-2.16.55,4.54,4.54,0,0,1-1.67-.31A3.42,3.42,0,0,1,9.77,19a4.48,4.48,0,0,1-.92-1.36A3.85,3.85,0,0,1,8.54,16a4.54,4.54,0,0,1,.31-1.67A4.36,4.36,0,0,1,9.77,13,5,5,0,0,1,11.13,12,3.58,3.58,0,0,1,12.8,11.73Zm-.12,1.48A2.73,2.73,0,0,0,10,16a2.37,2.37,0,0,0,.25,1.11,4.91,4.91,0,0,0,.55.87,3,3,0,0,0,.87.61,2,2,0,0,0,1.11.25,2.38,2.38,0,0,0,1.91-.8,2.87,2.87,0,0,0,.81-2,2.56,2.56,0,0,0-.81-2A2.39,2.39,0,0,0,12.68,13.21Z"
            transform="translate(0.73 1)"
            fill="#fff"
          />
          <path
            d="M18.17,19.94V12a.15.15,0,0,1,.13-.13h1.23a.15.15,0,0,1,.13.13v3a.13.13,0,0,0,.14.13.12.12,0,0,0,.1-.07l2.23-3.15c0-.06.06-.06.12-.06h1.49c.12,0,.18.12.12.25L21,15.8A.14.14,0,0,0,21,16l0,0,2.84,3.89a.18.18,0,0,1-.07.23l-.05,0H22.25c-.06,0-.06,0-.12-.06L19.9,16.85a.13.13,0,0,0-.18,0,.11.11,0,0,0-.06.11v3a.15.15,0,0,1-.13.13H18.3A.15.15,0,0,1,18.17,19.94Z"
            transform="translate(0.73 1)"
            fill="#fff"
          />
        </g>
      </>
    )}
  </CreateSvgIcon>
);
