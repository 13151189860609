import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Smile = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Smile}>
    {({ color }) => (
      <>
        <g transform="translate(-10.653 -12.53)" fill={color}>
          <ellipse
            cx="2.006"
            cy="2.006"
            rx="2.006"
            ry="2.006"
            transform="translate(19.71 21.586)"
          />
          <ellipse
            cx="2.006"
            cy="2.006"
            rx="2.006"
            ry="2.006"
            transform="translate(29.585 21.586)"
          />
          <path
            d="M48.3,48.554a25.247,25.247,0,0,1-17.872,0,1.639,1.639,0,0,0-2.119,2.013c1.173,3.73,4.083,8.576,11.062,8.576s9.89-4.846,11.083-8.569a1.639,1.639,0,0,0-2.155-2.02Zm.283,3.278c-2.077,1.54-5.425,2.374-9.211,2.374s-7.142-.834-9.219-2.366c-.572-.424-.057-1.554.629-1.328a29.055,29.055,0,0,0,8.59,1.151,29.054,29.054,0,0,0,8.59-1.123c.685-.29,1.2.876.593,1.3Z"
            transform="translate(-12.664 -19.395)"
          />
          <path
            d="M37.27,25.06a16,16,0,1,0,16,16,16,16,0,0,0-16-16Zm0,30.46A14.46,14.46,0,1,1,51.73,41.06,14.46,14.46,0,0,1,37.27,55.52Z"
            transform="translate(-10.616 -12.53)"
          />
        </g>
      </>
    )}
  </CreateSvgIcon>
);
