const nacl = require("tweetnacl");
const util = require("tweetnacl-util");
const sha256 = require("js-sha256");

export class Encryption {
  _theirPublicKey: Uint8Array | null;
  _myPublicKey: Uint8Array;
  _mySecretKey: Uint8Array;
  _nonce: Uint8Array;

  constructor(
    publicKeyBase64?: string,
    secretKeyBase64?: string,
    theirPublicKeyBase64?: string
  ) {
    this._theirPublicKey = null;
    if (theirPublicKeyBase64)
      this._theirPublicKey = util.decodeBase64(theirPublicKeyBase64);

    if (publicKeyBase64 && secretKeyBase64) {
      this._myPublicKey = util.decodeBase64(publicKeyBase64);
      this._mySecretKey = util.decodeBase64(secretKeyBase64);
    } else {
      let keys = nacl.box.keyPair();
      this._myPublicKey = keys.publicKey;
      this._mySecretKey = keys.secretKey;
    }

    //odigo.chat-it's amazing!
    this._nonce = new Uint8Array([
      111, 100, 105, 103, 111, 46, 99, 104, 97, 116, 45, 105, 116, 39, 115, 32,
      97, 109, 97, 122, 105, 110, 103, 33,
    ]);
  }

  get mySecretKeyBase64() {
    return util.encodeBase64(this._mySecretKey);
  }

  get myPublicKeyBase64() {
    return util.encodeBase64(this._myPublicKey);
  }

  encryptImage(image: ArrayBuffer) {
    if (!this._theirPublicKey) return false;

    return nacl.box(
      image,
      this._nonce,
      this._theirPublicKey,
      this._mySecretKey
    );
  }

  decryptImage(image: ArrayBuffer) {
    if (!this._theirPublicKey) return false;

    return nacl.box.open(
      image,
      this._nonce,
      this._theirPublicKey,
      this._mySecretKey
    );
  }

  decryptImageForMe(image: ArrayBuffer) {
    if (!this._mySecretKey) return false;
    const sha = sha256.arrayBuffer(this._mySecretKey);

    return (
      nacl.secretbox.open(image, this._nonce, new Uint8Array(sha)) ||
      nacl.secretbox.open(image, this._nonce, this._mySecretKey)
    );
  }

  encryptImageForMe(image: ArrayBuffer) {
    const sha = sha256.arrayBuffer(this._mySecretKey);

    return nacl.secretbox(image, this._nonce, new Uint8Array(sha));
  }

  encryptMessage(messageText: string) {
    if (!this._theirPublicKey) return false;

    return util.encodeBase64(
      nacl.box(
        util.decodeUTF8(messageText),
        this._nonce,
        this._theirPublicKey,
        this._mySecretKey
      )
    );
  }

  encryptMessageForMe(messageText: string) {
    const sha = sha256.arrayBuffer(this._mySecretKey);

    return util.encodeBase64(
      nacl.secretbox(
        util.decodeUTF8(messageText),
        this._nonce,
        new Uint8Array(sha)
      )
    );
  }

  decryptMessage(messageBase64: string) {
    if (!this._theirPublicKey) return false;

    let msgArr = nacl.box.open(
      util.decodeBase64(messageBase64),
      this._nonce,
      this._theirPublicKey,
      this._mySecretKey
    );
    return msgArr ? util.encodeUTF8(msgArr) : false;
  }

  decryptMessageForMe(messageBase64: string) {
    const sha = sha256.arrayBuffer(this._mySecretKey);

    let msgArr =
      nacl.secretbox.open(
        util.decodeBase64(messageBase64),
        this._nonce,
        new Uint8Array(sha)
      ) ||
      nacl.secretbox.open(
        util.decodeBase64(messageBase64),
        this._nonce,
        this._mySecretKey
      );
    return msgArr ? util.encodeUTF8(msgArr) : false;
  }
}
