import React from "react";
import { observer } from "mobx-react";
import { globalUIStore } from "../../../global-stores/global-ui-store";
import { DesktopLayout } from "./desktop-layout/desktop-layout";
import { MobileLayout } from "./mobile-layout/mobile-layout";

export const IsMobileContext = React.createContext(false);

export const Layout = observer(() => {
  return globalUIStore.mobileDevice ? (
    <IsMobileContext.Provider value={true}>
      <MobileLayout />
    </IsMobileContext.Provider>
  ) : (
    <IsMobileContext.Provider value={false}>
      <DesktopLayout />
    </IsMobileContext.Provider>
  );
});
