import React from "react";
import { makeStyles } from "hooks/makeStyles";
import { Typography, Icons } from "ui/shared";
import { fade } from "utils";

export interface InterestItemProps {
  item: string;
  label: string;
  checked?: boolean;
  onClick: (e: React.MouseEvent, item: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 40,
    padding: `0 ${theme.spacing(6)}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: fade(theme.colors.background.paper.lighter, 0.6)
    }
  }
}));

export const InterestItem = React.memo((props: InterestItemProps) => {
  const { item, label, checked, onClick, ...passthrough } = props;
  const css = useStyles();

  return (
    <div
      className={css.root}
      onClick={(e) => onClick(e, item)}
      {...passthrough}
    >
      <Typography>{label}</Typography>
      {checked ? <Icons.Checkmark /> : null}
    </div>
  );
});
