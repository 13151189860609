import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  useCallback,
  useRef,
} from "react";
import clsx from "clsx";
import { makeStyles } from "hooks/makeStyles";
import { lighten, fade } from "utils/fade";
import { Theme } from "lib/theme";
import { KEY_CODES } from "lib";
import useForkRef from "hooks/useForkRef";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  fullWidth?: boolean;
  color?: string;
  disabledColor?: string;
  leftIcon?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  button: {
    width: "auto",
    padding: theme.spacing(3),
    borderRadius: 999,
    backgroundColor: (props: ButtonProps) => props && getColor(theme, props),
    border: "none",
    fontFamily: theme.fonts.light,
    fontSize: theme.sizes.typography.body,
    textTransform: "uppercase",
    color: theme.colors.textPrimary,
    cursor: "pointer",
    textDecoration: "none",
    transition: "background .2s",
    "&$disabled": {
      pointerEvents: "none",
      cursor: "default",
      color: fade(theme.colors.textPrimary, 0.4),
    },
    "&:hover, &:focus": {
      outline: "none",
      backgroundColor: (props: ButtonProps) =>
        props && lighten(getColor(theme, props), 0.1),
    },
    outline: "none",
  },
  secondary: {
    border: `1px solid ${theme.colors.textPrimary}`,
    textTransform: "capitalize",
  },
  fullWidth: {
    width: "100%",
  },
  disabled: {},
  link: {
    color: theme.colors.primary,
    cursor: "pointer",
    fontSize: theme.sizes.typography.body,
    outline: "none",
    "&:hover > p, &:focus > p": {
      color: theme.colors.yellow.main,
    },
  },
  withIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  leftIconContainer: {
    marginRight: theme.spacing(2),
  },
}));

function getColor(theme: Theme, props: ButtonProps) {
  if (!theme || !props) return;
  const { primary, tertiary, color, disabled, disabledColor } = props;

  if (disabled) return disabledColor ?? fade(theme.colors.purple.main, 0.1);
  if (color) return color;
  if (primary) return theme.colors.primary;
  if (tertiary) return theme.colors.purple.main;
  return theme.colors.background.paper.topbar;
}

export const Button = React.forwardRef(
  (props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const {
      primary,
      secondary,
      tertiary,
      color,
      fullWidth,
      children,
      className,
      disabledColor,
      leftIcon,
      ...passthrough
    } = props;

    const css = useStyles(props);

    const cx = clsx(
      css.button,
      {
        [css.fullWidth]: fullWidth,
        [css.secondary]: secondary,
        [css.disabled]: passthrough.disabled,
        [css.withIcon]: !!leftIcon,
        [css.spacedContainer]: !!leftIcon,
      },
      className
    );

    return (
      <button ref={ref} className={cx} {...passthrough}>
        {leftIcon ? (
          <span className={css.leftIconContainer}>{leftIcon}</span>
        ) : null}
        {children}
      </button>
    );
  }
);

interface AnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  primary?: boolean;
  secondary?: boolean;
  fullWidth?: boolean;
  asLink?: boolean;
}

export const Anchor = React.forwardRef(
  (props: AnchorProps, ref: React.Ref<HTMLAnchorElement>) => {
    const css = useStyles(props);
    const internalRef = useRef<HTMLAnchorElement>(null);
    const handleRef = useForkRef(internalRef, ref);

    const {
      primary,
      secondary,
      fullWidth,
      children,
      asLink,
      className,
      ...passthrough
    } = props;

    const cx = clsx(
      {
        [css.button]: !asLink,
        [css.link]: asLink,
        [css.fullWidth]: fullWidth,
        [css.primary]: primary,
        [css.secondary]: secondary,
      },
      className
    );

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent) => {
        let flag = false;

        switch (e.keyCode) {
          case KEY_CODES.RETURN:
          case KEY_CODES.SPACE:
            const ref = internalRef as React.RefObject<HTMLAnchorElement>;
            if (ref.current) {
              ref.current.click();
            }
            flag = true;
            break;
        }

        if (flag) {
          e.preventDefault();
          e.stopPropagation();
        }
      },
      [internalRef]
    );

    return (
      <a
        ref={handleRef}
        role={passthrough.onClick ? "button" : undefined}
        className={cx}
        onKeyDown={handleKeyDown}
        {...passthrough}
      >
        {children}
      </a>
    );
  }
);
