import React from "react";
import { makeStyles } from "hooks/makeStyles";
import { AnimatedPage } from "ui/shared";
import { observer } from "mobx-react";
import { globalUIStore } from "global-stores/global-ui-store";
import { Profile } from "./profile";
import ProfileSkeleton from "./profile-skeleton";

export interface ProfileProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
}));

const ProfilePage = observer((props: ProfileProps) => {
  const css = useStyles();

  return (
    <AnimatedPage animation="vertical" containerClassName={css.root}>
      {globalUIStore.isAnimating ? <ProfileSkeleton /> : <Profile />}
    </AnimatedPage>
  );
});

export default ProfilePage;
