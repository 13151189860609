export enum OverlayPosition {
  TopLeft = "TopLeft",
  TopCenter = "TopCenter",
  TopRight = "TopRight",
  BottomRight = "BottomRight",
  BottomCenter = "BottomCenter",
  BottomLeft = "BottomLeft",
}

export function calculateOverlayPosition(
  triggerRect: ClientRect,
  overlayWidth: number,
  overlayHeight: number,
  preferRight = false,
  preferTop = false,
  preferCenter = false,
  container = document.documentElement,
  margin = 20
) {
  const containerRect = container.getBoundingClientRect();

  /** Find Vertical position */
  const topFit = checkTopFit(containerRect, triggerRect, overlayHeight, margin);
  const bottomFit = checkBottomFit(
    containerRect,
    triggerRect,
    overlayHeight,
    margin
  );
  const bestVertical = Math.max(topFit, bottomFit);

  const vert =
    topFit >= 0 && bottomFit >= 0
      ? preferTop
        ? "Top"
        : "Bottom"
      : bestVertical === topFit
      ? "Top"
      : "Bottom";

  if (preferCenter) {
    const centerFit = checkHorizontalCenterFit(
      containerRect,
      triggerRect,
      overlayWidth,
      margin
    );
    if (centerFit > 0) {
      return (OverlayPosition as any)[vert + "Center"];
    }
  }

  /** Find Horizontal position */
  const leftFit = checkLeftFit(
    containerRect,
    triggerRect,
    overlayWidth,
    margin
  );
  const rightFit = checkRightFit(
    containerRect,
    triggerRect,
    overlayWidth,
    margin
  );
  const bestHorizontal = Math.max(leftFit, rightFit);

  const horz =
    leftFit >= 0 && rightFit >= 0
      ? preferRight
        ? "Right"
        : "Left"
      : bestHorizontal === leftFit
      ? "Left"
      : "Right";

  return (OverlayPosition as any)[vert + horz];
}

function checkTopFit(
  containerRect: ClientRect,
  triggerRect: ClientRect,
  overlayHeight: number,
  margin: number
) {
  return triggerRect.top - containerRect.top - overlayHeight - margin;
}

function checkRightFit(
  containerRect: ClientRect,
  triggerRect: ClientRect,
  overlayWidth: number,
  margin: number
) {
  return containerRect.right - triggerRect.right - overlayWidth - margin;
}

function checkLeftFit(
  containerRect: ClientRect,
  triggerRect: ClientRect,
  overlayWidth: number,
  margin: number
) {
  return triggerRect.left - containerRect.left - overlayWidth - margin;
}

function checkBottomFit(
  containerRect: ClientRect,
  triggerRect: ClientRect,
  overlayHeight: number,
  margin: number
) {
  return containerRect.bottom - triggerRect.bottom - overlayHeight - margin;
}

function checkHorizontalCenterFit(
  containerRect: ClientRect,
  triggerRect: ClientRect,
  overlayWidth: number,
  margin: number
) {
  return Math.min(
    checkLeftFit(
      containerRect,
      triggerRect,
      (overlayWidth - triggerRect.width) / 2,
      margin
    ),
    checkRightFit(
      containerRect,
      triggerRect,
      (overlayWidth - triggerRect.width) / 2,
      margin
    )
  );
}
