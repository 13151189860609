import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Female = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Female}>
    {({ color }) => (
      <path
        d="M22.2563 11.1286C22.2563 7.816 19.6064 5 16.1282 5C12.8156 5 10 7.65037 10 11.1286C10 14.1099 12.3187 16.7601 15.3 17.0913V21.7286H12.3186V23.2191H15.3V27.029H16.7905V23.2191H19.7719V21.7286H16.7905V17.0913C19.9374 16.7601 22.2563 14.2755 22.2563 11.1286V11.1286ZM11.4905 11.1286C11.4905 8.64414 13.4781 6.4913 16.1282 6.4913C18.6126 6.4913 20.7658 8.47852 20.7658 11.1286C20.7658 13.613 18.7782 15.7658 16.1282 15.7658C13.6437 15.7658 11.4905 13.613 11.4905 11.1286Z"
        fill={color}
      />
    )}
  </CreateSvgIcon>
);
