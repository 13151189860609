import React from "react";

interface BigBubbleProps {
  className?: string;
}

const BigBubble = ({ className }: BigBubbleProps) => {
  return (
    <svg
      width="389"
      height="121"
      viewBox="0 0 389 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity="0.201"
        d="M0.900024 120.8H388.2C356.5 112.9 348.9 98 341.7 84.5C332.1 66.4 306.8 41.6 264.3 42C172.9 42.7 184.6 3.30003 120.7 0.800034C94.7 -0.199966 78.8 18.6001 83.5 45.4001C91.4 90.9001 2.10002 57.9 0.900024 120.8Z"
        fill="#FDF9DD"
      />
    </svg>
  );
};

export default BigBubble;
