import React from "react";
import moment from "moment";
import { makeStyles } from "hooks/makeStyles";
import { ServiceMessage } from "./service-message";
import * as ChatBubble from "./chat-bubble";
import { Icons, Typography } from "ui/shared";
import { observer } from "mobx-react";
import { Maybe, Message } from "lib";
import { useRef } from "react";
import { useLayoutEffect } from "react";
import { ChatStore } from "./stores/chat-store";
import { useEffect } from "react";
import { chatsStore } from "global-stores/chats-store";

interface MessagesListProps {
  isFriend?: boolean;
  typing?: boolean;
  store: ChatStore;
  messages: Message[];
  friendId: string;
  scrollToBottom: (smooth?: boolean) => void;
  initializedCallback: () => void;
  visible?: boolean;
}

const useStyles = makeStyles((theme) => ({
  messagesContainer: {
    listStyle: "none",
    margin: 0,
    padding: 0,
    paddingBottom: theme.spacing(4),
  },
  messageRow: {
    display: "flex",
    width: "100%",
    padding: `${theme.spacing(5)} ${theme.spacing(3)} 0`,
    boxSizing: "border-box",
  },
  loader: {
    width: "100%",
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.background.paper.lighter,
  },
  dayGroupLabelRow: {
    display: "flex",
    width: "100%",
    padding: `${theme.spacing(3)} ${theme.spacing(3)} 0`,
    boxSizing: "border-box",
    justifyContent: "center",
    position: "sticky",
    top: 0,
    zIndex: 3,
  },
  dayGroupLabel: {
    minWidth: 100,
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    backgroundColor: theme.colors.background.paper.darker,
    borderRadius: 999,
  },
}));

export const MessagesList = observer((props: MessagesListProps) => {
  const {
    friendId,
    typing,
    isFriend,
    messages,
    store,
    scrollToBottom,
    initializedCallback,
    visible,
  } = props;

  const css = useStyles();

  let previousDate: Date | undefined;

  const ref = useRef<HTMLUListElement>(null);
  const observer: React.MutableRefObject<Maybe<ResizeObserver>> =
    useRef<ResizeObserver>();

  useLayoutEffect(() => {
    if (ref.current && messages.length) {
      observer.current = new ResizeObserver((entries) => {
        if (!store.loading) {
          if (!store.isReady) {
            // Initial scroll to bottom when open chat
            scrollToBottom();
            initializedCallback();
          }
        }
      });
      observer.current.observe(ref.current);
    }
  }, [messages.length]);

  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  const translationSettings =
    chatsStore.selectedChatStore &&
    chatsStore.selectedChatStore.translationSettings;

  return (
    <ul
      className={css.messagesContainer}
      ref={ref}
      style={{
        opacity: store.isReady ? 1 : 0,
        visibility: visible ? "visible" : "hidden",
      }}
    >
      {store.loading ? (
        <li className={css.loader}>
          <Icons.DotsLoader
            width={32}
            height={32}
            viewBox="0 0 120 30"
            fill="#fff"
          />
        </li>
      ) : null}
      {messages.map((message, index) => {
        const prev = previousDate
          ? moment(previousDate).startOf("day")
          : undefined;
        const next = moment(message.createdAt).startOf("day");

        previousDate = message.createdAt;

        const needDayGroupLabel = prev ? prev.diff(next, "days") : 1;

        return needDayGroupLabel ? (
          [
            <li
              key={message.createdAt.toISOString()}
              className={css.dayGroupLabelRow}
            >
              <span className={css.dayGroupLabel}>
                <Typography size="caption(12px)" align="center">
                  {moment(message.createdAt).format("DD MMMM")}
                </Typography>
              </span>
            </li>,
            <li
              key={message._id}
              className={css.messageRow}
              style={{
                justifyContent: message.service
                  ? "center"
                  : message.my
                  ? "flex-start"
                  : "flex-end",
              }}
            >
              {message.service && message.messageText ? (
                <ServiceMessage
                  message={message.messageText}
                  isFriend={!!isFriend}
                  friendId={friendId}
                />
              ) : message.boxSize ? (
                <ChatBubble.ImageBox message={message} />
              ) : (
                <ChatBubble.Default
                  message={message}
                  isFriend={isFriend}
                  translationSettings={translationSettings}
                />
              )}
            </li>,
          ]
        ) : (
          <li
            key={message._id}
            className={css.messageRow}
            style={{
              justifyContent: message.service
                ? "center"
                : message.my
                ? "flex-start"
                : "flex-end",
            }}
          >
            {message.service && message.messageText ? (
              <ServiceMessage
                message={message.messageText}
                isFriend={!!isFriend}
                friendId={friendId}
              />
            ) : message.boxSize ? (
              <ChatBubble.ImageBox message={message} />
            ) : (
              <ChatBubble.Default
                message={message}
                isFriend={isFriend}
                translationSettings={translationSettings}
              />
            )}
          </li>
        );
      })}
      {typing ? (
        <li
          className={css.messageRow}
          style={{
            justifyContent: "flex-end",
          }}
        >
          <ChatBubble.Typing />
        </li>
      ) : null}
    </ul>
  );
});
