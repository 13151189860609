import { inviteStore } from "global-stores/invite-store";
import { modalManager } from "global-stores/modal-manager";
import { makeStyles } from "hooks/makeStyles";
import { MODALS } from "lib/constants";
import { observer } from "mobx-react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import ModalLayoutContainer from "ui/components/modal-layout-container/modal-layout-container";
import {
  Box,
  Button,
  Checkbox,
  Input,
  LayoutMessageBubble,
  Typography,
} from "ui/shared";
import { LoginFlowStore } from "./stores/login-flow-store";

const useStyles = makeStyles((theme) => ({
  title: {
    lineHeight: 1,
    marginBottom: theme.spacing(2),
  },
  inputContainer: {
    position: "relative",
    width: "100%",
    marginBottom: theme.spacing(4),
  },
  bubble: {
    position: "absolute",
    left: -133,
    top: -37,
    color: theme.colors.common.black,
  },
  centered: {
    display: "flex",
    justifyContent: "center",
  },
  modal: {
    background: theme.colors.background.paper.lighter,
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  import: {
    backgroundColor: "transparent",
    cursor: "pointer",
    textDecoration: "underline",
    textTransform: "none",
    color: theme.colors.textPrimary,
    "&:hover, &:focus": {
      backgroundColor: "transparent",
    },
  },
  hidden: {
    width: 0,
    height: 0,
    visibility: "hidden",
  },
  importAccountModalContainer: {
    display: "flex",
    backgroundColor: theme.colors.background.paper.topbar,
    borderRadius: theme.sizes.borderRadius.medium,
    padding: `${theme.spacing(5)}`,
    "&:focus": {
      outline: "none",
    },
  },
  defaultPanel: {
    maxWidth: 268,
    "&:focus": {
      outline: 0,
    },
  },
  checkbox: {
    "& > p": {
      color: theme.colors.primary,
    },
  },
  linkWrapper: {
    "& > a": {
      color: theme.colors.textPrimary,
    },
  },
}));

export const LoginLayout = observer(() => {
  const { t } = useTranslation("all");
  const css = useStyles();

  const [loginStore] = useState(new LoginFlowStore());

  /**************************************************/

  const handleUsernameInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      loginStore.setUsername(e.target.value);
    },
    [loginStore]
  );

  const handleOccupyUsername = useCallback(
    (e: React.MouseEvent) => {
      loginStore.occupyUsername(e);

      if (inviteStore.invited && inviteStore.inviteCode) {
        inviteStore.activateInviteCode();
      }
    },
    [loginStore]
  );

  /**************************************************/

  return (
    <ModalLayoutContainer>
      <Redirect to="/" />
      <Box mt={6}>
        <Typography size="h1(33px)" align="center" className={css.title}>
          {t("all:reg_1_text")}
        </Typography>
      </Box>
      <div className={css.inputContainer}>
        <LayoutMessageBubble
          className={css.bubble}
          data-cy="login.messages.login"
        >
          {loginStore.usernameError || loginStore.message || loginStore.warning}
        </LayoutMessageBubble>
        <Input
          value={loginStore.username}
          onChange={handleUsernameInput}
          mt={2}
          placeholder={t("all:main_placeholder_login")}
          error={!!loginStore.usernameError}
          succeed={!!loginStore.message}
          data-cy="login.input"
        />
      </div>
      <Checkbox
        className={css.checkbox}
        checked={inviteStore.invited}
        onChange={() => {
          modalManager.open(MODALS.LOGIN_INVITE_CODE);
        }}
        label={t("all:reg_1_invite_checkbox")}
        data-cy="login.actions.invited"
      />
      <Box mt={6}>
        <Button
          fullWidth
          disabled={!!loginStore.usernameError || !loginStore.message}
          primary
          onClick={handleOccupyUsername}
          data-cy="login.actions.continue"
        >
          {t("all:main_button_reg")}
        </Button>
      </Box>
      <Box mt={4} mb={6} className={css.centered}>
        <Link to="/import" className={css.import}>
          {t("all:main_button_recovery")}
        </Link>
      </Box>
    </ModalLayoutContainer>
  );
});
