import React from "react";
import clsx from "clsx";
import { Modal, DefaultPanel } from "ui/shared";
import { MODALS } from "lib/constants";
import { TopUserDetails } from "lib";
import { useTranslation } from "react-i18next";
import { TopUserProfileModal } from "./top-user-profile-modal";
import { makeStyles } from "hooks/makeStyles";

interface TopUserProfileProps {
  selectedUser?: TopUserDetails;
  onClose?: () => void;
}

const useStyles = makeStyles((theme) => ({
  profileModal: {
    maxWidth: 345,
  },
}));

const TopUserProfile = (props: TopUserProfileProps) => {
  const { selectedUser, onClose } = props;
  const css = useStyles();
  const { t } = useTranslation();

  return (
    <Modal modalId={MODALS.TOP_USER_PROFILE} onClose={onClose}>
      {(visible, className) => (
        <DefaultPanel
          title={t("all:friend_profile_titile")}
          className={clsx(className, css.profileModal)}
        >
          <TopUserProfileModal
            topUserDetails={selectedUser}
            visible={visible}
          />
        </DefaultPanel>
      )}
    </Modal>
  );
};

export default TopUserProfile;
