import React, { useEffect, useRef } from "react";
import { makeStyles } from "hooks/makeStyles";
import { Typography, Textarea } from "ui/shared";
import { useTranslation } from "react-i18next";
import { capitalize } from "utils";

export interface AboutMeHeaderProps {
  value?: number;
  max?: number;
}

export interface AboutMeProps {
  visible: boolean;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
}

const useStyles = makeStyles((theme) => ({
  aboutMeHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
    backgroundColor: theme.colors.background.paper.lighter,
  },
  headerItem: {
    width: 40,
  },
  textWrapper: {
    padding: `${theme.spacing(5)}`,
  },
  textarea: {
    border: "none",
    padding: 10,
    color: theme.colors.textPrimary,
    backgroundColor: "transparent",
    resize: "none",
    "&:focus": {
      border: "none",
      outline: 0,
    },
  },
}));

export const AboutMeHeader = (props: AboutMeHeaderProps) => {
  const { value = 0, max = 300 } = props;
  const { t } = useTranslation("all");
  const css = useStyles();

  return (
    <div className={css.aboutMeHeader}>
      <Typography
        className={css.headerItem}
        data-cy="profile.aboutMe.header.textLength"
      >
        {value}/{max}
      </Typography>
      <Typography size="subtitle1(18px)" align="center" uppercase>
        {capitalize(t("all:profile_about_title"))}
      </Typography>
      <div className={css.headerItem}></div>
    </div>
  );
};

export const AboutMe = (props: AboutMeProps) => {
  const { visible, value, onChange, placeholder } = props;
  const css = useStyles();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef!.current!.focus();
      textAreaRef!.current!.setSelectionRange(value.length, value.length);
    }
  }, [textAreaRef, value.length, visible]);

  return (
    <div className={css.textWrapper}>
      <Textarea
        ref={textAreaRef}
        value={value}
        onChange={onChange}
        minRows={5}
        maxRows={10}
        placeholder={placeholder}
        className={css.textarea}
        data-cy="profile.aboutMe.textarea"
      />
    </div>
  );
};
