import React, { useCallback } from "react";
import { Button } from "../button/button";
import { makeStyles } from "hooks/makeStyles";
import clsx from "clsx";

interface SwitchProps {
  checked: boolean;
  onChange?: (value: boolean) => void;
  defaultBgColor?: string;
  activeBgColor?: string;
}

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  switch: {
    position: "relative",
    padding: theme.spacing(1),
    width: 48,
    height: 32,
    backgroundColor: ({ defaultBgColor }: SwitchProps) =>
      defaultBgColor || theme.colors.background.paper.topbar,
    "&:hover, &:active": {
      backgroundColor: ({ defaultBgColor }: SwitchProps) =>
        defaultBgColor || theme.colors.background.paper.topbar,
    },
    "&:focus": {
      backgroundColor: ({
        checked,
        defaultBgColor,
        activeBgColor,
      }: SwitchProps) =>
        checked
          ? activeBgColor || theme.colors.primary
          : defaultBgColor || theme.colors.background.paper.topbar,
    },
    "&:before": {
      content: '""',
      position: "absolute",
      left: 4,
      top: 4,
      width: 24,
      height: 24,
      borderRadius: "50%",
      backgroundColor: theme.colors.common.white,
      transition: "transform .2s, backgroundColor .2s",
    },
  },
  checked: {
    backgroundColor: ({ activeBgColor }: SwitchProps) =>
      activeBgColor || theme.colors.primary,
    "&:hover, &:active": {
      backgroundColor: ({ activeBgColor }: SwitchProps) =>
        activeBgColor || theme.colors.primary,
    },
    "&:before": {
      transform: "translateX(16px)",
    },
  },
}));

export const Switch = (props: SwitchProps) => {
  const css = useStyles(props);

  /**************************************************/

  const handleChange = useCallback(() => {
    if (props.onChange) props.onChange(!props.checked);
  }, [props.onChange, props.checked]);

  /**************************************************/

  return (
    <span>
      <Button
        role="switch"
        className={clsx(css.switch, {
          [css.checked]: props.checked,
        })}
        onClick={handleChange}
        aria-checked={props.checked}
      ></Button>
    </span>
  );
};
