import { makeStyles } from "hooks/makeStyles";
import React, { useCallback, useRef, useState } from "react";
import { Button } from "../button/button";
import AvatarEditor from "react-avatar-editor";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  relative: {
    position: "relative",
  },
  zoom: {
    position: "absolute",
    bottom: "49px",
    left: "calc(50% - 88px)",
  },
  zoomBtn: { width: 40, height: 40 },
  zoomResetBtn: { width: 80, margin: `0 ${theme.spacing(2)}` },
  rotate: {
    position: "absolute",
    top: "7px",
    left: "calc(50% - 90px)",
  },
  submit: {
    width: "60%",
    margin: `${theme.spacing(4)} 0`,
  },
}));

interface AvatarResizerProps {
  image: string | File;
  onSave: (file: Blob) => void;
}

export const AvatarResizer = (props: AvatarResizerProps) => {
  const css = useStyles();

  const editorRef = useRef<any>(null);
  const [rotate, setRotate] = useState(0);
  const [zoom, setZoom] = useState(1.2);

  /**************************************************/

  const handleZoomIn = useCallback(() => {
    if (zoom >= 5) {
      setZoom(5);
    } else {
      setZoom(zoom + 0.1);
    }
  }, [zoom, setZoom]);

  const handleZoomOut = useCallback(() => {
    if (zoom <= 0.2) {
      setZoom(0.1);
    } else {
      setZoom(zoom - 0.1);
    }
  }, [zoom, setZoom]);

  const handleResetZoom = useCallback(() => {
    setZoom(1.2);
  }, [setZoom]);

  const handleSave = useCallback(() => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImage() as HTMLCanvasElement;
      canvas.toBlob((blob) => {
        if (blob && props.onSave) {
          props.onSave(blob);
        }
      });
    }
  }, [editorRef.current, props.onSave]);

  /**************************************************/

  return (
    <div className={css.container}>
      <div className={css.relative}>
        <div className={css.zoom}>
          <Button onClick={handleZoomOut} className={css.zoomBtn}>
            -
          </Button>
          <Button onClick={handleResetZoom} className={css.zoomResetBtn}>
            100%
          </Button>
          <Button onClick={handleZoomIn} className={css.zoomBtn}>
            +
          </Button>
        </div>
        <AvatarEditor
          ref={editorRef}
          image={props.image}
          width={250}
          height={250}
          border={50}
          borderRadius={125}
          scale={zoom}
          rotate={rotate}
        />
      </div>
      <Button primary onClick={handleSave} className={css.submit}>
        OK
      </Button>
    </div>
  );
};
