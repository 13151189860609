import { Theme } from "lib/theme";

export const spacedContainer = (theme: Theme, value: number = 2) => ({
  spacedContainer: {
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(value),
    },
  },
});

export const centered = () => ({
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
