import React from "react";
import { makeStyles } from "hooks/makeStyles";
import { Typography, Icons } from "ui/shared";
import { fade } from "utils";

export interface ReasonItemProps {
  item: string;
  label: string;
  checked?: boolean;
  onClick: (e: React.MouseEvent, item: any) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 40,
    padding: `0 ${theme.spacing(6)} 0  ${theme.spacing(10)}`,
    cursor: "pointer",
    position: "relative",
    "&:hover": {
      backgroundColor: fade(theme.colors.background.paper.lighter, 0.6),
    },
  },
  icon: {
    position: "absolute",
    left: 0,
  },
}));

export const ReasonItem = React.memo((props: ReasonItemProps) => {
  const { item, label, checked, onClick, ...passthrough } = props;
  const css = useStyles();

  return (
    <div
      className={css.root}
      onClick={(e) => onClick(e, item)}
      {...passthrough}
    >
      {checked ? <Icons.Checkmark className={css.icon} /> : null}
      <Typography>{label}</Typography>
    </div>
  );
});
