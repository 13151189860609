import { action, observable, computed } from "mobx";
import { Meteor } from "meteor/meteor";
import { callWithPromise } from "utils";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Maybe } from "lib";
import { VIPOption } from "lib/common-interfaces/types";
import { Subscription, ToastType } from "lib";
import { toastStore } from "./toasts-store";
import { getPriceDurationInMonths, getSale } from "utils/price";

interface InitConfig {
  stripePK: string;
  products: any[];
  prices: any[];
  customerId: string;
}

interface StripeSubscriptionResponse {
  subscriptionId: string;
  clientSecret: string;
}

class VipStore {
  @observable inProgress?: boolean;
  @observable initConfig?: InitConfig;
  @observable initError?: Meteor.Error;

  @observable successfullyPaid: boolean = false;

  @observable stripe?: Maybe<Stripe>;

  @observable error?: any;

  @observable selectedSubscription?: StripeSubscriptionResponse;

  @observable loadingSubscriptions: boolean = false;
  @observable activeSubscriptions: Subscription[] = [];

  @action
  init() {
    if (this.initConfig || this.inProgress) return;
    this.inProgress = true;
    const self = this;
    Meteor.call(
      "subscriptions.stipeInitAndGetData",
      (err: Meteor.Error, res: InitConfig) => {
        if (err) {
          self.initError = err;
          return;
        }

        self.initConfig = res;

        self.loadStripe();
        this.inProgress = false;
      }
    );
  }

  @computed
  get subscriptionOptions(): VIPOption[] {
    if (!this.initConfig) return [];

    const smallestPrice = this.initConfig!.prices.find(
      (price) =>
        price.recurring.interval === "month" &&
        price.recurring.interval_count === 1
    );

    return this.initConfig.products
      .slice()
      .reverse()
      .map((product) => {
        const priceObject = this.initConfig!.prices.find(
          (price) => price.product === product.id
        );

        const duration = getPriceDurationInMonths(priceObject);
        const price = duration
          ? (priceObject.unit_amount / duration).toFixed(2)
          : priceObject.unit_amount;

        return {
          id: product.id.split("prod_")[1],
          price,
          currency: priceObject.currency,
          priceId: priceObject.id,
          oldPrice:
            smallestPrice.id === priceObject.id
              ? 0
              : smallestPrice.unit_amount * duration,
          payPrice: priceObject.unit_amount,
          sale:
            smallestPrice.id === priceObject.id
              ? 0
              : getSale(+price, smallestPrice.unit_amount),
          duration: priceObject.nickname,
          freeDays: priceObject.recurring.trial_period_days,
          active: priceObject.nickname === "vip_12_months",
        } as VIPOption;
      });
  }

  @action
  async createStripeSubscription(priceId: string) {
    if (!this.initConfig) return;

    try {
      const sub = await callWithPromise(
        "subscriptions.stipeCreateSubscription",
        { priceId }
      );
      this.selectedSubscription = sub;
    } catch (err) {
      toastStore.showToast({
        type: ToastType.Error,
        message: (err as any).message,
      });
      this.error = err;
    }
  }

  @action
  async getCustomerPortalUrl() {
    if (!this.initConfig) return;

    try {
      const url = await callWithPromise("subscriptions.stipeCustomerPortal", {
        returnUrl: `${window.location.origin}/vip`,
      });
      return url;
    } catch (err) {
      console.log(err);
      toastStore.showToast({
        type: ToastType.Error,
        message: (err as any).message,
      });
      this.error = err;
    }
  }

  @action
  setActiveSubscriptions(subs: any[]) {
    this.activeSubscriptions = subs;
  }

  @action
  setLoadingSubscriptions(value: boolean) {
    this.loadingSubscriptions = value;
  }

  selectedOptionById(optionId: string) {
    return this.subscriptionOptions.find((option) => option.id === optionId);
  }

  @action
  setSuccessfullyPaid(value: boolean) {
    this.successfullyPaid = value;
  }

  @action
  async loadStripe() {
    if (!this.initConfig) return;

    this.stripe = await loadStripe(this.initConfig.stripePK);
  }

  @action
  clear() {
    this.initConfig = undefined;
    this.initError = undefined;
    this.successfullyPaid = false;

    this.stripe = undefined;

    this.error = undefined;

    this.selectedSubscription = undefined;
    this.activeSubscriptions = [];
  }
}

const vipStore = new VipStore();

export default vipStore;
