import React from "react";
import { makeStyles } from "hooks/makeStyles";
import clsx from "clsx";

interface LayoutMessageBubbleProps {
  left?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    "&$hidden": {
      display: "none",
    },
  },
  bubble: {
    fontSize: "1.3rem",
    textTransform: "uppercase",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 120,
    borderRadius: 10,
    backgroundColor: theme.colors.background.paper.default,
    textAlign: "center",
    padding: theme.spacing(2),
    zIndex: 2,
  },
  tailBg: {
    position: "absolute",
    zIndex: 0,
    bottom: -16,
    right: ({ left }: LayoutMessageBubbleProps) => (!left ? 1 : undefined),
    left: ({ left }: LayoutMessageBubbleProps) => (left ? 5 : undefined),
    height: 30,
    width: 22,
    background: theme.colors.background.paper.default,
    borderBottomLeftRadius: 21,
    transform: ({ left }: LayoutMessageBubbleProps) =>
      left ? "scaleX(-1) rotate(-40deg)" : "rotate(-40deg)",
  },
  tailAntiBg: {
    position: "absolute",
    left: ({ left }: LayoutMessageBubbleProps) => (left ? -6 : undefined),
    bottom: -8,
    zIndex: 1,
    right: ({ left }: LayoutMessageBubbleProps) => (!left ? -9 : undefined),
    height: 30,
    width: 18,
    borderBottomLeftRadius: 9,
    backgroundColor: theme.colors.background.paper.topbar,
    transform: ({ left }: LayoutMessageBubbleProps) =>
      left ? "scaleX(-1) rotate(-9deg)" : "rotate(-9deg)",
  },
  hidden: {},
}));

export const LayoutMessageBubble = (props: LayoutMessageBubbleProps) => {
  const { left, className, children, ...passthrough } = props;
  const css = useStyles(props);

  const cx = clsx(css.container, {
    [css.hidden]: !children,
    [className!]: !!className,
  });

  return (
    <div className={cx}>
      <div className={css.bubble} {...passthrough}>
        {children}
      </div>
      <>
        <div className={css.tailBg}></div>
        <div className={css.tailAntiBg}></div>
      </>
    </div>
  );
};
