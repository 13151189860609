import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Attention = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Attention}>
    {({ color }) => (
      <path
        d="M15.4 20.3C14.5 18.1 13.5 11.5 13.5 8.79999C13.5 6.49999 14.4 5.29999 16 5.29999C17.6 5.29999 18.5 6.49999 18.5 8.79999C18.5 11.6 17.5 18.1 16.6 20.4H15.4V20.3ZM18.4 24.3C18.4 25.7 17.3 26.8 15.9 26.8C14.5 26.8 13.4 25.7 13.4 24.3C13.4 23 14.5 21.9 15.8 21.9C17.3 21.9 18.4 22.9 18.4 24.3Z"
        fill={color}
      />
    )}
  </CreateSvgIcon>
);
