import { observable } from "mobx";

export class Timer {
    constructor(private seconds: number) {}

    @observable counter = 0;
    @observable running = false;

    private _timer: NodeJS.Timeout | undefined;
    
    start(){
        if (!this.running) {
            this.running = true;
            this.counter = this.seconds;
            this.startCount();
        } else {
            this.stopTimer()
            this.start()
        }
    }

    private startCount(){
        if (this.running && this.counter > 0) {
            this._timer = setTimeout(() => {
              this.counter = (this.counter - 1);
              this.startCount();
            }, 1000);
          } else {
            this.stopTimer()
          }
    }

    private stopTimer() {
        this.running = false;
        this.counter = 0;
        clearTimeout(this._timer!);
    }
}
