import React, { useCallback } from "react";
import { makeStyles } from "hooks/makeStyles";
import { Avatar, Typography, Icons, Skeleton } from "ui/shared";
import clsx from "clsx";
import { Friend } from "lib";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { chatsStore } from "global-stores/chats-store";
import { motion } from "framer-motion";
import { ArrowKeyFocuserItemProps } from "ui/shared/arrow-key-focuser/arrow-key-focuser";

export interface ChatItemProps extends ArrowKeyFocuserItemProps<Friend> {
  loading?: boolean;
  selectedFriendId?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    boxSizing: "border-box",
    justifyContent: "space-between",

    padding: theme.spacing(4),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.colors.background.paper.topbar,
    },
    "&$active, &:focus": {
      backgroundColor: theme.colors.background.paper.topbar,
    },
    outline: "none",
  },
  flex: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  textContainer: {
    margin: `0 ${theme.spacing(4)}`,
    width: "calc(100% - 60px - 25px - 16px - 16px)",
  },
  padded: {
    marginBottom: theme.spacing(1),
    textOverflow: "ellipsis",
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "inline-block",
  },
  actionsContainer: {
    justifyContent: "space-between !important",
    alignItems: "center",
    height: "100%",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  unread: {
    width: 22,
    height: 22,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.purple.main,
    borderRadius: "50%",
  },
  active: {},
}));

export const ChatItem = React.forwardRef((props: ChatItemProps, ref: any) => {
  const { item, onClick, loading, onFocus, tabIndex, selectedFriendId } = props;
  const css = useStyles();

  const { t } = useTranslation("all");

  /**************************************************/

  const getLastMessage = useCallback(() => {
    if (item.typing) {
      return t("all:friend_typing");
    } else if (
      item.lastMessage &&
      item.lastMessage.service &&
      item.lastMessage.messageText
    ) {
      return t(`all:status_${item.lastMessage.messageText}`, {
        friend: item.friendLogin,
      });
    } else if (item.lastMessage && item._id) {
      const decrypted =
        item.lastMessage.messageText &&
        chatsStore.decryptMessage(item.lastMessage as any, item._id, {
          dropCache: true,
        });
      if (item.lastMessage.isImage) return t("all:notification_image");
      if (decrypted) {
        const isGif =
          decrypted.startsWith('{"giphy"') ||
          decrypted.startsWith('{"t":"giphy"') ||
          decrypted.startsWith('[{"t":"giphy"');
        if (isGif || item.lastMessage.isImage) return t("all:notification_gif");
        return decrypted;
      } else {
        return null;
      }
    }
  }, [item, t]);

  const handleClickChat = useCallback(
    (e: React.MouseEvent) => {
      if (onClick) {
        onClick(item);
      }
    },
    [onClick, item]
  );

  /**************************************************/

  if (loading) {
    return (
      <div
        className={clsx(css.root, {
          [css.active]: !!selectedFriendId && item._id === selectedFriendId,
        })}
      >
        <div className={css.flex}>
          <Skeleton.Circle size="medium" />
          <div className={clsx(css.columnContainer, css.textContainer)}>
            <Skeleton.Line width="60%" />
            <Skeleton.Line size="large" />
            <Skeleton.Line width="40%" size="small" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <motion.li
      role="button"
      ref={ref}
      initial={{ scaleY: 1 }}
      animate={{ scaleY: 1 }}
      exit={{ scaleY: 0 }}
      className={clsx(css.root, {
        [css.active]: !!selectedFriendId && item._id === selectedFriendId,
      })}
      onClick={handleClickChat}
      onFocus={onFocus}
      tabIndex={tabIndex}
      data-cy={`chat-item-${item.friendLogin}-container`}
    >
      <div className={css.flex}>
        <Avatar.Minified
          width={60}
          height={60}
          verified={item.level === "ver"}
          vip={item.level === "vip"}
          img={item.avatar && item.avatar.low}
          official={item.official}
        />
        <div className={clsx(css.columnContainer, css.textContainer)}>
          <Typography
            font="light"
            className={css.padded}
            data-cy={`chat-item-${item.friendLogin}-login`}
          >
            {item.friendLogin}
          </Typography>
          <Typography
            size="subtitle2(16px)"
            className={css.padded}
            color={
              (item.lastMessage && item.lastMessage.service) || item.typing
                ? "yellow"
                : "white"
            }
            data-cy={`chat-item-${item.friendLogin}-last-message`}
          >
            {getLastMessage()}
          </Typography>
          <Typography
            size="tiny(10px)"
            font="light"
            color={
              item.online || item.friendLogin === "ODI.chat"
                ? "yellow"
                : undefined
            }
          >
            {item.online || item.friendLogin === "ODI.chat"
              ? t("all:friend_online")
              : moment(item.lastActivity.date).fromNow()}
          </Typography>
        </div>
        <div
          className={clsx(css.columnContainer, {
            [css.actionsContainer]: item.friend,
          })}
        >
          {item.friend ? (
            <Icons.Star width={25} height={25} color="#655285" />
          ) : null}
          {item.newMessageCounter ? (
            <div className={css.unread}>
              <Typography
                size="caption(12px)"
                color="white"
                data-cy={`chat-item-${item.friendLogin}-new-messages-counter`}
              >
                {item.newMessageCounter}
              </Typography>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </motion.li>
  );
});
