import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const AddAvatar = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.AddAvatar}>
    {({ color }) => (
      <g>
        <g>
          <g>
            <circle cx="16" cy="16" r="13" fill="none" />
            <path
              d="M16,29A13,13,0,1,1,29,16,13,13,0,0,1,16,29ZM16,5A11,11,0,1,0,27,16,11,11,0,0,0,16,5Z"
              transform="translate(0)"
              fill="#7b8c99"
            />
          </g>
        </g>
        <polygon
          points="22 15 17 15 17 10 15 10 15 15 10 15 10 17 15 17 15 22 17 22 17 17 22 17 22 15"
          fill="#7b8c99"
        />
      </g>
    )}
  </CreateSvgIcon>
);
