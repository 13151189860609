import React, { useCallback, useLayoutEffect } from "react";
import { makeStyles, CSSProperties } from "hooks/makeStyles";
import clsx from "clsx";
import { getSizingData } from "./getSize";
import { calculateNodeHeight } from "./calculateNodeHeight";
import { useWindowResizeListener } from "hooks/useWindowResizeListener";

interface TextareaProps
  extends React.DetailsHTMLAttributes<HTMLTextAreaElement> {
  minRows?: number;
  maxRows?: number;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onHeightChange?: (height: number) => void;
  placeholder?: string;
  rightButton?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
}

const useStyles = makeStyles((theme) => ({
  textareaContainer: {
    position: "relative",
  },
  input: {
    width: "100%",
    borderColor: "transparent",
    backgroundColor: theme.colors.common.white,
    color: theme.colors.common.black,
    borderRadius: 8,
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    textAlign: "left",
    fontFamily: theme.fonts.regular,
    border: "2px solid transparent",
    "&:focus": {
      outline: "none",
      border: `2px solid ${theme.colors.primary}`,
    },
    transition: "border .2s",
    height: 42,
  },
  rightButtonContainer: {
    position: "absolute",
    top: 5,
    right: 8,
  },
}));

export const Textarea = React.forwardRef(
  (props: TextareaProps, ref: React.Ref<HTMLTextAreaElement>) => {
    const {
      value = "",
      onChange,
      onHeightChange,
      minRows = 1,
      maxRows,
      placeholder = "",
      rightButton,
      disabled,
      className,
      style,
      ...passthrough
    } = props;
    const heightRef = React.useRef(0);
    const css = useStyles();

    const cx = clsx(css.input, className);

    /**************************************************/

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e);
      },
      [onChange]
    );

    const resizeTextArea = useCallback(() => {
      if (!(ref as React.RefObject<HTMLTextAreaElement>).current) return;

      const node = (ref as React.RefObject<HTMLTextAreaElement>).current;
      const nodeSizingData = node && getSizingData(node);

      if (!node || !nodeSizingData) {
        return;
      }

      const height = calculateNodeHeight(
        nodeSizingData,
        node.value || "",
        minRows,
        maxRows
      );

      if (heightRef.current !== height) {
        heightRef.current = height;
        node.style.setProperty("height", `${height}px`, "important");
        if (typeof onHeightChange === "function") {
          onHeightChange(height);
        }
      }
    }, [heightRef, ref, minRows, maxRows, onHeightChange]);

    /**************************************************/

    if (typeof document !== "undefined") {
      React.useEffect(resizeTextArea);
    }
    useWindowResizeListener(resizeTextArea);

    useLayoutEffect(() => {
      const textAreaRef = ref as React.RefObject<HTMLTextAreaElement>;
      setTimeout(() => {
        if (textAreaRef.current) {
          textAreaRef.current.focus();
        }
      }, 1000);
    }, [ref]);

    return (
      <div className={css.textareaContainer}>
        <textarea
          ref={ref}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          placeholder={placeholder}
          className={cx}
          {...passthrough}
        />
        <span className={css.rightButtonContainer}>
          {rightButton ? rightButton : null}
        </span>
      </div>
    );
  }
);
