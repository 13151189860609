export const MODALS = {
  SETTINGS_DELETE_ACCOUNT: "SETTINGS_DELETE_ACCOUNT",
  PROFILE_EXPORT_ACCOUNT: "PROFILE_EXPORT_ACCOUNT",
  PROFILE_INVITE: "PROFILE_INVITE",
  PROFILE_INVITE_FRIENDS: "PROFILE_INVITE_FRIENDS",
  PROFILE_AVATARS: "PROFILE_AVATARS",
  PROFILE_MY_AVATARS: "PROFILE_MY_AVATARS",
  PROFILE_LANGUAGES: "PROFILE_LANGUAGES",
  PROFILE_INTERESTS: "PROFILE_INTERESTS",
  PROFILE_ABOUT: "PROFILE_ABOUT",
  LOGIN_VIDEO_STREAM: "LOGIN_VIDEO_STREAM",
  LOGIN_INVITE_CODE: "LOGIN_INVITE_CODE",
  CHAT_PARTNER_PROFILE: "CHAT_PARTNER_PROFILE",
  TOP_USER_PROFILE: "TOP_USER_PROFILE",
  CHAT_DELETE_DIALOG: "CHAT_DELETE_DIALOG",
  CHAT_FULLSIZE_MEDIA: "CHAT_FULLSIZE_MEDIA",
  AVATAR_RESIZER: "AVATAR_RESIZER",
  VIP_GUARD: "VIP_GUARD",
  HELPER: "HELPER",
  SEARCH_SET_YOUR_GENDER: "SEARCH_SET_YOUR_GENDER",
  SEARCH_SET_YOUR_AGE: "SEARCH_SET_YOUR_AGE",
  TRANSLATE_SETTINGS: "TRANSLATE_SETTINGS",
  TRANSLATE_HIDE_ALERT: "TRANSLATE_HIDE_ALERT",
  TRANSLATE_VIP_ALERT: "TRANSLATE_VIP_ALERT",
  TRANSLATE_VER_ALERT: "TRANSLATE_VER_ALERT",
  CAPTURE_PHOTO: "CAPTURE_PHOTO",
};
