import React from "react";
import { makeStyles } from "hooks/makeStyles";
import { Box, Skeleton } from "ui/shared";
import { observer } from "mobx-react";

export interface ProfileProps {}

const useStyles = makeStyles((theme) => ({
  sidebar: {
    flex: "0 1 35%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(12),
  },
  flex: {
    display: "flex",
    "& > *:first-child": {
      marginRight: theme.spacing(4),
    },
  },
  aside: {
    paddingTop: theme.spacing(8),
  },
  avatarContainer: {
    position: "relative",
  },
  avatarDropdown: {
    width: 150,
    marginTop: 4,
    display: "flex",
    justifyContent: "center",
  },
  profileInfo: {
    flex: "0 1 65%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(12),
  },
  codeContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    marginBottom: 12,
  },
  exportButton: {
    marginTop: theme.spacing(8),
  },
  field: {
    height: 40,
  },
}));

const ProfileSkeleton = observer(() => {
  const css = useStyles();

  return (
    <>
      <aside className={css.sidebar}>
        <div>
          <div className={css.avatarContainer}>
            <Skeleton.Circle width={150} height={150} />
            <div className={css.avatarDropdown}>
              <Skeleton.Circle width={32} height={32} />
            </div>
          </div>
          <div>
            <Box className={css.aside}>
              <Skeleton.Line width="20%" />
              <Box mt={2} className={css.field}>
                <Skeleton.Line size="large" width="30%" />
              </Box>
            </Box>
            <Box className={css.aside}>
              <Skeleton.Line width="20%" />
              <Box mt={2} className={css.field}>
                <Skeleton.Line size="large" width="80%" />
              </Box>
            </Box>
            <Box className={css.aside}>
              <Skeleton.Line width="20%" />
              <Box mt={2}>
                <Skeleton.Line size="large" width="30%" />
              </Box>
            </Box>
            <Box className={css.aside}>
              <Skeleton.Line width="30%" />
              <Box mt={2}>
                <Skeleton.Line size="large" width="20%" />
              </Box>
            </Box>
          </div>
        </div>
        <Skeleton.Line
          width={170}
          height={40}
          borderRadius={99}
          className={css.exportButton}
        />
      </aside>
      <section className={css.profileInfo}>
        <div>
          <Box mb={6}>
            <Skeleton.Line width={100} height={14} />
            <Box mt={2}>
              <Skeleton.Line width="50%" size="large" height={18} />
            </Box>
          </Box>
          <Box mb={6}>
            <Skeleton.Line width={200} height={14} />
            <Box mt={2}>
              <Skeleton.Line width="60%" size="large" height={18} />
            </Box>
          </Box>
          <Box mb={6}>
            <Skeleton.Line width="30%" height={14} />
            <Box mt={2}>
              <Skeleton.Line size="large" height={18} />
            </Box>
          </Box>
        </div>
      </section>
    </>
  );
});

export default ProfileSkeleton;
