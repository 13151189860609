import React, { useEffect } from "react";
import { makeStyles } from "hooks/makeStyles";
import clsx from "clsx";

export interface OverlayProps
  extends React.DetailsHTMLAttributes<HTMLDivElement> {
  visible?: boolean;
  readyToRender?: boolean;
  onEnter: () => void;
  onExit: () => void;
  useVisibility?: boolean;
  children: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: "absolute",
    zIndex: 999,
    margin: 0,
    padding: 0,
    display: "none",
    outline: "none",
    "&$visible": {
      display: "block"
    }
  },
  visible: {},
  visibility: {
    display: "block",
    visibility: "hidden",
    zIndex: -1,
    "&$visible": {
      visibility: "visible",
      zIndex: 999
    }
  }
}));

export const Overlay = React.forwardRef(
  (props: OverlayProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      visible,
      readyToRender,
      onEnter,
      onExit,
      useVisibility,
      children,
      className,
      ...passthrough
    } = props;
    const css = useStyles({ withTail: true });

    const cx = clsx(
      css.overlay,
      {
        [css.visibility]: useVisibility,
        [css.visible]: readyToRender
      },
      className
    );

    useEffect(() => {
      if (visible) {
        onEnter();
      } else {
        onExit();
      }
    }, [visible, onEnter, onExit]);

    return (
      <div
        aria-expanded={!visible}
        ref={ref}
        className={cx}
        tabIndex={0}
        {...passthrough}
      >
        {children}
      </div>
    );
  }
);
