import clsx from "clsx";
import { makeStyles } from "hooks/makeStyles";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { centered, spacedContainer } from "styles";
import { Box, Icons, Tooltip, Typography } from "ui/shared";

const useStyles = makeStyles((theme) => ({
  vipTableContainer: {
    display: "grid",
    gridTemplateColumns: "32% 70px 1fr 2fr 1fr",
    gridTemplateRows: "repeat(8, auto)",
    borderRadius: 10,
    backgroundColor: theme.colors.background.paper.topbar,
  },
  tableRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(4),
  },
  tableRowItem: {
    flex: "100%",
  },
  firstItem: {
    paddingLeft: theme.spacing(6),
    textAlign: "left",
  },
  lastItem: {
    paddingRight: theme.spacing(4),
  },
  tableHeader: {
    backgroundColor: theme.colors.background.paper.lighter,
    height: 45,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:first-child": {
      borderTopLeftRadius: 10,
    },
    "&:nth-child(5)": {
      borderTopRightRadius: 10,
    },
  },
  tooltip: {
    maxWidth: 300,
  },
  pointer: {
    cursor: "pointer",
  },
  ...centered(),
  ...spacedContainer(theme),
}));

type Align = "left" | "right" | "center" | "justify" | undefined;
type Color =
  | "black"
  | "red"
  | "white"
  | "yellow"
  | "disabled"
  | "primary"
  | "secondary"
  | undefined;

const QuestionMark = ({ title }: { title: string }) => {
  const css = useStyles();

  return (
    <Tooltip multiline preferRight title={title} overlayClassName={css.tooltip}>
      <div className={css.pointer}>
        <Icons.QuestionCircle width={20} color="#7D718B" />
      </div>
    </Tooltip>
  );
};
const checkMark = <Icons.Checkmark />;
const empty = <Icons.Dash />;

const VipTable = () => {
  const css = useStyles();
  const { t } = useTranslation("all");

  /**************************************************/

  const headerItemWrapper = useCallback(
    (
      text: string,
      align: Align = "center",
      color: Color = "white",
      fullWidth: boolean = true
    ) => {
      return (
        <Typography
          size="subtitle1(18px)"
          align={align}
          color={color}
          className={clsx({ [css.tableRowItem]: fullWidth })}
        >
          {text}
        </Typography>
      );
    },
    [css.tableRowItem]
  );

  const regularItemWrapper = useCallback(
    (text: string, align: Align = "center", color: Color = "white") => {
      return text === "v" ? (
        checkMark
      ) : text === "-" ? (
        empty
      ) : (
        <Typography
          size="body(14px)"
          align={align}
          color={color}
          className={css.tableRowItem}
        >
          {text}
        </Typography>
      );
    },
    [css.tableRowItem]
  );

  /**************************************************/

  const tableData = JSON.parse(t("all:pay_table_data_web")).map((data: any) => [
    regularItemWrapper(data["benifit"], "left"),
    <QuestionMark title={data["help"]} />,
    regularItemWrapper(data["anon"]),
    regularItemWrapper(data["ver"]),
    regularItemWrapper(data["vip"]),
  ]);

  const headerColumns = [
    headerItemWrapper(t("all:pay_table_benefits_web"), "left"),
    null,
    headerItemWrapper(t("all:pay_table_anonymous_web")),
    headerItemWrapper(t("all:pay_table_verified_web")),
    <Box className={clsx(css.centered, css.spacedContainer)}>
      {headerItemWrapper(t("all:pay_table_vip_web"), "center", "yellow", false)}
      <Icons.VIP width={21} />
    </Box>,
  ];

  return (
    <Box mt={5} pb={6} className={css.vipTableContainer}>
      <VipTableRow header columns={headerColumns} />
      {tableData.map((row: any, index: number) => (
        <VipTableRow key={`row-${index}`} columns={row} />
      ))}
    </Box>
  );
};

interface VipTableRowProps {
  columns: React.ReactNode[];
  header?: boolean;
}

const VipTableRow = ({ columns, header }: VipTableRowProps) => {
  return (
    <>
      {columns.map((column, index) => (
        <VipTableRowItem
          header={header}
          key={`header-column-${index}`}
          element={column}
          first={index === 0}
          last={index === columns.length - 1}
        />
      ))}
    </>
  );
};

interface VipTableRowItemProps {
  element: React.ReactNode;
  header?: boolean;
  first?: boolean;
  last?: boolean;
}

const VipTableRowItem = ({
  element,
  header,
  first,
  last,
}: VipTableRowItemProps) => {
  const css = useStyles();

  return (
    <Box
      className={clsx({
        [css.tableHeader]: header,
        [css.tableRow]: !header,
        [css.firstItem]: first,
        [css.lastItem]: last,
      })}
    >
      {element}
    </Box>
  );
};

export default VipTable;
