import React from "react";
import { makeStyles } from "hooks/makeStyles";
import { Typography, Icons } from "ui/shared";

export interface LanguageItemProps {
  item: string;
  label: string;
  checked?: boolean;
  onClick: (e: React.MouseEvent, item: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 40,
    padding: `0 ${theme.spacing(6)}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.colors.purple.main,
    },
  },
}));

export const LanguageItem = (props: LanguageItemProps) => {
  const { item, label, checked, onClick, ...passthrough } = props;
  const css = useStyles();

  return (
    <div
      className={css.root}
      onClick={(e) => onClick(e, item)}
      {...passthrough}
    >
      <Typography>{label}</Typography>
      {checked ? <Icons.Checkmark /> : null}
    </div>
  );
};
