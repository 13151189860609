import { Meteor } from "meteor/meteor";
import { observable, action } from "mobx";
import { i18n } from "services/i18n";

class InviteStore {
  @observable invited: boolean = false;
  @observable message: string = "";
  @observable warning: string = "";
  @observable inviteCode: string = "";
  @observable inviteCodeError?: string;

  @observable myInviteCode: string = "";
  @observable inviteTime: number = 0;
  @observable inviteTimeKarma: number = 0;
  @observable inviteBonusFromRef: number = 0;
  @observable inviteBonus: number = 0;

  _inviteRegEx = /^[a-zA-Z0-9]{4}-?[a-zA-Z0-9]{4}$/;

  @action
  setInviteCode(value: string) {
    if (value.length <= 9) {
      this.inviteCode = value;
      if (value.length >= 9) {
        this.checkInviteCode();
      } else {
        this.message = "";
        this.inviteCodeError = undefined;
        this.warning = i18n.t("all:profile_invite_msg_invalid");
        this.invited = false;
      }
      if (!value) {
        this.message = "";
        this.warning = "";
        this.inviteCodeError = undefined;
      }
    }
  }

  @action
  async checkInviteCode() {
    if (!this.inviteCode.match(this._inviteRegEx)) {
      this.message = "";
      this.warning = "";
      this.inviteCodeError = i18n.t("all:profile_invite_msg_invalid");
      return;
    }
    Meteor.call(
      "users.inviteExistCheck",
      { invite: this.inviteCode },
      (error: Meteor.Error, result: any) => {
        if (error) {
          this.inviteCodeError = i18n.t("all:profile_invite_msg_invalid");
          this.message = "";
          this.invited = false;
          return;
        }
        if (result === false) {
          this.inviteCodeError = i18n.t("all:profile_invite_msg_not_exist");
          this.message = "";
          this.warning = "";
          this.invited = false;
        } else {
          this.message = i18n.t("all:profile_invite_msg_exist");
          this.warning = "";
          this.inviteCodeError = undefined;
          this.invited = true;
        }
      }
    );
  }

  @action async getMyInviteCode() {
    const self = this;
    Meteor.call("users.getMyInvite", (error: Meteor.Error, result: any) => {
      if (!error) {
        self.myInviteCode = result;
      }
    });
  }

  @action async getInviteParams() {
    const self = this;
    Meteor.call("users.getInviteStatus", (error: Meteor.Error, result: any) => {
      if (!error) {
        self.inviteTime = result.millisecondsLeft;
        self.inviteTimeKarma = result.karmaReward;
        self.inviteBonusFromRef = result.karmaRewardFromRef;
        self.inviteBonus = result.karmaReward;
      }
    });
  }

  @action
  async activateInviteCode() {
    const self = this;

    if (this.inviteCode && !this.inviteCodeError && !this.warning) {
      Meteor.call(
        "users.inviteActivate",
        { invite: this.inviteCode },
        (error: Meteor.Error, result: any) => {
          if (!error) {
            //   storage.write("inviteCode", this.inviteCode);
          }
          self.clear();
        }
      );
    } else {
      self.clear();
    }
    this.inviteCode = "";
  }

  @action
  setInvited(value: boolean) {
    this.invited = value;
  }

  @action
  clear() {
    this.invited = false;
    this.inviteCode = "";
    this.inviteCodeError = undefined;
    this.warning = "";
    this.message = "";
  }
}

export const inviteStore = new InviteStore();
