import React from "react";
import parse, { DOMNode, domToReact } from "html-react-parser";
import { HTMLAttributes } from "react";
import { modalManager } from "global-stores/modal-manager";
import { Typography } from "ui/shared";

const EMAIL_LINK = "mailto:help@inside.me?subject=Web&body=v.0.3";

const parseHtmlString = (
  text: string,
  replaceAnchors?: boolean,
  replaceAdditionalProps?: (
    node: DOMNode
  ) => Partial<HTMLAttributes<HTMLButtonElement>>
) => {
  const options = {
    replace: (node: DOMNode) => {
      if (node.type === "tag" && node.name === "a") {
        const defaultProps = replaceAnchors
          ? {
              onClick: (e: React.MouseEvent) =>
                modalManager.push(node.attribs.href),
            }
          : null;
        const additionalProps = replaceAdditionalProps
          ? replaceAdditionalProps(node)
          : null;

        if (node.attribs.href === "feedback_form") {
          return (
            <a
              href={EMAIL_LINK}
              style={{
                outline: 0,
                textDecoration: "none",
              }}
            >
              <Typography font="light" color="yellow">
                {domToReact(node.children, options)}
              </Typography>
            </a>
          );
        }

        if (node.attribs.href.startsWith("http")) {
          return replaceAnchorsWithTargetBlank(node, {
            fontFamily: "SFUIText-Light",
            fontSize: 14,
            color: "rgb(208, 172, 0)",
            textDecoration: "none",
          });
        }

        return (
          <button
            {...defaultProps}
            {...additionalProps}
            style={{
              backgroundColor: "transparent",
              border: 0,
              cursor: "pointer",
              outline: 0,
            }}
          >
            <Typography font="light" color="yellow" size="subtitle2(16px)">
              {domToReact(node.children, options)}
            </Typography>
          </button>
        );
      } else if (node.type === "tag" && node.name === "p") {
        return (
          <Typography htmlTag="div" font="light" size="subtitle2(16px)">
            {domToReact(node.children, options)}
          </Typography>
        );
      } else if (node.type === "tag" && node.name === "h2") {
        return (
          <Typography
            font="semibold"
            size="subtitle1(18px)"
            style={{ marginBottom: 26 }}
          >
            {domToReact(node.children, options)}
          </Typography>
        );
      } else if (node.type === "tag" && node.name === "li") {
        return (
          <li style={{ fontSize: 14, marginLeft: 22, marginBottom: 10 }}>
            {domToReact(node.children, options)}
          </li>
        );
      }
    },
  };
  return parse(text, options);
};

export const replaceAnchorsWithTargetBlank = (
  node: DOMNode,
  styles?: React.CSSProperties
) => {
  if (node.type === "tag" && node.name === "a") {
    return (
      <a
        href={node.attribs.href}
        target="_blank"
        style={styles}
        rel="noopener noreferrer"
      >
        {domToReact(node.children)}
      </a>
    );
  }
};

export default parseHtmlString;
