import { Toast } from "lib";
import { uniqueId } from "lodash";
import { action, observable } from "mobx";

const DEFAULT_TICK = 4000;
const MAX_TOASTS = 3;

function toastLength(toasts: Toast[]) {
  return toasts.filter((toast) => !toast.persisted).length;
}

class ToastStore {
  @observable isRunning: boolean = false;
  @observable toasts: Toast[] = [];

  private timer?: NodeJS.Timeout;

  @action
  showToast(toast: Toast) {
    const richToast: Toast = {
      id: uniqueId("toast-"),
      ...toast,
    };
    if (toastLength(this.toasts) < MAX_TOASTS) {
      this.toasts.push(richToast);
    } else {
      this.stop();
      const indexToRemove = this.toasts.findIndex((toast) => !toast.persisted);
      const toastToRemove = this.toasts[indexToRemove];
      this.toasts = [
        ...this.toasts.filter((toast) => toast.id !== toastToRemove.id),
        richToast,
      ];
    }

    if (!this.isRunning) {
      this.run();
    }
  }

  @action
  remove(id: string) {
    this.stop();
    this.toasts = this.toasts.filter((toast) => toast.id !== id);
    this.run();
  }

  @action
  run() {
    if (toastLength(this.toasts)) {
      this.isRunning = true;
      const nextIndexToRemove = this.toasts.findIndex(
        (toast) => !toast.persisted
      );
      const nextToastToRemove = this.toasts[nextIndexToRemove];
      if (nextToastToRemove) {
        this.timer = setTimeout(() => {
          this.toasts = this.toasts.filter(
            (toast) => toast.id !== nextToastToRemove.id
          );
          this.run();
        }, nextToastToRemove.delay || DEFAULT_TICK);
      }
    } else {
      this.stop();
    }
  }

  @action
  stop() {
    this.isRunning = false;
    clearTimeout(this.timer!);
  }
}

export const toastStore = new ToastStore();
