import React from "react";
import { motion } from "framer-motion";

interface BooksProps {
  className?: string;
}

const Books = ({ className }: BooksProps) => {
  return (
    <motion.svg
      width="132"
      height="118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      initial={{
        x: 40,
        opacity: 0,
        scale: 0.8,
      }}
      animate={{
        x: 0,
        opacity: 1,
        scale: 1,
      }}
      transition={{
        duration: 0.3,
        delay: 0.1,
        type: "spring",
        damping: 10,
        stiffness: 100,
      }}
      className={className}
    >
      <path
        d="M91.534 54.714c-7.647-17.91-29.04-23.141-29.04-23.141L32.066 83.808l.043 1.565c15.09-1.128 26.735 10.812 30.023 14.883.544.253 2.078.748 2.664.911 12.256-4.987 33.466 9.868 33.466 9.868l27.816-52.79c-.181-.085-.037-1.342-.218-1.426-14.315-9.765-32.11-2.836-34.327-2.105Z"
        fill="#45385B"
      />
      <path
        d="M91.49 53.314C83.75 35.362 62.358 30.131 62.358 30.131l-30.384 53.8c15.091-1.128 26.736 10.812 30.024 14.882.543.254 2.078.749 2.664.912 12.256-4.988 33.465 9.867 33.465 9.867l27.817-52.79c-14.418-10.254-32.328-4.262-34.454-3.488Z"
        fill="#CFAB00"
      />
      <path
        d="M63.194 34.006s20.65 4.223 27.59 20.81L64.783 97.088S51.59 79.24 35.563 80.483l27.63-46.477Z"
        fill="#E4DBCA"
      />
      <path
        d="M68.52 35.599c-1.674-.67-3.523-1.201-5.323-1.6L35.566 80.477c5.624-.467 10.91 1.446 15.37 4.188 4.035.337 8.12.807 12.167 1.591 2.635-16.535 2.48-33.71 5.416-50.657Z"
        fill="#898989"
      />
      <path
        d="M90.563 54.601s17.185-6.33 31.192 3.622L96.8 104.403s-22.446-11.018-32.196-7.62L90.563 54.6Z"
        fill="#fff"
      />
      <path
        d="m56.342 78.865-38.72-5.697 9.595-58.198 37.602 5.728-8.477 58.167Z"
        fill="#655285"
      />
      <path
        d="m17.81 72.674 39.754 5.848 2.953-3.257-39.295-7.068s-3.6 2.514-3.411 4.477Z"
        fill="#E4DBCA"
      />
      <path
        d="m60.433 75.297 8.51-58.179-38.49-5.63-8.51 58.18 38.49 5.63Z"
        fill="#DBCCED"
      />
      <path
        d="M30.428 11.47s-2.55.024-3.436 3.583l-9.287 58.01.4-.145s.802-3.377 3.889-3.372l8.434-58.077Z"
        fill="#655285"
      />
    </motion.svg>
  );
};

export default Books;
