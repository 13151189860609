export const getBestTranslation = (translationResponse: any) => {
  const translatedMessage =
    translationResponse &&
    translationResponse.length &&
    translationResponse[0].translations &&
    translationResponse[0].translations.length &&
    translationResponse[0].translations[0];

  return translatedMessage;
};

export const getDetectedLang = (translationResponse: any) => {
  return (
    translationResponse &&
    translationResponse.length &&
    translationResponse[0].detectedLanguage &&
    translationResponse[0].detectedLanguage.language
  );
};
